import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
`

export const BoxPermission = styled.div`
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
`
