import styled from 'styled-components'

export const Container = styled.aside`
  background: #f2f2f2;
  box-shadow: 5px 0px 6px rgba(0, 0, 0, 0.25);
  width: 300px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
`
