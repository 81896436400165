import styled from 'styled-components'

export const CircleActive = styled.div<{ active: boolean }>`
  height: 9px;
  width: 9px;
  border-radius: 50px;
  background-color: ${({ active = false }) => (active ? '#3097E2' : '#C4C4C4')};
`

export default CircleActive
