import { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'

import IconClose from 'src/assets/icons/close.svg'
import { Modal } from 'src/components/elements'
import { Button, Input } from 'src/components/form'
import { Text } from 'src/components/helper'
import { formatCurrency, unMaskOnlyNumber } from 'src/util/helpers/string'

interface DeliveryModalProps {
  delivery: any
  onCloseHandler: () => void
  onSubmitHandler: (index: number, data: any) => void
}

function DeliveryModal({
  delivery,
  onCloseHandler,
  onSubmitHandler,
}: DeliveryModalProps) {
  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      ...(delivery.data || {}),
      price: delivery.data?.price || 0,
    },
  })

  const onChangeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(unMaskOnlyNumber(e.target.value))

    if (value) setValue('price', value / 100)
  }

  const submitHandler = (data: any) => {
    onSubmitHandler(delivery.index, data)
    onCloseHandler()
  }

  return (
    <Modal width={42}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className='flex justify-between items-center'>
          <h1 className='text-2xl font-extrabold'>
            {delivery.name ? 'Editar entrega' : 'Adicionar entrega'}
          </h1>

          <img
            src={IconClose}
            className='cursor-pointer'
            onClick={onCloseHandler}
          />
        </div>
        <hr className='mt-2' />

        <div className='mt-4'>
          <Text size={14} mb={0.4}>
            Descrição
          </Text>
          <Input
            {...register('description', { required: true })}
            id='description'
            placeholder='Descrição'
          />
        </div>

        <div className='mt-4'>
          <Text size={14} mb={0.4}>
            Preço
          </Text>
          <Input
            id='price'
            value={formatCurrency(watch('price'))}
            onChange={onChangeChange}
            placeholder='Preço'
          />
        </div>

        <div className='w-40 mt-8'>
          <Button label='Salvar' type='submit' background='#3097E2' />
        </div>
      </form>
    </Modal>
  )
}

export default DeliveryModal
