import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'

import {
  getStoreSeller,
  postAssociateUser,
  putStoreInative,
} from 'src/services/StoreService'
import useSWR, { mutate } from 'swr'

const useStore = (storeId?: string) => {
  const [storeSelected, setStoreSelected] = useState<string[]>([])
  const [openModal, setModal] = useState(false)
  const [openImportStoresModal, setImportStoresModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isAllOffer, setAllOffer] = useState(false)
  const [isModalAssociate, setIsModalAssociate] = useState(false)
  const [singleStore, setSingleStore] = useState('')

  const { data: sellers } = useSWR(
    '/sales-center/stores/sellers',
    getStoreSeller
  )

  const history = useHistory()
  const alert = useAlert()

  const toggleImportStoresModal = () => {
    setImportStoresModal((oldState) => !oldState)
  }

  const handleToogleModal = () => setModal(!openModal)

  const handleAddStore = () => history.push('/stores/addStore')

  const handleSelected = (id: string) => () => {
    const index = storeSelected.indexOf(id)
    if (index > -1)
      setStoreSelected(storeSelected.filter((item) => item !== id))
    else setStoreSelected([...storeSelected, id])
  }

  const handleAllSelected = (stores: any[]) => () => {
    setAllOffer(!isAllOffer)
    if (isAllOffer) setStoreSelected([])
    else setStoreSelected(stores.map((item) => item.pub_id))
  }

  const handleDeleteSingle = (id: string) => () => {
    setStoreSelected([id])
    setModal(!openModal)
  }

  const handleActiveStore = () => {
    return async () => {
      try {
        if (!storeId) {
          throw new Error('ID da loja é necessário para essa ação.')
        }
        setLoading(true)
        await putStoreInative(storeId, storeSelected)
        mutate('/sales-center/stores')
        alert.success('Lojas inativadas com sucesso')
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setModal(false)
        setLoading(false)
      }
    }
  }

  const handleEdit = (storeId: string) => () =>
    history.push({
      pathname: `/stores/storeDetail/${storeId}`,
    })

  const hanleModalAssociate = (storeSingleId?: string) => () => {
    setIsModalAssociate((state) => {
      if (!state && storeId && storeSingleId) {
        setLoading(true)
        setSingleStore(storeSingleId)
        setLoading(false)
      }
      return !state
    })
  }

  const handleSubmitAssociate = async (data: any) => {
    try {
      setLoading(true)
      await postAssociateUser(data)
      mutate('/sales-center/stores')
      alert.success('Usuário associado com sucesso')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
      setIsModalAssociate(false)
    }
  }

  return {
    singleStore,
    sellers,
    isModalAssociate,
    openModal,
    openImportStoresModal,
    loading,
    storeSelected,
    isAllOffer,
    toggleImportStoresModal,
    handleToogleModal,
    handleAddStore,
    handleSelected,
    handleAllSelected,
    handleDeleteSingle,
    handleActiveStore,
    hanleModalAssociate,
    handleEdit,
    handleSubmitAssociate,
  }
}

export default useStore
