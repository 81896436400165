import { SaleProps } from 'src/hooks/useSale'

const SET_DAYS = 'saleReducer/SET_DAYS'
const SET_IDENTIFIER = 'saleReducer/SET_IDENTIFIER'
const TOGGLE_ORDER = 'saleReducer/TOGGLE_ORDER'
const ADD_DETAIL = 'saleReducer/ADD_DETAIL'
const CLEAN_DETAIL = 'saleReducer/CLEAN_DETAIL'
export interface SaleReducerProps {
  days: number
  identifier: string
  order: string
  details: SaleProps | null
}

const stateInit = {
  days: 7,
  order: 'asc',
  identifier: '',
  details: null,
}

export const saleReducer = (
  state: SaleReducerProps = stateInit,
  { type, payload }: any
) => {
  switch (type) {
    case CLEAN_DETAIL:
      return { ...state, details: null }
    case ADD_DETAIL:
      return { ...state, details: payload }
    case SET_DAYS:
      return { ...state, days: payload }
    case SET_IDENTIFIER:
      return { ...state, identifier: payload }
    case TOGGLE_ORDER:
      return { ...state, order: state.order === 'asc' ? 'desc' : 'asc' }
    default:
      return state
  }
}

export const cleanSaleDetailAction = () => ({ type: CLEAN_DETAIL })

export const addSaleDetailAction = (payload: any) => ({
  type: ADD_DETAIL,
  payload,
})

export const setDaysSaleAction = (payload: number) => ({
  type: SET_DAYS,
  payload,
})

export const setIdentifierSaleAction = (payload: string) => ({
  type: SET_IDENTIFIER,
  payload,
})

export const toggleOrderAction = () => ({ type: TOGGLE_ORDER })
