import { DataRow, Header, Table, Text, View } from 'src/components/helper'

interface RackingItem {
  pub_id: string
  name: string
}

interface NewClientsProps {
  items: RackingItem[]
}

const NewClients = ({ items }: NewClientsProps) => {
  return (
    <View mt={1}>
      <Table.Table>
        <thead>
          <tr>
            <Header textAlign='left'>Nome</Header>
          </tr>
        </thead>
        <tbody>
          {items?.length > 0 ? (
            items?.map((item, idx: number) => (
              <Table.Row key={idx}>
                <DataRow>
                  <Text size={15}>{item.name}</Text>
                </DataRow>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <DataRow colSpan={6}>
                <Text size={15}>
                  <Text textAlign='center' size={14} fontWeight={500}>
                    Nenhum registro
                  </Text>
                </Text>
              </DataRow>
            </Table.Row>
          )}
        </tbody>
      </Table.Table>
    </View>
  )
}

export default NewClients
