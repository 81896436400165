import { ChangeEvent } from 'react'
import { Col, Row } from 'react-grid-system'
import Map from 'react-map-gl'
import { useDispatch } from 'react-redux'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line

import 'mapbox-gl/dist/mapbox-gl.css'

import Close from 'src/assets/icons/icone-sair.svg'
import Shop from 'src/assets/icons/icone-shop.svg'
import { Dots } from 'src/components/elements'
import { Button, Input, Range, Switch } from 'src/components/form'
import { ShadowBox, Text, View } from 'src/components/helper'
import useStoreOnboarding from 'src/hooks/useStoreOnboarding'
import {
  addOnboardingFeeAction,
  addOnboardingHasDeliveryAction,
  addOnboardingAllowFarmazonDeliveryAction,
  addOnboardingRangeAction,
  addOnboardingTimeAction,
  StoreOnboardingReducerProps,
} from 'src/redux/reducers/storeOnboardingReducer'
import { formatMoney, unMaskOnlyNumber } from 'src/util/helpers/string'

import { Leave } from './styles'

function StoredeliveryService({ data }: { data: StoreOnboardingReducerProps }) {
  const dispatch = useDispatch()
  const { createStoreLoading, handleLeave, handleCreateStore } =
    useStoreOnboarding()

  const handleDelivery = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked && !data.allow_farmazon_delivery) {
      dispatch(addOnboardingAllowFarmazonDeliveryAction(true))
    }

    dispatch(addOnboardingHasDeliveryAction(e.target.checked))
  }

  const handleFarmazonDelivery = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked && !data.self_delivery) {
      dispatch(addOnboardingHasDeliveryAction(true))
    }

    dispatch(addOnboardingAllowFarmazonDeliveryAction(e.target.checked))
  }

  const handleChangeRange = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch(addOnboardingRangeAction(parseInt(e.target.value)))

  const handleChangeDeliveryFee = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(unMaskOnlyNumber(e.target.value))

    dispatch(addOnboardingFeeAction(formatMoney(String(value))))
  }

  const handleChangeDeliveryTime = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(addOnboardingTimeAction(Number(e.target.value)))
  }

  return (
    <>
      <View flex justify='flex-end'>
        <Leave onClick={handleLeave}>
          <Text size={11}>Sair</Text>
          <img src={Close} alt='Sair' />
        </Leave>
      </View>

      <View>
        <img src={Shop} alt='Serviço' />
      </View>

      <View mt={0.2}>
        <Text color='#A70000' fontWeight='bold' size={27}>
          Serviço de entrega
        </Text>
      </View>

      <View mt={1}>
        <Text size={14}>A Farmazon faz o serviço de entrega para você!</Text>
      </View>

      <View textAlign='center' mt={1}>
        <ShadowBox>
          <View flex justify='space-between' style={{ width: '100%' }}>
            <View width='150px'>
              <Text size={13} fontWeight={500}>
                Também tenho serviço de entrega própria
              </Text>
            </View>
            <Switch onChange={handleDelivery} checked={data.self_delivery} />
          </View>
        </ShadowBox>

        <ShadowBox style={{ marginTop: '1rem' }}>
          <View flex justify='space-between' style={{ width: '100%' }}>
            <View width='150px'>
              <Text size={13} fontWeight={500}>
                Também aceito entrega farmazon
              </Text>
            </View>
            <Switch
              onChange={handleFarmazonDelivery}
              checked={data.allow_farmazon_delivery}
            />
          </View>
        </ShadowBox>
      </View>

      {data.self_delivery && (
        <>
          <View mt={1.5} style={{ alignSelf: 'center' }}>
            <Map
              initialViewState={{
                latitude: +data.lat,
                longitude: +data.lng,
                zoom: 14,
              }}
              mapStyle='mapbox://styles/mapbox/streets-v12'
              mapboxAccessToken={process.env.REACT_APP_MAPBOX}
              style={{ height: 350, width: 580 }}
            />
          </View>

          <View mt={0.6}>
            <Range
              min={1}
              max={6}
              onInput={handleChangeRange}
              defaultValue={data.self_delivery_range}
            />
          </View>

          <View mt={0.6} mb={0.6}>
            <Text color='#2D9CDB' fontWeight='bold' textAlign='center'>
              {data.self_delivery_range} km
            </Text>
          </View>

          <View mb={0.5}>
            <Text size={12}>Preço da entrega</Text>
            <Input
              value={data.self_delivery_fee}
              onChange={handleChangeDeliveryFee}
            />
          </View>
          <View mb={1}>
            <Text size={12}>
              Tempo médio de entrega <b>em minutos</b>
            </Text>
            <Input
              type='number'
              value={data.self_delivery_time}
              onChange={handleChangeDeliveryTime}
            />
          </View>
        </>
      )}

      <View style={{ marginTop: 'auto' }}>
        <Row align='center'>
          <Col xs={7}>
            <Dots length={5} active={4} />
          </Col>
          <Col xs={5}>
            <Button
              label='Finalizar'
              loading={createStoreLoading}
              background='#52C372'
              onClick={handleCreateStore}
            />
          </Col>
        </Row>
      </View>
    </>
  )
}

export default StoredeliveryService
