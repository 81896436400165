import { Route, Switch, useRouteMatch } from 'react-router'

import AddDelivery from './AddDelivery'
import AddHour from './AddHour'
import AddPhones from './AddPhones'
import AddStore from './AddStore'
import Main from './Main'
import StoreDetail from './StoreDetail'

function Store() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Main />
      </Route>
      <Route exact path={`${path}/addStore`}>
        <AddStore />
      </Route>
      <Route exact path={`${path}/addDelivery`}>
        <AddDelivery />
      </Route>
      <Route exact path={`${path}/addPhones`}>
        <AddPhones />
      </Route>
      <Route exact path={`${path}/addHour`}>
        <AddHour />
      </Route>
      <Route exact path={`${path}/storeDetail/:storeId`}>
        <StoreDetail />
      </Route>
    </Switch>
  )
}

export default Store
