import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import IconArrowLeft from 'src/assets/icons/icon-arrow-left.svg'
import IconArrowRight from 'src/assets/icons/icon-arrow-right.svg'
import Verificado from 'src/assets/icons/status-aprovado.svg'
import Rejeitado from 'src/assets/icons/status-rejeitado.svg'
import Frown from 'src/assets/images/dashboard/frown.svg'
import Store from 'src/assets/images/my-stores-black.svg'
import { DropDownMenu, LoadingSearch, MenuItem } from 'src/components/elements'
import { Button, Input, Select } from 'src/components/form'
import { DataRow, Header, Table, Text, View } from 'src/components/helper'
import { useFetchFilterNewApi } from 'src/hooks/useFetch'
import useStores from 'src/hooks/useStores'
import { formattedCreatedAt } from 'src/util/helpers/date'
import { removeEmptyString } from 'src/util/helpers/object'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

function Stores() {
  const { filter, setFilterParams, handleDeactiveStore } = useStores()

  const { register, handleSubmit } = useForm()
  const history = useHistory()

  const { data } = useFetchFilterNewApi(['/admin/stores', filter], {
    refreshInterval: 1000,
  })

  const onSubmit = handleSubmit((data: any) =>
    setFilterParams(removeEmptyString(data))
  )

  const onNextPageHandler = () => {
    if (filter.page >= data.pagination.lastPage) return

    setFilterParams({ ...filter, page: filter.page + 1 })
  }

  const onPreviousPageHandler = () => {
    if (filter.page === 1) return

    setFilterParams({ ...filter, page: filter.page - 1 })
  }

  const handleMap = () => {
    history.push('/lojas/mapa')
  }

  const handleStoreDetails = (storeId: string) => {
    return () => {
      history.push(`/lojas/detalhe/${storeId}`)
    }
  }

  return (
    <Container>
      <View pb={2}>
        <Row>
          <Col xs={12}>
            <View direction='row' align='center' justify='space-between'>
              <Text size={25} fontWeight={700}>
                Lojas
              </Text>
              <View mt={1} width='160px'>
                <Button
                  onClick={handleMap}
                  label='Mostrar mapa'
                  background='#3097E2'
                  height='40px'
                />
              </View>
            </View>

            <View mt={0.9}>
              <View style={shadowBox}>
                <form onSubmit={onSubmit}>
                  <Row>
                    <Col xs={2}>
                      <View mt={1}>
                        <Input
                          {...register('name')}
                          placeholder='Nome da loja'
                        />
                      </View>
                    </Col>
                    <Col xs={2}>
                      <View mt={1}>
                        <Select
                          {...register('state')}
                          id='state'
                          defaultValue='SP'
                        >
                          <option value='' />
                          <option value='AC'>AC</option>
                          <option value='AL'>AL</option>
                          <option value='AP'>AP</option>
                          <option value='AM'>AM</option>
                          <option value='BA'>BA</option>
                          <option value='CE'>CE</option>
                          <option value='DF'>DF</option>
                          <option value='ES'>ES</option>
                          <option value='GO'>GO</option>
                          <option value='MA'>MA</option>
                          <option value='MT'>MT</option>
                          <option value='MS'>MS</option>
                          <option value='MG'>MG</option>
                          <option value='PA'>PA</option>
                          <option value='PB'>PB</option>
                          <option value='PR'>PR</option>
                          <option value='PE'>PE</option>
                          <option value='PI'>PI</option>
                          <option value='RJ'>RJ</option>
                          <option value='RN'>RN</option>
                          <option value='RS'>RS</option>
                          <option value='RO'>RO</option>
                          <option value='RR'>RR</option>
                          <option value='SC'>SC</option>
                          <option value='SP'>SP</option>
                          <option value='SE'>SE</option>
                          <option value='TO'>TO</option>
                        </Select>
                      </View>
                    </Col>
                    <Col xs={2}>
                      <View mt={1}>
                        <Select {...register('active')} id='active'>
                          <option value=''>Todas as lojas</option>
                          <option value='true'>Ativo</option>
                          <option value='false'>Inativo</option>
                        </Select>
                      </View>
                    </Col>
                    <Col xs={3}>
                      <View mt={1}>
                        <Select {...register('limit')} id='limit'>
                          <option value=''>Selecione um limite</option>
                          {[20, 50, 100, 200].map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </Select>
                      </View>
                    </Col>
                    <Col xs={2}>
                      <View mt={1}>
                        <Button label='Filtrar' height='40px' />
                      </View>
                    </Col>
                  </Row>
                </form>
              </View>
            </View>
          </Col>
        </Row>
      </View>

      <View
        height='calc(100% - 200px)'
        style={{ ...shadowBox, overflow: 'inherit', padding: '16px 30px' }}
      >
        {!data && (
          <View height='100%' justify='center' align='center'>
            <LoadingSearch />
          </View>
        )}

        {data && (
          <View mt={1.4} style={{ height: '100%' }}>
            <View style={{ height: 'calc(100% - 80px)', overflow: 'auto' }}>
              <Table.Table id='listSale'>
                <thead>
                  <tr>
                    <Header textAlign='left'>CNPJ</Header>
                    <Header textAlign='left'>Nome</Header>
                    <Header textAlign='left'>Ativo</Header>
                    <Header textAlign='left'>PIN</Header>
                    <Header textAlign='left'>Data criação</Header>
                    <Header textAlign='left'>Forçado</Header>
                    <Header textAlign='left'>Ações</Header>
                  </tr>
                </thead>
                <tbody>
                  {data.items.map((item: any, idx: number) => (
                    <Table.Row key={idx}>
                      <DataRow>
                        <Text size={15} fontWeight={500}>
                          {item.cnpj}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>{item.name}</Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>
                          {item.active ? (
                            <img src={Verificado} />
                          ) : (
                            <img src={Rejeitado} />
                          )}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>{item?.pin ?? '-'}</Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>
                          {formattedCreatedAt(item.created_at)}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>
                          {item?.create_force ? (
                            <img src={Verificado} />
                          ) : (
                            <img src={Rejeitado} />
                          )}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <View mt={0.2} mr={0.2} cursor>
                          <DropDownMenu>
                            <MenuItem
                              source={Store}
                              label='Informações da loja'
                              onClick={handleStoreDetails(item.pub_id)}
                            />
                            <MenuItem
                              source={Frown}
                              label='Desativar Loja'
                              onClick={handleDeactiveStore(item.pub_id)}
                            />
                          </DropDownMenu>
                        </View>
                      </DataRow>
                    </Table.Row>
                  ))}
                </tbody>
              </Table.Table>
            </View>

            <View flex align='center' justify='space-between' mt={1}>
              <View>
                <Text fontWeight={600}>
                  Total: {data.pagination.total ?? '-'}
                </Text>
              </View>

              <View flex align='center'>
                <View padding={1} onClick={onPreviousPageHandler} mr={1}>
                  <img
                    style={{ opacity: filter.page === 1 ? 0.6 : 1 }}
                    src={IconArrowLeft}
                  />
                </View>

                <Text mb={0.2} fontWeight={600} size={18}>
                  {filter.page}
                </Text>

                <View padding={1} onClick={onNextPageHandler} ml={1}>
                  <img
                    style={{
                      opacity: filter.page < data.pagination.lastPage ? 1 : 0.6,
                    }}
                    src={IconArrowRight}
                  />
                </View>
              </View>
            </View>
          </View>
        )}
      </View>
    </Container>
  )
}

export default Stores
