import { forwardRef } from 'react'

import { Container, Spinner, Image, RightImage } from './styles'

interface ButtonProps extends React.InputHTMLAttributes<HTMLButtonElement> {
  background?: string
  label: any
  loading?: boolean
  type?: 'submit' | 'button' | 'reset'
  disabled?: boolean
  borderColor?: string
  icone?: any
  rightIcone?: any
  height?: string
}

function Button(
  {
    label,
    background,
    loading,
    disabled,
    type = 'submit',
    icone,
    rightIcone,
    ...rest
  }: ButtonProps,
  forwardedRef: React.Ref<HTMLButtonElement>
) {
  return (
    <Container
      {...rest}
      type={type}
      ref={forwardedRef}
      background={disabled ? '#C4C4C4' : background}
      disabled={loading || disabled}
    >
      {icone && <Image src={icone} alt='Ícone' />}
      {loading ? <Spinner /> : label}
      {rightIcone && <RightImage src={rightIcone} alt='Ícone' />}
    </Container>
  )
}

export default forwardRef(Button)
