import { CategorySortT, ProductT } from 'src/pages/Highlights/types'

import api, { newApi } from './api'

export const getAllProducts = async (params: any = { page: 1, limit: 20 }) =>
  newApi.get('/admin/products', { params })

export const putProductById = async (id: string, data: any) =>
  newApi.put(`/admin/products/${id}`, data)

export const deleteProduct = async (id: string) =>
  newApi.delete(`/admin/products/${id}`)

export const fetchProduct = async (
  url: string,
  quantity: string,
  expiration: string
) => {
  const response = await api.get(url, {
    params: { quantity, expiration },
  })
  return response.data
}

export const getSearchProductService = async (searchText: string) =>
  api.get('/products/search', {
    params: {
      q: searchText,
    },
  })

export const getHomeHighlights = async (params: any = { city: 'sao paulo' }) =>
  api.get('/products/home-highlights', {
    params,
  })

export const setHomeHighlights = async (
  category: string,
  products_categories: ProductT[]
) =>
  api.post(
    '/products/category/product-carousel-highlight',
    { products_categories },
    {
      params: {
        category,
      },
    }
  )

export const sortCategoriesHighlights = async (categories: CategorySortT[]) =>
  api.post('/products/category/carousel-highlight', { categories })

export const getSearchProductsCategories = async (searchText: string) =>
  api.get('/products/category/search', {
    params: {
      q: searchText,
    },
  })

export const getProductSetup = async () => api.get('/products/setup')
