import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div`
  position: absolute;
  background-color: #fff;
  z-index: 2;
  top: 22px;
  right: 12px;
  min-width: 10rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 20%);
  padding-top: 4px;
`

export const Backgrond = styled.div`
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const Item = styled.div`
  padding: 12px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Text = styled.p`
  margin-left: 10px;
  font-size: 12px;
`
