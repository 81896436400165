import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'

import IconClose from 'src/assets/icons/close.svg'
import { Modal } from 'src/components/elements'
import { Button, Input, Switch } from 'src/components/form'
import { Text } from 'src/components/helper'
import { formatCurrency, unMaskOnlyNumber } from 'src/util/helpers/string'

interface ProductModalProps {
  product: any
  onCloseHandler: () => void
  onSubmitHandler: (index: number, data: any) => void
}

function ProductModal({
  product,
  onCloseHandler,
  onSubmitHandler,
}: ProductModalProps) {
  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      ...(product.data || {}),
      quantity: +(product.data?.quantity || 0),
      price: product.data?.price || 0,
    },
  })

  const [notFound, setNotFound] = useState(false)

  const toggleNotFoundHandler = () => {
    setNotFound((old) => !old)
  }

  const onChangeQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('quantity', +e.target.value)
  }

  const onChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(unMaskOnlyNumber(e.target.value))

    if (value) setValue('price', value / 100)
  }

  const submitHandler = (data: any) => {
    onSubmitHandler(product.index, { ...data, notFound })
    onCloseHandler()
  }

  return (
    <Modal width={42}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className='flex justify-between items-center'>
          <h1 className='text-2xl font-extrabold'>
            {product.name ? 'Editar produto' : 'Adicionar produto'}
          </h1>

          <img
            src={IconClose}
            className='cursor-pointer'
            onClick={onCloseHandler}
          />
        </div>
        <hr className='mt-2' />

        <div className='mt-4'>
          <Text size={14} mb={0.4}>
            Nome
          </Text>
          <Input
            {...register('name', { required: true })}
            id='name'
            placeholder='Nome'
          />
        </div>

        <div className='mt-4'>
          <Text size={14} mb={0.4}>
            Quantidade
          </Text>
          <Input
            id='quantity'
            value={watch('quantity')}
            onChange={onChangeQuantity}
            placeholder='Preço'
          />
        </div>

        <div className='mt-4'>
          <Text size={14} mb={0.4}>
            Preço
          </Text>
          <Input
            id='price'
            value={formatCurrency(watch('price'))}
            onChange={onChangePrice}
            placeholder='Preço'
          />
        </div>

        <div className='mt-4'>
          <Text size={14} mb={0.4}>
            Produto não encontrado
          </Text>
          <Switch
            {...register('byPass')}
            checked={notFound}
            onChange={toggleNotFoundHandler}
          />
        </div>

        <div className='w-40 mt-8'>
          <Button label='Salvar' type='submit' background='#3097E2' />
        </div>
      </form>
    </Modal>
  )
}

export default ProductModal
