import { CSSProperties, forwardRef } from 'react'

import { Container, SelectIinput, TextError } from './styles'

interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  messageError?: string
  validationError?: any
  containerStyle?: CSSProperties
}

function Select(
  { messageError, validationError, containerStyle, ...props }: SelectProps,
  forwardedRef: React.Ref<HTMLSelectElement>
) {
  return (
    <>
      <Container style={containerStyle} error={validationError ? 1 : 0}>
        <SelectIinput ref={forwardedRef} {...props} />
        <span className='focus' />
      </Container>
      {messageError && <TextError>{messageError}</TextError>}
    </>
  )
}

export default forwardRef(Select)
