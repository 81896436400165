/* eslint-disable no-unsafe-optional-chaining */
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useAlert } from 'react-alert'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import Map, { Marker } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line

import CloseIcon from '@material-ui/icons/Close'
import 'mapbox-gl/dist/mapbox-gl.css'
import { addMinutes, format, fromUnixTime, parseISO, set } from 'date-fns'
import { parsePhoneNumber } from 'libphonenumber-js'
import Check from 'src/assets/icons/icon-check.svg'
import Reject from 'src/assets/icons/icon-rejeitado.svg'
import Search from 'src/assets/icons/icon-search.svg'
import Edit from 'src/assets/icons/icone-edit.svg'
import NotaFiscal from 'src/assets/icons/icone-nota-fiscal.svg'
import RightArrow from 'src/assets/icons/icone-right.svg'
import Aprovado from 'src/assets/icons/status-aprovado.svg'
import DeliveryDriverImage from 'src/assets/images/delivery_driver.png'
import { LoadingSearch, Modal, ModalConfirm } from 'src/components/elements'
import {
  Button,
  Input,
  InputText,
  Radio,
  Select,
  Switch,
  TextArea,
} from 'src/components/form'
import { Circle, Line, Text, View } from 'src/components/helper'
import Pin from 'src/components/map/Pin'
import { useNewFetch } from 'src/hooks/useFetch'
import useOperation from 'src/hooks/useOperation'
import useSale, { SaleDetailProps } from 'src/hooks/useSale'
import { DefaultRootState } from 'src/redux/store'
import {
  cancelDelivery,
  postProposalCheckout,
  retryDelivery,
} from 'src/services/OrderService'
import {
  OrderStatusEnum,
  AllOrderStatusEnum,
  statusValues,
} from 'src/util/constants'
import { totalPriceProduct } from 'src/util/helpers/calc'
import {
  formatCurrency,
  formatMoney,
  unformatMoney,
  unFormatPhone,
} from 'src/util/helpers/string'
import { mutate } from 'swr'

import { CloseBox, ModalContent } from '../SaleCenter/Order/styles'
import { Container, ModalMainContent, StatusBox, StatusItem } from './styles'
import { shadowBox } from 'src/styles'

import ChangePaymentModal from './ChangePaymentModal'
import EditAddressModal from './EditAddressModal'
import LogModal from './LogModal'
import UpdateDriverModal from './UpdateDriverModal'

const Detail = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const { app, store } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )

  const isSaleCenter = store.group.includes('store_sales_center')
  const { data: details } = useNewFetch<SaleDetailProps>(
    isSaleCenter
      ? `/sales-center/orders/${orderId}`
      : `/admin/orders/${orderId}`
  )
  const storeId = app?.setup?.store?.backoffice_store ?? ''
  const [observation, setObservation] = useState('')
  const alert = useAlert()

  const [byPass, setByPass] = useState(false)
  const [reason, setRease] = useState('Oferta vencida')
  const [description, setDescription] = useState('')

  const fileInputRef = useRef<HTMLInputElement>(null)

  const [cancelDeliveryLoading, setCancelDeliveryLoading] = useState(false)
  const [requestNewDriverLoading, setRequestNewDriverLoading] = useState(false)

  const [scheduleNewDeliveryFor, setScheduleNewDeliveryFor] = useState<string>()

  const [showUpdateDriverModal, setShowUpdateDriverModal] = useState(false)

  const [showTrackDriverModal, setShowTrackDriverModal] = useState(false)
  const [isLogModalOpened, setIsLogModalOpened] = useState(false)
  const [isEditAddressModalOpened, setIsEditAddressModalOpened] =
    useState(false)

  const [isVisibleChangePaymentModal, setIsVisibleChangePaymentModal] =
    useState(false)

  const {
    nfLoading,
    storeInvites,
    customerAddress,
    loadingObservation,
    openObservation,
    openModal,
    openModalCancel,
    loading,
    handleGoogleLink,
    handleOpenObservaton,
    handleSaveObservation,
    handleOpenModalDecline,
    handleAcceptOrder,
    handleToggleLoading,
    handleProgress,
    handleCancelOrder,
    handleDeclineOrder,
    handleToggleModalCancel,
    getAllUserAddress,
    handleRestartInvite,
    handleGetStoreInvites,
    handleSendInviteStore,
    handleUpdateAddressCustomer,
  } = useSale()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      byPass: false,
      nf_total: undefined,
      lat: undefined,
      lng: undefined,
      delivery_fee: formatMoney(
        String(
          (app.setup?.admin.delivery_average.store_delivery.value ?? 0) * 100
        )
      ),
      nf_file: undefined,
      proposal: undefined,
    },
  })

  const { register: register2, handleSubmit: handleSubmit2 } = useForm()

  const { register: registerInvite, handleSubmit: handleSubmitInvite } =
    useForm()

  const { getProductOption, getIconOption } = useOperation()

  useEffect(() => {
    if (
      details &&
      !watch('byPass') &&
      [5, 6, 7, 8].includes(details.state.id)
    ) {
      window.addEventListener('paste', (event: any) => {
        if (event?.clipboardData?.files?.length && fileInputRef.current) {
          const dataTransfer = new DataTransfer()
          dataTransfer.items.add(event.clipboardData.files[0])

          fileInputRef.current.files = dataTransfer.files
          setValue('nf_file', dataTransfer.files)
        }
      })
    } else {
      window.removeEventListener('paste', () => {
        //
      })
    }
  }, [details])

  useEffect(() => {
    if (details?.customer?.sub) getAllUserAddress(details?.customer?.sub)
    if (details?.pub_id) handleGetStoreInvites(orderId)
  }, [details])

  useEffect(() => {
    if (!details?.delivery_driver?.track && showTrackDriverModal) {
      setShowTrackDriverModal(false)
    }
  }, [details?.delivery_driver?.track, showTrackDriverModal])

  const handleSubmitAddress = handleSubmit2((data) => {
    handleUpdateAddressCustomer(orderId, data.address)
  })

  const handleGoogle = () => handleGoogleLink(details)

  const currentState = useCallback((idState: number, index: number) => {
    if (idState > index) return 'previous'
    else if (idState === index) return 'current'
    else return ''
  }, [])

  const handleChangeObservation = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setObservation(e.target.value)
  }

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      handleToggleLoading()

      const dataForm = new FormData()
      if (data.byPass) {
        data.nf_file = undefined
      }
      if (data?.nf_file && data?.nf_file?.length !== 0) {
        dataForm.append('nf_file', data?.nf_file[0])
      }

      const total: any = unformatMoney(data.nf_total)
      const delivery_fee: any = unformatMoney(data.delivery_fee)

      dataForm.append('nf_total', total)
      dataForm.append('bypass_tax_coupon', data.byPass)
      dataForm.append('delivery_fee', delivery_fee)
      dataForm.append('lat', data.lat)
      dataForm.append('lng', data.lng)

      const result: any = await postProposalCheckout(
        storeId,
        data.proposal,
        dataForm
      )

      if (result?.response?.status === 400) {
        throw new Error(result?.response?.data?.message)
      }

      await mutate(`/admin/orders/${orderId}`)
      alert.success('Status atualizado com sucesso')

      reset()
    } catch (error: any) {
      alert.error(error.message ?? 'Falha na chamada da api')
    } finally {
      handleToggleLoading()
    }
  })

  const handleByPass = () => setByPass((state) => !state)

  const formatMoneyInput = (event: any) => {
    const { value } = event.target
    event.target.value = formatMoney(value)
  }

  const handleReason = (reason: string) => () => setRease(reason)

  const handleDescription = (e: ChangeEvent<HTMLInputElement>) =>
    setDescription(e.target.value)

  const getPaymentoHistoryTranslation = (text: string): string => {
    const dictionary: Record<string, string> = {
      created: 'Criado',
      pre_authorization: 'Pré Autorizado',
      'Operation Successful': 'Operação Realizada',
    }

    return dictionary[text] ?? text
  }

  const getDeliveryAverage = () => {
    if (details?.schedule_info) {
      const [date, month, year] = details?.schedule_info.date.split('/')
      const [hours, minutes] = details?.schedule_info.time.split(':')

      const schedule_for = set(new Date(), {
        date: Number(date),
        month: Number(month),
        year: Number(year),
        hours: Number(hours),
        minutes: Number(minutes),
        seconds: 0,
        milliseconds: 0,
      })

      const delivery_averages = app.setup?.admin.delivery_average

      if (details.delivery_type === 'farmazon_delivery') {
        const average = addMinutes(
          schedule_for,
          delivery_averages?.farmazon?.timeOnlyNumber ?? 0
        )
        return `${Number(date)}/${month.padStart(2, '0')}/${Number(
          year
        )} - ${format(average, 'HH:mm')}`
      }

      const average = addMinutes(
        schedule_for,
        delivery_averages?.store_delivery?.timeOnlyNumber ?? 0
      )
      return `${Number(date)}/${month.padStart(2, '0')}/${Number(
        year
      )} - ${format(average, 'HH:mm')}`
    }

    return null
  }

  const isRestartInvites = useMemo(() => {
    const confirm = details?.invites?.find(
      (item: any) => item.state === 'confirm_checkout'
    )

    if (confirm && !details?.invites?.length) return false

    return (
      details?.invites
        ?.filter((item: any) => !item?.master_store)
        ?.filter((item: any) => !['expired', 'declined'].includes(item.state))
        .length === 0
    )
  }, [details?.invites])

  const isSendInvites = useMemo(() => {
    return (
      details?.invites
        ?.filter((item: any) => !item?.master_store)
        ?.filter((item: any) => ['confirm_checkout'].includes(item.state))
        .length === 0
    )
  }, [details?.invites])

  const handleSubmitInviteForm = handleSubmitInvite((data) => {
    handleSendInviteStore(orderId, data.store_id)
  })

  const handleOpenChangePaymentModal = () => {
    setIsVisibleChangePaymentModal(true)
  }

  const handleOpenLogsModal = () => {
    setIsLogModalOpened(true)
  }

  const handleCloseLogsModal = () => {
    setIsLogModalOpened(false)
  }

  const handleOpenEditAddressModal = () => {
    setIsEditAddressModalOpened(true)
  }

  const handleCloseEditAddressModal = () => {
    setIsEditAddressModalOpened(false)
  }

  const handleToggleTrackDriverModal = () => {
    setShowTrackDriverModal((old) => !old)
  }

  const cancelDeliveryHandler = async () => {
    try {
      setCancelDeliveryLoading(true)

      await cancelDelivery(orderId)
      await mutate(`/admin/orders/${orderId}`)
      alert.success('Entrega cancelada com sucesso')
    } catch (error: any) {
      alert.error(error.response.data.message ?? 'Falha na chamada da api')
    } finally {
      setCancelDeliveryLoading(false)
    }
  }

  const onChangeDeliveryScheduleFor = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setScheduleNewDeliveryFor(event.target.value)
  }

  const onRequestNewDriverHandler = async () => {
    try {
      setRequestNewDriverLoading(true)

      await retryDelivery(orderId, scheduleNewDeliveryFor)
      await mutate(`/admin/orders/${orderId}`)
      alert.success('Nova entrega solicitada com sucesso')
    } catch (error: any) {
      alert.error(error.response.data.message ?? 'Falha na chamada da api')
    } finally {
      setRequestNewDriverLoading(false)
    }
  }

  const toggleUpdateDriverModelHandler = () => {
    setShowUpdateDriverModal((old) => !old)
  }

  const nfFileIsUri = useMemo(() => {
    const file = details?.confirm_checkout?.nf_file
    return file && RegExp(/^(http|https)/).test(file)
  }, [details?.confirm_checkout?.nf_file])

  const openNfFile = () => {
    const uri = details?.confirm_checkout?.nf_file || ''
    window.open(uri, '_blank')?.focus()
  }

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View mr={1}>
            <Link to='/pedidos'>
              <Text size={18} fontWeight={300}>
                Pedido
              </Text>
            </Link>
          </View>
          <View mr={1}>
            <img src={RightArrow} alt='Próximo' />
          </View>
          <View>
            <Text size={25} fontWeight={700}>
              Pedido{' '}
              {details &&
                `#${
                  details?.identifier?.toUpperCase() ??
                  String(details?.pub_id).substr(-6)
                }`}
            </Text>
          </View>
        </View>

        <View height='calc(100% - 60px)' style={shadowBox}>
          {!details && (
            <View height='500px' justify='center' align='center'>
              <LoadingSearch />
            </View>
          )}

          {details && (
            <Row style={{ height: '480px' }}>
              <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Text fontWeight={700} color='#3097E2'>
                  Detalhes do pedido
                </Text>
                <View mt={1}>
                  {details.products.map((item, index) => (
                    <>
                      <View
                        flex
                        align='center'
                        justify='space-between'
                        mb={0.6}
                        key={index}
                      >
                        <View width='350px'>
                          <Text size={12} fontWeight={700}>
                            {item.name}
                          </Text>
                          <Text size={11} fontWeight={300} mt={1}>
                            Quantidade: {item.quantity}
                          </Text>
                        </View>
                        <View ml={1} width='7rem'>
                          <Text size={14} fontWeight={700}>
                            {(item as any)?.normal_price
                              ? formatCurrency(item.price)
                              : formatCurrency(item.max_price)}
                          </Text>
                        </View>
                      </View>

                      <View
                        direction='row'
                        justify='space-between'
                        padding={0.6}
                        width='100%'
                      >
                        <View direction='row'>
                          <img
                            src={getIconOption(
                              item.required,
                              item.substitute_products ?? []
                            )}
                            width={20}
                            height={20}
                            alt='Trocar'
                          />
                          <Text ml={1} size={14} fontWeight={500}>
                            {getProductOption(
                              item.required,
                              item.substitute_products ?? []
                            )}
                          </Text>
                        </View>
                      </View>

                      {item.substitute_products?.length > 0 && (
                        <View mt={1}>
                          <Text size={10} fontWeight={400}>
                            O cliente selecionou{' '}
                            <strong>{item.substitute_products?.length}</strong>{' '}
                            produtos substitutos para este item.
                          </Text>
                          {item.substitute_products.map((product, index) => (
                            <View
                              background='#F2F2F2'
                              padding={0.4}
                              mt={0.4}
                              flex
                              justify='space-between'
                              key={index}
                            >
                              <Text size={11} fontWeight={400}>
                                {product.name}
                              </Text>
                              <Text size={12} fontWeight={500}>
                                {formatCurrency(product.max_price)}
                              </Text>
                            </View>
                          ))}
                        </View>
                      )}

                      {item.medical_prescription && (
                        <View
                          background='#F2F2F2'
                          padding={0.4}
                          mt={0.4}
                          flex
                          justify='space-between'
                          key={index}
                        >
                          <View flex direction='row' align='center'>
                            <img
                              src={NotaFiscal}
                              alt='Receita'
                              style={{ height: 12 }}
                            />
                            <Text size={11} fontWeight={400} ml={0.4}>
                              Receita anexada
                            </Text>
                          </View>
                          <View
                            flex
                            direction='row'
                            align='center'
                            width='4rem'
                            justify='space-between'
                          >
                            <a
                              href={item.medical_prescription?.url}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <Circle>
                                <img
                                  src={Search}
                                  alt='Visualizar'
                                  style={{ height: 12 }}
                                />
                              </Circle>
                            </a>

                            <Circle color='#52C372'>
                              <img
                                src={Check}
                                alt='Postado'
                                style={{ height: 12 }}
                              />
                            </Circle>
                          </View>
                        </View>
                      )}

                      <View mt={1} mb={1}>
                        <Line />
                      </View>
                    </>
                  ))}
                </View>

                <View mt={1}>
                  <Text fontWeight={700} color='#3097E2'>
                    Observação
                  </Text>
                  <View height='5rem' mt={1}>
                    {openObservation ? (
                      <TextArea
                        rows={4}
                        defaultValue={details?.admin_observation}
                        onChange={handleChangeObservation}
                      />
                    ) : (
                      <Text size={12} fontWeight={400}>
                        {details?.admin_observation || ' Sem Observação '}
                      </Text>
                    )}
                  </View>
                  <View flex direction='row' gap={1}>
                    <Button
                      label='Salvar'
                      height='40px'
                      disabled={!openObservation}
                      onClick={handleSaveObservation(orderId, observation)}
                      loading={loadingObservation}
                    />
                    <Button
                      label={openObservation ? 'Cancelar' : 'Editar'}
                      disabled={loadingObservation}
                      height='40px'
                      background='#3097E2'
                      onClick={handleOpenObservaton}
                    />
                  </View>
                </View>

                <Row>
                  <Col xs={12} lg={6} style={{ marginTop: '1rem' }}>
                    <Button
                      label='Ver logs'
                      height='40px'
                      onClick={handleOpenLogsModal}
                    />
                  </Col>
                </Row>

                {details?.payment_info?.history && (
                  <View mt={1}>
                    <Text fontWeight={700} color='#3097E2' mb={1}>
                      Histórico de pagamento
                    </Text>
                    {details?.payment_info?.history.map((item, idx) => (
                      <View
                        flex
                        align='center'
                        justify='space-between'
                        mb={1}
                        key={idx}
                      >
                        <View flex direction='row' align='center'>
                          <img
                            src={Aprovado}
                            alt='Postado'
                            style={{ height: 20 }}
                          />
                          <View ml={1}>
                            <Text size={14} fontWeight={700}>
                              {getPaymentoHistoryTranslation(
                                item.operation_type
                              )}
                            </Text>
                            <Text size={12}>
                              {item.created_at.replaceAll('-', '/')}
                            </Text>
                          </View>
                        </View>
                        <Text size={14} fontWeight={400} mt={1}>
                          {formatCurrency(parseFloat(item.amount))}
                        </Text>
                      </View>
                    ))}
                  </View>
                )}

                {details?.delivery_history && (
                  <View mt={1}>
                    <Text fontWeight={700} color='#3097E2' mb={1}>
                      Histórico dos status da lalamove
                    </Text>
                    {details?.delivery_history?.map((item, idx) => (
                      <View
                        flex
                        align='center'
                        justify='space-between'
                        mb={1}
                        key={idx}
                      >
                        <View width='30%'>
                          <View flex direction='row'>
                            <Text size={14} fontWeight={700}>
                              Drive ID:{' '}
                            </Text>
                            <Text size={14} ml={0.2}>
                              {item.driver_id}
                            </Text>
                          </View>

                          <View flex direction='row'>
                            <Text size={14} fontWeight={700}>
                              ID:{' '}
                            </Text>
                            <Text size={14} ml={0.2}>
                              {item.id}
                            </Text>
                          </View>

                          <View flex direction='row'>
                            <Text size={14} fontWeight={700}>
                              Cidade:{' '}
                            </Text>
                            <Text size={14} ml={0.2}>
                              {item.city}
                            </Text>
                          </View>
                        </View>

                        <View width='70%'>
                          <View flex direction='row'>
                            <Text size={14} fontWeight={700}>
                              Status:
                            </Text>
                            <Text size={14} ml={0.2}>
                              {item.status}
                            </Text>
                          </View>

                          <View flex direction='row'>
                            <Text size={14} fontWeight={700}>
                              Status anterior:
                            </Text>
                            <Text size={14} ml={0.2}>
                              {item.previous_status}
                            </Text>
                          </View>

                          {item?.updated_at ? (
                            <View flex direction='row'>
                              <Text size={14} fontWeight={700}>
                                Atualizado:{' '}
                              </Text>
                              <Text size={14} ml={0.2}>
                                {format(
                                  parseISO(item.updated_at),
                                  'dd/MM - HH:mm'
                                )}
                              </Text>
                            </View>
                          ) : null}
                        </View>
                      </View>
                    ))}
                  </View>
                )}
              </Col>
              <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Text fontWeight={700} color='#3097E2'>
                  Status
                </Text>

                {![3, 4].includes(details.state.id) && (
                  <StatusBox>
                    {Object.keys(OrderStatusEnum).map((_item, index) => (
                      <StatusItem
                        color={currentState(details.state.id, index + 1)}
                        key={index}
                      />
                    ))}
                  </StatusBox>
                )}

                <Text size={18} fontWeight={700} color='#3097E2'>
                  {AllOrderStatusEnum[details.state.id]}
                </Text>

                {details?.schedule_info ? (
                  <>
                    <Text size={16} fontWeight={500}>
                      {getDeliveryAverage()}
                    </Text>
                    <Text size={11}>Previsão de entrega</Text>
                  </>
                ) : null}

                <View>
                  {loading && (
                    <View height='300px' justify='center' align='center'>
                      <LoadingSearch />
                    </View>
                  )}

                  {!loading && details?.proposal && (
                    <>
                      {[3].includes(details.state.id) && (
                        <View flex direction='row' gap={1} mt={1}>
                          {!loading && (
                            <Button
                              label='Rejeitar'
                              background='#FF0000'
                              onClick={handleOpenModalDecline}
                              height='40px'
                            />
                          )}
                          <Button
                            label='Aceitar'
                            background='#52C372'
                            height='40px'
                            loading={loading}
                            onClick={handleAcceptOrder(
                              storeId,
                              details?.proposal,
                              orderId
                            )}
                          />
                        </View>
                      )}

                      {[5, 6, 7, 8].includes(details.state.id) ? (
                        <form onSubmit={onSubmit}>
                          <View mt={1}>
                            <Text size={14} fontWeight={400}>
                              Sem Nota Fiscal
                            </Text>
                            <Switch
                              {...register('byPass')}
                              checked={byPass}
                              onChange={handleByPass}
                            />
                          </View>
                          {!byPass && (
                            <View>
                              <Text size={14} fontWeight={400}>
                                Nota Fiscal
                              </Text>
                              <Input
                                type='file'
                                id='nf_file'
                                {...register('nf_file')}
                                ref={fileInputRef}
                              />
                              {errors?.nf_file?.message && (
                                <p style={{ color: 'red' }}>
                                  {errors.nf_file.message}
                                </p>
                              )}

                              <View
                                mt={1}
                                mb={1}
                                padding={1}
                                style={{
                                  borderWidth: '1px',
                                  borderStyle: 'dotted',
                                  borderColor: '#C4C4C4',
                                  borderRadius: '4px',
                                }}
                              >
                                <Text size={12} fontWeight={400}>
                                  E possível colar o arquivo aqui
                                </Text>
                              </View>
                            </View>
                          )}

                          <Text size={14} fontWeight={400}>
                            Valor máximo:{' '}
                            <strong>
                              {formatCurrency(
                                totalPriceProduct(
                                  details.products,
                                  details?.coupon?.value
                                )
                              )}
                            </strong>
                          </Text>

                          <View mt={0.6}>
                            <Text size={14} fontWeight={400}>
                              Valor total
                            </Text>
                            <Input
                              {...register('nf_total')}
                              type='text'
                              id='nf_total'
                              onChange={formatMoneyInput}
                            />
                            {errors?.nf_total?.message && (
                              <p style={{ color: 'red' }}>
                                {errors.nf_total.message}
                              </p>
                            )}
                          </View>

                          <>
                            <View mt={0.6}>
                              <Text size={14} fontWeight={400}>
                                Taxa de entrega
                              </Text>
                              <Input
                                {...register('delivery_fee')}
                                type='text'
                                id='delivery_fee'
                                onChange={formatMoneyInput}
                              />
                            </View>

                            <View mt={0.6}>
                              <Text size={14} fontWeight={400}>
                                Latitude
                              </Text>
                              <Input
                                type='text'
                                id='lat'
                                {...register('lat')}
                              />
                              {errors?.lat?.message && (
                                <p style={{ color: 'red' }}>
                                  {errors.lat.message}
                                </p>
                              )}
                            </View>

                            <View mt={0.6}>
                              <Text size={14} fontWeight={400}>
                                Longitude
                              </Text>
                              <Input
                                type='text'
                                id='lng'
                                {...register('lng')}
                              />
                              {errors?.lng?.message && (
                                <p style={{ color: 'red' }}>
                                  {errors.lng.message}
                                </p>
                              )}
                            </View>
                          </>

                          <input
                            type='hidden'
                            {...register('proposal')}
                            value={details?.proposal}
                          />

                          <View mt={1}>
                            <Button
                              label={
                                details.state.id === 5
                                  ? 'Enviar - Checkout'
                                  : 'Alterar valor do pedido'
                              }
                              type='submit'
                              height='40px'
                              loading={nfLoading}
                            />
                          </View>
                        </form>
                      ) : null}
                    </>
                  )}

                  {[5, 6, 7, 8].includes(details.state.id) &&
                  details?.proposal ? (
                    !loading ? (
                      <View mt={1}>
                        {details.state.id < 10 && details.state.id > 5 ? (
                          <Button
                            label={OrderStatusEnum[details.state.id + 1] ?? ''}
                            height='40px'
                            background='#52C372'
                            onClick={handleProgress(
                              orderId,
                              details.state.id + 1
                            )}
                          />
                        ) : null}
                      </View>
                    ) : (
                      <View>
                        <LoadingSearch />
                      </View>
                    )
                  ) : null}

                  {!loading &&
                  [1, 2, 3, 5, 10, 11, 12].includes(details.state.id) ? (
                    <View>
                      <Button
                        label='Cancelar pedido'
                        height='40px'
                        background='#FF0000'
                        onClick={handleToggleModalCancel}
                        loading={loading}
                      />
                    </View>
                  ) : null}

                  {![4, 9].includes(details.state.id) ? (
                    <View mt={2} flex gap={4}>
                      {!loading && isRestartInvites && (
                        <Button
                          label='Reiniciar convites'
                          height='30px'
                          background='#3097E2'
                          onClick={handleRestartInvite(orderId)}
                          loading={loading}
                        />
                      )}
                      {!loading && storeInvites && isSendInvites && (
                        <div>
                          <form onSubmit={handleSubmitInviteForm}>
                            <View flex gap={1} width='250px'>
                              <Select
                                {...registerInvite('store_id', {
                                  required: true,
                                })}
                              >
                                <option value=''>Selecione uma loja</option>
                                {storeInvites.map(
                                  (item: any, index: number) => (
                                    <option value={item.pub_id} key={index}>
                                      {item.name}
                                    </option>
                                  )
                                )}
                              </Select>
                              <Button
                                label='Enviar'
                                height='30px'
                                background='#3097E2'
                              />
                            </View>
                          </form>
                        </div>
                      )}
                    </View>
                  ) : null}

                  {[6].includes(details.state.id) &&
                  details.delivery_type === 'farmazon_delivery' &&
                  details.delivery_order_id ? (
                    <View>
                      <Button
                        label='Cancelar entrega'
                        height='40px'
                        background='#3097E2'
                        onClick={cancelDeliveryHandler}
                        loading={cancelDeliveryLoading}
                      />
                    </View>
                  ) : null}

                  {[6, 7, 8].includes(details.state.id) &&
                  details.delivery_type === 'farmazon_delivery' ? (
                    <View mt={1}>
                      <Button
                        label='Atualizar dados do entregador'
                        height='40px'
                        background='#3097E2'
                        onClick={toggleUpdateDriverModelHandler}
                      />
                    </View>
                  ) : null}

                  {[6, 7].includes(details.state.id) &&
                  details.delivery_type === 'farmazon_delivery' &&
                  !details.delivery_order_id &&
                  !details.delivery_driver?.name ? (
                    <View mt={1}>
                      <View mt={1}>
                        <Line />
                      </View>

                      <Text mt={1} fontWeight={700} color='#3097E2'>
                        Solicitar nova entrega
                      </Text>

                      <View mt={1} mb={1}>
                        <Text size={14} fontWeight={400}>
                          Agendar para
                        </Text>
                        <Input
                          type='datetime-local'
                          value={scheduleNewDeliveryFor}
                          onChange={onChangeDeliveryScheduleFor}
                          disabled={details.farmazon_delivery_type === 'bkc'}
                        />
                        {details.farmazon_delivery_type === 'bkc' ? (
                          <p className='text-[11px] font-bold mt-1 text-red-main'>
                            Agendamento não é pemitido pela BKC
                          </p>
                        ) : null}
                      </View>

                      <Button
                        label='Enviar'
                        height='40px'
                        background='#3097E2'
                        onClick={onRequestNewDriverHandler}
                        loading={requestNewDriverLoading}
                      />
                    </View>
                  ) : null}
                </View>

                <View mt={1} mb={1}>
                  <Line />
                </View>

                <Text fontWeight={700} color='#3097E2'>
                  Valores
                </Text>
                <View mt={1}>
                  <View flex align='center' justify='space-between' mb={0.6}>
                    <Text size={14} fontWeight={400}>
                      Id da Transação
                    </Text>
                    <Text size={14} fontWeight={400}>
                      {details?.transaction_id}
                    </Text>
                  </View>

                  <View flex align='center' justify='space-between' mb={0.6}>
                    <Text size={14} fontWeight={400}>
                      Valor máximo dos produtos
                    </Text>
                    <Text size={14} fontWeight={400}>
                      {formatCurrency(details.max_total)}
                    </Text>
                  </View>

                  <View flex align='center' justify='space-between' mb={0.6}>
                    <View>
                      <Text size={14} fontWeight={400}>
                        Valor final dos produtos
                      </Text>
                      {nfFileIsUri ? (
                        <Text
                          onClick={openNfFile}
                          size={12}
                          fontWeight={500}
                          mt={0.2}
                          color='#3097E2'
                          style={{
                            alignSelf: 'flex-start',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          Abrir nota fiscal
                        </Text>
                      ) : null}
                    </View>
                    <Text size={14} fontWeight={400}>
                      {details.confirm_checkout?.nf_total
                        ? formatCurrency(details.confirm_checkout?.nf_total)
                        : ' - '}
                    </Text>
                  </View>

                  <View flex align='center' justify='space-between' mb={0.6}>
                    <Text size={14} fontWeight={400}>
                      Entrega
                    </Text>
                    <Text size={14} fontWeight={400}>
                      {formatCurrency(details.delivery_fee)}
                    </Text>
                  </View>

                  {details?.rate !== '' && (
                    <View flex align='center' justify='space-between' mb={0.6}>
                      <Text size={14} fontWeight={400}>
                        Taxas
                      </Text>
                      <Text size={14} fontWeight={400}>
                        {formatCurrency(parseFloat(details.rate))}
                      </Text>
                    </View>
                  )}

                  {details?.coupon !== undefined && (
                    <View flex align='center' justify='space-between' mb={0.6}>
                      <Text size={14} fontWeight={400}>
                        Desconto
                      </Text>
                      <Text size={14} fontWeight={400}>
                        {formatCurrency(details.coupon_details?.value)}
                      </Text>
                    </View>
                  )}

                  {details?.antifraude_tax !== undefined && (
                    <View flex align='center' justify='space-between' mb={0.6}>
                      <Text size={14} fontWeight={400}>
                        Taxa de Anti-Fraude
                      </Text>
                      <Text size={14} fontWeight={400}>
                        {formatCurrency(details?.antifraude_tax)}
                      </Text>
                    </View>
                  )}

                  <View flex align='center' justify='space-between' mb={0.6}>
                    <Text size={14} fontWeight={500}>
                      Forma de Pagamento
                    </Text>

                    <View flex direction='column' align='flex-end'>
                      <Text size={14} fontWeight={500}>
                        <small>
                          {details.payment_type.description === 'app'
                            ? 'Pelo aplicativo - Cartão de crédito'
                            : null}
                          {details.payment_type.description === 'pix'
                            ? 'Pelo aplicativo - PIX'
                            : null}
                          {details.payment_type.description === 'presential'
                            ? 'Na entrega'
                            : null}
                        </small>
                        {details.payment_type.description === 'presential' ? (
                          <>
                            <small>
                              {details.payment_type.mode === 'card'
                                ? ' - Maquininha (débito/crédito)'
                                : ' - Em espécie '}
                            </small>
                            {details.payment_type.mode !== 'card' &&
                              formatCurrency(
                                parseFloat(details.payment_type.change_for)
                              )}
                          </>
                        ) : null}
                      </Text>
                    </View>
                  </View>

                  <View flex align='center' justify='space-between' mb={0.6}>
                    <Text size={14} fontWeight={700}>
                      Valor total
                    </Text>
                    <Text size={14} fontWeight={700}>
                      {formatCurrency(details.total)}
                    </Text>
                  </View>

                  {details.delivery_type === 'store_delivery' &&
                  ![1, 9, 4, 12].includes(details.state.id) ? (
                    <Col xs={12} xl={8} style={{ paddingLeft: 0 }}>
                      <Button
                        onClick={handleOpenChangePaymentModal}
                        height='40px'
                        background='#3097E2'
                        label='Alterar tipo de pagamento'
                      />
                    </Col>
                  ) : null}
                </View>

                <View mt={1}>
                  <Line />
                </View>

                <View mt={0.6}>
                  <Text fontWeight={700} color='#3097E2'>
                    Origem
                  </Text>
                  <Text size={13}>{details?.origin}</Text>
                </View>

                <View mt={1} pt={0.6}>
                  <Text mb={0.6} fontWeight={700} color='#3097E2'>
                    Cliente
                  </Text>
                  <View flex direction='row' justify='space-between'>
                    <View mb={0.6}>
                      <Text size={14} fontWeight={500}>
                        Nome
                      </Text>
                      <Text size={14}>{details.customer.name}</Text>
                    </View>
                    <View mb={0.6}>
                      <Text size={14} fontWeight={500}>
                        Telefone
                      </Text>
                      <Text size={14}>
                        {details.customer.phone_number
                          ? unFormatPhone(details.customer.phone_number)
                          : ' Sem número '}
                      </Text>
                    </View>
                  </View>
                </View>

                <View mt={0.6}>
                  <Text fontWeight={700} color='#3097E2'>
                    Endereço de entrega
                  </Text>
                  <View
                    flex
                    direction='row'
                    justify='space-between'
                    align='center'
                  >
                    <View>
                      <Text size={12} fontWeight={500}>
                        {details.delivery_address.street},{' '}
                        {details.delivery_address.number},{' '}
                        {details.delivery_address?.more
                          ? details.delivery_address?.more
                          : ''}{' '}
                        - {details.delivery_address.neighborhood},{' '}
                        {details.delivery_address.city} -{' '}
                        {details.delivery_address.state}
                      </Text>
                    </View>

                    {details.state.id < 7 && (
                      <View
                        background='#3097E2'
                        height='3rem'
                        width='5rem'
                        flex
                        justify='center'
                        align='center'
                        direction='column'
                        style={{ borderRadius: 6, marginLeft: 'auto' }}
                        ml={1}
                        cursor
                        onClick={handleOpenEditAddressModal}
                      >
                        <img src={Edit} alt='Icone editar' />
                        <Text size={11} mt={0.2} color='#fff'>
                          Editar
                        </Text>
                      </View>
                    )}

                    <View
                      background='#F5B85B'
                      height='3rem'
                      width='5rem'
                      flex
                      justify='center'
                      align='center'
                      direction='column'
                      style={{ borderRadius: 6 }}
                      ml={1}
                      cursor
                      onClick={handleGoogle}
                    >
                      <svg
                        width='16'
                        height='20'
                        viewBox='0 0 16 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.75 8.5C14.75 13.75 8 18.25 8 18.25C8 18.25 1.25 13.75 1.25 8.5C1.25 6.70979 1.96116 4.9929 3.22703 3.72703C4.4929 2.46116 6.20979 1.75 8 1.75C9.79021 1.75 11.5071 2.46116 12.773 3.72703C14.0388 4.9929 14.75 6.70979 14.75 8.5Z'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M8 10.75C9.24264 10.75 10.25 9.74264 10.25 8.5C10.25 7.25736 9.24264 6.25 8 6.25C6.75736 6.25 5.75 7.25736 5.75 8.5C5.75 9.74264 6.75736 10.75 8 10.75Z'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <Text size={11} color='#fff'>
                        Mapa
                      </Text>
                    </View>
                  </View>
                  <View mt={0.6}>
                    <Text size={12} fontWeight={500}>
                      Referência
                    </Text>
                    <Text size={12} fontWeight={500}>
                      {details.delivery_address?.reference || 'Sem referência'}
                    </Text>
                  </View>

                  {details.delivery_type !== 'farmazon_delivery' &&
                    ![4, 9].includes(details.state.id) && (
                      <form onSubmit={handleSubmitAddress}>
                        <View mt={1} flex direction='row' align='center'>
                          <View width='300px'>
                            <Select
                              {...register2('address', { required: true })}
                            >
                              <option value=''>Alterar enderço</option>
                              {customerAddress.map(
                                (item: any, index: number) => (
                                  <option value={item.pub_id} key={index}>
                                    {item.nickname} - {item.street} {item.city}{' '}
                                  </option>
                                )
                              )}
                            </Select>
                          </View>
                          <View width='140px'>
                            <Button
                              type='submit'
                              label='Atualiza endereço'
                              background='#3097E2'
                              loading={loading}
                            />
                          </View>
                        </View>
                      </form>
                    )}
                </View>

                {![4].includes(details.state.id) ? (
                  <View pb={0.8} mt={0.6}>
                    <Text fontWeight={700} color='#3097E2'>
                      Entrega por:
                    </Text>
                    <Text size={14} fontWeight={400}>
                      {details.farmazon_delivery_type || 'lalamove'}
                    </Text>
                  </View>
                ) : null}

                {details?.delivery_driver?.name ? (
                  <View pb={0.8} mt={0.6}>
                    <Text fontWeight={700} color='#3097E2'>
                      Entregador:
                    </Text>

                    <View mt={1} flex direction='row' align='center'>
                      <img
                        src={
                          details?.delivery_driver?.photo || DeliveryDriverImage
                        }
                        alt='Foto entregador'
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: details?.delivery_driver?.photo
                            ? '20px'
                            : '0px',
                        }}
                      />

                      <View ml={0.6}>
                        <Text size={14} fontWeight={700}>
                          {details?.delivery_driver?.name}
                        </Text>
                        {details?.delivery_driver?.phone ? (
                          <Text size={14} fontWeight={400}>
                            {parsePhoneNumber(
                              details?.delivery_driver?.phone
                            ).format('INTERNATIONAL')}
                          </Text>
                        ) : null}
                      </View>
                    </View>

                    {details.delivery_driver?.track ? (
                      <View mt={1}>
                        <Text
                          onClick={handleToggleTrackDriverModal}
                          fontWeight={500}
                          size={13}
                          color='#3097E2'
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          Acompanhar entregador
                        </Text>
                      </View>
                    ) : null}
                  </View>
                ) : null}

                <View mt={1}>
                  <Map
                    initialViewState={{
                      latitude: +details.delivery_address.lat,
                      longitude: +details.delivery_address.lng,
                      zoom: 14,
                    }}
                    mapStyle='mapbox://styles/mapbox/streets-v12'
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                    style={{ height: 350, width: 580 }}
                  >
                    <Marker
                      key='marker'
                      longitude={+details.delivery_address.lng}
                      latitude={+details.delivery_address.lat}
                      anchor='bottom'
                    >
                      <Pin active />
                    </Marker>
                  </Map>
                </View>

                <View mt={1}>
                  <Text fontWeight={700} color='#3097E2'>
                    Histórico
                  </Text>
                  {details?.state_history?.map((item: any, idx: number) => (
                    <View
                      flex
                      align='center'
                      justify='space-between'
                      mb={0.6}
                      key={idx}
                    >
                      <Text size={14} fontWeight={400}>
                        {statusValues[item.description]}
                      </Text>
                      <Text size={14} fontWeight={400}>
                        {format(fromUnixTime(item.created_at), 'dd/MM - HH:mm')}
                      </Text>
                    </View>
                  ))}
                </View>

                <View mt={1}>
                  <Text fontWeight={700} color='#3097E2'>
                    Convites
                  </Text>
                  {details?.invites &&
                    details?.invites?.map((item: any, idx: number) => (
                      <View
                        flex
                        align='center'
                        justify='space-between'
                        mb={0.6}
                        key={idx}
                      >
                        <Text size={14} fontWeight={400}>
                          {item.name}
                        </Text>
                        <Text size={14} fontWeight={400}>
                          {item?.state}
                        </Text>
                        <Text size={14} fontWeight={400}>
                          {item?.distance
                            ? `${Math.round(item?.distance)} m `
                            : 'Indisponível'}
                        </Text>
                      </View>
                    ))}
                </View>
              </Col>
            </Row>
          )}
        </View>
      </Container>

      {openModalCancel && (
        <ModalConfirm
          icon={Reject}
          loading={loading}
          description='Tem certeza que deseja cancelar o pedido?'
          labelCancel='Não'
          handleCancel={handleToggleModalCancel}
          labelConfirm='Sim'
          backgroundConfirm='#FF0000'
          handleConfirm={handleCancelOrder(orderId)}
        />
      )}

      {details && openModal && details?.proposal && (
        <Modal paddingTop={3} width={20} padding={0}>
          <ModalMainContent>
            <View flex direction='column' height='29rem'>
              <View mt={1} mb={1} flex justify='center'>
                <img src={Reject} alt='Direita' />
              </View>
              <Text textAlign='center' fontWeight={700} color='#FF0000'>
                Pedido rejeitado
              </Text>
              <Text textAlign='center' size={13} mt={0.6}>
                Qual o motivo que fez você rejeitar este pedido?
              </Text>

              <View style={{ right: 20 }}>
                <View height='38px'>
                  <Radio
                    checked={reason === 'Oferta vencida'}
                    onClick={handleReason('Oferta vencida')}
                    readOnly
                  />
                  <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                    Oferta vencida
                  </Text>
                </View>
                <View height='38px'>
                  <Radio
                    checked={reason === 'Preços abaixo da tabela'}
                    onClick={handleReason('Preços abaixo da tabela')}
                    readOnly
                  />
                  <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                    Preços abaixo da tabela
                  </Text>
                </View>
                <View height='38px'>
                  <Radio
                    checked={reason === 'Sem estoque'}
                    onClick={handleReason('Sem estoque')}
                    readOnly
                  />
                  <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                    Sem estoque
                  </Text>
                </View>
                <View height='38px'>
                  <Radio
                    checked={reason === 'Fora da área de entrega'}
                    onClick={handleReason('Fora da área de entrega')}
                    readOnly
                  />
                  <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                    Fora da área de entrega
                  </Text>
                </View>
                <View height='38px'>
                  <Radio
                    checked={reason === 'Outro'}
                    onClick={handleReason('Outro')}
                    readOnly
                  />
                  <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                    Outro
                  </Text>
                  {reason === 'Outro' && (
                    <View mt={1} ml={1}>
                      <InputText
                        label='descreva o motivo:'
                        value={description}
                        onChange={handleDescription}
                      />
                    </View>
                  )}
                </View>
              </View>

              <View flex style={{ marginTop: 'auto' }}>
                <Button
                  label='Enviar'
                  background='#3097E2'
                  loading={loading}
                  onClick={handleDeclineOrder(
                    storeId,
                    details.proposal,
                    { reason, description },
                    orderId
                  )}
                />
              </View>
            </View>
          </ModalMainContent>
        </Modal>
      )}

      {isVisibleChangePaymentModal && details ? (
        <ChangePaymentModal
          orderId={details.pub_id}
          onClose={() => setIsVisibleChangePaymentModal(false)}
          payment={details.payment_type}
          isVisible={isVisibleChangePaymentModal}
          minChangeFor={details.total}
        />
      ) : null}

      {isLogModalOpened ? (
        <LogModal orderId={orderId} onClose={handleCloseLogsModal} />
      ) : null}

      {isEditAddressModalOpened && details && details.delivery_address ? (
        <EditAddressModal
          orderId={orderId}
          address={details.delivery_address}
          onClose={handleCloseEditAddressModal}
        />
      ) : null}

      {showTrackDriverModal && details && details?.delivery_driver?.track ? (
        <Modal paddingTop={8} width={50} padding={0}>
          <CloseBox onClick={handleToggleTrackDriverModal}>
            <CloseIcon style={{ fill: '#474747' }} />
          </CloseBox>
          <ModalContent>
            <iframe
              style={{ width: '100%', height: '600px', marginTop: '1rem' }}
              src={details.delivery_driver.track}
              allow="geolocation 'src';"
            />
          </ModalContent>
        </Modal>
      ) : null}

      {showUpdateDriverModal ? (
        <UpdateDriverModal
          orderId={orderId}
          onClose={toggleUpdateDriverModelHandler}
        />
      ) : null}
    </>
  )
}

export default Detail
