import { LOGS } from 'src/constants/log'

import api from './api'

interface CreateLogParams {
  orderId: string
  log: keyof typeof LOGS
}

export const createLog = async ({ orderId, log }: CreateLogParams) => {
  const payload = LOGS[log]

  return api
    .post(`/orders/insert-log/${orderId}`, payload)
    .then(() => {
      console.log('Log created successfully')
    })
    .catch((error) => {
      console.error(error.response?.data?.message)
    })
}

export const getLogs = async (orderId: string) =>
  api.get(`/orders/retrieve-log/${orderId}`)
