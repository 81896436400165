import { Route, Switch, useParams, useRouteMatch } from 'react-router'

import { LoadingSearch } from 'src/components/elements'
import { View } from 'src/components/helper'
import { useNewFetch } from 'src/hooks/useFetch'
import { getSWRAllBankAccount } from 'src/services/CardsService'

import AddBank from './AddBank'
import EmptyUser from './EmptyBank'
import ListBank from './ListBank'

function Banks() {
  const { storeId } = useParams<any>()
  const { data } = useNewFetch(
    `/stores/${storeId}/banking-accounts`,
    getSWRAllBankAccount
  )
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        {!data ? (
          <View height='100%' justify='center' align='center'>
            <LoadingSearch />
          </View>
        ) : (
          data && (data.length > 0 ? <ListBank /> : <EmptyUser />)
        )}
      </Route>
      <Route path={`${path}/addBank/:userId?`}>
        <AddBank />
      </Route>
    </Switch>
  )
}

export default Banks
