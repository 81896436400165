import { useParams } from 'react-router'

import EmptyBankImage from 'src/assets/images/empty-bank.svg'
import { Button } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useBank from 'src/hooks/useBank'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

const EmptyBank = () => {
  const { storeId } = useParams<any>()
  const { handleNavegateAdd } = useBank(storeId)

  return (
    <Container>
      <View pb={2}>
        <Text size={25} fontWeight={700}>
          Dados bancários
        </Text>
      </View>
      <View
        flex
        justify='center'
        align='center'
        height='calc(100% - 70px)'
        style={shadowBox}
      >
        <View width='300px' justify='center' direction='column'>
          <View pb={2} textAlign='center'>
            <img src={EmptyBankImage} alt='Dados bancários vazio' />
          </View>
          <View pb={2}>
            <Text textAlign='center'>
              Você não possui nenhuma conta cadastrada.
            </Text>
          </View>
          <Button
            label='Cadastrar conta'
            background='#3097E2'
            onClick={handleNavegateAdd}
          />
        </View>
      </View>
    </Container>
  )
}
export default EmptyBank
