import {
  eachHourOfInterval,
  format,
  fromUnixTime,
  getUnixTime,
  isToday,
  set,
} from 'date-fns'
import { DATE_CURRENT } from 'src/redux/reducers/onboardingReducer'

export const formatDate = (date: string) => {
  const dArr = date.split('-')
  return dArr[2] + '/' + dArr[1] + '/' + dArr[0]
}

const start = set(DATE_CURRENT, {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
})

const end = set(DATE_CURRENT, {
  hours: 23,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
})

const interval = eachHourOfInterval(
  {
    start,
    end,
  },
  { step: 1 }
)

export const hours = interval.map((item) => ({
  label: format(item, "H'h"),
  value: getUnixTime(item),
}))

export const formattedCreatedAt = (
  createdAt: number | Date,
  pattern?: string
) => {
  const parsed =
    typeof createdAt === 'number'
      ? fromUnixTime(createdAt)
      : new Date(createdAt)

  if (pattern) {
    return format(parsed, pattern)
  }

  if (isToday(parsed)) {
    return `Hoje, ${format(parsed, 'HH:mm')}`
  }
  return format(parsed, 'dd/MM/yyyy HH:mm')
}
