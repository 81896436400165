import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useLocation, useParams, withRouter } from 'react-router'
import { Link, useHistory } from 'react-router-dom'

import EmptyProduct from 'src/assets/images/empty-product-medium.svg'
import { Button, Input } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useProduct from 'src/hooks/useProduct'

import { columnStyle, Container, FormContent, shadowBox } from './styles'

function AddProduct() {
  const { productId } = useParams<{ productId: string }>()
  const history = useHistory()
  const { state } = useLocation<any>()
  const { register, handleSubmit } = useForm()

  const { loading, handleUpdateProduct } = useProduct()

  const handleCancel = () => {
    history.goBack()
  }

  const handleSubmitForm = handleSubmit((data) => {
    handleUpdateProduct(productId, data)
  })

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View mr={1}>
            <Link to='/products'>
              <Text size={18} fontWeight={300}>
                Produtos
              </Text>
            </Link>
          </View>
        </View>
        <form onSubmit={handleSubmitForm}>
          <View height='calc(100% - 150px)' style={shadowBox}>
            <Row style={{ height: '100%' }}>
              <Col xs={4}>
                <View justify='center'>
                  <img
                    src={state.img || EmptyProduct}
                    alt='Produto'
                    height={260}
                    width={260}
                  />
                </View>
              </Col>
              <Col xs={8} style={columnStyle}>
                <View ml={1}>
                  <FormContent>
                    <View>
                      <Text fontWeight={500} size={12}>
                        Nome
                      </Text>
                      <View direction='row' align='center' mt={0.8}>
                        <Input
                          type='text'
                          {...register('name', { required: true })}
                          defaultValue={state?.name}
                          style={{ width: 400 }}
                        />
                      </View>
                    </View>
                  </FormContent>
                </View>
                <View ml={1}>
                  <FormContent>
                    <View>
                      <Text fontWeight={500} size={12}>
                        Preço:
                      </Text>
                      <View direction='row' align='center' mt={0.8}>
                        <Text mr={1}>R$</Text>
                        <Input
                          type='text'
                          {...register('max_price', {
                            required: true,
                            valueAsNumber: true,
                          })}
                          maxLength={10}
                          defaultValue={state?.max_price}
                        />
                      </View>
                    </View>
                  </FormContent>
                </View>
                <Input
                  type='hidden'
                  {...register('min_price', {
                    required: true,
                    valueAsNumber: true,
                  })}
                  maxLength={10}
                  defaultValue={state?.min_price}
                />
                {/* <View ml={1}>
                  <FormContent>
                    <View>
                      <Text fontWeight={500} size={12}>Preço mínimo:</Text>
                      <View direction='row' align='center' mt={0.8}>
                        <Text mr={1}>R$</Text>

                      </View>
                    </View>
                  </FormContent>
                </View> */}
                <View ml={1}>
                  <FormContent>
                    <View>
                      <Text fontWeight={500} size={12}>
                        Descrição:
                      </Text>
                      <View direction='row' align='center' mt={0.8}>
                        <textarea
                          {...register('description', { required: true })}
                          defaultValue={state?.description}
                          style={{ width: 500, height: 160 }}
                        />
                      </View>
                    </View>
                  </FormContent>
                </View>
              </Col>
            </Row>
          </View>

          <View flex justify='flex-end' mt={2}>
            <View width='50%' direction='row'>
              <View width='100%' mr={1}>
                <Button
                  label='Cancelar'
                  borderColor='#3097E2'
                  color='#3097E2'
                  background='#fff'
                  onClick={handleCancel}
                  type='reset'
                />
              </View>
              <View width='100%'>
                <Button
                  label={productId ? 'Editar produto' : 'Cadastrar produto'}
                  background='#3097E2'
                  loading={loading}
                />
              </View>
            </View>
          </View>
        </form>
      </Container>
    </>
  )
}

export default withRouter(AddProduct)
