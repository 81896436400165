import { Col, Row } from 'react-grid-system'
import { PuffLoader } from 'react-spinners'

import { Button } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useHome from 'src/hooks/useHome'
import { formatCurrency } from 'src/util/helpers/string'

import { shadowBox } from 'src/styles'

import NewClients from './NewClients'
import RankingUsers from './RakingUsers'

function Main() {
  const {
    loading,
    clientsRanking,
    quantityOfNewUsers,
    quantityOfLostedUsers,
    ticketAvarage,
    latestSale,
    avarageDeliveryTime,
    newClients,
    handleLoadInformations,
  } = useHome()

  if (loading) {
    return (
      <View width='100%' flex height='100%' justify='center' align='center'>
        <PuffLoader color='#A70000' />
      </View>
    )
  }

  return (
    <Col>
      <Row>
        <Col xs={12}>
          <View style={{ overflow: 'scroll' }}>
            <Col xs={12} lg={4} xl={3}>
              <Button
                type='button'
                onClick={handleLoadInformations}
                label='Atualizar dados'
              />
            </Col>

            <View mt={1} flex direction='row' className='kpis_grid'>
              <View style={shadowBox}>
                <Text size={18} fontWeight={900}>
                  Ticket médio
                </Text>
                <hr />

                <Text mt={1}>{formatCurrency(ticketAvarage)}</Text>
              </View>

              <View style={shadowBox}>
                <Text size={18} fontWeight={900}>
                  Quantidade de usuários novos
                </Text>
                <hr />

                <Text mt={1}>{quantityOfNewUsers}</Text>
              </View>

              <View style={shadowBox}>
                <Text size={18} fontWeight={900}>
                  Quantidade de usuários perdidos
                </Text>
                <hr />

                <Text mt={1}>{quantityOfLostedUsers}</Text>
              </View>

              <View style={shadowBox}>
                <Text size={18} fontWeight={900}>
                  Valor da ultima venda
                </Text>
                <hr />

                <Text mt={1}>{formatCurrency(latestSale)}</Text>
              </View>

              <View style={shadowBox}>
                <Text size={18} fontWeight={900}>
                  Tempo médio de entrega
                </Text>
                <hr />

                <Text mt={1}>{avarageDeliveryTime}</Text>
              </View>
            </View>

            <View mt={1} flex direction='row' className='kpis_grid'>
              <View style={shadowBox}>
                <Text size={18} fontWeight={900}>
                  Ranking usuários
                </Text>
                <hr />

                <RankingUsers items={clientsRanking} />
              </View>

              <View style={shadowBox}>
                <Text size={18} fontWeight={900}>
                  Últimos clientes registrados
                </Text>
                <hr />

                <NewClients items={newClients} />
              </View>
            </View>
          </View>
        </Col>
      </Row>
    </Col>
  )
}

export default Main
