import IconeRelogio from 'src/assets/icons/icone-relogio.svg'
import { Text, View } from 'src/components/helper'

import { Container } from './styles'

interface CardRequestProps {
  title: string
  time: string
  price: string
  color: string
  onClick?: () => void
  cursor?: boolean
}

function CardRequest({
  title,
  time,
  price,
  color,
  onClick,
  cursor,
}: CardRequestProps) {
  return (
    <Container
      color={color}
      onClick={onClick}
      style={{ cursor: cursor ? 'pointer' : '' }}
    >
      <View>
        <Text fontWeight={700} size={14}>
          {title}
        </Text>
        <View flex direction='row' align='center' mt={0.6}>
          <img src={IconeRelogio} alt='Relógio' />
          <Text ml={0.4} fontWeight={300} size={10}>
            {time}
          </Text>
        </View>
      </View>
      <View>
        <Text fontWeight={300} size={8}>
          Valor total
        </Text>
        <View mt={0.5}>
          <Text fontWeight={700} size={18}>
            {price}
          </Text>
        </View>
      </View>
    </Container>
  )
}

export default CardRequest
