const FIRST_MODAL_ACCESS = 'appReducer/FIRST_MODAL_ACCESS'
const ADD_SETUP = 'appReducer/ADD_SETUP'
const ENABLE_NOTIFICATION = 'appReducer/ENABLE_NOTIFICATION'
const DISABLE_NOTIFICATION = 'appReducer/DISABLE_NOTIFICATION'
const ENABLE_MAIN_MODAL = 'appReducer/ENABLE_MAIN_MODAL'
const DISABLE_MAIN_MODAL = 'appReducer/DISABLE_MAIN_MODAL'
interface SetupProps {
  default_range: number
  admin: any
  default_opening_hour: any[]
  antifraude_tax: number
  customer: any
  max_range: number
  max_total_percentage: number
  pub_id: string
  order_proposal_timer: number
  store: {
    banks: Array<{
      name: string
      cod: string
    }>
    android: any
    backoffice_store: string
    terms_of_use: {
      version: string
      url: string
    }
    ios: any
    static_pages: any
    delivery_fee: number
    account_types: any[]
    rate: string
    terms_url: string
    update_uri: string
    welcome: any
    maintenence: any
  }
}

export interface AppReducerProps {
  firstModal: boolean
  loading: boolean
  setup: SetupProps | null
  enableNotification: boolean
  openMainModal: boolean
}

const stateInit = {
  firstModal: true,
  loading: false,
  setup: null,
  enableNotification: false,
  idGeneric: '',
  mainModalScreen: '',
  openMainModal: false,
}

export const appReducer = (
  state: AppReducerProps = stateInit,
  { type, payload }: any
) => {
  switch (type) {
    case ENABLE_MAIN_MODAL:
      return { ...state, openMainModal: true }
    case DISABLE_MAIN_MODAL:
      return { ...state, openMainModal: false }
    case FIRST_MODAL_ACCESS:
      return { ...state, firstModal: false }
    case ADD_SETUP:
      return { ...state, setup: payload }
    case ENABLE_NOTIFICATION:
      return { ...state, enableNotification: true }
    case DISABLE_NOTIFICATION:
      return { ...state, enableNotification: false }
    default:
      return state
  }
}

export const enableMainModalAction = () => ({ type: ENABLE_MAIN_MODAL })

export const disableMainModalAction = () => ({ type: DISABLE_MAIN_MODAL })

export const enableNotificationAction = () => ({ type: ENABLE_NOTIFICATION })

export const disableNotificationAction = () => ({ type: DISABLE_NOTIFICATION })

export const setFirstModalAction = () => ({ type: FIRST_MODAL_ACCESS })

export const addSetupDataAction = (payload: SetupProps) => ({
  type: ADD_SETUP,
  payload,
})
