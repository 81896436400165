import { Container, Text } from './styles'

const AlertTemplate: React.FC = ({ message, options }: any) => {
  return (
    <Container type={options.type}>
      <Text type={options.type}>{message}</Text>
    </Container>
  )
}

export default AlertTemplate
