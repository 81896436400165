import styled from 'styled-components'

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  border: none;
`
