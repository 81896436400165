import styled from 'styled-components'

interface ContainerProps {
  paddingTop?: number
}

export const Container = styled.div<ContainerProps>`
  display: block;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: ${({ paddingTop = 15 }) => `${paddingTop}%`};
`

export const Backgrond = styled.div`
  display: block;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`

interface ContentProps {
  width?: number
  padding?: number
}

export const Content = styled.div<ContentProps>`
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: auto;
  padding: ${({ padding = 20 }) => `${padding}px`};
  border: 1px solid #888;
  width: ${({ width = 25 }) => `${width}rem`};
  position: absolute;
  z-index: 110;
  right: 50%;
  transform: translate(50%, 0);
`
