import { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { format, fromUnixTime } from 'date-fns'
import Alert from 'src/assets/icons/icon-alert.svg'
import IconArrowLeft from 'src/assets/icons/icon-arrow-left.svg'
import IconArrowRight from 'src/assets/icons/icon-arrow-right.svg'
import Nota from 'src/assets/icons/icone-nota-fiscal.svg'
import Rejeitado from 'src/assets/icons/status-rejeitado.svg'
import Log from 'src/assets/images/log.svg'
import { CardRequest, LoadingSearch, Modal } from 'src/components/elements'
import { Button, Input, Select } from 'src/components/form'
import { DataRow, Header, Table, Text, View } from 'src/components/helper'
import { useFetch, useFetchFilterNewApi } from 'src/hooks/useFetch'
import useOperation from 'src/hooks/useOperation'
import useOrder from 'src/hooks/useOrder'
import useSale from 'src/hooks/useSale'
import { DefaultRootState } from 'src/redux/store'
import { statusValues } from 'src/util/constants'
import { formattedCreatedAt } from 'src/util/helpers/date'
import { removeEmptyString } from 'src/util/helpers/object'
import { formatCurrency } from 'src/util/helpers/string'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

import LogModal from './LogModal'
import MainModal from './MainModal'

function Sales() {
  const { app, store } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )
  const storeId = app?.setup?.store?.backoffice_store ?? ''
  const isSaleCenter = store.group.includes('store_sales_center')
  const orderIdRef = useRef('')
  const history = useHistory()

  const [orderIdToSeeLogs, setOrderIdToSeeLogs] = useState<string>()

  const [warning, setWarning] = useState(false)

  const { register, handleSubmit } = useForm()
  const { filter, setFilterParams, setValuesSaved, getValuesSaved } = useOrder()

  const { data } = useFetchFilterNewApi(
    [isSaleCenter ? '/sales-center/orders' : '/admin/orders', filter],
    { refreshInterval: 1000 }
  )
  const { data: dataStore } = useFetch(
    isSaleCenter
      ? null
      : `/orders/admin/proposals/${storeId}/?only=new proposal`,
    {},
    { refreshInterval: 2000 }
  )

  useEffect(() => {
    if (data) {
      const newData = data.items
        .filter((item: any) => item.attention)
        .map((item: any) => item.pub_id)

      const dataSaved = getValuesSaved()
      if (dataSaved == null) {
        setValuesSaved(newData)
      } else {
        const saved = JSON.parse(dataSaved)
        const intersection = newData.filter((id: string) => {
          saved.includes(id)
        })
        if (intersection.length > 0) {
          setWarning(true)
          orderIdRef.current = intersection[0]
          setValuesSaved(newData)
        }
      }
    }
  }, [data])

  const { orderColors, proposalStatus } = useOperation()

  const { loading, handleOrderModalDetails } = useSale()

  const onNextPageHandler = () => {
    const page = +filter.page || 1
    if (page >= +data.pagination.lastPage) return
    console.log(page + 1)
    setFilterParams({ ...filter, page: page + 1 })
  }

  const onPreviousPageHandler = () => {
    const page = +filter.page || 1
    if (page === 1) return
    setFilterParams({ ...filter, page: page - 1 })
  }

  const handleNewOrder = (orderId: string, proposalId: string) => async () =>
    handleOrderModalDetails(orderId, proposalId, storeId)

  const onSubmit = handleSubmit((data: any) =>
    setFilterParams(removeEmptyString(data))
  )

  const handleModalWarning = () => {
    setWarning((state) => !state)
  }

  const handleDetailOrder = () => {
    history.push(`/pedidos/detalhe/${orderIdRef.current}`)
  }

  const handleOpenLogModal = (id: string) => {
    return () => {
      setOrderIdToSeeLogs(id)
    }
  }

  const handleCloseLogsModal = () => {
    setOrderIdToSeeLogs(undefined)
  }

  return (
    <>
      <Container>
        <View pb={2}>
          <Row>
            <Col xs={isSaleCenter ? 12 : 7}>
              <Text size={25} fontWeight={700}>
                Pedidos
              </Text>

              <View mt={0.9}>
                <View style={shadowBox}>
                  <form onSubmit={onSubmit}>
                    <Row>
                      <Col xs={6}>
                        <Input
                          {...register('index')}
                          type='text'
                          id='index'
                          placeholder='Número do pedido'
                        />
                      </Col>

                      <Col xs={6}>
                        <Input
                          {...register('phone')}
                          type='text'
                          id='phone'
                          placeholder='Exemplo: +5511999999999'
                        />
                      </Col>

                      <Col xs={6}>
                        <View mt={1}>
                          <Select {...register('state')} id='state'>
                            <option value=''>Todos os status</option>
                            {Object.entries(statusValues).map((item, index) => (
                              <option value={item[0]} key={index}>
                                {item[1]}
                              </option>
                            ))}
                          </Select>
                        </View>
                      </Col>

                      <Col xs={6}>
                        <View mt={1}>
                          <Select {...register('limit')} id='limit'>
                            <option value=''>Selecione um limite</option>
                            {[10, 20, 30, 40, 50].map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </Select>
                        </View>
                      </Col>

                      <Col xs={6} />

                      <Col xs={6}>
                        <View mt={1}>
                          <Button label='Filtrar' height='40px' />
                        </View>
                      </Col>
                    </Row>
                  </form>
                </View>
              </View>
            </Col>
            {isSaleCenter ? null : (
              <Col xs={5}>
                <Text size={25} fontWeight={700}>
                  Pedidos back office
                </Text>
                <View
                  height='240px'
                  style={{ overflowY: 'auto', paddingRight: 10 }}
                >
                  {dataStore && dataStore.Items.length ? (
                    dataStore.Items.map((item: any, index: number) => (
                      <CardRequest
                        key={index}
                        color={orderColors[item.state.id]}
                        title={`${proposalStatus[item.state.id]}  ${`#${
                          item?.identifier?.toUpperCase() ??
                          String(item?.pub_id).substr(-6)
                        }`}`}
                        time={format(
                          fromUnixTime(item.created_at),
                          'dd/MM - HH:mm'
                        )}
                        price={formatCurrency(item.total)}
                        onClick={handleNewOrder(item?.order_id, item?.pub_id)}
                        cursor
                      />
                    ))
                  ) : (
                    <View height='240px' justify='center' direction='column'>
                      <View pb={2}>
                        <Text textAlign='center' size={14}>
                          Nenhum pedido encontrado
                        </Text>
                      </View>
                    </View>
                  )}
                </View>
              </Col>
            )}
          </Row>
        </View>

        <View
          height='calc(100% - 310px)'
          style={{
            ...shadowBox,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 30,
            paddingRight: 30,
            overflow: 'initial',
          }}
        >
          {!data && (
            <View height='100%' justify='center' align='center'>
              <LoadingSearch />
            </View>
          )}

          {data && (
            <>
              <View
                height='calc(100% - 80px)'
                mt={1}
                style={{ overflow: 'auto' }}
              >
                <Table.Table id='listSale'>
                  <thead>
                    <tr>
                      <Header textAlign='left'>Número</Header>
                      <Header textAlign='left'>Status</Header>
                      <Header textAlign='left'>Quantidade</Header>
                      <Header textAlign='left'>Data criação</Header>
                      <Header textAlign='left'>Atenção</Header>
                      <Header textAlign='left'>Ações</Header>
                    </tr>
                  </thead>
                  <tbody>
                    {data.items.map((item: any, idx: number) => {
                      const quantity = item.products?.reduce(
                        (quantity: number, item: any) => {
                          const old = quantity || 0
                          return old + Number(item.quantity)
                        },
                        0
                      )

                      return (
                        <Table.Row key={idx}>
                          <DataRow>
                            <Text size={15} fontWeight={500}>
                              #
                              {item?.identifier?.toUpperCase() ??
                                item.pub_id?.substr(-6)?.toUpperCase()}
                            </Text>
                          </DataRow>
                          <DataRow>
                            <Text size={15}>
                              {statusValues[item.state.description]}
                            </Text>
                          </DataRow>
                          <DataRow>
                            <Text size={15}>{quantity}</Text>
                          </DataRow>
                          <DataRow>
                            <Text size={15}>
                              {formattedCreatedAt(item.created_at)}
                            </Text>
                          </DataRow>
                          <DataRow>
                            {item.attention && <img src={Rejeitado} />}
                          </DataRow>
                          <DataRow>
                            <View direction='row' align='center'>
                              {store.group.includes('admin') ? (
                                <View
                                  mt={0.2}
                                  mr={1}
                                  cursor
                                  onClick={handleOpenLogModal(item.pub_id)}
                                >
                                  <img
                                    src={Log}
                                    alt='Log'
                                    className='w-6 h-6'
                                  />
                                </View>
                              ) : null}

                              <View mt={0.2} mr={0.2} cursor>
                                <Link to={`/pedidos/detalhe/${item.pub_id}`}>
                                  <img
                                    src={Nota}
                                    alt='Detalhe'
                                    className='w-6 h-6'
                                  />
                                </Link>
                              </View>
                            </View>
                          </DataRow>
                        </Table.Row>
                      )
                    })}
                  </tbody>
                </Table.Table>
              </View>
              <View flex align='center' justify='space-between' mt={1}>
                <View>
                  <Text fontWeight={600}>
                    Total: {data.pagination.total ?? '-'}
                  </Text>
                </View>

                <View flex align='center'>
                  <View padding={1} onClick={onPreviousPageHandler} mr={1}>
                    <img
                      style={{ opacity: filter.page === 1 ? 0.6 : 1 }}
                      src={IconArrowLeft}
                    />
                  </View>

                  <Text mb={0.2} fontWeight={600} size={18}>
                    {filter.page ?? 1}
                  </Text>

                  <View padding={1} onClick={onNextPageHandler} ml={1}>
                    <img
                      style={{
                        opacity:
                          filter.page >= data.pagination.lastPage ? 0.6 : 1,
                      }}
                      src={IconArrowRight}
                    />
                  </View>
                </View>
              </View>
            </>
          )}
        </View>
      </Container>

      {app.openMainModal && <MainModal loading={loading} storeId={storeId} />}

      {warning && (
        <Modal paddingTop={10} width={20} padding={0}>
          <View padding={1}>
            <View flex justify='center'>
              <img src={Alert} height={36} />
            </View>

            <View mt={1} mb={1}>
              <Text textAlign='center' fontWeight={700}>
                Existe um pedido com status expirado que precisa de atenção
              </Text>
            </View>

            <View flex justify='flex-end' mt={2}>
              <View width='100%' direction='row'>
                <View width='100%' mr={1}>
                  <Button
                    type='reset'
                    label='Fechar'
                    borderColor='#3097E2'
                    color='#3097E2'
                    background='#fff'
                    onClick={handleModalWarning}
                  />
                </View>
                <View width='100%'>
                  <Button
                    type='reset'
                    label='Ver detalhe'
                    background='#3097E2'
                    onClick={handleDetailOrder}
                  />
                </View>
              </View>
            </View>
          </View>
        </Modal>
      )}

      {orderIdToSeeLogs ? (
        <LogModal orderId={orderIdToSeeLogs} onClose={handleCloseLogsModal} />
      ) : null}
    </>
  )
}

export default Sales
