const RESET_DATA = 'offerReducer/RESET_DATA'
const ADD_PRODUCT = 'offerReducer/ADD_PRODUCT'
const ADD_INFO_PRODUCT = 'offerReducer/ADD_INFO_PRODUCT'
const ADD_EXPIRATION = 'offerReducer/ADD_EXPIRATION'
const ADD_QUANTITY = 'offerReducer/ADD_QUANTITY'
const ADD_PRICE = 'offerReducer/ADD_PRICE'
const ADD_DISCOUNT = 'offerReducer/ADD_DISCOUNT'
const SET_FILTER_QUANT = 'offerReducer/SET_FILTER_QUANT'
const SET_FILTER_EXPIRATION = 'offerReducer/SET_FILTER_EXPIRATION'

export interface OfferReducerProps {
  name: string
  img: string
  principle: string
  manufacturer: string
  product: string
  quantity: number
  price: number
  discount: number
  normal_price: number
  price_promo: number
  expiration: string
  filterQuantity: string
  filterExpiration: string
}

const stateInit = {
  name: '',
  img: '',
  principle: '',
  manufacturer: '',
  product: '',
  quantity: 1,
  price: 0,
  discount: 0,
  normal_price: 0,
  price_promo: 0,
  expiration: '',
  filterQuantity: 'asc',
  filterExpiration: 'asc',
}

export const offerReducer = (
  state: OfferReducerProps = stateInit,
  { type, payload }: any
) => {
  switch (type) {
    case SET_FILTER_QUANT:
      return {
        ...state,
        filterQuantity: payload,
      }
    case SET_FILTER_EXPIRATION:
      return {
        ...state,
        filterExpiration: payload,
      }
    case ADD_DISCOUNT:
      return {
        ...state,
        discount: payload,
      }
    case ADD_PRICE:
      return {
        ...state,
        price: payload,
      }
    case ADD_QUANTITY:
      return {
        ...state,
        quantity: payload,
      }
    case ADD_EXPIRATION:
      return {
        ...state,
        expiration: payload,
      }
    case ADD_PRODUCT:
      return {
        ...state,
        product: payload,
      }
    case ADD_INFO_PRODUCT:
      return {
        ...state,
        name: payload.name,
        img: payload.img,
        principle: payload.principle,
        manufacturer: payload.manufacturer,
        product: payload.product,
        quantity: payload.quantity,
        price: payload.price,
        price_promo: payload.price_promo,
        normal_price: payload.normal_price,
        expiration: payload.expiration,
      }
    case RESET_DATA:
      return stateInit
    default:
      return state
  }
}

export const setFilterExpirationAction = (payload: string) => ({
  type: SET_FILTER_EXPIRATION,
  payload,
})

export const setFilterQuantityAction = (payload: string) => ({
  type: SET_FILTER_QUANT,
  payload,
})

export const addOfferDiscountAction = (payload: number) => ({
  type: ADD_DISCOUNT,
  payload,
})

export const addOfferPriceAction = (payload: number) => ({
  type: ADD_PRICE,
  payload,
})

export const addOfferQuantityAction = (payload: number) => ({
  type: ADD_QUANTITY,
  payload,
})

export const addOfferExpirationAction = (payload: string) => ({
  type: ADD_EXPIRATION,
  payload,
})

export const addOfferInfoProductAction = (
  payload: Partial<OfferReducerProps>
) => ({ type: ADD_INFO_PRODUCT, payload })

export const addOfferProductAction = (payload: string) => ({
  type: ADD_PRODUCT,
  payload,
})

export const resetOfferAction = () => ({ type: RESET_DATA })
