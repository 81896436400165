import { useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory, useLocation } from 'react-router'

import { deleteProduct, putProductById } from 'src/services/ProductService'
import { mutate } from 'swr'

const useProduct = () => {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const productIdRef = useRef<string>('')
  const location = useLocation<any>()
  const name = new URLSearchParams(location.search).get('name')

  const history = useHistory()
  const alert = useAlert()

  const handleToggleModal = () => {
    setOpenModal((state) => !state)
  }

  const handleDisable = async () => {
    try {
      await deleteProduct(productIdRef.current)
      mutate(['/admin/products', name])
      handleToggleModal()
      alert.success('Produto desabilitado com sucesso')
    } catch (error) {
      alert.success('Falha na operação')
    }
  }

  const handleUpdateProduct = async (id: string, data: any) => {
    try {
      await putProductById(id, data)
      history.goBack()
      alert.success('Produto atualizado com sucesso')
    } catch (error) {
      alert.success('Falha na operação')
    }
  }

  const handleOpenDisable = (id: string) => () => {
    productIdRef.current = id
    handleToggleModal()
  }

  const handleEditProduct = (id: string, data: any) => () =>
    history.push(`/produtos/adicionar/${id}`, data)

  return {
    openModal,
    loading,
    setLoading,
    handleToggleModal,
    handleDisable,
    handleOpenDisable,
    handleEditProduct,
    handleUpdateProduct,
  }
}

export default useProduct
