import { ChangeEvent, useState } from 'react'
import { useAlert } from 'react-alert'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { format, fromUnixTime } from 'date-fns'
import { Dispatch } from 'redux'
import {
  addOnboardingAllowFarmazonDeliveryAction,
  addOnboardingHasDeliveryAction,
  addOnboardingLocationAction,
  addOnboardingNameAction,
  addOnboardingRangeAction,
  addPointStoreAction,
  resetOnboardingAction,
} from 'src/redux/reducers/onboardingReducer'
import { DefaultRootState } from 'src/redux/store'
import { postCreateStore } from 'src/services/StoreService'
import { formatMoney, unMaskOnlyNumber } from 'src/util/helpers/string'

const useSaleCenter = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const alert = useAlert()

  const {
    self_delivery,
    self_delivery_range,
    self_delivery_fee,
    self_delivery_time,
    allow_farmazon_delivery,
  } = useSelector<DefaultRootState, DefaultRootState['onboarding']>(
    (state) => state.onboarding
  )

  const [loading, setLoading] = useState(false)

  const [openModal, setModal] = useState(false)
  const [delivery, setDelivery] = useState(self_delivery)
  const [farmazonDelivery, setFarmazonDelivery] = useState(
    allow_farmazon_delivery
  )
  const [range, setRange] = useState(self_delivery_range)
  const [deliveryFee, setDeliveryFee] = useState(self_delivery_fee)
  const [deliveryTime, setDeliveryTime] = useState(self_delivery_time)
  const [location, setLocation] = useState({ lat: '', lng: '' })

  const handleNavegateStore = () => {
    history.push('/stores/addStore')
  }

  const handleSwitch = () =>
    setDelivery((old) => {
      const newValue = !old

      if (!newValue && !farmazonDelivery) {
        setFarmazonDelivery(true)
      }

      return newValue
    })

  const handleSwitchFarmazonDelivery = () =>
    setFarmazonDelivery((old) => {
      const newValue = !old

      if (!newValue && !delivery) {
        setDelivery(true)
      }

      return newValue
    })

  const handleChangeRange = (e: ChangeEvent<HTMLInputElement>) =>
    setRange(parseInt(e.target.value))

  const handleToggleLoading = () => {
    setLoading((state) => !state)
  }

  const handleToggleModal = () => {
    setModal((state) => !state)
  }

  const handleCancelConfirm = () => {
    history.push('/stores')
  }

  const handleAddStore = (data: any, geolocation: any) => {
    batch(() => {
      dispatch(addOnboardingNameAction({ name: data.name, cnpj: data.cnpj }))
      dispatch(addOnboardingLocationAction(data))
      dispatch(addPointStoreAction(geolocation.lat, geolocation.lng))
    })
    history.push('/stores/addDelivery')
  }

  const handleAddDelivery = () => {
    if (deliveryTime === 0) {
      alert.error('O tempo de entrega não pode ser zero')
      return
    }

    const parsedDeliveryFee = Number(unMaskOnlyNumber(deliveryFee)) / 100
    if (parsedDeliveryFee === 0) {
      alert.error('A taxa de entrega não pode ser zero')
      return
    }

    batch(() => {
      dispatch(addOnboardingHasDeliveryAction(delivery))
      dispatch(addOnboardingAllowFarmazonDeliveryAction(farmazonDelivery))
      dispatch(addOnboardingRangeAction(range))
    })
    history.push('/stores/addPhones')
  }

  const handleCreateStore = () => {
    return async (dispatch: Dispatch, getState: () => DefaultRootState) => {
      try {
        const { onboarding, app } = getState()
        setLoading(true)

        const formattedHoursClose = onboarding.openingHours.hours
          .filter((item) => item.short !== 'fer')
          .reduce((acc, item) => {
            const modifield = {
              ...acc,
              [item.short]: [
                format(fromUnixTime(item.open), 'HH:mm'),
                format(fromUnixTime(item.close), 'HH:mm'),
              ],
            }
            return modifield
          }, [])

        const payload = {
          name: onboarding.name,
          cnpj: onboarding.cnpj.replace(/\D/g, ''),
          self_delivery: onboarding.self_delivery,
          allow_farmazon_delivery: onboarding.allow_farmazon_delivery,
          self_delivery_range: onboarding.self_delivery
            ? onboarding.self_delivery_range
            : 0,
          self_delivery_fee:
            Number(unMaskOnlyNumber(onboarding.self_delivery_fee)) / 100,
          self_delivery_time: onboarding.self_delivery_time,
          address: onboarding.location,
          contact_phones: onboarding.contact_phones,
          terms_of_use: app.setup?.store?.terms_of_use?.version || '1.0',
          opening_hours: {
            ...(onboarding.openingHours.notClose
              ? { all_week: ['00:00', '23:59'] }
              : formattedHoursClose),
          },
        }

        await postCreateStore(payload)

        alert.success('Loja cadastrada com sucesso')
        dispatch(resetOnboardingAction())
        history.push('/stores')
      } catch (error: any) {
        alert.error(
          error.response?.data?.message ??
            'ocorreu um erro durante a operação. Entre em contato com suporte, por favor.'
        )
      } finally {
        setLoading(false)
      }
    }
  }

  const handleChangeDeliveryFee = (e: any) => {
    const value = Number(unMaskOnlyNumber(e.target.value))

    setDeliveryFee(formatMoney(String(value)))
  }

  const handleChangeDeliveryTime = (e: any) => {
    setDeliveryTime(e.target.value)
  }

  return {
    loading,
    openModal,
    delivery,
    farmazonDelivery,
    range,
    location,
    setLocation,
    handleNavegateStore,
    handleToggleLoading,
    handleToggleModal,
    handleCancelConfirm,
    handleAddStore,
    handleSwitch,
    handleSwitchFarmazonDelivery,
    handleChangeRange,
    handleAddDelivery,
    handleCreateStore,
    deliveryFee,
    handleChangeDeliveryFee,
    deliveryTime,
    handleChangeDeliveryTime,
  }
}

export default useSaleCenter
