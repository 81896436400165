import { useState } from 'react'
import { useAlert } from 'react-alert'
import { batch, useDispatch } from 'react-redux'

import { format, fromUnixTime } from 'date-fns'
import { Dispatch } from 'redux'
import {
  disableMainModalAction,
  enableMainModalAction,
} from 'src/redux/reducers/appReducer'
import {
  addSaleDetailAction,
  cleanSaleDetailAction,
  setDaysSaleAction,
} from 'src/redux/reducers/saleReducer'
import { DefaultRootState } from 'src/redux/store'
import { getAllAddressCustomer } from 'src/services/Costumer'
import { createLog } from 'src/services/log'
import {
  getAcceptOrder,
  getAcceptOrderByIdSale,
  getAllstoresInvite,
  getDeclineOrder,
  getRestartInvites,
  getSalesById,
  getTransferInvite,
  postOrderNf,
  postOrderProposal,
  postOrderStatusAdmin,
  postProposalCheckout,
  putAddressOrder,
  putObservationOrder,
} from 'src/services/OrderService'
import { deleteCancelOrderById } from 'src/services/StoresService'
import { saleStatus } from 'src/util/constants'
import { formatCurrency } from 'src/util/helpers/string'
import { mutate } from 'swr'

export interface Product {
  name: string
  quantity: number
  pub_id: string
  price: number
  min_price: number
  max_price: number
  offer?: any
  product_image: string
  required: boolean
  substitute_products: any[]
  medical_prescription?: any
}

export interface Customer {
  phone_number: string
  name: string
  phone: string
  cpf?: any
}

export interface DeliveryAddress {
  pub_id: string
  country: string
  lng: string
  city: string
  more: string
  created_at: number
  active: boolean
  cep: string
  number: number
  default: boolean
  street: string
  nickname: string
  state: string
  neighborhood: string
  lat: string
  customer: string
}

export interface PaymentInfo {
  status: string
  split_value: number
  history?: any[]
}

export interface SaleProps {
  created_at: number
  products: Product[]
  identifier?: any
  order: string
  total: number
  customer: Customer
  pub_id: string
  rate: number
  nf_key: string
  delivery_fee: number
  payment_type?: any
  delivery_address: DeliveryAddress
  payment_info: PaymentInfo
  schedule_info?: {
    date: string
    time: string
  }
  confirm_checkout?: {
    nf_total: number
  }
  store_rate: number
  delivery_type?: string
  max_total: number
  proposal: string
}

export interface ProductProps {
  name: string
  quantity: number
  pub_id: string
  price: number
  min_price: number
  max_price: number
  offer?: any
  product_image: string
  required: boolean
  substitute_products: any[]
  medical_prescription?: any
}

export interface CustomerProps {
  phone_number: any
  name: string
  phone: string
  cpf?: any
  sub?: string
}

export interface State {
  description: string
  id: number
}

export interface PaymentType {
  mode: 'card' | 'cash'
  change_for: string
  description: 'app' | 'presential' | 'pix'
}

export interface DeliveryAddressProps {
  reference?: string
  pub_id: string
  country: string
  lng: string
  city: string
  more: string
  created_at: number
  active: boolean
  cep: string
  number: number
  default: boolean
  street: string
  nickname: string
  state: string
  neighborhood: string
  lat: string
  customer: string
}

export interface DeliveryLatLngProps {
  lng: string
  lat: string
}

export interface SaleDetailProps {
  origin?: string
  state_history: any
  transaction_id: string
  coupon?: any
  coupon_details?: any
  created_at: number
  products: ProductProps[]
  order: string
  total: number
  customer: CustomerProps
  max_total: number
  state: State
  pub_id: string
  obs?: any
  rate: string
  delivery_fee: number
  delivery_driver?: {
    name: string
    photo?: string
    license_plate: string
    phone?: string
    track?: string
  }
  antifraude_tax: number
  payment_type: PaymentType
  delivery_order_id?: string
  delivery_type: 'store_delivery' | 'farmazon_delivery'
  farmazon_delivery_type?: 'lalamove' | 'bkc'
  delivery_address: DeliveryAddressProps
  delivery_lat_lng: DeliveryLatLngProps
  payment_info: PaymentInfo
  confirm_checkout?: {
    nf_total: number
    nf_file?: string
  }
  schedule_info?: {
    date: string
    time: string
  }
  store_rate?: number
  identifier?: string
  invites?: any
  admin_observation?: string
  attention?: boolean
  delivery_history?: DeliveryHistoryProps[]
  proposal?: string
}

export interface DeliveryHistoryProps {
  driver_id: string
  id: string
  updated_at: string
  city: string
  previous_status: string
  status: string
}

const daysFilter = [1, 2, 7, 15, 30, 60, 90]

const useSale = () => {
  const [openModal, setModal] = useState(false)
  const [openModalCancel, setModalCancel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [nfLoading, setNfLoading] = useState(false)
  const [details, setDetails] = useState<SaleProps | null>(null)
  const [customerAddress, setCustomAddress] = useState([])
  const [storeInvites, setStoreInvites] = useState([])

  const [openObservation, setOpenObservation] = useState(false)
  const [loadingObservation, setLoadingObservation] = useState(false)
  const alert = useAlert()
  const dispatch = useDispatch()

  const getAllUserAddress = async (customerId: string) => {
    try {
      const { data } = await getAllAddressCustomer(customerId)
      setCustomAddress(data.Items)
    } catch (error) {
      /* empty */
    }
  }

  const handleUpdateAddressCustomer = async (
    orderId: string,
    deliveryAddressId: string
  ) => {
    try {
      setLoading(true)
      await putAddressOrder(orderId, deliveryAddressId)
      createLog({
        orderId,
        log: 'CHANGE_ADDRESS',
      })
      alert.success('Endereço atualizado com sucesso')
      mutate(`/admin/orders/${orderId}`)
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  }

  const handleToggleModal = () => setModal((state) => !state)

  const handleToggleModalCancel = () => setModalCancel((state) => !state)

  const handleOrderDetails = (storeId: string, saleId: string) => {
    return async () => {
      try {
        setDetails(null)
        setLoading(true)
        setModal(true)
        const response = await getSalesById(storeId, saleId)
        setDetails(response.data)
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleIncrementDay = () => {
    return (dispatch: Dispatch, getStore: () => DefaultRootState) => {
      const { sale } = getStore()
      const index = daysFilter.indexOf(sale.days)
      if (index < daysFilter.length - 1) {
        dispatch(setDaysSaleAction(daysFilter[index + 1]))
      }
    }
  }

  const handleDecrementDay = () => {
    return (dispatch: Dispatch, getStore: () => DefaultRootState) => {
      const { sale } = getStore()

      const index = daysFilter.indexOf(sale.days)
      if (index > 0) dispatch(setDaysSaleAction(daysFilter[index - 1]))
      else dispatch(setDaysSaleAction(daysFilter[0]))
    }
  }

  const handleExportExcel = (data: any) => {
    return () => {
      const header =
        'data:text/csv;charset=utf-8,Data,Pedido,Status,Meio de pagamento,Valor total\r\n'

      const getPayment = (item: any) => {
        let value = 'Pagamento via cartão de crédito'
        if (
          item.payment_type?.description === 'presential' &&
          item.payment_type?.mode === 'cash'
        ) {
          value = 'Pagamento em dinheiro'
        }
        if (
          item.payment_type?.description === 'presential' &&
          item.payment_type?.mode === 'card'
        ) {
          value = 'Pagamento via maquininha (débido/crédito)'
        }
        return value
      }

      const content = data
        .map((item: any) =>
          [
            format(fromUnixTime(item.created_at), 'dd/MM'),
            `${item.identifier}`,
            saleStatus[item?.payment_info?.status] ?? '',
            getPayment(item),
            `"${formatCurrency(item.total)}"`,
          ].join(',')
        )
        .join('\r\n')

      const csvContent = `${header}${content}`

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'minhas_vendas.xls')
      document.body.appendChild(link)
      link.click()
    }
  }

  const handleAcceptOrder = (
    storeId: string,
    saleId: string,
    orderId?: string
  ) => {
    return async () => {
      try {
        setLoading(true)
        await getAcceptOrder(storeId, saleId)
        alert.success('Pedido aceito com sucesso')
        mutate(`/orders/admin/proposals/${storeId}/?only=new proposal`)
        if (orderId) {
          createLog({
            orderId,
            log: 'ACCEPT_ORDER',
          })
          await mutate(`/admin/orders/${orderId}`)
        }
        batch(() => {
          dispatch(cleanSaleDetailAction())
          dispatch(disableMainModalAction())
        })
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleOpenModalDecline = () => setModal(true)

  const handleDeclineOrder = (
    storeId: string,
    saleId: string,
    data: any,
    orderId: string
  ) => {
    return async () => {
      try {
        setLoading(true)
        await getDeclineOrder(storeId, saleId, data)
        alert.success('Pedido rejeitado com sucesso')
        mutate(`/orders/admin/proposals/${storeId}/?only=new proposal`)
        if (orderId) {
          createLog({
            orderId,
            log: 'DECLINE_ORDER',
          })
          mutate(`/admin/orders/${orderId}`)
        }
        batch(() => {
          dispatch(cleanSaleDetailAction())
          dispatch(disableMainModalAction())
        })
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setModal(false)
        setLoading(false)
      }
    }
  }

  const handleOrderModalDetails = async (
    orderId: string,
    proposalId: string,
    storeId: string
  ) => {
    try {
      setLoading(true)
      batch(() => {
        dispatch(cleanSaleDetailAction())
        dispatch(enableMainModalAction())
      })

      const { data } = await getAcceptOrderByIdSale(orderId, storeId)

      dispatch(addSaleDetailAction({ ...data, proposal: proposalId }))
    } catch (error: any) {
      alert.error(error?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => dispatch(disableMainModalAction())

  const handleDetail = async (storeId: string, orderId: string) => {
    try {
      setLoading(true)
      const { data } = await getAcceptOrderByIdSale(orderId, storeId)
      setDetails(data)
    } catch (error: any) {
      alert.error(error?.response?.data?.message ?? 'Ação não concluída')
    } finally {
      setLoading(false)
    }
  }

  const handleGoogleLink = (details: any) => {
    if (details) {
      const clientAddress = `${details.delivery_address.street},+${details.delivery_address.number}+-+${details.delivery_address.neighborhood},+${details.delivery_address.city}+-+${details.delivery_address.state},+${details.delivery_address.cep}`
      const googleLink = `https://www.google.com/maps/search/farmacia/@${details.delivery_address.lat},${details.delivery_address.lng},15z/data=!4m8!2m7!3m6!1sfarmacia!2s${clientAddress}!3s0x9bd0bdcb4d385f:0x3246793563845369!4m2!1d${details.delivery_address.lng}!2d${details.delivery_address.lat}`
      window.open(googleLink, '_blank')
    }
  }

  const handleChangeState = (
    storeId: string,
    orderId: string,
    stateId: number
  ) => {
    return async () => {
      try {
        setLoading(true)
        if (stateId < 5) stateId = 5
        await postOrderProposal(storeId, orderId, { state: stateId })
        mutate(`/orders/proposals/${storeId}/${orderId}`)
        alert.success('Status alterado com sucesso')
      } catch (error: any) {
        alert.error(error?.response?.data?.message ?? 'Ação não concluída')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleSubmitNf = async (
    storeId: string,
    orderId: string,
    file: any,
    total: any
  ) => {
    try {
      setNfLoading(true)

      const data = new FormData()
      data.append('nf_file', file[0])
      data.set('nf_total', total)

      await postOrderNf(storeId, orderId, data)
      mutate(`/orders/proposals/${storeId}/${orderId}`)
      alert.success('Upload realizado com sucesso')
      setModal(false)
    } catch (error: any) {
      alert.error(error?.response?.data?.message ?? 'Ação não concluída')
    } finally {
      setNfLoading(false)
    }
  }

  const handleOpenObservaton = () => setOpenObservation((state) => !state)

  const handleSaveObservation = (orderId: string, text: string) => {
    return async () => {
      try {
        setLoadingObservation(true)
        await putObservationOrder(orderId, text)
        mutate(`/admin/orders/${orderId}`)
        alert.success('Observação atualizada com sucesso.')
        setOpenObservation(false)
      } catch (error: any) {
        alert.error(error?.response?.data?.message ?? 'Ação não concluída')
      } finally {
        setLoadingObservation(false)
      }
    }
  }

  const checkOutOrder = async (
    idStore: string,
    idProposal: string,
    data: any
  ) => {
    try {
      await postProposalCheckout(idStore, idProposal, data)
      alert.success('Checkout executado com sucesso.')
    } catch (error: any) {
      alert.error(
        error.response?.data?.message ??
          `Error com integração com api. ${error.toString()}`
      )
    }
  }

  const handleToggleLoading = () => setLoading((state) => !state)

  const handleProgress = (orderId: string, step: number) => {
    return async () => {
      setLoading(true)
      try {
        await postOrderStatusAdmin(orderId, { state: step })
        await mutate(`/admin/orders/${orderId}`)
        alert.success('Status atualizado com sucesso.')
      } catch (error: any) {
        alert.error(
          error.response?.data?.message ??
            `Error com integração com api. ${error.toString()}`
        )
      } finally {
        setLoading(false)
      }
    }
  }

  const handleCancelOrder = (orderId: string) => {
    return async () => {
      setLoading(true)
      try {
        setModalCancel(false)
        await deleteCancelOrderById(orderId)
        createLog({
          orderId,
          log: 'DECLINE_ORDER',
        })
        await mutate(`/admin/orders/${orderId}`)
        alert.success('Pedido cancelado com sucesso.')
      } catch (error: any) {
        alert.error(
          error.response?.data?.message ??
            `Error com integração com api. ${error.toString()}`
        )
      } finally {
        setLoading(false)
        setModalCancel(false)
      }
    }
  }

  const handleRestartInvite = (orderId: string) => {
    return async () => {
      try {
        setLoading(true)
        await getRestartInvites(orderId)
        createLog({
          orderId,
          log: 'RESTART_STORE_INVITATION',
        })
        await mutate(`/admin/orders/${orderId}`)
        alert.success('Convite reiniciado com sucesso.')
      } catch (error: any) {
        alert.error(
          error.response?.data?.message ??
            `Error com integração com api. ${error.toString()}`
        )
      } finally {
        setLoading(false)
      }
    }
  }

  const handleGetStoreInvites = async (orderId: string) => {
    const { data } = await getAllstoresInvite(orderId)
    setStoreInvites(data)
  }

  const handleSendInviteStore = async (orderId: string, storeId: string) => {
    try {
      setLoading(true)
      await getTransferInvite(orderId, storeId)
      createLog({
        orderId,
        log: 'CHANGE_STORE_INVITATION',
      })
      await mutate(`/admin/orders/${orderId}`)
      alert.success('Convite enviado com sucesso.')
    } catch (error: any) {
      alert.error(
        error.response?.data?.message ??
          `Error com integração com api. ${error.toString()}`
      )
    } finally {
      setLoading(false)
    }
  }

  return {
    nfLoading,
    customerAddress,
    openModalCancel,
    checkOutOrder,
    openObservation,
    openModal,
    loading,
    details,
    storeInvites,
    handleToggleModal,
    handleOrderDetails,
    handleIncrementDay,
    handleDecrementDay,
    handleExportExcel,
    handleAcceptOrder,
    handleOrderModalDetails,
    handleDeclineOrder,
    handleOpenModalDecline,
    handleCloseModal,
    handleDetail,
    handleGoogleLink,
    handleChangeState,
    handleSubmitNf,
    handleOpenObservaton,
    handleSaveObservation,
    loadingObservation,
    handleToggleLoading,
    handleProgress,
    handleCancelOrder,
    handleToggleModalCancel,
    getAllUserAddress,
    handleUpdateAddressCustomer,
    handleRestartInvite,
    handleGetStoreInvites,
    handleSendInviteStore,
  }
}

export default useSale
