import EmptyProduct from 'src/assets/images/empty-produto.svg'
import { Radio } from 'src/components/form'
import { Text, View } from 'src/components/helper'

import { Container } from './styles'

interface ProductSearchItemProps {
  selected?: boolean
  image?: string
  product: any
  onClick: () => void
}

function ProductSearchItem({
  product,
  selected = false,
  image,
  onClick,
}: ProductSearchItemProps) {
  return (
    <Container selected={selected}>
      <View pl={1}>
        <img
          src={image || EmptyProduct}
          alt='Imagem produto'
          width={85}
          height={85}
        />
      </View>

      <View pl={0.8} width='400px'>
        <View mt={0.8}>
          <Text fontWeight={700} size={14}>
            {product.name}
          </Text>
        </View>
        <View mt={0.75}>
          <Text fontWeight={400} size={11}>
            Princípio Ativo:{' '}
            {product.characteristic.active_principle?.[0]?.name}
          </Text>
          <Text fontWeight={400} size={11}>
            Marca: {product.characteristic.manufacturer}
          </Text>
        </View>
      </View>

      <View width='70px' style={{ marginLeft: 'auto' }}>
        <Radio checked={selected} onClick={onClick} readOnly />
      </View>
    </Container>
  )
}

export default ProductSearchItem
