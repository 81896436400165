import { useState } from 'react'
import { Col } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import DownloadIcon from 'src/assets/icons/download.svg'
import { Button, Input } from 'src/components/form'
import { Text } from 'src/components/helper'
import { DefaultRootState } from 'src/redux/store'

import { ModalMainContent } from './styles'

import Modal from '../Modal'

interface OwnProps {
  handleToggleModal(): void
}

const ModalImportStores = ({ handleToggleModal }: OwnProps) => {
  const { modelFileUri } = useSelector<
    DefaultRootState,
    { modelFileUri: string }
  >((state) => ({
    modelFileUri: state.app.setup?.admin.stores_csv_file_model,
  }))

  const [loading, setLoading] = useState(false)

  const { handleSubmit, register } = useForm()

  const handleOnSubmit = handleSubmit((data) => {
    setLoading(true)

    try {
      console.log(data.file)
    } finally {
      setLoading(false)
    }
  })

  const handleDownload = () => {
    if (modelFileUri) {
      const anchor = document.createElement('a')
      anchor.href = modelFileUri

      document.body.appendChild(anchor)
      anchor.click()

      document.body.removeChild(anchor)
    }
  }

  return (
    <Modal width={60} onClose={handleToggleModal}>
      <ModalMainContent>
        <Text mb={1} size={24}>
          Impotar Clientes
        </Text>

        <hr />

        <Text mt={1}>
          A planilha com os dados dos clientes deve ter o nome de "Clientes".
        </Text>
        <Text>
          A primeira linha da planilha deve conter o titulo das colunas
          exatamente como está descrito abaixo:
        </Text>

        <Text mt={2} fontWeight={700}>
          CPF/CNJP* | Nome/Razão social* | E-mail | E-mail secundário | Telefone
        </Text>
        <Text fontWeight={700}>
          Endereço | Número | Complemento | Bairro | Cidade | UF | CEP | Grupo |
          Observações
        </Text>

        <Text mt={2} size={12} fontWeight={500}>
          *Dados obrigatórios
        </Text>
        <Text mb={2} size={12} fontWeight={500}>
          Obs.: Caso um dos campos de endereço seja especificado, os demais (com
          exeção do complemento e bairro) são obrigatórios.
        </Text>

        <Col xs={3}>
          <Button
            type='button'
            label='Baixar modelo'
            borderColor='#3097E2'
            color='#3097E2'
            background='#fff'
            height='40px'
            icone={DownloadIcon}
            onClick={handleDownload}
          />
        </Col>

        <br />
        <br />

        <form onSubmit={handleOnSubmit}>
          <Col xs={6}>
            <Input type='file' accept='.csv' {...register('file')} />
          </Col>

          <br />

          <Col xs={3}>
            <Button
              type='submit'
              label='Enviar arquivo'
              background='#3097E2'
              height='40px'
              loading={loading}
            />
          </Col>
        </form>
      </ModalMainContent>
    </Modal>
  )
}

export default ModalImportStores
