import { Route, Switch, useRouteMatch } from 'react-router'

import { LoadingSearch } from 'src/components/elements'
import { View } from 'src/components/helper'
import { getStoreSeller } from 'src/services/StoreService'
import useSWR from 'swr'

import AddUser from './AddUser'
import EmptyUser from './EmptyUser'
import ListUser from './ListUser'

function User() {
  const { path } = useRouteMatch()

  const { data: users } = useSWR('/sales-center/stores/sellers', getStoreSeller)

  return (
    <Switch>
      <Route exact path={path}>
        {!users ? (
          <View height='100%' justify='center' align='center'>
            <LoadingSearch />
          </View>
        ) : (
          users &&
          (users?.length > 0 ? <ListUser users={users} /> : <EmptyUser />)
        )}
      </Route>
      <Route exact path={`${path}/addUser/:userId?`}>
        <AddUser />
      </Route>
    </Switch>
  )
}

export default User
