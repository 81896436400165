import styled from 'styled-components'

interface ContainerProps {
  selected: boolean
}

export const Container = styled.div<ContainerProps>`
  background-color: ${({ selected }) =>
    selected ? 'rgba(191, 228, 255, 0.5)' : 'transparent'};
  display: flex;
  padding: 24px 0;
  border-bottom: 2px solid #e4e4e4;
`
