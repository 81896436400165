import { ChangeEvent, FormEvent, useCallback, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'

import { format, fromUnixTime, getUnixTime, set } from 'date-fns'
import { DefaultRootState } from 'src/redux/store'
import {
  postAssociateUser,
  putStoreRetrive,
  StoreRetriveProps,
} from 'src/services/StoreService'
import { eventGA } from 'src/util/gtag'
import {
  formatMoney,
  unformatMoney,
  unMaskOnlyNumber,
} from 'src/util/helpers/string'

interface AboutDataProps {
  name: string
  address: string
  lat: number
  lng: number
  delivery: boolean
  deliveryRange: number
  deliveryFee: string
  deliveryTime: number
  customer: string
  allow_farmazon_delivery?: boolean
  hours?: {
    seg: string[]
    ter: string[]
    qua: string[]
    qui: string[]
    sex: string[]
    sab: string[]
    dom: string[]
    all_week?: string[]
  }
  phones: any[]
}

const DATE = new Date(1995, 11, 17)

const useAbout = () => {
  const alert = useAlert()

  const { app } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )

  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState(false)
  const [storeManagerMode, setStoreManagerMode] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [delivery, setDelivery] = useState(false)
  const [farmazonDelivery, setFarmazonDelivery] = useState(true)
  const [deliveryRange, setDeliveryRange] = useState(1)
  const [deliveryFee, setDeliveryFee] = useState(
    formatMoney(app.setup?.admin.delivery_average.store_delivery.default_value)
  )
  const [deliveryTime, setDeliveryTime] = useState<number>(
    app.setup?.admin.delivery_average.store_delivery.timeOnlyNumber
  )
  const [coords, setCoords] = useState<{ lat: string; lng: string }>()
  const [hoursWeek, setHoursWeek] = useState<any[]>([])
  const [check, setCheck] = useState(false)
  const [customer, setCustomer] = useState('')
  const storeCopy = useRef<any>(null)
  const [administrationUpdateLoading, setAdministrationUpdateLoading] =
    useState(false)

  const defaultHoursFactory = useCallback(() => {
    const data = [
      {
        label: 'Segunda-feira',
        short: 'seg',
        open: formatHours('08:00'),
        close: formatHours('18:00'),
        firstDayOfWeek: true,
      },
      {
        label: 'Terça-feira',
        short: 'ter',
        open: formatHours('08:00'),
        close: formatHours('18:00'),
      },
      {
        label: 'Quarta-feira',
        short: 'qua',
        open: formatHours('08:00'),
        close: formatHours('18:00'),
      },
      {
        label: 'Quinta-feira',
        short: 'qui',
        open: formatHours('08:00'),
        close: formatHours('18:00'),
      },
      {
        label: 'Sexta-feira',
        short: 'sex',
        open: formatHours('08:00'),
        close: formatHours('18:00'),
      },
      {
        label: 'Sábado',
        short: 'sab',
        open: formatHours('08:00'),
        close: formatHours('18:00'),
        openStore: false,
      },
      {
        label: 'Domingo',
        short: 'dom',
        open: formatHours('08:00'),
        close: formatHours('18:00'),
        openStore: false,
      },
    ]
    return data
  }, [])

  const hourFactory = useCallback((hours: any) => {
    let formattedHours = hours

    if (hours.all_week) {
      formattedHours = {
        seg: hours.all_week,
        ter: hours.all_week,
        qua: hours.all_week,
        qui: hours.all_week,
        sex: hours.all_week,
        sab: hours.all_week,
        dom: hours.all_week,
      }
    }

    const data = [
      {
        label: 'Segunda-feira',
        short: 'seg',
        open: formatHours(formattedHours.seg[0]),
        close: formatHours(formattedHours.seg[1]),
        firstDayOfWeek: true,
      },
      {
        label: 'Terça-feira',
        short: 'ter',
        open: formatHours(formattedHours.ter[0]),
        close: formatHours(formattedHours.ter[1]),
      },
      {
        label: 'Quarta-feira',
        short: 'qua',
        open: formatHours(formattedHours.qua[0]),
        close: formatHours(formattedHours.qua[1]),
      },
      {
        label: 'Quinta-feira',
        short: 'qui',
        open: formatHours(formattedHours.qui[0]),
        close: formatHours(formattedHours.qui[1]),
      },
      {
        label: 'Sexta-feira',
        short: 'sex',
        open: formatHours(formattedHours.sex[0]),
        close: formatHours(formattedHours.sex[1]),
      },
      {
        label: 'Sábado',
        short: 'sab',
        open: formatHours(formattedHours.sab[0]),
        close: formatHours(formattedHours.sab[1]),
        openStore:
          formattedHours.sab[0] === '00:00' &&
          formattedHours.sab[1] === '00:00',
      },
      {
        label: 'Domingo',
        short: 'dom',
        open: formatHours(formattedHours.dom[0]),
        close: formatHours(formattedHours.dom[1]),
        openStore:
          formattedHours.dom[0] === '00:00' &&
          formattedHours.dom[1] === '00:00',
      },
    ]
    return data
  }, [])

  const formatHours = (time: string) => {
    const [hour, minutes] = time.split(':')

    return getUnixTime(
      set(DATE, {
        hours: Number(hour),
        minutes: Number(minutes),
        seconds: 0,
        milliseconds: 0,
      })
    )
  }

  const populateDefaultHour = () => {
    const dataWeek = defaultHoursFactory()
    setHoursWeek(dataWeek)
  }

  const populateNoHour = () => {
    setHoursWeek([])
  }

  const populateAboutData = (data: AboutDataProps) => {
    setName(data.name)
    setAddress(data.address)
    setDelivery(data.delivery)
    setDeliveryRange(data.deliveryRange)
    setDeliveryFee(formatMoney(data.deliveryFee))
    setDeliveryTime(data.deliveryTime)
    setFarmazonDelivery(!!data?.allow_farmazon_delivery)
    setCustomer(data.customer)

    setCoords({
      lat: String(data.lat),
      lng: String(data.lng),
    })
    if (data.hours?.all_week) {
      setHoursWeek([])
    } else {
      const dataWeek = hourFactory(data.hours)
      setHoursWeek(dataWeek)
    }
    storeCopy.current = data
  }

  const handleToggleCancelModal = () => {
    eventGA('cancelar_loja')
    setOpenModal(!openModal)
  }

  const handleToggleEditMode = () => {
    eventGA('editar_loja')
    setMode(!mode)
  }

  const handleToggleEditModeStoreManager = () => {
    eventGA('editar_loja')
    setStoreManagerMode(!storeManagerMode)
  }

  const handleCancelEdit = async () => {
    setMode(false)
    const dataWeek = hourFactory(storeCopy.current.hours)
    setCheck(isSameHourAllWeek(storeCopy.current.hours))
    setHoursWeek(dataWeek)
    setOpenModal(false)
    return Promise.resolve(storeCopy.current.phones)
  }

  const handleSubmitStoreRetrive =
    (
      storeId: string,
      data: Partial<StoreRetriveProps>,
      hours: any[],
      notClose: boolean
    ) =>
    async () => {
      try {
        setLoading(true)
        const formattedHoursClose = hours
          .filter((item) => item.short !== 'fer')
          .map((item) =>
            item.openStore
              ? {
                  ...item,
                  open: formatHours('00:00'),
                  close: formatHours('00:00'),
                }
              : item
          )
          .reduce((acc, item) => {
            const modifield = {
              ...acc,
              [item.short]: [
                format(fromUnixTime(item.open), 'HH:mm'),
                format(fromUnixTime(item.close), 'HH:mm'),
              ],
            }
            return modifield
          }, [])

        const payload = {
          contact_phones: data.contact_phones ?? [],
          opening_hours: {
            ...(notClose
              ? { all_week: ['00:00', '23:59'] }
              : formattedHoursClose),
          },
          self_delivery: delivery,
          self_delivery_range: deliveryRange,
          self_delivery_time: deliveryTime,
          self_delivery_fee: unformatMoney(deliveryFee),
          allow_farmazon_delivery: farmazonDelivery,
        }

        await putStoreRetrive(storeId, payload)
        eventGA('salvar_loja')

        setMode(false)
        setCheck(isSameHourAllWeek(formattedHoursClose))

        setTimeout(() => {
          setHoursWeek(hours)
        }, 300)

        setDelivery(delivery)
        setDeliveryRange(deliveryRange)

        storeCopy.current = {
          name: storeCopy.current.name,
          address: storeCopy.current.address,
          hours: payload.opening_hours,
          phones: payload.contact_phones,
          allow_farmazon_delivery: payload.allow_farmazon_delivery,
        }

        alert.success('Dados salvos com sucesso')
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setLoading(false)
      }
    }

  const handleChangeAll = (hours: any) => {
    return () => {
      const currentCheck = !check
      setCheck(currentCheck)
      if (currentCheck) {
        const copy: any = [...hours]
        const open = Number(copy[0].open)
        const close = Number(copy[0].close)
        const updateData = copy.map((item: any) =>
          !['sab', 'dom'].includes(item.short) ? { ...item, open, close } : item
        )
        setHoursWeek(updateData)
      }
    }
  }

  const handleWeekEnd = (short: string, hours: any) => () => {
    const updateHours = hours.map((item: any) =>
      item.short === short ? { ...item, openStore: !item.openStore } : item
    )
    setHoursWeek(updateHours)
  }

  const handleSelectHour =
    (type: string, index: number, hours: any) =>
    (e: ChangeEvent<HTMLSelectElement>) => {
      const copy: any = [...hours]
      const value = parseInt(e.target.value)

      if (type === 'open') copy[index].open = value
      else copy[index].close = value
      setHoursWeek(copy)
    }

  const isSameHourAllWeek = (hours: any) => {
    if (hours.all_week) return true

    const firstDayOfWeek = hours.seg
    const deny = ['sab', 'dom']

    const filtered = Object.keys(hours)
      .filter((key) => !deny.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = hours[key]
        return obj
      }, {})

    const allWeek = Object.values(filtered).filter((item: any) => {
      return item[0] === firstDayOfWeek[0] && item[1] === firstDayOfWeek[1]
    })
    return allWeek.length === 5
  }

  const handleToggleDelivery = () => {
    setDelivery((old) => {
      const newValue = !old

      if (!newValue && !farmazonDelivery) {
        setFarmazonDelivery(true)
      }

      return newValue
    })
  }

  const handleToggleFarmazonDelivery = () => {
    setFarmazonDelivery((old) => {
      const newValue = !old

      if (!newValue && !delivery) {
        setDelivery(true)
      }

      return newValue
    })
  }

  const handleChangeDeliveryFee = (e: any) => {
    const value = Number(unMaskOnlyNumber(e.target.value))

    setDeliveryFee(formatMoney(String(value)))
  }

  const handleChangeDeliveryTime = (e: any) => {
    setDeliveryTime(e.target.value)
  }

  const onSubmitStoreManagerHandler = (storeId: string) => {
    return async (event: FormEvent<HTMLFormElement>) => {
      setAdministrationUpdateLoading(true)
      event.preventDefault()

      try {
        await postAssociateUser({
          store_id: storeId,
          seller_id: customer,
        })
        setStoreManagerMode(false)

        alert.success('Atualizado com sucesso')
      } finally {
        setAdministrationUpdateLoading(false)
      }
    }
  }

  const handleSelectCustomer = (e: ChangeEvent<HTMLSelectElement>) => {
    setCustomer(e.target.value)
  }

  return {
    name,
    address,
    coords,
    delivery,
    customer,
    farmazonDelivery,
    deliveryRange,
    hoursWeek,
    openModal,
    mode,
    loading,
    check,
    populateAboutData,
    handleSelectCustomer,
    handleToggleFarmazonDelivery,
    handleToggleDelivery,
    handleToggleCancelModal,
    handleToggleEditMode,
    handleCancelEdit,
    handleSubmitStoreRetrive,
    handleChangeAll,
    handleWeekEnd,
    handleSelectHour,
    isSameHourAllWeek,
    populateDefaultHour,
    populateNoHour,
    setCheck,
    setDeliveryRange,
    deliveryFee,
    deliveryTime,
    handleChangeDeliveryFee,
    handleChangeDeliveryTime,
    storeManagerMode,
    handleToggleEditModeStoreManager,
    onSubmitStoreManagerHandler,
    administrationUpdateLoading,
  }
}

export default useAbout
