import styled from 'styled-components'

const switchBackground = (type?: string) => {
  switch (type) {
    case 'info':
      return '#ffecb5'
    case 'success':
      return '#badbcc'
    case 'error':
      return '#f5c2c7'
    default:
      return '#d3d6d8'
  }
}

const switchBorder = (type?: string) => {
  switch (type) {
    case 'info':
      return '#664d03'
    case 'success':
      return '#0f5132'
    case 'error':
      return '#842029'
    default:
      return '#41464b'
  }
}

export const Container = styled.div<{ type?: string }>`
  width: 350px;
  min-height: 40px;
  box-sizing: border-box;
  background-color: ${({ type }) => switchBackground(type)};
  border-color: ${({ type }) => switchBorder(type)};
  border-width: 2px;
  border-radius: 7px;
  margin-bottom: 1rem;
  padding: 1rem;
`

export const Text = styled.p<{ type?: string }>`
  color: ${({ type }) => switchBorder(type)};
`
