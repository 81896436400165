/* eslint-disable no-unsafe-optional-chaining */
import { ChangeEvent, useState } from 'react'
import { Col } from 'react-grid-system'
import { useSelector } from 'react-redux'

import { addMinutes, format, fromUnixTime, set } from 'date-fns'
import Reject from 'src/assets/icons/icon-rejeitado.svg'
import { LoadingSearch, Modal, ModalEvent } from 'src/components/elements'
import { Button, InputText, Radio } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { deliveryColor } from 'src/hooks/useOperation'
import { SaleProps } from 'src/hooks/useSaleCenterSale'
import { DefaultRootState } from 'src/redux/store'
import { formattedCreatedAt } from 'src/util/helpers/date'
import { formatCurrency } from 'src/util/helpers/string'

import { DashedLine, ModalMainContent, ModalMainHeader } from './styles'
import { border } from 'src/styles'

import useOrder from './useOrder'

interface OrderModalProps {
  loading: boolean
  details: SaleProps
  handleCloseModal(): void
}

const OrderModal = ({
  loading,
  details,
  handleCloseModal,
}: OrderModalProps) => {
  const { app } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )
  const delivery_average = app.setup?.admin?.delivery_average

  const {
    loadingButton,
    showRejectReasonModal,
    handleDeclineOrder,
    handleAcceptOrder,
    handleOpenModalDecline,
  } = useOrder()

  const [reason, setRease] = useState('Oferta vencida')
  const [description, setDescription] = useState('')

  const handleReason = (reason: string) => () => setRease(reason)

  const handleDescription = (e: ChangeEvent<HTMLInputElement>) =>
    setDescription(e.target.value)

  const getAvarageText = () => {
    if (details) {
      if (details?.schedule_info?.time) {
        let formattedTime

        const [hours, minutes] = details?.schedule_info.time.split(':')
        const created_time = set(new Date(), {
          hours: Number(hours),
          minutes: Number(minutes),
          seconds: 0,
          milliseconds: 0,
        })

        if (details?.delivery_type === 'farmazon_delivery') {
          formattedTime = addMinutes(
            created_time,
            delivery_average?.farmazon?.timeOnlyNumber ?? 0
          )
        } else {
          formattedTime = addMinutes(
            created_time,
            delivery_average?.store_delivery?.timeOnlyNumber ?? 0
          )
        }

        return `Entrega prevista para ${format(formattedTime, 'HH:mm')}`
      }

      const created_at =
        typeof details?.created_at === 'number'
          ? fromUnixTime(details?.created_at)
          : new Date(details?.created_at)

      if (details?.delivery_type === 'farmazon_delivery') {
        const avarage = addMinutes(
          created_at,
          delivery_average?.farmazon?.timeOnlyNumber ?? 0
        )
        const formatted = format(avarage, 'HH:mm')
        return `Entrega prevista para ${formatted}`
      }

      if (details?.delivery_type === 'store_delivery') {
        const avarage = addMinutes(
          created_at,
          delivery_average?.store_delivery?.timeOnlyNumber ?? 0
        )
        const formatted = format(avarage, 'HH:mm')
        return `Entrega prevista para ${formatted}`
      }
    }

    return ''
  }

  if (showRejectReasonModal) {
    return (
      <Modal paddingTop={3} width={20} padding={0}>
        <ModalMainContent>
          <View flex direction='column' height='29rem'>
            <View mt={1} mb={1} flex justify='center'>
              <img src={Reject} alt='Direita' />
            </View>
            <Text textAlign='center' fontWeight={700} color='#FF0000'>
              Pedido rejeitado
            </Text>
            <Text textAlign='center' size={13} mt={0.6}>
              Qual o motivo que fez você rejeitar este pedido?
            </Text>

            <View style={{ right: 20 }}>
              <View height='38px'>
                <Radio
                  checked={reason === 'Oferta vencida'}
                  onClick={handleReason('Oferta vencida')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Oferta vencida
                </Text>
              </View>
              <View height='38px'>
                <Radio
                  checked={reason === 'Preços abaixo da tabela'}
                  onClick={handleReason('Preços abaixo da tabela')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Preços abaixo da tabela
                </Text>
              </View>
              <View height='38px'>
                <Radio
                  checked={reason === 'Sem estoque'}
                  onClick={handleReason('Sem estoque')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Sem estoque
                </Text>
              </View>
              <View height='38px'>
                <Radio
                  checked={reason === 'Fora da área de entrega'}
                  onClick={handleReason('Fora da área de entrega')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Fora da área de entrega
                </Text>
              </View>
              <View height='38px'>
                <Radio
                  checked={reason === 'Outro'}
                  onClick={handleReason('Outro')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Outro
                </Text>
                {reason === 'Outro' && (
                  <View mt={1} ml={1}>
                    <InputText
                      label='descreva o motivo:'
                      value={description}
                      onChange={handleDescription}
                    />
                  </View>
                )}
              </View>
            </View>

            <View flex style={{ marginTop: 'auto' }}>
              <Button
                label='Enviar'
                background='#3097E2'
                loading={loadingButton}
                onClick={handleDeclineOrder(
                  details.store.pub_id,
                  details.proposal,
                  details.pub_id,
                  { reason, description },
                  handleCloseModal
                )}
              />
            </View>
          </View>
        </ModalMainContent>
      </Modal>
    )
  }

  return (
    <ModalEvent
      paddingTop={3}
      width={30}
      padding={0}
      marginTop={3}
      onClick={handleCloseModal}
    >
      <ModalMainHeader>
        {details?.identifier ? (
          <Text
            size={20}
            fontWeight={700}
          >{`#${details?.identifier?.toUpperCase()}`}</Text>
        ) : null}
        {details?.created_at ? (
          <Text size={16} fontWeight={400} ml={1}>
            &bull;{' '}
            {`pedido feito às ${formattedCreatedAt(
              details?.created_at,
              'HH:mm'
            )}`}
          </Text>
        ) : null}
      </ModalMainHeader>

      <ModalMainContent>
        {loading && (
          <View height='300px' justify='center' align='center'>
            <LoadingSearch />
          </View>
        )}

        {!loading && details && (
          <View style={{ overflowY: 'auto' }}>
            <View
              direction='row'
              align='center'
              style={{ ...border }}
              pl={1}
              pr={1}
              pt={0.8}
              pb={0.8}
              mb={0.6}
            >
              <Text size={16} mr={0.5}>
                Empresa:
              </Text>
              <Text size={16} fontWeight={700}>
                {details?.store?.name}
              </Text>
            </View>

            <View
              style={{
                ...border,
                borderLeftWidth: 20,
                borderLeftColor: deliveryColor(
                  details.delivery_type === 'farmazon_delivery'
                ),
              }}
              pl={1}
              pr={1}
              pt={0.8}
              pb={0.8}
              mb={0.6}
            >
              <Text
                fontWeight={700}
                color={deliveryColor(
                  details.delivery_type === 'farmazon_delivery'
                )}
              >
                {details.delivery_type === 'farmazon_delivery'
                  ? 'ENTREGA PELA FARMAZON'
                  : 'ENTREGA PRÓPRIA'}
              </Text>
              <Text fontWeight={400} mt={0.6}>
                {getAvarageText()}
              </Text>
            </View>

            <View style={border} pl={1} pr={1} pt={0.8} pb={0.8} mb={0.6}>
              <Text size={14} fontWeight={700} color='#EA2A2F'>
                Aceite o pedido para ver os detalhes do cliente
              </Text>
              <Text size={14} fontWeight={400} mt={0.4}>
                As informações do cliente ficam ocultas até que o pedido seja
                confirmado.
              </Text>
            </View>

            <View mb={1} style={border} pl={1} pr={1} pt={0.8} pb={0.8}>
              {details.products.map((item, index) => (
                <View key={index}>
                  <View direction='row' mb={1}>
                    <Text size={15} mr={0.8}>{`${item.quantity}x`}</Text>
                    <Text size={14} fontWeight={700} mr={1.2}>
                      {item.name}
                    </Text>
                    {item.offer ? (
                      <Col>
                        <Text
                          size={14}
                          fontWeight={400}
                          style={{ textDecorationLine: 'line-through' }}
                        >
                          {formatCurrency(item.price)}
                        </Text>
                        {/* <Text size={14} fontWeight={400} mt={0.3}>{formatCurrency(item.max_price)}</Text> */}
                      </Col>
                    ) : (
                      <Col>
                        {/* <Text size={14} fontWeight={400}>{formatCurrency(item.min_price)}</Text> */}
                        <Text size={14} fontWeight={400} mt={0.3}>
                          {formatCurrency(item.max_price)}
                        </Text>
                      </Col>
                    )}
                  </View>

                  <View mt={1} mb={1}>
                    <DashedLine />
                  </View>
                </View>
              ))}

              <View direction='row' align='center' justify='space-between'>
                <Text size={14} fontWeight={700}>
                  Valor total
                </Text>
                <Text size={14} fontWeight={700}>
                  {formatCurrency(details.total)}
                </Text>
              </View>
            </View>

            <Button
              label='ACEITAR'
              background='#52C372'
              loading={loadingButton}
              onClick={handleAcceptOrder(
                details?.store?.pub_id,
                details?.proposal,
                details.pub_id,
                handleCloseModal
              )}
            />

            <View mt={1}>
              {!loadingButton && (
                <Button
                  label='REJEITAR'
                  background='#FF0000'
                  onClick={handleOpenModalDecline}
                />
              )}
            </View>
          </View>
        )}
      </ModalMainContent>
    </ModalEvent>
  )
}

export default OrderModal
