import { Modal } from 'src/components/elements'
import { Button } from 'src/components/form'
import { View, Text } from 'src/components/helper'

interface ModalConfirmProps {
  icon: any
  loading?: boolean
  description: string
  labelCancel: string
  handleCancel: () => void
  labelConfirm: string
  backgroundConfirm?: string
  handleConfirm: () => void
}

function ModalConfirm({
  icon,
  loading,
  description,
  labelCancel,
  handleCancel,
  labelConfirm,
  backgroundConfirm = '#3097E2',
  handleConfirm,
}: ModalConfirmProps) {
  return (
    <Modal width={24.25}>
      <View flex justify='center' mt={2}>
        <img width='55px' src={icon} alt='Icon' />
      </View>

      <View flex justify='center' mt={1.3} mb={3}>
        <View width='80%'>
          <Text size={18} fontWeight={700} color='#474747' textAlign='center'>
            {description}
          </Text>
        </View>
      </View>

      <View flex>
        <View mr={1} width='100%'>
          <Button
            borderColor='#3097E2'
            color='#3097E2'
            background='#fff'
            label={labelCancel}
            onClick={handleCancel}
          />
        </View>
        <View width='100%'>
          <Button
            background={backgroundConfirm}
            label={labelConfirm}
            onClick={handleConfirm}
            loading={loading}
          />
        </View>
      </View>
    </Modal>
  )
}

export default ModalConfirm
