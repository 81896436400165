import { ChangeEvent } from 'react'
import { Col, Row } from 'react-grid-system'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Plus from 'src/assets/icons/icone-plus.svg'
import OfferUser from 'src/assets/images/empty-user.svg'
import { ModalConfirm } from 'src/components/elements'
import { Button, InputText, Switch } from 'src/components/form'
import { ShadowBox, StepsRegister, Text, View } from 'src/components/helper'
import useSaleCenter from 'src/hooks/useSaleCenter'
import { addOnboardPhonesAction } from 'src/redux/reducers/onboardingReducer'
import { DefaultRootState } from 'src/redux/store'
import { formatPhone } from 'src/util/helpers/string'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

export interface PhoneProps {
  phones: Array<{ phone: string; whatsapp: boolean; principal: boolean }>
  phone: string
}

function AddPhones() {
  const { contact_phones } = useSelector<
    DefaultRootState,
    DefaultRootState['onboarding']
  >((state) => state.onboarding)

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<PhoneProps>({
    defaultValues: {
      phones: contact_phones,
    },
  })
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'phones',
  })
  const dispatch = useDispatch()
  const history = useHistory()

  const { openModal, handleToggleModal, handleCancelConfirm } = useSaleCenter()

  const onSubmit = () => {
    if (fields.length < 1) {
      setError('phone', {
        type: 'manual',
        message: 'Informe ao menos um número',
      })
      return
    }
    const phones = fields.map((item) => ({
      phone: item.phone,
      whatsapp: item.whatsapp,
      principal: item.principal,
    }))

    dispatch(addOnboardPhonesAction(phones))
    history.push('/stores/addHour')
  }

  const onSubmitPhones = (data: any) => {
    append({ phone: data.phone, whatsapp: false, principal: false })
    setValue('phone', '')
  }

  const maskPhone = (event: any) => {
    const value = formatPhone(event.target.value)
    setValue('phone', value)
    clearErrors()
  }

  const handleSwitch =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      update(index, { ...fields[index], whatsapp: e.target.checked })

      if (e.target.value) {
        fields.forEach((field, fieldIndex) => {
          if (fieldIndex !== index)
            update(fieldIndex, { ...field, whatsapp: false })
        })
      }
    }

  const handleSwitchDefault =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      update(index, { ...fields[index], principal: e.target.checked })

      if (e.target.value) {
        fields.forEach((field, fieldIndex) => {
          if (fieldIndex !== index)
            update(fieldIndex, { ...field, principal: false })
        })
      }
    }

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View>
            <Text size={25} fontWeight={700}>
              Cadastrar loja
            </Text>
          </View>
        </View>

        <div style={{ height: 'calc(100% - 40px)' }}>
          <View height='calc(100% - 110px)' style={shadowBox}>
            <View mb={5} flex justify='center' width='100%'>
              <StepsRegister.Container>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current color='#00997F' />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Link to='/stores/addStore'>
                        <Text size={15} textAlign='center'>
                          Nome e endereço
                        </Text>
                      </Link>
                    </View>
                  </StepsRegister.Li>

                  <StepsRegister.Li>
                    <StepsRegister.Divider activeStep={0} />
                  </StepsRegister.Li>
                </>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current color='#00997F' />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Link to='/stores/addDelivery'>
                        <Text size={15} textAlign='center'>
                          Entrega
                        </Text>
                      </Link>
                    </View>
                  </StepsRegister.Li>

                  <StepsRegister.Li>
                    <StepsRegister.Divider activeStep={0} />
                  </StepsRegister.Li>
                </>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current color='#3097E2' />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Text size={15} textAlign='center' fontWeight={500}>
                        Telefones
                      </Text>
                    </View>
                  </StepsRegister.Li>

                  <StepsRegister.Li>
                    <StepsRegister.Divider activeStep={0} />
                  </StepsRegister.Li>
                </>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Text size={15} textAlign='center'>
                        Horários
                      </Text>
                    </View>
                  </StepsRegister.Li>
                </>
              </StepsRegister.Container>
            </View>

            <View>
              <Text mt={0.6} size={13} textAlign='center'>
                Digite aqui todos os telefones de contato da sua loja, incluindo
                atendimento por Whatsapp
              </Text>
            </View>

            <View flex justify='center' width='100%' mt={2}>
              <View width='22rem'>
                <form onSubmit={handleSubmit(onSubmitPhones)}>
                  <Row>
                    <Col xs={9}>
                      <InputText
                        {...register('phone', {
                          required: 'O Número é obrigatório',
                          pattern: {
                            value: /\(\d{2}\) \d{4,5}-\d{4}/,
                            message: 'Digite seu número de telefone com DDD.',
                          },
                        })}
                        type='text'
                        name='phone'
                        validationError={errors.phone}
                        messageError={errors.phone?.message}
                        onChange={maskPhone}
                      />
                    </Col>
                    <Col xs={3}>
                      <Button
                        label={<img src={Plus} alt='mais' />}
                        style={{ padding: '10px 6px', minHeight: '0px' }}
                        background='#3097E2'
                      />
                    </Col>
                  </Row>
                </form>

                <View style={{ overflowY: 'auto', padding: 5, marginTop: 8 }}>
                  {fields.map((field, index) => (
                    <ShadowBox key={field.id} style={{ marginBottom: 18 }}>
                      <Text size={14} fontWeight={300}>
                        {field.phone}
                      </Text>
                      <View direction='column' align='center'>
                        <Switch
                          checked={field.principal}
                          onChange={handleSwitchDefault(index)}
                        />
                        <Text size={11}>Principal</Text>
                      </View>

                      <View direction='column' align='center'>
                        <Switch
                          checked={field.whatsapp}
                          onChange={handleSwitch(index)}
                        />
                        <Text size={11} style={{ marginRight: 6 }}>
                          Whatsapp
                        </Text>
                      </View>
                      <DeleteOutline
                        fontSize='medium'
                        onClick={() => remove(index)}
                        style={{
                          cursor: 'pointer',
                          marginLeft: 6,
                          color: '#FF0000',
                        }}
                      />
                    </ShadowBox>
                  ))}
                </View>
              </View>
            </View>
          </View>

          <View flex justify='flex-end' mt={2}>
            <View width='50%' direction='row'>
              <View width='100%' mr={1}>
                <Button
                  type='reset'
                  label='Cancelar'
                  borderColor='#3097E2'
                  color='#3097E2'
                  background='#fff'
                  onClick={handleToggleModal}
                />
              </View>
              <View width='100%'>
                <Button
                  type='reset'
                  label='Próximo'
                  background='#3097E2'
                  onClick={onSubmit}
                />
              </View>
            </View>
          </View>
        </div>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={OfferUser}
          description='Tem certeza que deseja cancelar o cadastro da loja?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          handleConfirm={handleCancelConfirm}
        />
      )}
    </>
  )
}

export default AddPhones
