import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
`

export const ScrollContent = styled.div`
  height: calc(100vh - 337px);
  overflow: auto;
`

export const shadowBox = {
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
  borderRadius: 5,
  padding: 30,
  overflow: 'auto',
}

export const columnStyle = {
  borderLeft: '1px solid #C4C4C4',
}

export const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px dashed #c4c4c4;
  height: 160px;
`

export const Box = styled.div<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? '#3097E2' : '#fff')};
  height: 30px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${({ selected }) => (selected ? '#3097E2' : '#C4C4C4')};
  margin-right: 10px;

  p {
    color: ${({ selected }) => (selected ? '#fff' : '#C4C4C4')};
  }
`
