import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const RangeInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  cursor: pointer;

  &::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #ccc;
    border: none;
    border-radius: 3px;
  }

  &::-webkit-range-track {
    background: #2d9cdb;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #2d9cdb;
    margin-top: -4px;
  }

  &:focus {
    outline: none;
  }
`
