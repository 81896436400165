import { useCallback, useEffect, useState } from 'react'
import { Col } from 'react-grid-system'

import update from 'immutability-helper'
import { LoadingSearch } from 'src/components/elements'
import { Button } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { Row } from 'src/components/helper/Table'
import {
  getHomeHighlights,
  setHomeHighlights,
  sortCategoriesHighlights,
} from 'src/services/ProductService'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

import { Category } from './components/Category'
import { SearchProducts } from './components/SearchProducts'
import { CategorySortT, CategoryT, ProductT } from './types'

export const Highlights = () => {
  const [categories, setCategories] = useState<CategoryT[]>([])
  const [selectedProduct, setSelectedProduct] = useState<ProductT | undefined>()
  const [enableSave, setEnableSave] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(true)
  const [currentCategory, setCurrentCategory] = useState<string>('')

  const fillCategories = async () => {
    setLoadingData(true)
    const result = await getHomeHighlights()
    const productsCategories = result.data.products_categories
    const activeCategories = result.data.categories_to_carousels_highlights
    const data = []
    let index = 0

    for (const category in productsCategories) {
      if (category === 'Dor e febre') continue

      const categorySort = activeCategories.find(
        (c: CategorySortT) => c.name === category
      )

      data.push({
        id: index,
        title: category,
        products: productsCategories[category],
        checked: !!categorySort,
        sort: categorySort?.sort || 999,
      })

      data.sort((a, b) => a.sort - b.sort)

      index++
    }

    setCategories(data)
    setLoadingData(false)
  }

  useEffect(() => {
    fillCategories()
  }, [])

  const moveCategory = useCallback((dragIndex, hoverIndex) => {
    !enableSave && setEnableSave(true)
    setCategories((prevCategories: CategoryT[]) =>
      update(prevCategories, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCategories[dragIndex]],
        ],
      })
    )
  }, [])

  const handleCheck = (id: number) => {
    setCategories((prev: CategoryT[]) =>
      prev
        .map((item: CategoryT) =>
          item.id === id
            ? { ...item, checked: !item.checked, sort: item.checked ? 999 : 0 }
            : item
        )
        .sort((a, b) => a.sort - b.sort)
    )
    setEnableSave(true)
  }

  const handleSave = async () => {
    setLoading(true)
    setLoadingData(true)
    const checkedCategories = categories.filter(({ checked }) => checked)

    try {
      setEnableSave(false)
      await sortCategoriesHighlights(
        checkedCategories.map((c, i) => ({ name: c.title, sort: i + 1 }))
      )

      for (const category of checkedCategories) {
        await setHomeHighlights(
          category.title,
          category.products.map((p, i) => ({ ...p, sort: i + 1 }))
        )
      }

      // await Promise.all(
      //   checkedCategories.map(async ({ title, products }) =>
      //     await setHomeHighlights(
      //       title,
      //       products.map((p, i) => ({ ...p, sort: i + 1 }))
      //     )
      //   )
      // )

      await fillCategories()
    } catch (error) {
      setEnableSave(true)
    }
    setLoading(false)
    // setLoadingData(false)
  }

  const handleCancel = () => {
    setEnableSave(false)
    fillCategories()
  }

  return currentCategory !== '' ? (
    <SearchProducts
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      setCurrentCategory={setCurrentCategory}
      categories={categories}
      setCategories={setCategories}
      currentCategory={currentCategory}
      setEnableSave={setEnableSave}
    />
  ) : (
    <>
      <Container>
        <View pb={2}>
          <Row>
            <Col xs={9}>
              <Text size={25} fontWeight={700}>
                Destaques
              </Text>
            </Col>
          </Row>
        </View>

        <View height='calc(100% - 150px)' style={shadowBox}>
          {loadingData ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <LoadingSearch />
            </div>
          ) : (
            <div>
              {categories.map((card: CategoryT, index: number) => (
                <Category
                  setCurrentCategory={setCurrentCategory}
                  enableSave={enableSave}
                  setEnableSave={setEnableSave}
                  products={card.products}
                  setCategories={setCategories}
                  key={card.id}
                  index={index}
                  id={card.id}
                  title={card.title}
                  moveCategory={moveCategory}
                  checked={card.checked}
                  handleCheck={handleCheck}
                />
              ))}
            </div>
          )}
        </View>
        <View flex justify='flex-end' mt={2}>
          <View width='50%' direction='row'>
            <View width='100%' mr={1}>
              <Button
                label='Cancelar'
                borderColor='#3097E2'
                color='#3097E2'
                background='#fff'
                onClick={enableSave ? handleCancel : () => null}
              />
            </View>
            <View width='100%'>
              <Button
                label='Salvar'
                background='#3097E2'
                onClick={handleSave}
                disabled={!enableSave || loading}
              />
            </View>
          </View>
        </View>
      </Container>
    </>
  )
}
