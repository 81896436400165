import OfferUser from 'src/assets/images/empty-user.svg'
import { Button } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useUser from 'src/hooks/useUser'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

const EmptyUser = () => {
  const { handleNavegateAdd } = useUser()

  return (
    <Container>
      <View pb={2}>
        <Text size={25} fontWeight={700}>
          Equipe
        </Text>
      </View>
      <View
        flex
        justify='center'
        align='center'
        height='calc(100% - 70px)'
        style={shadowBox}
      >
        <View width='300px' justify='center' direction='column'>
          <View pb={2} textAlign='center'>
            <img src={OfferUser} alt='Oferta vazia' />
          </View>
          <View pb={2}>
            <Text textAlign='center'>
              Você não possui nenhuma pessoa cadastrada.
            </Text>
            <Text textAlign='center' mt={1}>
              Convide pessoas para ajudar a sua farmácia a vender ainda mais!
            </Text>
          </View>
          <Button
            label='Cadastrar pessoa'
            background='#3097E2'
            onClick={handleNavegateAdd}
          />
        </View>
      </View>
    </Container>
  )
}
export default EmptyUser
