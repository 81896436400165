import { useState } from 'react'
import { Container, Col, Row, Hidden } from 'react-grid-system'
import PinInput from 'react-pin-input'

import Image from 'src/assets/images/farmazon-user.png'
import Logo from 'src/assets/images/logo-farmazon-black.svg'
import { AlertLogin } from 'src/components/elements'
import { Button } from 'src/components/form'
import {
  ImageResize,
  View,
  Text,
  ContainerAnimation,
} from 'src/components/helper'
import useAuth from 'src/hooks/useAuth'

import { loginAnimation } from 'src/styles'

const SignUpConfirm = () => {
  const [pin, setPin] = useState('')
  const { validation, loading, confirmPIN, resendPIN } = useAuth()

  const handleOnSubmit = (event: any) => {
    event.preventDefault()
    confirmPIN(pin)
  }

  const handleChange = (value: string) => setPin(value)

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row nogutter align='stretch' style={{ height: 'calc(100vh)' }}>
        <Hidden xs sm md>
          <Col md={8}>
            <ImageResize url={`url(${Image})`} />
          </Col>
        </Hidden>
        <Col xs={12} lg={4}>
          <Row
            align='center'
            justify='center'
            nogutter
            style={{ height: '100%' }}
          >
            <Col xs={8}>
              <ContainerAnimation {...loginAnimation}>
                <View textAlign='center' pt={4}>
                  <img src={Logo} alt='Farmazon logo' />
                </View>

                <View mt={1}>
                  <Text textAlign='center' fontWeight={500} size={24}>
                    SMS enviado
                  </Text>
                </View>

                <View mt={1}>
                  <Text size={14} textAlign='center'>
                    Enviamos um código PIN para confirmar o seu cadastro.
                  </Text>
                </View>

                <form onSubmit={handleOnSubmit} style={{ height: '100%' }}>
                  <View flex direction='column' height='100%'>
                    <View flex mt={2} mb={3} justify='center'>
                      <PinInput
                        length={6}
                        onChange={handleChange}
                        onComplete={handleChange}
                        inputStyle={{
                          border: '1px solid #C4C4C4',
                          borderRadius: 5,
                          width: 40,
                        }}
                      />
                    </View>

                    <View style={{ marginTop: 'auto' }}>
                      <Row align='center'>
                        <Col xs={12}>
                          <Button
                            label='Confirmar'
                            background='#52C372'
                            loading={loading}
                            disabled={pin === ''}
                          />
                        </Col>
                      </Row>
                    </View>

                    <View
                      mt={2}
                      flex
                      align='center'
                      justify='center'
                      direction='column'
                    >
                      <Text size={14} fontWeight={400}>
                        Não recebi o código
                      </Text>
                      <Text
                        size={12}
                        fontWeight={300}
                        onClick={resendPIN}
                        cursor={1}
                      >
                        Enviar novamente
                      </Text>
                    </View>
                  </View>
                </form>
              </ContainerAnimation>
            </Col>

            <View mt={1}>
              <Text size={13} color='#C4C4C4' textAlign='center'>
                Copyright © 2022 Farmazon. Todos os direitos reservados.
              </Text>
            </View>
          </Row>

          {validation && (
            <AlertLogin message='Ops! Seu login ou senha estão incorretos.' />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default SignUpConfirm
