import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useForm } from 'react-hook-form'
import Moment from 'react-moment'
import { Link, useHistory } from 'react-router-dom'

import { Grid } from '@material-ui/core'
import { isAfter } from 'date-fns'
import { Modal } from 'src/components/elements'
import { Button, InputText, Select } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { useNewFetch } from 'src/hooks/useFetch'
import {
  createHomecareOrder,
  downloadReport,
  searchUserhomecare,
} from 'src/services/homecare'
import { truncate } from 'src/util/helpers/string'
import { mutate } from 'swr'
import { twMerge } from 'tailwind-merge'

const Homecare = () => {
  const alert = useAlert()
  const history = useHistory()
  const { data, error } = useNewFetch<any>('/admin/homecares', undefined, {
    refreshInterval: 3000,
  })

  const [searchUserPhone, setSearchUserPhone] = useState('')
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [users, setUsers] = useState<any[]>([])
  const [registerOrderLoading, setRegisterOrderLoading] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [selectedAddress, setSelectedAddress] = useState()

  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [reportUserPhone, setReportUserPhone] = useState<string>('')
  const [reportStartDate, setReportStartDate] = useState(new Date())
  const [reportEndDate, setReportEndDate] = useState(new Date())
  const [reportLoading, setReportLoading] = useState(false)

  const { data: addressesData } = useNewFetch(
    selectedUser ? `/admin/customers/${selectedUser}/addresses` : undefined
  )

  const { handleSubmit } = useForm()

  useEffect(() => {
    if (!selectedUser && users?.length) {
      setSelectedUser(users?.[0].sub)
    }
  }, [selectedUser, users])

  useEffect(() => {
    if (!selectedAddress && addressesData?.length) {
      setSelectedAddress(addressesData?.[0].pub_id)
    }
  }, [selectedAddress, addressesData])

  const onSubmitHandler = async () => {
    try {
      setRegisterOrderLoading(true)

      const { data: order } = await createHomecareOrder({
        userId: selectedUser,
        delivery_address: selectedAddress,
      })
      await mutate('/admin/homecares')

      alert.success('Home care solicitado com sucesso')

      history.push(`/homecare/${order.id}`)
      closeModalHandler()

      alert.success('Pedido cadastrado com sucesso!')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setRegisterOrderLoading(false)
    }
  }

  const newOrderHandler = () => {
    setModalOpened(true)
  }

  const closeModalHandler = () => {
    setModalOpened(false)
  }

  const onSelecthandler = (e: any) => {
    setSelectedUser(e.target.value)
  }

  const onSelectAddressHandler = (e: any) => {
    setSelectedAddress(e.target.value)
  }

  const searchCustomerHandler = async () => {
    try {
      setLoadingUsers(true)
      const { data } = await searchUserhomecare(searchUserPhone)
      setUsers(data.Users)
      setSearchUserPhone('')
    } finally {
      setLoadingUsers(false)
    }
  }

  const onSearchUserChange = (e: any) => {
    setSearchUserPhone(e.target.value)
  }

  const onChangeRepportUserPhone = (e: any) => {
    setReportUserPhone(e.target.value)
  }

  const openReportModal = () => {
    setReportModalOpen(true)
  }

  const closeReportModal = () => {
    setReportModalOpen(false)
  }

  const reportSubmitHandler = async (e: any) => {
    e.preventDefault()

    try {
      setReportLoading(true)

      if (isAfter(reportEndDate, new Date())) {
        alert.error('A data de termino não pode ser maior que hoje')
        return
      }

      if (isAfter(reportStartDate, reportEndDate)) {
        alert.error('A data de início não pode ser maior que a de termino')
        return
      }

      const { data: reportBlob } = await downloadReport({
        user_phone: reportUserPhone,
        start_date: reportStartDate.toISOString(),
        end_date: reportEndDate.toISOString(),
      })

      const link = document.createElement('a')
      link.href = `data:application/pdf;base64,${reportBlob}`
      link.setAttribute('download', `${reportUserPhone}.pdf`)

      document.head.appendChild(link)
      link.click()
      document.head.removeChild(link)

      closeReportModal()
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setReportLoading(false)
    }
  }

  const onReportDateChange = (e: any) => {
    if (e.target.name === 'start_date') {
      setReportStartDate(new Date(e.target.value))
    } else {
      setReportEndDate(new Date(e.target.value))
    }
  }

  return (
    <>
      <main>
        <View
          style={{ width: '100%' }}
          className='tabbar-safe'
          direction='row'
          justify='space-between'
          align='center'
        >
          <Text color='#EA2A2F' size={20} fontWeight={700}>
            Home care
          </Text>

          <div className='flex '>
            <View width='200px' className='mr-4'>
              <Button
                background='#3097E2'
                label='Download relatório'
                onClick={openReportModal}
              />
            </View>

            <View width='200px'>
              <Button label='Novo pedido' onClick={newOrderHandler} />
            </View>
          </div>
        </View>

        <View pt={2}>
          {!data && !error && (
            <View
              style={{ height: '400px', width: '100%' }}
              justify='center'
              align='center'
            >
              {/* <LoadingMessage /> */}
            </View>
          )}

          {data && data.length <= 0 && (
            <View mb={2}>
              <Text>Você ainda não há pedido.</Text>
            </View>
          )}

          <Grid container spacing={2}>
            {data?.map((item: any, index: number) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <div
                  key={index}
                  className='flex flex-col w-full border-[1px] p-2 border-gray-200 rounded-md h-[200px]'
                >
                  <div className='flex justify-between items-center h-10'>
                    <div>
                      <Text color='#474747' size={12} fontWeight={700}>
                        <Moment format='DD [de] MMMM' locale='pt-br'>
                          {new Date(item.order.created_at)}
                        </Moment>
                      </Text>

                      <span className='mt-1 text-[11px]'>
                        Pedido nº #{item?.order.identifier}
                      </span>
                    </div>

                    <span
                      className={twMerge(
                        'text-[12px] text-blue-main font-bold',
                        item?.order?.status?.id === 6 && 'text-green-600'
                      )}
                    >
                      {item.order.status.description}
                    </span>
                  </div>
                  <hr />

                  <div className='flex flex-1 flex-col mt-4'>
                    <div className='flex flex-1 text-ellipsis mb-2'>
                      <span className='text-sm'>
                        {truncate(
                          item?.lastInteraction?.description || 'Sem interação',
                          135
                        )}
                      </span>
                    </div>

                    {item?.lastInteraction?.created_at ? (
                      <div className='mt-auto mb-2 text-xs'>
                        <span className='font-bold'>Enviado em: </span>
                        <Moment
                          format='DD/MM/YYYY [às] HH:mm:ss'
                          locale='pt-br'
                        >
                          {new Date(item?.lastInteraction?.created_at)}
                        </Moment>
                      </div>
                    ) : null}

                    <div className='mt-auto'>
                      <Link to={`/homecare/${item.order.id}`}>
                        <a>
                          <Button background='#3097E2' label='Ver detalhes' />
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </View>
      </main>

      {modalOpened ? (
        <Modal width={35} onClose={closeModalHandler}>
          <form className='w-full' onSubmit={handleSubmit(onSubmitHandler)}>
            <Text
              textAlign='center'
              mb={3}
              size={20}
              color='#EA2A2F'
              fontWeight={900}
            >
              Solicitar cotação
            </Text>

            <div className='mt-3'>
              <span className='text-sm font-bold'>Pesquisar cliente</span>
              <InputText
                placeholder='Digite o número ex: +551199999-9999'
                onChange={onSearchUserChange}
                value={searchUserPhone}
              />
            </div>

            <div className='w-[120px] mt-2'>
              <Button
                loading={loadingUsers}
                label='Pesquisar'
                style={{ height: '35px' }}
                onClick={searchCustomerHandler}
              />
            </div>

            <div className='mt-3'>
              <span className='text-sm font-bold'>Cliente</span>
              <Select style={{ textIndent: '0' }} onChange={onSelecthandler}>
                {users.map((user: any) => (
                  <option
                    key={user.sub}
                    selected={selectedUser === user.sub}
                    value={user.sub}
                  >
                    {user.name}
                  </option>
                ))}
              </Select>
            </div>

            <div className='mt-3'>
              <span className='text-sm font-bold'>Endereço de entrega</span>
              <Select
                style={{ textIndent: '0' }}
                onChange={onSelectAddressHandler}
              >
                {addressesData?.map((address: any) => {
                  const formatted = `${address.street}, ${address.number}, ${address.neighborhood}`
                  const isSelected = selectedAddress === address.pub_id

                  return (
                    <option
                      key={address.pub_id}
                      selected={isSelected}
                      value={address.pub_id}
                    >
                      {formatted}
                    </option>
                  )
                })}
              </Select>
            </div>

            <div className='mt-8'>
              <Button label='Enviar' loading={registerOrderLoading} />
            </div>
          </form>
        </Modal>
      ) : null}

      {reportModalOpen ? (
        <Modal width={35} onClose={closeReportModal}>
          <form className='w-full' onSubmit={reportSubmitHandler}>
            <Text
              textAlign='center'
              mb={3}
              size={20}
              color='#EA2A2F'
              fontWeight={900}
            >
              Solicitar relatório
            </Text>

            <div className='mt-3'>
              <span className='text-sm font-bold'>Cliente</span>
              <InputText
                placeholder='Digite o número ex: +551199999-9999'
                onChange={onChangeRepportUserPhone}
                value={reportUserPhone}
              />
            </div>

            <div className='mt-4'>
              <p className='font-bold'>Período</p>

              <div className='flex items-center mt-3'>
                <div className='mr-6 flex flex-1 flex-col'>
                  <label htmlFor='start_date' className='text-xs font-bold'>
                    Início
                  </label>
                  <input
                    className='mt-1 border-gray-400 border-b'
                    type='date'
                    id='start_date'
                    name='start_date'
                    onChange={onReportDateChange}
                  />
                </div>

                <div className='flex  flex-1  flex-col'>
                  <label htmlFor='end_date' className='text-xs font-bold'>
                    Termino
                  </label>
                  <input
                    className='mt-1 border-gray-400 border-b'
                    type='date'
                    id='end_date'
                    name='end_date'
                    onChange={onReportDateChange}
                  />
                </div>
              </div>
            </div>

            <div className='mt-8'>
              <Button
                label='Enviar'
                disabled={
                  !reportUserPhone || !reportStartDate || !reportEndDate
                }
                loading={reportLoading}
              />
            </div>
          </form>
        </Modal>
      ) : null}
    </>
  )
}

export default Homecare
