import { useSelector } from 'react-redux'

import { DefaultRootState } from 'src/redux/store'

import { Container, Content } from './styles'

import StoreInfo from './StoreInfo'
import StoreTerm from './StoreTerm'
import Welcome from './Welcome'

function Onboard() {
  const { onboarding } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )

  const renderSwitchMatrix = (screen: string) => {
    switch (screen) {
      case 'StoreTerm':
        return (
          <Content>
            <StoreTerm data={onboarding} />
          </Content>
        )
      case 'StoreInfo':
        return (
          <Content>
            <StoreInfo data={onboarding} />
          </Content>
        )
      case 'Init':
        return (
          <Content>
            <Welcome />
          </Content>
        )
    }
  }

  return <Container>{renderSwitchMatrix(onboarding.screenStep)}</Container>
}

export default Onboard
