import api from './api'

export const putCupomById = async (id: string, data: any) =>
  api.put(`/orders/admin/change/discount-coupon/${id}`, data)

export const postCupom = async (data: any) =>
  api.post('/orders/admin/insert/discount-coupon', data)

export const deleteCupomById = async (id: string) =>
  api.delete(`/orders/admin/delete/discount-coupon/${id}`)
