import { forwardRef } from 'react'

import { Container, RangeInput } from './styles'

function Range(
  { ...rest }: React.InputHTMLAttributes<HTMLInputElement>,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  return (
    <Container>
      <RangeInput {...rest} type='range' ref={forwardedRef} />
    </Container>
  )
}

export default forwardRef(Range)
