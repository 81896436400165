import Background from 'src/assets/images/onboarding-background.png'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: calc(100%);
  background-color: ${({ theme }) => theme.colors.blue[1]};
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`

export const Content = styled.div<{ height?: number }>`
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  padding: 27px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 320px) {
    width: 340px;
    height: ${({ height = 540 }) => `${height}px`};
    border-radius: 15px;
  }
`

export const Leave = styled.div`
  border: 1px solid #474747;
  border-radius: 5px;
  width: 65px;
  padding: 7px 12px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`
