import styled from 'styled-components'

const MenuLink = styled.div<{ selected?: boolean }>`
  padding: 16px 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${({ selected = false }) =>
    selected ? '#FFE7C2' : 'transparent'};

  &:hover {
    background: #ffe7c2;
  }

  p {
    color: #474747;
    font-weight: ${({ selected = false }) => (selected ? '500' : '200')};
  }
`

export default MenuLink
