import { ChangeEvent, useMemo, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useSelector } from 'react-redux'

import { format, fromUnixTime } from 'date-fns'
import Reject from 'src/assets/icons/icon-rejeitado.svg'
import { LoadingSearch, Modal, ModalEvent } from 'src/components/elements'
import { Button, InputText, Radio } from 'src/components/form'
import { Line, Text, View } from 'src/components/helper'
import useOperation from 'src/hooks/useOperation'
import useSale from 'src/hooks/useSale'
import { DefaultRootState } from 'src/redux/store'
import { formatCurrency, unFormatPhone } from 'src/util/helpers/string'

import { ModalMainContent, ModalMainHeader } from './styles'

interface MainModalProps {
  storeId: string
  loading: boolean
}

const MainModal = ({ loading, storeId }: MainModalProps) => {
  const {
    sale: { details },
  } = useSelector<DefaultRootState, DefaultRootState>((state) => state)

  const {
    openModal,
    loading: loadingButton,
    handleAcceptOrder,
    handleDeclineOrder,
    handleOpenModalDecline,
    handleCloseModal,
    handleGoogleLink,
  } = useSale()

  const { getProductOption, getIconOption } = useOperation()

  const [reason, setRease] = useState('Oferta vencida')
  const [description, setDescription] = useState('')

  const handleReason = (reason: string) => () => setRease(reason)

  const handleDescription = (e: ChangeEvent<HTMLInputElement>) =>
    setDescription(e.target.value)

  const handleGoogle = () => handleGoogleLink(details)

  const creationDateTime = useMemo(() => {
    if (details?.schedule_info) {
      return `${details?.schedule_info.date} - ${details?.schedule_info.time}`
    }

    if (details?.created_at) {
      return format(fromUnixTime(details?.created_at), 'dd/MM/yyy - HH:mm')
    }

    return ''
  }, [details])

  if (openModal && details) {
    return (
      <Modal paddingTop={3} width={20} padding={0}>
        <ModalMainContent>
          <View flex direction='column' height='29rem'>
            <View mt={1} mb={1} flex justify='center'>
              <img src={Reject} alt='Direita' />
            </View>
            <Text textAlign='center' fontWeight={700} color='#FF0000'>
              Pedido rejeitado
            </Text>
            <Text textAlign='center' size={13} mt={0.6}>
              Qual o motivo que fez você rejeitar este pedido?
            </Text>

            <View style={{ right: 20 }}>
              <View height='38px'>
                <Radio
                  checked={reason === 'Oferta vencida'}
                  onClick={handleReason('Oferta vencida')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Oferta vencida
                </Text>
              </View>
              <View height='38px'>
                <Radio
                  checked={reason === 'Preços abaixo da tabela'}
                  onClick={handleReason('Preços abaixo da tabela')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Preços abaixo da tabela
                </Text>
              </View>
              <View height='38px'>
                <Radio
                  checked={reason === 'Sem estoque'}
                  onClick={handleReason('Sem estoque')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Sem estoque
                </Text>
              </View>
              <View height='38px'>
                <Radio
                  checked={reason === 'Fora da área de entrega'}
                  onClick={handleReason('Fora da área de entrega')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Fora da área de entrega
                </Text>
              </View>
              <View height='38px'>
                <Radio
                  checked={reason === 'Outro'}
                  onClick={handleReason('Outro')}
                  readOnly
                />
                <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                  Outro
                </Text>
                {reason === 'Outro' && (
                  <View mt={1} ml={1}>
                    <InputText
                      label='descreva o motivo:'
                      value={description}
                      onChange={handleDescription}
                    />
                  </View>
                )}
              </View>
            </View>

            <View flex style={{ marginTop: 'auto' }}>
              <Button
                label='Enviar'
                background='#3097E2'
                loading={loadingButton}
                onClick={handleDeclineOrder(
                  storeId,
                  details.proposal,
                  { reason, description },
                  details.pub_id
                )}
              />
            </View>
          </View>
        </ModalMainContent>
      </Modal>
    )
  }

  return (
    <ModalEvent
      paddingTop={3}
      width={45}
      padding={0}
      marginTop={3}
      onClick={handleCloseModal}
    >
      <ModalMainHeader>
        <Text size={20} fontWeight={700} color='#fff'>
          {details?.identifier &&
            `Pedido  #${details?.identifier?.toUpperCase()}`}
        </Text>
        <Text size={16} fontWeight={400} color='#fff' ml={1}>
          {creationDateTime}
        </Text>
      </ModalMainHeader>

      <ModalMainContent>
        {loading && (
          <View height='300px' justify='center' align='center'>
            <LoadingSearch />
          </View>
        )}

        {!loading && details && (
          <Row style={{ height: '480px' }}>
            <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
              <Text fontWeight={700} color='#3097E2'>
                Detalhes do pedido
              </Text>
              <View mb={1} height='24rem' style={{ overflow: 'auto' }}>
                {details.products.map((item, index) => (
                  <>
                    <View
                      flex
                      align='center'
                      justify='space-between'
                      mb={0.6}
                      key={index}
                    >
                      <View>
                        <Text size={12} fontWeight={700}>
                          {item.name}
                        </Text>
                        <Text size={11} fontWeight={300} mt={1}>
                          Quantidade: {item.quantity}
                        </Text>
                      </View>

                      <View ml={1} width='7rem'>
                        <Text size={14} fontWeight={700}>
                          {formatCurrency(item.max_price)}
                        </Text>
                      </View>
                    </View>

                    <View
                      direction='row'
                      justify='space-between'
                      padding={0.6}
                      width='100%'
                    >
                      <View direction='row'>
                        <img
                          src={getIconOption(
                            item.required,
                            item.substitute_products ?? []
                          )}
                          width={20}
                          height={20}
                          alt='Trocar'
                        />
                        <Text ml={1} size={14} fontWeight={500}>
                          {getProductOption(
                            item.required,
                            item.substitute_products ?? []
                          )}
                        </Text>
                      </View>
                    </View>

                    {item.substitute_products?.length > 0 && (
                      <View mt={1}>
                        <Text size={10} fontWeight={400}>
                          O cliente selecionou{' '}
                          <strong>{item.substitute_products?.length}</strong>{' '}
                          produtos substitutos para este item.
                        </Text>
                        {item.substitute_products.map((product, index) => (
                          <View
                            background='#F2F2F2'
                            padding={0.4}
                            mt={0.4}
                            flex
                            justify='space-between'
                            key={index}
                          >
                            <Text size={11} fontWeight={400}>
                              {product.name}
                            </Text>
                            <Text size={12} fontWeight={500}>
                              {formatCurrency(product.max_price)}
                            </Text>
                          </View>
                        ))}
                      </View>
                    )}

                    <View mt={1} mb={1}>
                      <Line />
                    </View>
                  </>
                ))}
              </View>

              <View style={{ marginTop: 'auto' }}>
                {!loadingButton && (
                  <Button
                    label='Rejeitar'
                    background='#FF0000'
                    onClick={handleOpenModalDecline}
                  />
                )}
              </View>
            </Col>

            <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
              <Text fontWeight={700} color='#3097E2'>
                Valores
              </Text>

              <View mt={1}>
                <View flex align='center' justify='space-between' mb={0.6}>
                  <Text size={14} fontWeight={400}>
                    Valor máximo dos produtos
                  </Text>
                  <Text
                    size={14}
                    fontWeight={400}
                    style={{
                      textDecorationLine: details.confirm_checkout?.nf_total
                        ? 'line-through'
                        : 'auto',
                    }}
                  >
                    {formatCurrency(details.max_total)}
                  </Text>
                </View>

                {details?.confirm_checkout?.nf_total && (
                  <View flex align='center' justify='space-between' mb={0.6}>
                    <Text size={14} fontWeight={400}>
                      Valor atualizado dos produtos
                    </Text>
                    <Text size={14} fontWeight={400}>
                      {details.confirm_checkout?.nf_total
                        ? formatCurrency(details.confirm_checkout?.nf_total)
                        : ' - '}
                    </Text>
                  </View>
                )}

                {details.delivery_type === 'store_delivery' && (
                  <View flex align='center' justify='space-between' mb={0.6}>
                    <Text size={14} fontWeight={400}>
                      Entrega
                    </Text>
                    <Text size={14} fontWeight={400}>
                      {formatCurrency(details.delivery_fee)}
                    </Text>
                  </View>
                )}

                {typeof details?.store_rate === 'number' &&
                  details?.store_rate !== 0 && (
                    <View flex align='center' justify='space-between' mb={0.6}>
                      <Text size={14} fontWeight={400}>
                        Taxa Farmazon
                      </Text>
                      <Text size={14} fontWeight={400}>
                        {formatCurrency(details.store_rate)}
                      </Text>
                    </View>
                  )}

                <View flex align='center' justify='space-between' mb={0.6}>
                  <Text size={14} fontWeight={500}>
                    Valor total
                  </Text>
                  <Text size={14} fontWeight={500}>
                    {formatCurrency(details.total)}
                  </Text>
                </View>
              </View>

              <View mt={0.6}>
                <Line />
              </View>

              <View mt={0.6} pt={0.6}>
                <Text mb={0.6} fontWeight={700} color='#3097E2'>
                  Cliente
                </Text>
                <View flex direction='row' justify='space-between'>
                  <View mb={0.6}>
                    <Text size={13} fontWeight={500}>
                      Nome
                    </Text>
                    <Text size={13}>{details.customer.name}</Text>
                  </View>
                  <View mb={0.6}>
                    <Text size={13} fontWeight={500}>
                      Telefone
                    </Text>
                    <Text size={13}>
                      {unFormatPhone(details.customer.phone_number)}
                    </Text>
                  </View>
                </View>
              </View>

              <View mt={0.6}>
                <Text fontWeight={700} color='#3097E2'>
                  Endereço de entrega
                </Text>
                <View
                  flex
                  direction='row'
                  justify='space-between'
                  align='center'
                >
                  <View>
                    <Text size={12} fontWeight={500}>
                      {details.delivery_address.street},{' '}
                      {details.delivery_address.number} /{' '}
                      {details.delivery_address.more} -{' '}
                      {details.delivery_address.neighborhood},{' '}
                      {details.delivery_address.city} -{' '}
                      {details.delivery_address.state}
                    </Text>
                  </View>
                  <View
                    background='#F5B85B'
                    height='3rem'
                    width='5rem'
                    flex
                    justify='center'
                    align='center'
                    direction='column'
                    style={{ borderRadius: 6 }}
                    ml={1}
                    cursor
                    onClick={handleGoogle}
                  >
                    <svg
                      width='16'
                      height='20'
                      viewBox='0 0 16 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.75 8.5C14.75 13.75 8 18.25 8 18.25C8 18.25 1.25 13.75 1.25 8.5C1.25 6.70979 1.96116 4.9929 3.22703 3.72703C4.4929 2.46116 6.20979 1.75 8 1.75C9.79021 1.75 11.5071 2.46116 12.773 3.72703C14.0388 4.9929 14.75 6.70979 14.75 8.5Z'
                        stroke='white'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M8 10.75C9.24264 10.75 10.25 9.74264 10.25 8.5C10.25 7.25736 9.24264 6.25 8 6.25C6.75736 6.25 5.75 7.25736 5.75 8.5C5.75 9.74264 6.75736 10.75 8 10.75Z'
                        stroke='white'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <Text size={11} color='#fff'>
                      Mapa
                    </Text>
                  </View>
                </View>
              </View>

              <View mt={0.6} mb={2}>
                <Text fontWeight={700} color='#3097E2'>
                  Meio de pagamento
                </Text>
                <Text mt={1} fontWeight={500} size={12}>
                  {details.payment_type.description === 'app'
                    ? 'Pelo aplicativo'
                    : 'Na entrega'}
                  {details.payment_type.description !== 'app' ? (
                    <View>
                      {details.payment_type.mode === 'card'
                        ? 'Maquininha'
                        : 'Em dinheiro'}{' '}
                      {details.payment_type.mode !== 'card' &&
                        formatCurrency(
                          parseFloat(details.payment_type.change_for)
                        )}
                    </View>
                  ) : null}
                </Text>
              </View>

              {/* <View mt={1}>
                <Text fontWeight={700} color='#3097E2'>Cupom de desconto</Text>
                <Text mt={1} fontWeight={500} size={12}>Nenhum</Text>
              </View> */}

              <View style={{ marginTop: 'auto' }}>
                <Button
                  label='Aceitar'
                  background='#52C372'
                  loading={loadingButton}
                  onClick={handleAcceptOrder(
                    storeId,
                    details.proposal,
                    details.pub_id
                  )}
                />
              </View>
            </Col>
          </Row>
        )}
      </ModalMainContent>
    </ModalEvent>
  )
}

export default MainModal
