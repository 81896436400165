import { useState } from 'react'

import { useInterval } from './useInterval'

const ONE_MINUTE = 1000 * 60

export function useTimer(initialTimer = 5) {
  const [timer, setTimer] = useState(initialTimer)

  function handleDecreaseTimer() {
    setTimer((oldTimer) => {
      return oldTimer - 1
    })
  }

  useInterval(handleDecreaseTimer, ONE_MINUTE)

  return timer >= 0 ? timer : 0
}
