import { Link } from 'react-router-dom'

import { format } from 'date-fns'
import Nota from 'src/assets/icons/icone-nota-fiscal.svg'
import { DataRow, Header, Table, Text, View } from 'src/components/helper'
import { statusValues } from 'src/util/constants'
import { formatCurrency } from 'src/util/helpers/string'

import { LoadingSearch, Modal } from '..'

import { ModalMainContent } from './styles'

interface ModalListProps {
  data: any[]
  loading: boolean
  onClose?: () => void
}

function ModalList({ data, loading, onClose }: ModalListProps) {
  return (
    <Modal paddingTop={5} width={44} padding={0} onClose={onClose}>
      <ModalMainContent>
        {loading && (
          <View height='100%' justify='center' align='center'>
            <LoadingSearch />
          </View>
        )}

        {!loading && data && (
          <View height='100%' mt={1.4}>
            <Table.Table id='listSale'>
              <thead>
                <tr>
                  <Header textAlign='left' width='80px'>
                    Número
                  </Header>
                  <Header textAlign='left'>Status</Header>
                  <Header textAlign='left'>Valor</Header>
                  <Header textAlign='left'>Quantidade</Header>
                  <Header textAlign='left'>Data criação</Header>
                  <Header textAlign='left'>Ações</Header>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item: any, idx: number) => (
                    <Table.Row key={idx}>
                      <DataRow>
                        <Text size={15} fontWeight={500}>
                          {`#${
                            item?.identifier?.toUpperCase() ??
                            String(item?.pub_id).substr(-6)
                          }`}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>
                          {statusValues[item.state.description]}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>{formatCurrency(item.total)}</Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>
                          {item.products?.reduce(
                            (next: number, current: { quantity: number }) =>
                              +current.quantity + next,
                            0
                          )}
                        </Text>
                      </DataRow>

                      <DataRow>
                        <Text size={15}>
                          {format(new Date(item.created_at), 'dd/MM HH:mm')}
                        </Text>
                      </DataRow>

                      <DataRow>
                        <Link to={`/pedidos/detalhe/${item.pub_id}`}>
                          <img src={Nota} alt='Detalhe' height={18} />
                        </Link>
                      </DataRow>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <DataRow colSpan={6}>
                      <Text size={15}>
                        <Text textAlign='center' size={14} fontWeight={500}>
                          Nenhum registro
                        </Text>
                      </Text>
                    </DataRow>
                  </Table.Row>
                )}
              </tbody>
            </Table.Table>
          </View>
        )}
      </ModalMainContent>
    </Modal>
  )
}

export default ModalList
