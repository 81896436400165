import styled from 'styled-components'

export const Container = styled.div<{ error?: number }>`
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }
  max-width: 16rem;

  border: 1px solid
    ${({ error }) => (error ? 'rgba(227, 80, 43, 1)' : '#C4C4C4')};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 10px 8px;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;

  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

  &:not(.select--multiple)::after {
    content: '';
    justify-self: end;
    width: 0.6em;
    height: 0.4em;
    background-color: #777;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
`

export const SelectIinput = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 200;

  &::-ms-expand {
    display: none;
  }

  &:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    // border: 2px solid blue;
    border-radius: inherit;
  }
`

export const TextError = styled.p`
  font-size: 11px;
  color: rgba(227, 80, 43, 1);
  text-align: initial;
  padding-top: 8px;
`
