import React, { forwardRef } from 'react'

import { Container, InputStyle, TextError, Label } from './styles'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  messageError?: any
  validationError?: any
  label?: string
}

function Input(
  { messageError, validationError, label, ...props }: InputProps,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  return (
    <>
      <Container error={validationError ? 1 : 0}>
        {label && <Label>{label}</Label>}
        <InputStyle
          {...props}
          error={validationError ? 1 : 0}
          ref={forwardedRef}
        />
      </Container>
      {messageError && <TextError>{messageError}</TextError>}
    </>
  )
}

export default forwardRef(Input)
