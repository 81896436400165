import { useMemo } from 'react'

import IconeRelogio from 'src/assets/icons/icone-relogio.svg'
import { Text, View } from 'src/components/helper'
import { useTimer } from 'src/hooks/useTimer'

import { Container, Timer, Content } from './styles'

interface CardOrderProps {
  title: string
  onClick?: () => void
  cursor?: boolean
  deliveryType?: string
}

function CardOrder({ title, onClick, cursor, deliveryType }: CardOrderProps) {
  const timer = useTimer(5)
  const color = useMemo(() => {
    return deliveryType === 'store_delivery' ? '#F48D42' : '#7B61FF'
  }, [deliveryType])

  return (
    <Container
      color={color}
      onClick={onClick}
      style={{ cursor: cursor ? 'pointer' : '' }}
    >
      <Content>
        <Text fontWeight={400} size={16}>
          {title}
        </Text>

        <Timer>
          <img src={IconeRelogio} alt='Relógio' />
          <Text ml={0.4} fontWeight={300} size={12} color='#fff'>
            {timer} min
          </Text>
        </Timer>
      </Content>
      <View>
        <View mt={0.8}>
          <Text fontWeight={700} size={12} color={color}>
            {deliveryType === 'store_delivery'
              ? 'ENTREGA PRÓPRIA'
              : 'ENTREGA PELA FARMAZON'}
          </Text>
        </View>
      </View>
    </Container>
  )
}

export default CardOrder
