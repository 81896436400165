import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
`

export const ArrowButton = styled.button`
  background: transparent;
  border: 0;
  padding: 16px;

  background: ${({ disabled }) => (disabled ? '#cccccc' : '#3097E2')};

  border-radius: 4px;

  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
`
