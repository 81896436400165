import { memo, ReactNode, useRef, useState } from 'react'

import Menu from 'src/assets/icons/dots-vertical.svg'

import { Backgrond, Container, Content, Item, Text } from './styles'

interface DropDownMenuProps {
  children: ReactNode
}

interface MenuItemProps {
  source: any
  label: string
  onClick?: () => void
}

export const MenuItem = ({ source, label, onClick }: MenuItemProps) => (
  <Item onClick={onClick}>
    <img src={source} width={14} height={14} />
    <Text>{label}</Text>
  </Item>
)

function DropDownMenu({ children }: DropDownMenuProps) {
  const menuRef = useRef<HTMLDivElement>(null)
  const [enable, setEnable] = useState(false)

  const handleClick = () => {
    setEnable(false)
  }

  const handleOpenMenu = () => {
    setEnable(true)
  }

  return (
    <Container ref={menuRef}>
      <img src={Menu} alt='Opções' height={22} onClick={handleOpenMenu} />
      {enable && (
        <>
          <Backgrond onClick={handleClick} />
          <Content onClick={handleClick}>{children}</Content>
        </>
      )}
    </Container>
  )
}

export default memo(DropDownMenu)
