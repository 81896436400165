import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useAlert } from 'react-alert'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import Map, { Marker } from 'react-map-gl'
import QrReader from 'react-qr-scanner'
import { useSelector } from 'react-redux'
import { useParams, useHistory, Link } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line

import 'mapbox-gl/dist/mapbox-gl.css'
import CloseIcon from '@material-ui/icons/Close'
import { parsePhoneNumber } from 'libphonenumber-js'
import CardMachine from 'src/assets/icons/icon-card-machine.svg'
import ChangeRed from 'src/assets/icons/icon-change.svg'
import CheckFilled from 'src/assets/icons/icon-check-filled.svg'
import CreditCard from 'src/assets/icons/icon-credit-card.svg'
import MoneyGreen from 'src/assets/icons/icon-money-green.svg'
import MotorcycleOrange from 'src/assets/icons/icon-motorcycle-orange.svg'
import Motorcycle from 'src/assets/icons/icon-motorcycle.svg'
import NF from 'src/assets/icons/icon-nf-check.svg'
import Alerta from 'src/assets/icons/icone-alerta.svg'
import NotaFiscal from 'src/assets/icons/icone-nota-fiscal.svg'
import RightArrow from 'src/assets/icons/icone-right.svg'
import Motocycle from 'src/assets/icons/motocycle.svg'
import DeliveryDriverImage from 'src/assets/images/delivery_driver.png'
import { LoadingSearch, Modal } from 'src/components/elements'
import { Button, Input, Switch } from 'src/components/form'
import { Line, Text, View } from 'src/components/helper'
import Pin from 'src/components/map/Pin'
import { useNewFetch } from 'src/hooks/useFetch'
import useOperation, { deliveryColor } from 'src/hooks/useOperation'
import useSaleCenterSale, { SaleDetailProps } from 'src/hooks/useSaleCenterSale'
import { DefaultRootState } from 'src/redux/store'
import { getDriverLocation } from 'src/services/lalamove'
import { CHANGE_ORDER_STATUS_LABELS, OrderSteps } from 'src/util/constants'
import { copyToClipboard } from 'src/util/helpers/common'
import { formattedCreatedAt } from 'src/util/helpers/date'
import {
  formatCPF,
  formatCurrency,
  formatMoney,
  unFormatPhone,
  unMaskOnlyNumber,
} from 'src/util/helpers/string'
import useSWR from 'swr'

import {
  CloseBox,
  Container,
  DashedLine,
  ModalContent,
  StatusBox,
  StatusItem,
} from './styles'
import { border } from 'src/styles'

import DeclineOrderModal, { HandleConfirmProps } from './DeclineOrderModal'

const Detail = () => {
  const history = useHistory()
  const alert = useAlert()
  const { orderId } = useParams<{ orderId: string }>()
  const { app } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )

  const fileInputRef = useRef<HTMLInputElement>(null)

  const [showTrackDriverModal, setShowTrackDriverModal] = useState(false)
  const [scanQrCode, setScanQrCode] = useState(false)

  const { data: details } = useNewFetch<SaleDetailProps>(
    `/sales-center/orders/${orderId}`
  )

  const { data: driveLocation } = useSWR('/lalamove', async () => {
    const lalamoveId = details?.delivery_order_id
    const driverId = details?.delivery_order_driver_id

    if (
      driverId &&
      lalamoveId &&
      [5, 6, 7, 8].includes(details?.state?.id ?? 0)
    ) {
      const { data } = await getDriverLocation(lalamoveId, driverId)
      return data?.data?.coordinates
    }
  })

  useEffect(() => {
    if (!details?.delivery_driver?.track && showTrackDriverModal) {
      setShowTrackDriverModal(false)
    }
  }, [details?.delivery_driver?.track, showTrackDriverModal])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm()

  const {
    openModal,
    nfLoading,
    loading,
    handleChangeStateSaleCenter,
    handleToggleModal,
    handleOpenModalDecline,
    handleSubmitNf,
    modelToShow,
    handleDeclineOrder,
  } = useSaleCenterSale()

  const { getSaleCenterProductOption, getSaleCenterIconOption } = useOperation()

  useEffect(() => {
    if (openModal && modelToShow === 'nf') {
      window.addEventListener('paste', (event: any) => {
        if (event?.clipboardData?.files?.length && fileInputRef.current) {
          const dataTransfer = new DataTransfer()
          dataTransfer.items.add(event.clipboardData.files[0])

          fileInputRef.current.files = dataTransfer.files
          setValue('file_nf', dataTransfer.files)
        }
      })
    } else {
      window.removeEventListener('paste', () => {
        //
      })
    }
  }, [openModal, details, modelToShow === 'nf'])

  const currentState = useCallback((idState: number, item: number) => {
    if (item < idState) return 'previous'
    else if (idState === item) return 'current'
    else return ''
  }, [])

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(unMaskOnlyNumber(e.target.value))

    setValue('value_nf', formatMoney(value.toString(), { unit: 'R$' }))
  }

  const handleSubmitModal = handleSubmit((data: any) => {
    const total = parseFloat(unMaskOnlyNumber(data.value_nf)) / 100

    if (details) {
      handleSubmitNf(
        details.state.id,
        details.store.pub_id,
        details.order,
        details.pub_id,
        data.file_nf,
        total,
        scanQrCode ? data.link : null
      )
    }

    reset()
    setScanQrCode(false)
  })

  const handleSeePrescription = (url: string) => {
    const win = window.open(url, '_blank')
    win?.focus()
  }

  const getPaymentIcon = () => {
    if (details?.payment_type.description === 'app') return CreditCard

    if (details?.payment_type.mode === 'card') return CardMachine

    return MoneyGreen
  }

  const getPaymentTitle = () => {
    if (details?.payment_type.description === 'app')
      return 'Pagamento por cartão de crédito'

    if (details?.payment_type.mode === 'card')
      return 'Cobrar do cliente na entrega'

    return 'Cobrar do cliente na entrega'
  }

  const getPaymentSubtitle = () => {
    if (details?.payment_type.description === 'app') {
      return (
        <View flex direction='row' align='center' mt={0.5}>
          <Text fontWeight={400} size={14} mr={0.2}>
            Pedido já foi pago pelo cliente
          </Text>

          <img src={CheckFilled} width='20px' height='20px' />
        </View>
      )
    }

    if (details?.payment_type.mode === 'card') {
      return (
        <Text fontWeight={400} size={14} mt={0.5}>
          Maquininha
        </Text>
      )
    }

    if (
      details?.payment_type.change_for &&
      Number(details?.payment_type.change_for) === 0
    ) {
      return (
        <Text fontWeight={400} size={14} mt={0.5}>
          Dinheiro - Não precisa de troco
        </Text>
      )
    }

    if (
      details?.payment_type.change_for &&
      Number(details?.payment_type.change_for) > 0
    ) {
      return (
        <Text fontWeight={400} size={14} mt={0.5}>
          {`Dinheiro - Troca para ${formatCurrency(
            Number(details?.payment_type.change_for)
          )}`}
        </Text>
      )
    }

    return ''
  }

  const handleCancelOrder = (data: HandleConfirmProps) => {
    if (details) {
      handleDeclineOrder(
        details.store.pub_id,
        orderId,
        details.pub_id,
        data
      )().then(() => {
        history.goBack()
      })
    }
  }

  const getFormattedAddress = () => {
    if (!details) return ''

    const { street, number, more, state, city, country } =
      details.delivery_address
    const formattedMode = more ? ` ${more}` : ''
    return `${street}, ${number}${formattedMode}, ${state}, ${city}/${country}`
  }

  const handleClean = (event: any) => {
    event.target.value = null
  }

  const handleToggleScanQrcode = (e: ChangeEvent<HTMLInputElement>) => {
    setScanQrCode(e.target.checked)
  }

  const onQRCodeScanned = (data: any) => {
    if (
      data?.text &&
      (data?.text.startsWith('http') || data?.text.startsWith('https'))
    ) {
      setValue('link', data.text)
    }
  }

  const handleReScannerQrCode = () => {
    setValue('link', null)
  }

  const nfFileIsUri = useMemo(() => {
    const file = details?.confirm_checkout?.nf_file
    return file && RegExp(/^(http|https)/).test(file)
  }, [details?.confirm_checkout?.nf_file])

  const openNfFile = () => {
    const uri = details?.confirm_checkout?.nf_file || ''

    window.open(uri, '_blank')?.focus()
  }

  const handleToggleTrackDriverModal = () => {
    setShowTrackDriverModal((old) => !old)
  }

  const nfTotal = parseFloat(unMaskOnlyNumber(watch('value_nf') || '0')) / 100

  const onCopyNameHandler = async () => {
    await copyToClipboard(details?.customer.name ?? '')

    alert.show('Nome copiado com sucesso')
  }

  const onCopyPhoneHandler = async () => {
    await copyToClipboard(details?.customer.phone ?? '')

    alert.show('Telefone copiado com sucesso')
  }

  const onCopyEanHandler = (text: string) => {
    return async () => {
      await copyToClipboard(text ?? '')

      alert.show('Código de barras copiado com sucesso')
    }
  }

  const onChangeFileHandler = (event: any) => {
    if (event?.target?.files?.length && fileInputRef.current) {
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(event?.target.files[0])

      fileInputRef.current.files = dataTransfer.files
      setValue('file_nf', dataTransfer.files)
    }
  }

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View mr={1}>
            <Link to='/pedidos'>
              <Text size={18} fontWeight={300}>
                Pedido
              </Text>
            </Link>
          </View>
          <View mr={1}>
            <img src={RightArrow} alt='Próximo' />
          </View>
          <View>
            <Text size={25} fontWeight={700}>
              Pedido{' '}
              {details &&
                `#${
                  details?.identifier?.toUpperCase() ??
                  String(details?.pub_id).substr(-6)
                }`}
            </Text>
          </View>
        </View>

        <View height='calc(100% - 60px)'>
          {!details && (
            <View height='500px' justify='center' align='center'>
              <LoadingSearch />
            </View>
          )}

          {details && (
            <Row style={{ height: '480px' }}>
              <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                {typeof details.delivery_address === 'object' ? (
                  <View mt={1} mb={1}>
                    <Map
                      initialViewState={{
                        latitude: +details.delivery_address.lat,
                        longitude: +details.delivery_address.lng,
                        zoom: 14,
                      }}
                      mapStyle='mapbox://styles/mapbox/streets-v12'
                      mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                      style={{ height: 366 }}
                    >
                      <Marker
                        key='marker'
                        longitude={+details.delivery_address.lng}
                        latitude={+details.delivery_address.lat}
                        anchor='bottom'
                      >
                        <Pin active />
                      </Marker>

                      {driveLocation && (
                        <Marker
                          key='marker'
                          longitude={+driveLocation?.lng}
                          latitude={+driveLocation?.lat}
                          anchor='bottom'
                        >
                          <img
                            src={Motocycle}
                            width={18}
                            height={19}
                            alt='Entregador'
                          />
                        </Marker>
                      )}
                    </Map>
                  </View>
                ) : null}

                <View>
                  <View
                    style={border}
                    pl={1}
                    pr={1}
                    pt={0.8}
                    pb={0.8}
                    mb={0.6}
                    direction='row'
                    align='center'
                    justify='space-between'
                  >
                    <View direction='row' align='center'>
                      <Text mr={0.5} size={14}>
                        Criado em:
                      </Text>
                      <Text size={14} fontWeight={700}>
                        {formattedCreatedAt(details.created_at)}
                      </Text>
                    </View>
                  </View>

                  {details?.store?.name ? (
                    <View
                      style={border}
                      pl={1}
                      pr={1}
                      pt={0.8}
                      pb={0.8}
                      mb={0.6}
                      direction='row'
                      align='center'
                      justify='space-between'
                    >
                      <View direction='row' align='center'>
                        <Text mr={0.5} size={14}>
                          Loja:
                        </Text>
                        <Text size={14} fontWeight={700}>
                          {details?.store?.name}
                        </Text>
                      </View>
                    </View>
                  ) : null}

                  <View
                    style={border}
                    pl={1}
                    pr={1}
                    pt={0.8}
                    pb={0.8}
                    mb={0.6}
                    direction='row'
                    align='center'
                    justify='space-between'
                  >
                    <View flex direction='row' align='center'>
                      <Text size={14} fontWeight={500}>
                        {details.customer.name}
                      </Text>

                      <Text
                        ml={0.5}
                        onClick={onCopyNameHandler}
                        size={12}
                        color='#3097E2'
                        decoration='underline'
                        style={{ cursor: 'pointer' }}
                      >
                        Copiar
                      </Text>
                    </View>

                    <View flex direction='row' align='center'>
                      <Text size={14}>
                        Telefone: {unFormatPhone(details.customer.phone)}
                      </Text>

                      <Text
                        ml={0.5}
                        onClick={onCopyPhoneHandler}
                        size={12}
                        color='#3097E2'
                        decoration='underline'
                        style={{ cursor: 'pointer' }}
                      >
                        Copiar
                      </Text>
                    </View>
                    {details.customer.cpf ? (
                      <Text size={14}>
                        CPF: {formatCPF(details.customer.cpf)}
                      </Text>
                    ) : null}
                  </View>

                  <View
                    style={border}
                    pl={1}
                    pr={1}
                    pt={0.8}
                    pb={0.8}
                    mb={0.6}
                    direction='row'
                    align='flex-start'
                  >
                    <Text mr={0.5} size={14}>
                      Endereço:
                    </Text>
                    <Text size={14} fontWeight={700}>
                      {getFormattedAddress()}
                    </Text>
                  </View>

                  {details?.delivery_driver?.name ? (
                    <View
                      style={border}
                      pl={1}
                      pr={1}
                      pt={0.8}
                      pb={0.8}
                      mb={0.6}
                    >
                      <Text mr={0.5} size={14}>
                        Entregador:
                      </Text>

                      <View mt={1} flex direction='row' align='center'>
                        <img
                          src={
                            details?.delivery_driver?.photo ||
                            DeliveryDriverImage
                          }
                          alt='Foto entregador'
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: details?.delivery_driver?.photo
                              ? '20px'
                              : '0px',
                          }}
                        />

                        <View ml={0.6}>
                          <Text size={14} fontWeight={700}>
                            {details?.delivery_driver?.name}
                          </Text>
                          {details?.delivery_driver?.phone ? (
                            <Text size={14} fontWeight={400}>
                              {parsePhoneNumber(
                                details?.delivery_driver?.phone
                              ).format('INTERNATIONAL')}
                            </Text>
                          ) : null}
                        </View>
                      </View>

                      {details.delivery_driver?.track ? (
                        <View mt={1}>
                          <Text
                            onClick={handleToggleTrackDriverModal}
                            fontWeight={500}
                            size={13}
                            color='#3097E2'
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            Acompanhar entregador
                          </Text>
                        </View>
                      ) : null}
                    </View>
                  ) : null}

                  {details.products.map((item, index) => (
                    <View
                      key={index}
                      style={border}
                      pl={1}
                      pr={1}
                      pt={0.8}
                      pb={0.8}
                      mb={0.6}
                    >
                      <View flex align='flex-start' justify='space-between'>
                        <Text
                          size={16}
                          fontWeight={400}
                          mr={1}
                        >{`${item.quantity}x`}</Text>
                        <View style={{ flex: 1 }} flex direction='column'>
                          <Text size={14} fontWeight={700} mr={1}>
                            {item.name}
                          </Text>
                          <View flex direction='row' align='center' mt={0.2}>
                            <Text
                              size={13}
                              color='#3097E2'
                            >{`EAN: ${item.ean}`}</Text>
                            <Text
                              ml={1}
                              onClick={onCopyEanHandler(item.ean)}
                              size={12}
                              color='#3097E2'
                              decoration='underline'
                              style={{ cursor: 'pointer' }}
                            >
                              Copiar
                            </Text>
                          </View>
                        </View>
                      </View>

                      <DashedLine />

                      {item.substitute_products?.length > 0 ? (
                        <View mt={1}>
                          <View direction='row' align='center'>
                            <img
                              src={ChangeRed}
                              width={20}
                              height={20}
                              alt='Trocar'
                            />
                            <Text size={14} fontWeight={500} ml={1}>
                              Você pode substituir por
                            </Text>
                          </View>
                          {item.substitute_products.map((product, index) => (
                            <View
                              padding={0.4}
                              mt={0.4}
                              flex
                              justify='space-between'
                              key={index}
                            >
                              <Text size={11} fontWeight={400}>
                                {product.name}
                              </Text>
                              <Text size={12} fontWeight={500}>
                                {formatCurrency(product.max_price)}
                              </Text>
                            </View>
                          ))}
                        </View>
                      ) : (
                        <View
                          direction='row'
                          justify='space-between'
                          padding={0.6}
                          width='100%'
                        >
                          <View direction='row'>
                            <img
                              src={getSaleCenterIconOption(
                                item.required,
                                item.substitute_products ?? []
                              )}
                              width={20}
                              height={20}
                              alt='Trocar'
                            />
                            <View>
                              <Text ml={1} size={14} fontWeight={500}>
                                {
                                  getSaleCenterProductOption(
                                    item.required,
                                    item.substitute_products ?? []
                                  ).title
                                }
                              </Text>
                              <Text ml={1} size={12} fontWeight={100}>
                                {
                                  getSaleCenterProductOption(
                                    item.required,
                                    item.substitute_products ?? []
                                  ).text
                                }
                              </Text>
                            </View>
                          </View>
                        </View>
                      )}

                      {item.medical_prescription && (
                        <Button
                          type='reset'
                          label='Veja aqui a receita anexada'
                          borderColor='#3097E2'
                          color='#3097E2'
                          background='#fff'
                          style={{ marginTop: '1rem' }}
                          onClick={() =>
                            handleSeePrescription(
                              item.medical_prescription?.url
                            )
                          }
                        />
                      )}
                    </View>
                  ))}
                </View>
              </Col>

              <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <View
                  style={{
                    ...border,
                    borderLeftWidth: 20,
                    borderLeftColor: deliveryColor(
                      details.delivery_type === 'farmazon_delivery'
                    ),
                  }}
                  pl={1}
                  pr={1}
                  pt={0.8}
                  pb={0.8}
                  mb={0.6}
                >
                  <View flex direction='row' align='center'>
                    <img
                      src={
                        details.delivery_type === 'farmazon_delivery'
                          ? Motorcycle
                          : MotorcycleOrange
                      }
                      width='20px'
                      style={{ marginRight: '8px' }}
                    />
                    <Text
                      fontWeight={700}
                      color={deliveryColor(
                        details.delivery_type === 'farmazon_delivery'
                      )}
                    >
                      {details.delivery_type === 'farmazon_delivery'
                        ? 'ENTREGA PELA FARMAZON'
                        : 'ENTREGA PRÓPRIA'}
                    </Text>
                  </View>
                  <Text fontWeight={400} mt={0.6}>
                    {details.delivery_average}
                  </Text>
                </View>
                <View
                  mt={1}
                  style={border}
                  pl={1}
                  pr={1}
                  pt={0.8}
                  pb={0.8}
                  mb={0.6}
                >
                  {![3, 4].includes(details.state.id) && (
                    <StatusBox>
                      {Object.keys(OrderSteps).map((item) => (
                        <StatusItem
                          color={currentState(details.state.id, +item)}
                          key={item}
                        />
                      ))}
                    </StatusBox>
                  )}

                  <Text
                    size={18}
                    mb={1}
                    mt={![3, 4].includes(details.state.id) ? 2 : 1}
                    fontWeight={700}
                    color='#3097E2'
                  >
                    {OrderSteps[details.state.id]}
                  </Text>
                  {details.delivery_type === 'store_delivery' ? (
                    <>
                      {[5, 6, 7].includes(details.state.id) ? (
                        <View mt={0.4}>
                          <Button
                            label={CHANGE_ORDER_STATUS_LABELS[details.state.id]}
                            loading={loading}
                            onClick={handleChangeStateSaleCenter(
                              details.store.pub_id,
                              details.pub_id,
                              details.order,
                              details.state.id + 1
                            )}
                            background='#52C372'
                          />
                        </View>
                      ) : null}

                      {[2, 3, 5, 6, 7].includes(details.state.id) ? (
                        <View mt={0.4}>
                          <Button
                            label='CONFIRMAR VALOR DO PEDIDO'
                            background='#3097E2'
                            loading={nfLoading}
                            rightIcone={NF}
                            onClick={handleToggleModal}
                          />
                        </View>
                      ) : null}

                      {[2].includes(details.state.id) ? (
                        <View mt={0.4}>
                          <Button
                            label='Rejeitar Pedido'
                            background='#FF0000'
                            style={{
                              marginTop: '0.6rem',
                              width: '160px',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                            height='42px'
                            onClick={handleOpenModalDecline}
                          />
                        </View>
                      ) : null}

                      <View mt={1} mb={1}>
                        <a
                          target='_blank'
                          href={`https://api.whatsapp.com/send?phone=${app.setup?.admin?.callcenter_mobile}`}
                          rel='noreferrer'
                        >
                          <Text
                            size={14}
                            fontWeight={500}
                            decoration='underline'
                            textAlign='center'
                          >
                            Falar com suporte
                          </Text>
                        </a>
                      </View>
                    </>
                  ) : (
                    <>
                      {[2].includes(details.state.id) && (
                        <View mt={0.4}>
                          <Button
                            label={
                              details.state.id === 2
                                ? 'FINALIZAR VENDA'
                                : 'ALTERAR VALOR DO PEDIDO'
                            }
                            background='#3097E2'
                            loading={nfLoading}
                            rightIcone={NF}
                            onClick={handleToggleModal}
                          />
                        </View>
                      )}

                      {[2].includes(details.state.id) && (
                        <View mt={0.4}>
                          {!nfLoading ? (
                            <Button
                              label='Rejeitar Pedido'
                              background='#FF0000'
                              style={{
                                marginTop: '0.6rem',
                                width: '160px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              height='42px'
                              onClick={handleOpenModalDecline}
                            />
                          ) : null}
                        </View>
                      )}
                    </>
                  )}
                </View>

                <View mt={1} mb={1}>
                  <Line />
                </View>

                {details.confirm_checkout?.nf_total ? (
                  <View
                    mt={1}
                    style={border}
                    pl={1}
                    pr={1}
                    pt={0.8}
                    pb={0.8}
                    mb={0.6}
                  >
                    {!details.confirm_checkout?.nf_total ? (
                      <View flex align='center' justify='space-between' mb={1}>
                        <Text size={14} fontWeight={400}>
                          Valor máximo dos produtos
                        </Text>
                        <Text
                          size={14}
                          fontWeight={400}
                          style={{
                            textDecorationLine: details.confirm_checkout
                              ?.nf_total
                              ? 'line-through'
                              : 'auto',
                          }}
                        >
                          {formatCurrency(details.max_total)}
                        </Text>
                      </View>
                    ) : null}

                    <View flex direction='column' mb={1}>
                      <View flex align='center' justify='space-between'>
                        <Text size={14} fontWeight={400}>
                          Valor total dos produtos
                        </Text>
                        <Text size={14} fontWeight={400}>
                          {details.confirm_checkout?.nf_total
                            ? formatCurrency(details.confirm_checkout?.nf_total)
                            : ' - '}
                        </Text>
                      </View>

                      {nfFileIsUri ? (
                        <Text
                          onClick={openNfFile}
                          size={13}
                          fontWeight={600}
                          mt={0.2}
                          color='#3097E2'
                          style={{
                            alignSelf: 'flex-start',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          Abrir nota fiscal
                        </Text>
                      ) : null}
                    </View>

                    {details.delivery_type === 'store_delivery' && (
                      <View flex align='center' justify='space-between' mb={1}>
                        <Text size={14} fontWeight={400}>
                          Entrega
                        </Text>
                        <Text size={14} fontWeight={400} color='#00997F'>
                          +{formatCurrency(details.delivery_fee)}
                        </Text>
                      </View>
                    )}

                    {typeof details?.store_rate === 'number' &&
                      details?.store_rate !== 0 && (
                        <View
                          flex
                          align='center'
                          justify='space-between'
                          mb={1}
                        >
                          <Text size={14} fontWeight={400}>
                            Taxa Farmazon
                          </Text>
                          <Text size={14} fontWeight={400}>
                            {formatCurrency(details.store_rate)}
                          </Text>
                        </View>
                      )}

                    <View flex align='center' justify='space-between'>
                      <Text size={14} fontWeight={500}>
                        Valor total
                      </Text>
                      <Text size={14} fontWeight={500}>
                        {formatCurrency(details.total)}
                      </Text>
                    </View>
                  </View>
                ) : null}

                <View
                  mt={1}
                  style={border}
                  pl={1}
                  pr={1}
                  pt={0.8}
                  pb={0.8}
                  mb={0.6}
                  direction='row'
                  align='center'
                >
                  <img
                    src={getPaymentIcon()}
                    width={20}
                    height={20}
                    alt='Trocar'
                  />
                  <View ml={1}>
                    <Text size={14} fontWeight={700} color='#00997F'>
                      {getPaymentTitle()}
                    </Text>
                    {getPaymentSubtitle()}
                  </View>
                </View>
              </Col>
            </Row>
          )}
        </View>
      </Container>

      {openModal && details && modelToShow === 'nf' && (
        <Modal paddingTop={8} width={27} padding={0}>
          <CloseBox
            onClick={() => {
              handleToggleModal()
              setScanQrCode(false)
              reset()
            }}
          >
            <CloseIcon style={{ fill: '#474747' }} />
          </CloseBox>

          <ModalContent>
            <View flex justify='center' mb={1}>
              <img src={NotaFiscal} alt='Nota fiscal' />
            </View>

            <View mb={1}>
              <Text size={22} fontWeight={500} textAlign='center'>
                {details.state.id === 2
                  ? 'CONFIRMAR VALOR DO PEDIDO'
                  : 'ALTERAR VALOR DO PEDIDO'}
              </Text>
            </View>

            <Text mb={0.4} size={12} fontWeight={400}>
              Usar câmera para leitura do cupom fiscal (recomendado)
            </Text>
            <View
              style={{
                width: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#C4C4C4',
                borderRadius: '4px',
              }}
              mb={0.5}
              pl={0.8}
              pr={0.8}
              pb={0.4}
              pt={0.4}
              flex
              direction='row'
              align='center'
            >
              <Text mr={1} size={12} fontWeight={400}>
                Ler QR Code
              </Text>
              <Switch checked={scanQrCode} onChange={handleToggleScanQrcode} />
            </View>

            {scanQrCode && !watch('link') ? (
              <View mb={1} height='240px'>
                <QrReader
                  delay={2000}
                  facingMode='rear'
                  style={{
                    height: 240,
                    width: '100%',
                  }}
                  onError={(err: any) => {
                    console.error(err)
                  }}
                  onScan={onQRCodeScanned}
                />
              </View>
            ) : null}

            {scanQrCode && watch('link') ? (
              <View mb={1}>
                <Text mb={0.6} size={14} fontWeight={600} color='#52C372'>
                  QR code escaneado com sucesso
                </Text>
                <Text
                  size={12}
                  fontWeight={400}
                  onClick={handleReScannerQrCode}
                  style={{ cursor: 'pointer', textDecoration: 'underlined' }}
                >
                  Escanear novamente
                </Text>
              </View>
            ) : null}

            {!scanQrCode ? (
              <View mb={1}>
                <Text mb={0.4} size={12} fontWeight={400}>
                  Usar imagem do sistema ou foto do cupom fiscal
                </Text>
                <Input
                  {...register('file_nf', {
                    required: 'Arquivo da nota fiscal é obrigatório',
                  })}
                  type='file'
                  ref={fileInputRef}
                  style={{
                    color: watch('file_nf')?.length ? '#000' : 'transparent',
                  }}
                  validationError={errors.value_nf}
                  messageError={(errors.username as any)?.value_nf}
                  onChange={onChangeFileHandler}
                  onClick={handleClean}
                />

                <View
                  mt={1}
                  padding={1}
                  style={{
                    borderWidth: '1px',
                    borderStyle: 'dotted',
                    borderColor: '#C4C4C4',
                    borderRadius: '4px',
                  }}
                >
                  <Text size={12} fontWeight={400}>
                    E possível colar o arquivo aqui
                  </Text>
                </View>
              </View>
            ) : null}

            <View mb={0.6}>
              <Text mb={0.4} size={12} fontWeight={400}>
                Digite o valor total do pedido
              </Text>
              <Input
                {...register('value_nf', {
                  required: 'O Total dos produtos é obrigatório',
                })}
                pattern='\d*'
                onChange={onChangeValue}
                validationError={errors.value_nf}
                messageError={(errors.username as any)?.value_nf}
              />
            </View>

            {nfTotal > details.max_total ? (
              <View
                flex
                align='center'
                direction='column'
                background='#F2F2F2'
                padding={1}
              >
                <View mb={0.6}>
                  <img src={Alerta} alt='Alerta' />
                </View>
                <Text color='#EA2A2F' fontWeight={700}>
                  ATENÇÃO
                </Text>
                <View width='13rem'>
                  <Text mt={1} size={12} textAlign='center'>
                    Valor total dos produtos sem incluir a taxa de entrega não
                    pode ultrapassar:
                  </Text>
                </View>
                <Text mt={1} size={22} fontWeight={700}>
                  {formatCurrency(details.max_total)}
                </Text>
              </View>
            ) : null}

            <View mt={1}>
              <Button
                label='CONFIRMAR'
                background='#3097E2'
                loading={nfLoading}
                disabled={nfTotal > details.max_total}
                onClick={handleSubmitModal}
              />
            </View>
          </ModalContent>
        </Modal>
      )}

      {openModal && modelToShow === 'cancel' && details ? (
        <DeclineOrderModal
          loading={loading}
          handleConfirm={handleCancelOrder}
        />
      ) : null}

      {showTrackDriverModal && details && details?.delivery_driver?.track ? (
        <Modal paddingTop={8} width={50} padding={0}>
          <CloseBox onClick={handleToggleTrackDriverModal}>
            <CloseIcon style={{ fill: '#474747' }} />
          </CloseBox>
          <ModalContent>
            <iframe
              style={{ width: '100%', height: '600px', marginTop: '1rem' }}
              src={details.delivery_driver.track}
              allow="geolocation 'src';"
            />
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}

export default Detail
