/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import { batch, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Dispatch } from 'redux'
import {
  addPointStoreAction,
  resetOnboardingAction,
} from 'src/redux/reducers/onboardingReducer'
import {
  addStoreActiveAction,
  addStoreGroupAction,
} from 'src/redux/reducers/storeReducer'
import { DefaultRootState } from 'src/redux/store'
import { clearAccessToken } from 'src/services/api'
import { getCepAddressService, getGoogleAddress } from 'src/services/GeoService'
import { postSalesCenter } from 'src/services/StoreService'

const useOnboarding = () => {
  const alert = useAlert()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLeave = useCallback(() => {
    clearAccessToken()
    dispatch(resetOnboardingAction())
    history.push('/')
  }, [history])

  const getCepAddress = async (cep: string) => {
    try {
      const { data } = await getCepAddressService(cep)

      const params =
        `${data.logradouro}+${data.bairro},+${data.localidade},+${data.uf}`.replace(
          ' ',
          '+'
        )
      const { data: googleResp } = await getGoogleAddress(params)

      const lat = googleResp.data.features[0].center[1]
      const lng = googleResp.data.features[0].center[0]

      dispatch(addPointStoreAction(lat, lng))

      return data
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }

  const createSalesCenter = () => {
    return async (dispatch: Dispatch, getState: () => DefaultRootState) => {
      try {
        const { onboarding } = getState()

        const payload = {
          name: onboarding.name,
          cnpj: onboarding.cnpj.replace(/\D/g, ''),
        }
        const result = await postSalesCenter(payload)
        const { pub_id } = result.data

        // TODO: We'll need uncomment it later
        // const version = app.setup?.store?.terms_of_use?.version || '1.0'
        // await postTermUseById(pub_id, version)

        batch(() => {
          dispatch(
            addStoreActiveAction({
              pub_id: pub_id,
              name: onboarding.name,
              address: onboarding.location as any,
            })
          )
          dispatch(addStoreGroupAction(['customers', 'store_sales_center']))
          dispatch(resetOnboardingAction())
        })

        window.location.href = '/'
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      }
    }
  }

  return {
    loading,
    getCepAddress,
    setLoading,
    createSalesCenter,
    handleLeave,
  }
}

export default useOnboarding
