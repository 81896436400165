import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: calc(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`

export const Content = styled.div<{ height?: number }>`
  padding: 27px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 320px) {
    width: 400px;
    height: ${({ height = 540 }) => `${height}px`};
    border-radius: 15px;
  }
`

export const Leave = styled.div`
  border: 1px solid #474747;
  border-radius: 5px;
  width: 65px;
  padding: 7px 12px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`
