import { useMemo } from 'react'
import { Col, Row } from 'react-grid-system'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AccountBalance from '@material-ui/icons/AccountBalance'
import BlockIcon from 'src/assets/icons/icon-block.svg'
import IconeStoreCancel from 'src/assets/icons/icon-store-cancel.svg'
import StoreIcon from 'src/assets/icons/icon-store.svg'
import AddIcon from 'src/assets/icons/icone-adicionar.svg'
import Editar from 'src/assets/icons/icone-editar.svg'
import { LoadingSearch, ModalConfirm } from 'src/components/elements'
import ModalImportStores from 'src/components/elements/ModalImportStores'
import { Button, CheckBox } from 'src/components/form'
import { Header, Table, Text, View, DataRow } from 'src/components/helper'
import useStore from 'src/hooks/useStore'
import { DefaultRootState } from 'src/redux/store'
import { getSalesCenterSWR } from 'src/services/OrderService'
import { formatCurrency } from 'src/util/helpers/string'
import useSWR from 'swr'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

function ListStore() {
  const history = useHistory()

  const { storeId, defaultSelfDeliveryFee, defaultSelfDeliveryTime } =
    useSelector((state: DefaultRootState) => {
      return {
        storeId: state.store.pub_id,
        defaultSelfDeliveryTime:
          state.app.setup?.admin.delivery_average.store_delivery.timeOnlyNumber,
        defaultSelfDeliveryFee:
          state.app.setup?.admin.delivery_average.store_delivery.default_value,
      }
    })
  const dispatch = useDispatch()

  const { data, error } = useSWR('/sales-center/stores', getSalesCenterSWR)

  const {
    isAllOffer,
    storeSelected,
    openModal,
    openImportStoresModal,
    loading,
    toggleImportStoresModal,
    handleToogleModal,
    handleAddStore,
    handleSelected,
    handleAllSelected,
    handleActiveStore,
    handleDeleteSingle,
    handleEdit,
  } = useStore(storeId)

  const enableActive = useMemo(
    () => storeSelected.length === 0,
    [storeSelected]
  )

  const handleActive = () => dispatch(handleActiveStore())

  const getStoreDeliveryFee = (delivery = false, selfDeliveryFee: number) => {
    console.log()
    if (!delivery) return '-'

    if (selfDeliveryFee === 0) return 'Gratis'

    if (selfDeliveryFee > 0) return formatCurrency(selfDeliveryFee)

    return formatCurrency(defaultSelfDeliveryFee)
  }

  const getStoreDeliveryTime = (delivery = false, selfDeliveryTime: number) => {
    if (!delivery) return '-'

    if (!selfDeliveryTime) return `${defaultSelfDeliveryTime} minutos`

    return `${selfDeliveryTime} minutos`
  }

  const goToBankingAccountsHandler = (storeId: string) => {
    return () => {
      history.push(`/contas_bancarias/${storeId}`)
    }
  }

  return (
    <>
      <Container>
        <View pb={2}>
          <Row>
            <Col xs={7}>
              <Text size={25} fontWeight={700}>
                Minhas lojas
              </Text>
            </Col>
          </Row>
        </View>

        <View height='calc(100% - 100px)' style={shadowBox}>
          {!data && (
            <View height='calc(100% - 100px)' justify='center' align='center'>
              <LoadingSearch />
            </View>
          )}

          {data && (
            <>
              <View pb={1}>
                <Row>
                  <Col xs={2}>
                    <Button
                      type='reset'
                      label='Inativar'
                      background='#FF0000'
                      height='40px'
                      disabled={enableActive}
                      onClick={handleToogleModal}
                    />
                  </Col>
                  <Col xs={3}>
                    <Button
                      type='button'
                      label='Nova loja'
                      background='#3097E2'
                      height='40px'
                      icone={AddIcon}
                      onClick={handleAddStore}
                    />
                  </Col>
                </Row>
              </View>

              {!error && data && data?.length === 0 ? (
                <View height='100%' flex justify='center' align='center'>
                  <View
                    width='15rem'
                    flex
                    align='center'
                    justify='center'
                    direction='column'
                  >
                    <img
                      src={StoreIcon}
                      height={55}
                      width={55}
                      alt='Dados vendas'
                    />
                    <Text textAlign='center' fontWeight={400} mt={1}>
                      Nenhuma loja registrada até o momento
                    </Text>
                  </View>
                </View>
              ) : (
                <View height='100%' mt={1.4}>
                  <Table.Table id='listSale'>
                    <thead>
                      <tr>
                        <Header width='40px'>
                          <CheckBox
                            name='all'
                            background='#52C372'
                            onClick={handleAllSelected(data)}
                            checked={isAllOffer}
                            readOnly
                          />
                        </Header>
                        <Header textAlign='left'>Nome</Header>
                        <Header textAlign='left'>Endereço</Header>

                        <Header textAlign='left'>Possue entrega</Header>

                        <Header textAlign='left'>Tempo de entrega</Header>

                        <Header textAlign='left'>Radio de entrega</Header>

                        <Header textAlign='left'>Preço da entrega</Header>

                        <Header textAlign='left'>Status</Header>
                        <Header textAlign='left'>Ações</Header>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item: any, idx: number) => (
                        <Table.Row key={idx}>
                          <DataRow>
                            {item.active && (
                              <CheckBox
                                name='all'
                                background='#52C372'
                                onClick={handleSelected(item.pub_id)}
                                checked={storeSelected.includes(item.pub_id)}
                                readOnly
                              />
                            )}
                          </DataRow>
                          <DataRow>
                            <Text size={14} fontWeight={700}>
                              {item.name}
                            </Text>
                          </DataRow>
                          <DataRow>
                            <Text size={11} fontWeight={400}>
                              {item.address.street}, {item.address.number} ,CEP:{' '}
                              {item.address.cep}, {item.address.city} -{' '}
                              {item.address.state}
                            </Text>
                          </DataRow>
                          <DataRow>
                            <Text size={11} fontWeight={400}>
                              {item.self_delivery ? 'Sim' : 'Não'}
                            </Text>
                          </DataRow>
                          <DataRow>
                            <Text size={11} fontWeight={400}>
                              {getStoreDeliveryTime(
                                item.self_delivery,
                                item.self_delivery_time
                              )}
                            </Text>
                          </DataRow>
                          <DataRow>
                            <Text size={11} fontWeight={400}>
                              {item.self_delivery
                                ? `${item.self_delivery_range}Km`
                                : '-'}
                            </Text>
                          </DataRow>
                          <DataRow>
                            <Text size={11} fontWeight={400}>
                              {getStoreDeliveryFee(
                                item.self_delivery,
                                item.self_delivery_fee
                              )}
                            </Text>
                          </DataRow>
                          <DataRow>
                            <Text
                              size={11}
                              color={item.active ? '#00997F' : '#FC0000'}
                            >
                              {item.active ? 'Ativa' : 'Inativa'}
                            </Text>
                          </DataRow>
                          <DataRow>
                            <View flex direction='row' align='center'>
                              <View mt={0.2} mr={0.8} cursor>
                                <img
                                  src={Editar}
                                  alt='Editar'
                                  onClick={handleEdit(item.pub_id)}
                                />
                              </View>
                              <View
                                mt={0.2}
                                mr={0.8}
                                cursor
                                onClick={goToBankingAccountsHandler(
                                  item.pub_id
                                )}
                              >
                                <AccountBalance
                                  fontSize='small'
                                  style={{ fill: '#3097E2' }}
                                />
                              </View>

                              {item.active && (
                                <View cursor mt={0.4}>
                                  <img
                                    src={BlockIcon}
                                    alt='Deletar'
                                    width={18}
                                    height={18}
                                    onClick={handleDeleteSingle(item.pub_id)}
                                  />
                                </View>
                              )}
                            </View>
                          </DataRow>
                        </Table.Row>
                      ))}
                    </tbody>
                  </Table.Table>
                </View>
              )}
            </>
          )}
        </View>
      </Container>

      {openModal && (
        <ModalConfirm
          icon={IconeStoreCancel}
          loading={loading}
          description='Tem certeza que deseja inativar estas lojas?'
          labelCancel='Não'
          handleCancel={handleToogleModal}
          labelConfirm='Sim'
          backgroundConfirm='#FF0000'
          handleConfirm={handleActive}
        />
      )}

      {openImportStoresModal && (
        <ModalImportStores handleToggleModal={toggleImportStoresModal} />
      )}
    </>
  )
}

export default ListStore
