import { useCallback } from 'react'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { cnpj } from 'cpf-cnpj-validator'
import Close from 'src/assets/icons/icone-sair.svg'
import Talk from 'src/assets/icons/talk.svg'
import { Dots } from 'src/components/elements'
import { Button, InputText } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useOnboarding from 'src/hooks/useOnboarding'
import {
  addOnboardingNameAction,
  OnboardingReducerProps,
  setScreenStepAction,
} from 'src/redux/reducers/onboardingReducer'
import { formatCNPJ } from 'src/util/helpers/string'

import { Leave } from './styles'

function StoreInfo({ data }: { data: OnboardingReducerProps }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm()
  const dispatch = useDispatch()
  const { handleLeave } = useOnboarding()

  const onSubmit = useCallback((data: any) => {
    const cnpjStrip = cnpj.strip(data.cnpj)
    if (!cnpj.isValid(cnpjStrip)) {
      setError('cnpj', {
        type: 'manual',
        message: 'O CNPJ é inválido',
      })
      return
    }
    dispatch(addOnboardingNameAction(data))

    dispatch(setScreenStepAction('StoreTerm'))
  }, [])

  const maskCNPJ = useCallback((event: any) => {
    setValue('cnpj', formatCNPJ(event.target.value))
    clearErrors()
  }, [])

  return (
    <>
      <View flex justify='flex-end'>
        <Leave onClick={handleLeave}>
          <Text size={11}>Sair</Text>
          <img src={Close} alt='Sair' />
        </Leave>
      </View>

      <View>
        <img src={Talk} alt='Conversa' />
      </View>

      <View mt={0.2}>
        <Text color='#A70000' fontWeight='bold' size={27}>
          Qual o nome e CNPJ da sua loja?
        </Text>
      </View>

      <View mt={1}>
        <Text size={14}>
          Digite abaixo o nome fantasia, o nome que está na sua fachada e o CNPJ
        </Text>
      </View>

      <View textAlign='center' mt={2}>
        <InputText
          {...register('name', {
            required: 'Nome da loja é obrigatório',
          })}
          defaultValue={data.name}
          type='text'
          name='name'
          placeholder='Nome da sua loja'
          validationError={errors.name}
          messageError={errors.name?.message}
        />
      </View>
      <View textAlign='center' mt={2}>
        <InputText
          {...register('cnpj', {
            required: 'O CNPJ é obrigatório',
          })}
          defaultValue={data.cnpj}
          type='text'
          name='cnpj'
          placeholder='CNPJ'
          validationError={errors.cnpj}
          messageError={errors.cnpj?.message}
          onChange={maskCNPJ}
        />
      </View>

      <View style={{ marginTop: 'auto' }}>
        <Row align='center'>
          <Col xs={7}>
            <Dots length={2} active={0} />
          </Col>
          <Col xs={5}>
            <Button
              label='Próximo'
              background='#52C372'
              onClick={handleSubmit(onSubmit)}
            />
          </Col>
        </Row>
      </View>
    </>
  )
}

export default StoreInfo
