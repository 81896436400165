import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import HumanWalking from 'src/assets/images/human-walking.svg'
import LogoHorizontal from 'src/assets/images/logo-farmazon-black.svg'
import { Button } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { setScreenStepAction } from 'src/redux/reducers/onboardingReducer'

function Welcome() {
  const dispatch = useDispatch()

  const handleStartOnboarding = useCallback(() => {
    dispatch(setScreenStepAction('StoreInfo'))
  }, [])

  return (
    <>
      <View>
        <img src={LogoHorizontal} height={30} alt='Farmazon logo' />
      </View>

      <View mt={2} textAlign='center'>
        <img src={HumanWalking} alt='Farmazon' height={190} />
      </View>

      <View mt={1}>
        <Text color='#52C372' size={30} fontWeight={600} textAlign='center'>
          Vamos começar?
        </Text>
      </View>

      <View mt={0.6}>
        <Text size={14} textAlign='center'>
          Esse será um passo a passo bem simples para você conseguir cadastrar a
          sua loja e começar a vender aqui com a gente!
        </Text>
      </View>

      <View style={{ marginTop: 'auto' }}>
        <Button
          label='Ok, entendi'
          background='#3097E2'
          onClick={handleStartOnboarding}
        />
      </View>
    </>
  )
}

export default Welcome
