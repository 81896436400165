import { Col, Row } from 'react-grid-system'
import { useDispatch } from 'react-redux'

import Close from 'src/assets/icons/icone-sair.svg'
import Term from 'src/assets/icons/icone-terms.svg'
import { Dots } from 'src/components/elements'
import { Button, CheckBox } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useOnboarding from 'src/hooks/useOnboarding'
import {
  OnboardingReducerProps,
  setOnboardingAcceptAction,
} from 'src/redux/reducers/onboardingReducer'

import { Leave } from './styles'

function StoreTerm({ data }: { data: OnboardingReducerProps }) {
  const dispatch = useDispatch()

  const { loading, createSalesCenter, handleLeave } = useOnboarding()

  const handleAcceptTerm = () =>
    dispatch(setOnboardingAcceptAction(!data.acceptTerms))

  const handleNext = () => {
    if (data.acceptTerms) {
      dispatch(createSalesCenter())
    }
  }

  return (
    <>
      <View flex justify='flex-end'>
        <Leave onClick={handleLeave}>
          <Text size={11}>Sair</Text>
          <img src={Close} alt='Sair' />
        </Leave>
      </View>

      <View>
        <img src={Term} alt='Termos de uso' />
      </View>

      <View mt={0.2}>
        <Text color='#A70000' fontWeight='bold' size={27}>
          Termos de Uso e contrato
        </Text>
      </View>

      <div
        style={{ width: '100%', height: 250, overflow: 'hidden' }}
        dangerouslySetInnerHTML={{
          __html:
            "<iframe style='height:252px;width:100%;' src='https://farmazon.com.br.s3-website-us-east-1.amazonaws.com/resources/termos_uso.html' />",
        }}
      />

      <View mt={0.6} direction='row' align='center'>
        <CheckBox checked={data.acceptTerms} onClick={handleAcceptTerm} />
        <Text fontWeight={500} size={11}>
          Estou ciente e de acordo com os Termos de Uso
        </Text>
      </View>

      <View style={{ marginTop: 'auto' }}>
        <Row align='center'>
          <Col xs={7}>
            <Dots length={2} active={1} />
          </Col>
          <Col xs={5}>
            <Button
              label='Finalizar'
              background='#52C372'
              onClick={handleNext}
              loading={loading}
              disabled={!data.acceptTerms}
            />
          </Col>
        </Row>
      </View>
    </>
  )
}

export default StoreTerm
