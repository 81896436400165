import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    font-family: 'Ubuntu', sans-serif !important;
    font-size: 16px;
    line-height: 1.2;
    color: #474747;
    height: 100%;
    -webkit-overflow-scrolling:touch;
    overflow: auto;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    line-height: inherit;
  }

  *:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .alert-custom > div{
    width: 100%;
  }
  ::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: #EDEDED;
  }
  ::-webkit-scrollbar-thumb{
    background-color: #9a9a9a;
    border-radius: 10px;
  }

  .BrainhubCarousel__arrows {
    background-color: #EB272C!important;
  }

  .BrainhubCarousel__arrowLeft {
    position: absolute;
    left: 0;
    z-index: 1;
  }

  .BrainhubCarousel__arrowRight {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .kpis_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 2fr));

    & > div {
      margin: 16px;
    }
  }
`

const baseColors = {
  black: '#000',
  white: '#fff',
  red: ['#FF5A5F', '#eb2629', '#c0392b'],
  green: ['#99cc00', '#2db300', '#1b984b'],
  yellow: ['#ffff66', '#e6b800'],
  blue: ['#00b8e6', '#008ae6'],
  neutrals: [
    '#EEEEEE',
    '#DBDBDB',
    '#B5B1B1',
    '#999999',
    '#636e72',
    '#484848',
    '#333333',
    '#2d3436',
  ],
}

export const shadowBox = {
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
  borderRadius: 5,
  padding: 30,
  overflow: 'auto',
}

export const theme = {
  fontSizes: {
    body1: 14,
    body2: 16,
    title: 18,
    display: 24,
  },
  colors: {
    primary: '#1d1d1d',
    secondary: '#1b984b',
    tertiary: '#f2f2f2',
    text: {
      light: '#636e72',
      main: '#333333',
      dark: '#2d3436',
    },
    background: {
      grey: baseColors.neutrals[0],
    },
    error: {
      main: baseColors.red[1],
    },
    ...baseColors,
  },
  font: {
    size: 16,
  },
}

export const loginAnimation = {
  initial: { opacity: 0.2, x: 50 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.3 },
}

export const border = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#C4C4C4',
  borderRadius: 4,
}
