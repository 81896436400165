/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import { Container, Col, Row, Hidden } from 'react-grid-system'
import { useForm } from 'react-hook-form'

import Image from 'src/assets/images/farmazon-user.png'
import Logo from 'src/assets/images/logo-farmazon-black.svg'
import { AlertLogin } from 'src/components/elements'
import { Button, InputText } from 'src/components/form'
import {
  ImageResize,
  View,
  Text,
  Link,
  ContainerAnimation,
  Line,
} from 'src/components/helper'
import useAuth from 'src/hooks/useAuth'
import { formatPhone } from 'src/util/helpers/string'

import { loginAnimation } from 'src/styles'

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm()
  const { validation, loading, doSignIn, handleGoToSignup } = useAuth()

  const onSubmit = (data: any) => {
    doSignIn(data.username, data.password, data.keepLogin)
  }

  const formatPhoneInput = useCallback((event: any) => {
    setValue('username', formatPhone(event.target.value))
    clearErrors()
  }, [])

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row nogutter align='stretch' style={{ height: 'calc(100vh)' }}>
        <Hidden xs sm md>
          <Col md={8}>
            <ImageResize url={`url(${Image})`} />
          </Col>
        </Hidden>
        <Col xs={12} lg={4}>
          <Row
            align='center'
            justify='center'
            nogutter
            style={{ height: '100%' }}
          >
            <Col xs={8}>
              <ContainerAnimation {...loginAnimation}>
                <View textAlign='center' pt={4}>
                  <img src={Logo} alt='Farmazon logo' />
                </View>

                <View mt={2}>
                  <Text textAlign='center' fontWeight={500} size={25}>
                    Faça seu login
                  </Text>
                </View>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <View textAlign='center' mt={2}>
                    <InputText
                      {...register('username', {
                        required: 'O telefone é obrigatório',
                        pattern: {
                          value: /\d{5}-\d{4}/,
                          message: 'Digite seu número de telefone com DDD.',
                        },
                      })}
                      type='text'
                      name='username'
                      placeholder='Número do telefone'
                      validationError={errors.username}
                      messageError={errors.username?.message}
                      onChange={formatPhoneInput}
                    />
                  </View>

                  <View textAlign='center' mt={1.4} mb={1}>
                    <InputText
                      {...register('password', {
                        required: 'Senha é obrigatória.',
                      })}
                      type='password'
                      name='password'
                      placeholder='Senha'
                      validationError={errors.password}
                      messageError={errors.password?.message}
                    />
                  </View>

                  <View mt={3}>
                    <Button label='Acessar' loading={loading} />
                  </View>

                  <View flex mt={2} justify='center'>
                    <Link to='recovery'>Esqueci minha senha</Link>
                  </View>
                </form>

                <View mt={2} mb={2}>
                  <Line />
                </View>

                <View mt={1.4} mb={1.4}>
                  <Text size={12} textAlign='center' fontWeight={500}>
                    Não tenho cadastro
                  </Text>
                </View>

                <View>
                  <Button
                    background='#3097E2'
                    type='button'
                    label='Criar conta'
                    onClick={handleGoToSignup}
                  />
                </View>
              </ContainerAnimation>
            </Col>

            <View mt={1}>
              <Text size={13} color='#C4C4C4' textAlign='center'>
                Copyright © 2022 Farmazon. Todos os direitos reservados.
              </Text>
            </View>
          </Row>

          {validation && (
            <AlertLogin message='Ops! Seu login ou senha estão incorretos.' />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Login
