import { ChangeEvent } from 'react'
import { Col, Row } from 'react-grid-system'
import { useFieldArray, useForm } from 'react-hook-form'
import { batch, useDispatch } from 'react-redux'

import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Phone from 'src/assets/icons/icone-phone.svg'
import Plus from 'src/assets/icons/icone-plus.svg'
import Close from 'src/assets/icons/icone-sair.svg'
import { Dots } from 'src/components/elements'
import { Button, InputText, Switch } from 'src/components/form'
import { ShadowBox, Text, View } from 'src/components/helper'
import useStoreOnboarding from 'src/hooks/useStoreOnboarding'
import {
  addOnboardPhonesAction,
  setScreenStepAction,
  StoreOnboardingReducerProps,
} from 'src/redux/reducers/storeOnboardingReducer'
import { formatPhone } from 'src/util/helpers/string'

import { Leave } from './styles'

export interface PhoneProps {
  phones: Array<{ phone: string; whatsapp: boolean; principal: boolean }>
  phone: string
}

function StorePhone({ data }: { data: StoreOnboardingReducerProps }) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<PhoneProps>({
    defaultValues: {
      phones: data.contact_phones,
    },
  })
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'phones',
  })
  const dispatch = useDispatch()
  const { handleLeave } = useStoreOnboarding()

  const onSubmit = () => {
    if (fields.length < 1) {
      setError('phone', {
        type: 'manual',
        message: 'Informe ao menos um número',
      })
      return
    }
    const phones = fields.map((item) => ({
      phone: item.phone,
      whatsapp: item.whatsapp,
      principal: item.principal,
    }))

    batch(() => {
      dispatch(addOnboardPhonesAction(phones))
      dispatch(setScreenStepAction('StoreHour'))
    })
  }

  const onSubmitPhones = (data: any) => {
    append({ phone: data.phone, whatsapp: false, principal: false })
    setValue('phone', '')
  }

  const maskPhone = (event: any) => {
    const value = formatPhone(event.target.value)
    setValue('phone', value)
    clearErrors()
  }

  const handleSwitch =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        fields.forEach((field, fieldIndex) => {
          if (fieldIndex === index) {
            update(fieldIndex, { ...field, whatsapp: true })
          } else {
            update(fieldIndex, { ...field, whatsapp: false })
          }
        })
      } else {
        update(index, { ...fields[index], whatsapp: e.target.checked })
      }
    }

  const handlePrincipalSwitch =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        fields.forEach((field, fieldIndex) => {
          if (fieldIndex === index) {
            update(fieldIndex, { ...field, principal: true })
          } else {
            update(fieldIndex, { ...field, principal: false })
          }
        })
      } else {
        update(index, { ...fields[index], principal: e.target.checked })
      }
    }

  return (
    <>
      <View flex justify='flex-end'>
        <Leave onClick={handleLeave}>
          <Text size={11}>Sair</Text>
          <img src={Close} alt='Sair' />
        </Leave>
      </View>

      <View>
        <img src={Phone} alt='Telefone' />
      </View>

      <View mt={0.2}>
        <Text color='#A70000' fontWeight='bold' size={27}>
          Telefones de atendimento
        </Text>
      </View>

      <View mt={1}>
        <Text size={14}>
          Digite aqui todos os telefones de contato da sua loja, incluindo
          atendimento por Whatsapp
        </Text>
      </View>

      <View textAlign='center' mt={2}>
        <form onSubmit={handleSubmit(onSubmitPhones)}>
          <Row>
            <Col xs={9}>
              <InputText
                {...register('phone', {
                  required: 'O Número é obrigatório',
                  pattern: {
                    value: /\(\d{2}\) \d{4,5}-\d{4}/,
                    message: 'Digite seu número de telefone com DDD.',
                  },
                })}
                type='text'
                name='phone'
                validationError={errors.phone}
                messageError={errors.phone?.message}
                onChange={maskPhone}
              />
            </Col>
            <Col xs={3}>
              <Button
                label={<img src={Plus} alt='mais' />}
                style={{ padding: '10px 6px', minHeight: '0px' }}
                background='#3097E2'
              />
            </Col>
          </Row>
        </form>
      </View>

      <View style={{ overflowY: 'auto', padding: 5, marginTop: 8 }}>
        {fields.map((field, index) => (
          <ShadowBox key={field.id} style={{ marginBottom: 18 }}>
            <Text size={14} fontWeight={300}>
              {field.phone}
            </Text>
            <View direction='column' align='center'>
              <Switch
                checked={field.principal}
                onChange={handlePrincipalSwitch(index)}
              />
              <Text size={11} style={{ marginRight: 6 }}>
                Principal
              </Text>
            </View>

            <View direction='column' align='center'>
              <Switch checked={field.whatsapp} onChange={handleSwitch(index)} />
              <Text size={11} style={{ marginRight: 6 }}>
                Whatsapp
              </Text>
            </View>
            <DeleteOutline
              fontSize='medium'
              onClick={() => remove(index)}
              style={{ cursor: 'pointer', marginLeft: 6, color: '#FF0000' }}
            />
          </ShadowBox>
        ))}
      </View>

      <View style={{ marginTop: 'auto' }}>
        <Row align='center'>
          <Col xs={7}>
            <Dots length={5} active={2} />
          </Col>
          <Col xs={5}>
            <Button label='Próximo' background='#52C372' onClick={onSubmit} />
          </Col>
        </Row>
      </View>
    </>
  )
}

export default StorePhone
