// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectEnv = (env?: string) => {
  const commonConfig = {
    region: 'us-east-1',
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }

  switch (env) {
    case 'production':
      return {
        Auth: {
          ...commonConfig,
          userPoolId: 'sa-east-1_IjlIp1v69',
          userPoolWebClientId: 'hol93ab877db63shif6dir9m9',
        },
      }
    case 'homolog':
      return {
        Auth: {
          ...commonConfig,
          userPoolId: 'sa-east-1_xal2FOfOk',
          userPoolWebClientId: '74d9ufvbu7utf9fpaudikljkbj',
        },
      }
    default:
      return {
        Auth: {
          ...commonConfig,
          userPoolId: 'sa-east-1_xal2FOfOk',
          userPoolWebClientId: '74d9ufvbu7utf9fpaudikljkbj',
        },
      }
  }
}

const amplifyConfig = selectEnv(process.env.REACT_APP_COGNITO)

export default amplifyConfig
