import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import moment from 'moment'
import { Button, Input, Select } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { cupomTypes } from 'src/util/constants'

import { Modal } from '..'

import { ModalMainContent } from './styles'

interface ModalListProps {
  onClose?: () => void
  onSubmit?: (data: any) => void
  loading: boolean
  data: any
}

function ModalCoupon({ data, onClose, loading, onSubmit }: ModalListProps) {
  const { register, handleSubmit, setValue } = useForm()

  useEffect(() => {
    if (data) {
      setValue('pub_id', data.pub_id)
      setValue('value', data.value)
      setValue('code', data.code)
      setValue(
        'expiration',
        moment(data.expiration, 'DD/MM/YYYY').format('YYYY-MM-DD')
      )
      setValue('description', data.description)
      setValue('quantity', data.quantity)
      setValue('type', data.type)
    }
  }, [data])

  const handleSubmitFormc = handleSubmit((data) => {
    data.expiration = moment(data.expiration).format('DD/MM/YYYY')
    data.value = parseFloat(data.value)
    data.quantity = Number(data.quantity)
    onSubmit && onSubmit(data)
  })

  return (
    <Modal paddingTop={5} width={32} padding={0} onClose={onClose}>
      <ModalMainContent>
        <form onSubmit={handleSubmitFormc}>
          <View>
            <Text size={10} mt={1}>
              Tipo
            </Text>
            <Select {...register('type', { required: true })}>
              <option value=''>Selecione um tipo</option>
              {cupomTypes.map((item: any, index: number) => (
                <option value={item.value} key={index}>
                  {item.name}
                </option>
              ))}
            </Select>
          </View>

          <View>
            <Text size={10} mt={1}>
              Valor
            </Text>
            <Input
              {...register('value', { required: true })}
              id='value'
              placeholder='Valor'
            />
          </View>

          <View>
            <Text size={10} mt={1}>
              Quantidade
            </Text>
            <Input
              type='number'
              {...register('quantity', { required: true })}
              placeholder='Quantidade'
            />
          </View>

          <View>
            <Text size={10} mt={1}>
              Código
            </Text>
            <Input
              type='text'
              {...register('code', { required: true })}
              id='code'
              placeholder='Código do voucher'
            />
          </View>

          <View>
            <Text size={10} mt={1}>
              Descrição
            </Text>
            <Input
              type='text'
              {...register('description', { required: true })}
              id='description'
              placeholder='Descrição do voucher'
            />
          </View>

          <View>
            <Text size={10} mt={1}>
              Data expiração
            </Text>
            <Input
              {...register('expiration', { required: true })}
              id='expiration'
              type='date'
              name='expiration'
            />
          </View>

          <input type='hidden' {...register('pub_id')} />

          <View mt={2}>
            <Button
              label={typeof data?.pub_id === 'string' ? 'Alterar' : 'Adicionar'}
              loading={loading}
            />
          </View>
        </form>
      </ModalMainContent>
    </Modal>
  )
}

export default ModalCoupon
