import { useCallback, useState } from 'react'

import { format } from 'date-fns'
import IconDeleteRed from 'src/assets/icons/delete-red.svg'
import IconEditarBlue from 'src/assets/icons/icone-editar.svg'
import IconStrikethrough from 'src/assets/images/strikethrough.svg'
import { Button, Input } from 'src/components/form'
import { HOMECARE_STATUS_LABELS } from 'src/constants/homecare'
import useHomecare from 'src/hooks/useHomecare'
import { getMultiply } from 'src/util/helpers/calc'
import { formatCurrency } from 'src/util/helpers/string'
import { twMerge } from 'tailwind-merge'

import DeliveryModal from './components/DeliveryModal'
import ProductModal from './components/ProductModal'
import { Spinner } from 'src/components/form/Button/styles'

const MessageComponent = ({
  is_admin = false,
  description,
  created_at,
}: any) => {
  return (
    <div
      className={twMerge(
        'flex flex-col p-4 mb-4 shadow-md rounded-md md:max-w-md bg-gray-50 w-[90%]',
        is_admin && 'ml-auto items-end'
      )}
    >
      <p className='text-sm font-bold whitespace-pre-line'>{description}</p>
      <p className='text-[12px] font-light mt-2'>
        {format(new Date(created_at), "dd/MM/yyyy 'às' HH:mm'h")}
      </p>
      {is_admin ? <p className='mt-4'>- Administrador</p> : null}
    </div>
  )
}
const HomecareDetails = () => {
  const {
    changeStatusLoading,
    loading,
    data,
    mode,
    formattedAddress,
    product,
    delivery,
    productsForm,
    deliveriesForm,
    toggleMode,
    selectProductToEdit,
    createProductHandler,
    closeProductModalHandler,
    onProductSubmitHandler,
    selectDeliveryToEdit,
    createDeliveryHandler,
    closeDeliveryModalHandler,
    onDeliverySubmitHandler,
    handleSubmit,
    saveHandler,
    removeProductHandler,
    removeDeliveryHandler,
    changeStatusHandler,
    onNotFoundChange,
    registerInteractionHandler,
    registerInteractionForm,
    handleInteractionChange,
    handleUpdatePayment,
    handleAccept,
  } = useHomecare()

  const [statusPayment, setStatusPayment] = useState<string>(
    data?.order?.payment_status as string
  )

  const renderInteraction = useCallback((interaction: any, index: number) => {
    if (typeof interaction?.description === 'string') {
      return <MessageComponent {...{ ...interaction, index }} />
    }
  }, [])

  return (
    <>
      <header className='mb-6'>
        <h1 className='font-bold text-2xl'>Homecare Detalhes</h1>
      </header>

      <main className='h-[87vh]'>
        <div className='container ml-auto mr-auto flex flex-1 flex-row p-6 h-full'>
          <div className='ml-auto mr-auto flex flex-1 flex-row p-6 h-full'>
            <div className='hidden md:flex md:flex-1 md:flex-col'>
              <div className='flex flex-1 flex-col '>
                {data?.interactions?.length
                  ? data?.interactions?.map(renderInteraction)
                  : null}

                {!data?.interactions?.length ? (
                  <div>
                    <span className='text-lg font-bold'>
                      Nenhum interação adicionada
                    </span>
                  </div>
                ) : null}
              </div>

              {data?.order?.status && data?.order?.status.id !== 6 ? (
                <footer className='invisible md:visible'>
                  <form
                    className='flex py-4'
                    onSubmit={registerInteractionForm.handleSubmit(
                      registerInteractionHandler
                    )}
                  >
                    <input
                      onChange={handleInteractionChange}
                      value={registerInteractionForm.watch('description')}
                      name='description'
                      placeholder='Digite sua mensagem'
                      type='text'
                      id='first_name'
                      className='flex flex-1 mr-6 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5'
                    />
                    <div className='w-[150px]'>
                      <Button
                        type='submit'
                        height='24px'
                        className='text-white bg-blue outline-none font-medium rounded-md text-sm px-8 py-2.5 text-center'
                        loading={loading}
                        label='Enviar'
                      />
                    </div>
                  </form>
                </footer>
              ) : null}
            </div>
          </div>

          {data?.order ? (
            <form
              className='flex flex-1 flex-col md:ml-8 lg:ml-16 max-w-3xl text-xs'
              onSubmit={handleSubmit(saveHandler)}
            >
              <div className='flex flex-row justify-between items-center'>
                <div className='flex flex-1 justify-between items-center'>
                  {data.order.status ? (
                    <div className='flex flex-col mb-4'>
                      {data.order.identifier ? (
                        <span className='text-xs mb-2'>{`Pedido nº #${data.order.identifier}`}</span>
                      ) : null}
                      <span className='text-xl font-bold text-red-main'>
                        {data.order.status.description} -{' '}
                        {data.order.payment_status}
                      </span>
                    </div>
                  ) : null}

                  <div className='ml-auto w-[180px]'>
                    {data.order.card && data.order.status.id !== 6 ? (
                      <Button
                        type='button'
                        label={
                          HOMECARE_STATUS_LABELS[+data.order.status.id + 1]
                        }
                        height='30px'
                        background='#3097E2'
                        onClick={changeStatusHandler}
                        loading={changeStatusLoading}
                      />
                    ) : null}

                    {mode && data.order.status.id === 1 ? (
                      <Button
                        type='submit'
                        label='Salvar'
                        height='30px'
                        background='#52C372'
                        loading={loading}
                        disabled={
                          !productsForm?.fields.length ||
                          !deliveriesForm?.fields.length
                        }
                      />
                    ) : null}

                    {!mode && data.order.status.id === 1 ? (
                      <button
                        className='w-32 h-[30px] bg-[#3097E2] text-white rounded-md font-bold'
                        onClick={toggleMode}
                      >
                        Editar
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>

              {data.order.status.id === 1 && (
                <div className='my-6'>
                  <Button
                    type='reset'
                    label='Aceitar Orçamento'
                    height='32px'
                    background='#52C372'
                    loading={loading}
                    disabled={loading}
                    onClick={handleAccept}
                  />
                </div>
              )}

              <div className='border border-gray-300 p-4 rounded-lg flex justify-between'>
                <select
                  name='payment_status'
                  onChange={(e) => setStatusPayment(e.target.value)}
                  value={statusPayment as string}
                  defaultValue={data.order.payment_status}
                >
                  <option value=''></option>
                  <option value='Em aberto'>Em aberto</option>
                  <option value='Quitado'>Quitado</option>
                </select>
                <button
                  type='reset'
                  className='w-32 h-[30px] bg-[#3097E2] text-white rounded-md font-bold'
                  onClick={handleUpdatePayment(statusPayment)}
                  disabled={loading}
                >
                  {loading ? <Spinner /> : 'Atualizar'}
                </button>
              </div>

              <div>
                <p className='text-[16px] font-bold text-red-500 mt-4 mb-2'>
                  Cliente
                </p>
                <p>
                  {data.customer?.name} <br />
                  {data.customer?.phone_number}
                </p>
              </div>

              <p className='text-[16px] font-bold text-red-500 mt-4'>
                Endereço de entregar
              </p>
              <hr />

              <p className='mt-1'>{formattedAddress}</p>

              <p className='text-[16px] font-bold text-red-500 mt-4'>
                Pagamento
              </p>
              <hr />

              {data.order.card ? (
                <div className='flex items-center'>
                  <span>{data.order.card.mask_number}</span>
                </div>
              ) : (
                <div className='mt-1'>
                  <span>Cartão ainda não selecionado</span>
                </div>
              )}

              <section id='products'>
                <p className='text-[16px] font-bold text-red-500 mt-4'>
                  Produtos
                </p>
                <hr />

                <div className='min-h-[80px] max-h-[150px] overflow-y-auto px-0.5'>
                  {!!productsForm?.fields?.length &&
                    productsForm?.fields?.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={twMerge(
                            'mt-2 text-xs',
                            item.notFound && 'line-through'
                          )}
                        >
                          <div className='flex'>
                            <div>
                              <p className='font-bold'>{item.name}</p>
                              <p className='font-light'>
                                Quantidade: {item.quantity}
                              </p>
                            </div>

                            <div className='mt-0.5 ml-auto flex'>
                              <div>
                                <p className='font-light text-right'>
                                  Valor unitário
                                </p>
                                <p className='font-semibold text-right'>
                                  {formatCurrency(item.price)}
                                </p>
                              </div>
                              <div className='ml-4'>
                                <p className='font-light text-right'>
                                  Valor total
                                </p>
                                <p className='font-semibold text-right'>
                                  {formatCurrency(
                                    getMultiply(item.price, item.quantity)
                                  )}
                                </p>
                              </div>
                            </div>

                            {mode ? (
                              <div className='ml-8 flex'>
                                <img
                                  src={IconEditarBlue}
                                  className='w-5 h-5 mr-4 cursor-pointer'
                                  onClick={selectProductToEdit(index, item)}
                                />
                                <img
                                  src={IconStrikethrough}
                                  className='w-6 h-6 mr-4 cursor-pointer'
                                  onClick={onNotFoundChange(index, {
                                    ...item,
                                    notFound: !item.notFound,
                                  })}
                                />
                                <img
                                  src={IconDeleteRed}
                                  className='w-6 h-6 cursor-pointer'
                                  onClick={removeProductHandler(index)}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    })}

                  {!productsForm?.fields?.length && (
                    <div className='mt-1'>
                      <span>Ainda não foram inseridos os produtos</span>
                    </div>
                  )}
                </div>
                {mode ? (
                  <div className='ml-auto w-32 mt-6'>
                    <Button
                      label='Adicionar'
                      type='button'
                      height='30px'
                      background='#3097E2'
                      onClick={createProductHandler}
                    />
                  </div>
                ) : null}
              </section>

              <p className='mt-3 text-[16px] text-red-500 font-bold'>
                Serviços
              </p>
              <hr />

              <div className='min-h-[80px] max-h-[150px] overflow-y-auto px-0.5'>
                {!!deliveriesForm?.fields?.length &&
                  deliveriesForm?.fields?.map((item: any, index: number) => (
                    <div key={index} className={twMerge('mt-2 text-xs')}>
                      <div className='flex'>
                        <p className='font-bold'>{item.description}</p>
                        <div className='ml-auto'>
                          <p className='font-light text-right'>Preço</p>
                          <p className='font-semibold'>
                            {formatCurrency(item.price)}
                          </p>
                        </div>

                        {mode ? (
                          <div className='ml-8 flex'>
                            <img
                              src={IconEditarBlue}
                              className='w-5 h-5 mr-4 cursor-pointer'
                              onClick={selectDeliveryToEdit(index, item)}
                            />
                            <img
                              src={IconDeleteRed}
                              className='w-6 h-6 cursor-pointer'
                              onClick={removeDeliveryHandler(index)}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}

                {!deliveriesForm?.fields?.length && (
                  <div className='mt-1'>
                    <span>
                      Ainda não foram inseridas as informações sobre entregas
                    </span>
                  </div>
                )}
              </div>
              {mode ? (
                <div className='ml-auto w-32 mt-6'>
                  <Button
                    label='Adicionar'
                    type='button'
                    height='30px'
                    background='#3097E2'
                    onClick={createDeliveryHandler}
                  />
                </div>
              ) : null}

              <p className='mt-4 text-[16px] text-red-500 font-bold'>
                Subtotal
              </p>
              <hr />

              <p className='flex mt-1'>
                Total produtos:
                <span className='ml-auto'>
                  {data.order.total_products
                    ? formatCurrency(data.order.total_products)
                    : '-'}
                </span>
              </p>

              <p className='flex mt-1'>
                Total serviços:
                <span className='ml-auto'>
                  {data.order.total_deliveries
                    ? formatCurrency(data.order.total_deliveries)
                    : '-'}
                </span>
              </p>

              {/* <p className='flex mt-1'>
                Taxas:
                <span className='ml-auto'>
                  {data.order.rate ? formatCurrency(data.order.rate) : '-'}
                </span>
              </p> */}

              <p className='flex mt-1 font-semibold'>
                Total:
                <span className='ml-auto'>
                  {data.order.total ? formatCurrency(data.order.total) : '-'}
                </span>
              </p>

              <p className='mt-3 text-[16px] text-red-500 font-bold'>
                Histórico Pagamento
              </p>
              <hr className='mb-1' />

              {data?.order?.payment_info?.history?.length
                ? data?.order?.payment_info?.history?.map((item: any) => (
                    <div className='mb-1.5 flex flex-col'>
                      <p className='text-sm font-bold'>{item.operation_type}</p>
                      <p>
                        {format(
                          new Date(item.created_at),
                          'dd/MM/yyyy HH:mm:ss'
                        )}
                      </p>
                    </div>
                  ))
                : null}

              {!data?.order?.payment_info?.history?.length ? (
                <div className='mb-1.5 flex flex-col'>
                  <p className='text-sm font-bold'>Não há informações</p>
                </div>
              ) : null}
            </form>
          ) : null}
        </div>

        {data?.order?.status && data?.order?.status.id !== 6 ? (
          <footer className='fixed bottom-0 w-screen md:invisible'>
            <form
              className='flex w-screen bg-gray-100 shadow-lg z-20 p-4'
              onSubmit={registerInteractionForm.handleSubmit(
                registerInteractionHandler
              )}
            >
              <Input
                onChange={handleInteractionChange}
                value={registerInteractionForm.watch('description')}
                name='description'
                placeholder='Digite sua mensagem'
                type='text'
                id='first_name'
                className='flex flex-1 !h-[24px] mr-6 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5'
              />
              <div>
                <Button
                  type='submit'
                  className='text-white bg-blue outline-none font-medium rounded-md text-sm px-8 py-2.5 text-center !h-[24px]'
                  loading={loading}
                  label='Enviar'
                />
              </div>
            </form>
          </footer>
        ) : null}
      </main>

      {product ? (
        <ProductModal
          product={product}
          onSubmitHandler={onProductSubmitHandler}
          onCloseHandler={closeProductModalHandler}
        />
      ) : null}

      {delivery ? (
        <DeliveryModal
          delivery={delivery}
          onSubmitHandler={onDeliverySubmitHandler}
          onCloseHandler={closeDeliveryModalHandler}
        />
      ) : null}
    </>
  )
}

export default HomecareDetails
