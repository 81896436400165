import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
`

export const Box = styled.div`
  padding: 17px 0;
  border-bottom: 1px dashed #c4c4c4;
`

export const Content = styled.div`
  height: calc(100vh - 258px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px 4px;
`
