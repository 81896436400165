import api, { newApi } from './api'

export interface BankAccountProps {
  name: string
  bank_code: string
  account_number: string
  routing_number: string
  type: 'checking' | 'savings'
  pub_id: string
}

export const postBankAccount = async (
  storeId: string,
  data: BankAccountProps
) => newApi.post(`/stores/${storeId}/banking-accounts`, data)

export const getAllBankAccount = async (storeId: string) =>
  newApi.get(`/stores/${storeId}/banking-accounts`)

export const deleteBankAccountById = async (storeId: string, bankId: string) =>
  newApi.delete(`/stores/${storeId}/banking-accounts/${bankId}`)

export const getSWRAllBankAccount = async (url: string) => {
  const response = await api.get<any[]>(url)
  return response.data
}
