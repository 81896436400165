import { ChangeEvent, useCallback, useState } from 'react'

import Change from 'src/assets/icons/icon-change.svg'
import Important from 'src/assets/icons/icon-important.svg'
import Money from 'src/assets/icons/icon-up.svg'
import WarningRed from 'src/assets/icons/icon-warning-red.svg'

const listStatus = [
  {
    label: 'Aceito',
    value: 'accept',
  },
  {
    label: 'Recusado',
    value: 'decline',
  },
  {
    label: 'Expirado',
    value: 'expired',
  },
  {
    label: 'Confirmado',
    value: 'checkout confirmed',
  },
  {
    label: 'Em entrega',
    value: 'store delivering',
  },
  {
    label: 'Entrega do cliente',
    value: 'customer delivering',
  },
  {
    label: 'Entregue',
    value: 'delivered',
  },
  {
    label: 'Cancelado',
    value: 'canceled',
  },
]

const orderColors: { [key: number]: string } = {
  1: '#3097E2',
  2: '#3B5998',
  3: '#C0392B',
  4: '#C0392B',
  5: '#F48D42',
  6: '#F48D42',
  7: '#F48D42',
  8: '#52C372',
  9: '#C0392B',
}

export const proposalStatus: { [key: number]: string } = {
  1: 'Nova Proposta',
  2: 'Aceito',
  3: 'Rejeitado',
  4: 'Expirado',
  5: 'Em andamento',
  6: 'Em andamento',
  7: 'Em andamento',
  8: 'Pedido entregue',
  9: 'Cancelado',
}

export const deliveryColor = (isFarmazonDelivery: boolean) =>
  isFarmazonDelivery ? '#7B61FF' : '#F48D42'

const daysFilter = [7, 15, 30]

const useOperation = () => {
  const [days, setDays] = useState(7)
  const [status, setStatus] = useState('')
  const activeStep = 1

  const handleChangeDaysRight = () => {
    const index = daysFilter.indexOf(days)
    if (index < daysFilter.length - 1) setDays(daysFilter[index + 1])
  }

  const handleChangeDaysLeft = () => {
    const index = daysFilter.indexOf(days)
    if (index > 0) setDays(daysFilter[index - 1])
    else setDays(daysFilter[0])
  }

  const handleChangeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setStatus(value)
  }

  const getProductOption = useCallback((required = null, substitutes = []) => {
    if (required === null && substitutes.length === 0)
      return 'Ele pode escolher um similar'
    if (required === null && substitutes.length > 0)
      return 'Quero escolher um substituto'
    if (required === false && substitutes.length === 0)
      return 'Tudo bem, não é obrigatório.'
    return 'Este produto é obrigatório.'
  }, [])

  const getIconOption = useCallback((required = null, substitutes = []) => {
    if (required === null && substitutes.length === 0) return Change
    if (required === null && substitutes.length > 0) return Change
    if (required === false && substitutes.length === 0) return Money
    return Important
  }, [])

  const getSaleCenterProductOption = useCallback(
    (required = null, substitutes = []) => {
      if (required === null && substitutes.length === 0) {
        return {
          title:
            'Você pode escolher um substituto de marca ou princípio ativo similar.',
          text: '',
        }
      }
      if (required === false && substitutes.length === 0) {
        return {
          title: 'Esse produto não é obrigatório.',
          text: 'Caso não tenha, pode aceitar o pedido mesmo assim.',
        }
      }

      return {
        title: 'Este produto é obrigatório.',
        text: 'Caso você não tenha, não aceite este pedido.',
      }
    },
    []
  )

  const getSaleCenterIconOption = useCallback(
    (required = null, substitutes = []) => {
      if (required === null && substitutes.length === 0) return Change
      if (required === false && substitutes.length === 0) return Money
      return WarningRed
    },
    []
  )

  return {
    activeStep,
    status,
    days,
    handleChangeDaysRight,
    handleChangeDaysLeft,
    handleChangeStatus,
    listStatus,
    orderColors,
    proposalStatus,
    getProductOption,
    getIconOption,
    getSaleCenterProductOption,
    getSaleCenterIconOption,
  }
}

export default useOperation
