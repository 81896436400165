/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import Map, { Marker } from 'react-map-gl'
import { useParams } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line

import 'mapbox-gl/dist/mapbox-gl.css'

// import DeleteOutline from '@material-ui/icons/DeleteOutline'
import { LoadingSearch } from 'src/components/elements'
import { Button, Input, Switch } from 'src/components/form'
import { Text, View, ContentShadow } from 'src/components/helper'
import Pin from 'src/components/map/Pin'
import { getStoreInfo, updateLocationStore } from 'src/services/StoresService'

import { Container, Box, Content } from './styles'

function StoreDetail() {
  const { storeId } = useParams<{ storeId: string }>()
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>(undefined)
  const { handleSubmit, register, reset } = useForm()

  useEffect(() => {
    getStoreInfo(storeId)
      .then((result) => {
        setData(result.data)
        reset({
          allow_bkc_delivery: result.data.allow_bkc_delivery,
        })
      })
      .finally(() => {
        setLoadingData(false)
      })
  }, [storeId])

  const handleOnSubmit = handleSubmit((data) => {
    setLoadingData(true)
    updateLocationStore(storeId, {
      address: {
        lat: data.lat,
        lng: data.lng,
      },
      allow_bkc_delivery: !!data.allow_bkc_delivery,
    })
      .then(() => {
        getStoreInfo(storeId).then((result) => {
          setData(result.data)
        })
      })
      .finally(() => {
        setLoadingData(false)
      })
  })

  return (
    <>
      <Container>
        {loadingData && (
          <View height='calc(100% - 100px)' justify='center' align='center'>
            <LoadingSearch />
          </View>
        )}

        {!loadingData && data && (
          <>
            <Row>
              <Col xs={10}>
                <View pb={2} flex align='center'>
                  <View>
                    <Text size={25} fontWeight={700}>
                      Sobre a loja
                    </Text>
                  </View>
                </View>
              </Col>
            </Row>

            <Content>
              <ContentShadow>
                <Row>
                  <Col xs={3}>
                    <View>
                      <Text fontWeight={500} size={14}>
                        Nome da loja
                      </Text>
                      <View mt={0.4}>
                        <Text size={14}>{data.name}</Text>
                      </View>
                    </View>
                  </Col>
                  <Col xs={9}>
                    <View>
                      <Text fontWeight={500} size={14}>
                        Endereço
                      </Text>
                      <View mt={0.4}>
                        <Text size={14}>
                          {data.address.street}, {data.address.number} ,CEP:{' '}
                          {data.address.cep}, {data.address.city} -{' '}
                          {data.address.state}
                        </Text>
                      </View>
                    </View>
                  </Col>
                </Row>
              </ContentShadow>

              <View mt={2}>
                <ContentShadow>
                  <Row align='center'>
                    <Col xs={4}>
                      <View>
                        <Text fontWeight={500} size={14}>
                          Telefones de contato
                        </Text>
                      </View>
                    </Col>
                    <Col xs={4}>
                      {data?.contact_phones?.map((item: any) => (
                        <View>
                          <Text size={14}>
                            {item.phone} - Whatsapp?{' '}
                            {item.whatsapp ? 'Sim' : 'Não'}
                          </Text>
                        </View>
                      ))}
                    </Col>
                  </Row>
                </ContentShadow>
              </View>

              <View mt={2}>
                <ContentShadow>
                  <Row align='center'>
                    <Col xs={12}>
                      <View>
                        <Text fontWeight={500} size={14}>
                          Informações do usuário
                        </Text>
                      </View>
                      <View mt={1}>
                        <Text fontWeight={500} size={12}>
                          name
                        </Text>
                        <Text size={14}>{data.customer?.name}</Text>
                      </View>
                      <View mt={1}>
                        <Text fontWeight={500} size={12}>
                          Endereço
                        </Text>
                        <Text size={14}>{data.customer?.email}</Text>
                      </View>

                      <View mt={1}>
                        <Text fontWeight={500} size={12}>
                          Número cadastrado no cognito
                        </Text>
                        <Text size={14}>{data.customer?.phone_number}</Text>
                      </View>
                    </Col>
                  </Row>
                </ContentShadow>
              </View>

              <View mt={2}>
                <ContentShadow>
                  <Box>
                    <View
                      flex
                      direction='row'
                      justify='space-between'
                      align='center'
                    >
                      <View>
                        <Text fontWeight={500} size={14}>
                          Horários de atendimento
                        </Text>
                      </View>
                      <View
                        flex
                        justify='space-between'
                        style={{ width: '180px' }}
                      >
                        {data?.opening_hours?.all_week ? (
                          <View>
                            <Text size={13} fontWeight={500}>
                              atendimento 24h
                            </Text>
                            <Text size={9}>De segunda a domingo</Text>
                          </View>
                        ) : (
                          <View>
                            {Object.entries(data?.opening_hours).map(
                              (item: any) => (
                                <Text size={14} textAlign='right'>
                                  {item[0]} - {JSON.stringify(item[1])}
                                </Text>
                              )
                            )}
                          </View>
                        )}
                      </View>
                    </View>
                  </Box>
                </ContentShadow>
              </View>

              <View mt={2}>
                <ContentShadow>
                  <Box>
                    <View>
                      <div>
                        <form onSubmit={handleOnSubmit}>
                          <Text fontWeight={500} size={14}>
                            Entrega BKC?
                          </Text>

                          <div className='mt-2'>
                            <Switch {...register('allow_bkc_delivery')} />
                          </div>

                          <div className='mt-4 w-[180px]'>
                            <Button label='Atualizar' type='submit' />
                          </div>
                        </form>
                      </div>
                    </View>
                  </Box>
                </ContentShadow>
              </View>

              <View mt={2}>
                <ContentShadow>
                  <Box>
                    <View direction='row'>
                      <View
                        flex
                        direction='column'
                        justify='space-between'
                        align='center'
                        width='500px'
                      >
                        <Text fontWeight={500} size={14}>
                          Área de entrega
                        </Text>
                        <View mt={1} width='500px'>
                          <Map
                            initialViewState={{
                              latitude: +data.address.lat,
                              longitude: +data.address.lng,
                              zoom: 13,
                            }}
                            mapStyle='mapbox://styles/mapbox/streets-v12'
                            mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                            style={{ height: 350, width: 480 }}
                          >
                            <Marker
                              key='marker'
                              longitude={+data.address.lng}
                              latitude={+data.address.lat}
                              anchor='bottom'
                            >
                              <Pin active />
                            </Marker>
                          </Map>
                        </View>
                      </View>

                      <View ml={3}>
                        <form onSubmit={handleOnSubmit}>
                          <Text>Latitude</Text>
                          <Input
                            type='text'
                            {...register('lat', { required: true })}
                            defaultValue={data.address.lat}
                          />
                          <View mt={1}>
                            <Text>Longitude</Text>
                            <Input
                              type='text'
                              {...register('lng', { required: true })}
                              defaultValue={data.address.lng}
                            />
                          </View>
                          <View mt={1}>
                            <Button label='Atualizar' type='submit' />
                          </View>
                        </form>
                      </View>
                    </View>
                  </Box>
                </ContentShadow>
              </View>
            </Content>
          </>
        )}
      </Container>
    </>
  )
}

export default StoreDetail
