import { useEffect } from 'react'
import { useAlert } from 'react-alert'

export interface AlertEvent {
  type: 'success' | 'error' | 'info'
  message: string
}

export function showAlert(data: AlertEvent) {
  const customEvent = new CustomEvent<AlertEvent>('alert', { detail: data })
  document.dispatchEvent(customEvent)
}

function AlertEventListener() {
  const alert = useAlert()

  useEffect(() => {
    document.addEventListener('alert', ({ detail }: any) => {
      alert[detail.type as AlertEvent['type']](
        detail.message as AlertEvent['message']
      )
    })
  }, [])

  return null
}

export default AlertEventListener
