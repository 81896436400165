import { useState } from 'react'
import { useAlert } from 'react-alert'

import { createLog } from 'src/services/log'
import {
  getSaleCenterAcceptOrder,
  getSaleCenterDeclineOrder,
  getSalesCenterAcceptOrderByIdSale,
} from 'src/services/OrderService'
import { eventGA } from 'src/util/gtag'
import { mutate } from 'swr'

interface DeclineOrderParams {
  reason: string
  description: string
}

const useOrder = () => {
  const alert = useAlert()

  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)

  const [orderDetails, setOrderDetails] = useState<any>(undefined)
  const [orderModalVisible, setOrderModalVisible] = useState(false)

  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false)

  const handleOrderModalDetails = async (saleId: string) => {
    try {
      setLoading(true)
      setOrderDetails(undefined)
      setOrderModalVisible(true)

      const { data } = await getSalesCenterAcceptOrderByIdSale(saleId)

      setOrderDetails({ ...data, proposal: saleId })
    } catch (error: any) {
      alert.error(error?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  }

  const handleAcceptOrder = (
    storeId: string,
    saleId: string,
    orderId: string,
    handleCloseModal: () => void
  ) => {
    return async () => {
      try {
        setLoadingButton(true)

        await getSaleCenterAcceptOrder(storeId, saleId)
        createLog({
          orderId,
          log: 'ACCEPT_ORDER',
        })

        mutate('/admin/orders')

        handleCloseModal()
      } finally {
        setLoadingButton(true)
      }
    }
  }

  const handleDeclineOrder = (
    storeId: string,
    saleId: string,
    orderId: string,
    reasonData: DeclineOrderParams,
    handleCloseModal: () => void
  ) => {
    return async () => {
      try {
        setLoadingButton(true)

        await getSaleCenterDeclineOrder(storeId, saleId, reasonData)
        createLog({
          orderId,
          log: 'DECLINE_ORDER',
        })

        mutate('/admin/orders')

        handleCloseModal()
      } finally {
        setLoadingButton(true)
      }
    }
  }

  const handleOpenModalDecline = () => {
    eventGA('rejeitar_pedido')

    setShowRejectReasonModal(true)
  }

  const handleCloseModal = () => {
    setOrderModalVisible(false)
    setShowRejectReasonModal(false)
  }

  return {
    loading,
    loadingButton,
    orderDetails,
    orderModalVisible,
    showRejectReasonModal,
    handleCloseModal,
    handleOrderModalDetails,
    handleAcceptOrder,
    handleDeclineOrder,
    handleOpenModalDecline,
  }
}

export default useOrder
