import styled from 'styled-components'

const Divider = styled.div<{ activeStep?: number }>`
  width: 20px;
  height: 3px;
  background-color: ${({ activeStep }) => (activeStep ? '#3097E2' : '#C4C4C4')};
  transition: all 800ms ease;
`

const Node = styled.div<{ activeStep?: number }>`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transition: all 1000ms ease;
  border: 2px solid ${({ activeStep }) => (activeStep ? '#3097E2' : '#C4C4C4')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Li = styled.li`
  list-style: none;
  line-height: 1px;
  display: flex;
  align-items: center;
`

const Current = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: all 1000ms ease;
  border: 3px solid #3097e2;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.ul`
  display: flex;
`

export default {
  Container,
  Current,
  Divider,
  Node,
  Li,
}
