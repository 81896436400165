import { getUnixTime, set } from 'date-fns'
import { formatMoney } from 'src/util/helpers/string'

const RESET_DATA = 'storeOnboardingReducer/RESET_DATA'
const ADD_CUSTOMER = 'storeOnboardingReducer/ADD_CUSTOMER'
const ADD_INFO = 'storeOnboardingReducer/ADD_NAME'
const ADD_LOCATION = 'storeOnboardingReducer/ADD_LOCATION'
const SET_STEP = 'storeOnboardingReducer/SET_STEP'
const ADD_LIST_PHONES = 'storeOnboardingReducer/ADD_LIST_PHONES'
const ADD_OPENING = 'storeOnboardingReducer/ADD_OPENING'
const ADD_NO_CLOSE = 'storeOnboardingReducer/ADD_NO_CLOSE'
const SET_DELIVERY_THIS = 'storeOnboardingReducer/SET_DELIVERY_THIS'
const SET_DELIVERY_RANGE = 'storeOnboardingReducer/SET_DELIVERY_RANGE'
const SET_DELIVERY_FEE = 'storeOnboardingReducer/SET_DELIVERY_FEE'
const SET_FARMAZON_DELIVERY_THIS =
  'storeOnboardingReducer/SET_FARMAZON_DELIVERY_THIS'
const SET_DELIVERY_TIME = 'storeOnboardingReducer/SET_DELIVERY_TIME'
const ACCEPT_TERM = 'storeOnboardingReducer/ACCEPT_TERM'
const STORE_LOCATION = 'storeOnboardingReducer/STORE_LOCATION'
const SET_START = 'storeOnboardingReducer/SET_START'

export const DATE_CURRENT = new Date(1995, 11, 17)

const DEFAULT_OPEN_HOUR = getUnixTime(
  set(DATE_CURRENT, {
    hours: 8,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
)

const DEFAULT_CLOSE_HOUR = getUnixTime(
  set(DATE_CURRENT, {
    hours: 18,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
)

interface LocationProps {
  cep: string
  street: string
  number: string
  more: string
  neighborhood: string
  city: string
  state: string
}

interface PhonesProps {
  phone: string
  whatsapp: boolean
}

interface HoursProps {
  label: string
  short: string
  open: number
  close: number
  firstDayOfWeek?: boolean
  openStore?: boolean
}

interface OpeningProps {
  notClose: boolean
  hours: HoursProps[]
}

export interface StoreOnboardingReducerProps {
  name: string
  cnpj: string
  customer: string
  lat: string
  lng: string
  location: LocationProps
  contact_phones: PhonesProps[]
  screenStep: string
  openingHours: OpeningProps
  allow_farmazon_delivery: boolean
  self_delivery: boolean
  self_delivery_range: number
  self_delivery_time: number
  self_delivery_fee: string
  acceptTerms: boolean
  startFlow: boolean
}

const stateInit = {
  name: '',
  customer: '',
  cnpj: '',
  acceptTerms: false,
  lat: '',
  lng: '',
  location: {
    cep: '',
    street: '',
    number: '',
    more: '',
    neighborhood: '',
    city: '',
    state: '',
    country: 'Brasil',
  },
  allow_farmazon_delivery: true,
  self_delivery: false,
  self_delivery_range: 1,
  self_delivery_fee: formatMoney('100'),
  self_delivery_time: 90,
  contact_phones: [],
  openingHours: {
    notClose: false,
    hours: [
      {
        label: 'Segunda-feira',
        short: 'seg',
        open: DEFAULT_OPEN_HOUR,
        close: DEFAULT_CLOSE_HOUR,
        firstDayOfWeek: true,
      },
      {
        label: 'Terça-feira',
        short: 'ter',
        open: DEFAULT_OPEN_HOUR,
        close: DEFAULT_CLOSE_HOUR,
      },
      {
        label: 'Quarta-feira',
        short: 'qua',
        open: DEFAULT_OPEN_HOUR,
        close: DEFAULT_CLOSE_HOUR,
      },
      {
        label: 'Quinta-feira',
        short: 'qui',
        open: DEFAULT_OPEN_HOUR,
        close: DEFAULT_CLOSE_HOUR,
      },
      {
        label: 'Sexta-feira',
        short: 'sex',
        open: DEFAULT_OPEN_HOUR,
        close: DEFAULT_CLOSE_HOUR,
      },
      {
        label: 'Sábado',
        short: 'sab',
        open: DEFAULT_OPEN_HOUR,
        close: DEFAULT_CLOSE_HOUR,
        openStore: false,
      },
      {
        label: 'Domingo',
        short: 'dom',
        open: DEFAULT_OPEN_HOUR,
        close: DEFAULT_CLOSE_HOUR,
        openStore: false,
      },
    ],
  },
  startFlow: false,
  screenStep: 'StoreInfo',
}

export const storeOnboardingReducer = (
  state: StoreOnboardingReducerProps = stateInit,
  { type, payload }: any
) => {
  switch (type) {
    case SET_START:
      return { ...state, startFlow: true }
    case STORE_LOCATION:
      return { ...state, lat: payload.lat, lng: payload.lng }
    case ACCEPT_TERM:
      return {
        ...state,
        acceptTerms: payload,
      }
    case SET_DELIVERY_THIS:
      return {
        ...state,
        self_delivery: payload,
      }
    case SET_FARMAZON_DELIVERY_THIS:
      return {
        ...state,
        allow_farmazon_delivery: payload,
      }
    case SET_DELIVERY_RANGE:
      return {
        ...state,
        self_delivery_range: payload,
      }
    case SET_DELIVERY_FEE:
      return {
        ...state,
        self_delivery_fee: payload,
      }
    case SET_DELIVERY_TIME:
      return {
        ...state,
        self_delivery_time: payload,
      }
    case ADD_NO_CLOSE:
      return {
        ...state,
        openingHours: {
          ...state.openingHours,
          notClose: payload,
        },
      }
    case SET_STEP:
      return {
        ...state,
        screenStep: payload,
      }
    case ADD_OPENING:
      return {
        ...state,
        openingHours: {
          ...state.openingHours,
          hours: payload,
        },
      }
    case ADD_LIST_PHONES:
      return { ...state, contact_phones: payload }
    case ADD_INFO:
      return {
        ...state,
        name: payload.name,
        cnpj: payload.cnpj,
      }
    case ADD_CUSTOMER:
      return {
        ...state,
        customer: payload,
      }
    case ADD_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          cep: payload.cep,
          street: payload.street,
          number: parseInt(payload.number),
          more: payload.more,
          neighborhood: payload.neighborhood,
          city: payload.city,
          state: payload.state,
        },
      }
    case RESET_DATA:
      return stateInit
    default:
      return state
  }
}

export const startOnboardingAction = () => ({ type: SET_START })

export const addPointStoreAction = (lat: number, lng: number) => ({
  type: STORE_LOCATION,
  payload: { lat, lng },
})

export const setOnboardingAcceptAction = (payload: boolean) => ({
  type: ACCEPT_TERM,
  payload,
})

export const resetOnboardingAction = () => ({ type: RESET_DATA })

export const addOnboardingNameAction = (payload: {
  name: string
  cnpj: string
}) => ({ type: ADD_INFO, payload })

export const addOnboardingLocationAction = (payload: LocationProps) => ({
  type: ADD_LOCATION,
  payload,
})

export const setScreenStepAction = (payload: string) => ({
  type: SET_STEP,
  payload,
})

export const addOnboardPhonesAction = (payload: PhonesProps[]) => ({
  type: ADD_LIST_PHONES,
  payload,
})

export const addOnboardingOpeningAction = (payload: HoursProps[]) => ({
  type: ADD_OPENING,
  payload,
})

export const addOnboardingNotCloseAction = (payload: boolean) => ({
  type: ADD_NO_CLOSE,
  payload,
})

export const addOnboardingHasDeliveryAction = (payload: boolean) => ({
  type: SET_DELIVERY_THIS,
  payload,
})

export const addOnboardingAllowFarmazonDeliveryAction = (payload: boolean) => ({
  type: SET_FARMAZON_DELIVERY_THIS,
  payload,
})

export const addOnboardingRangeAction = (payload: number) => ({
  type: SET_DELIVERY_RANGE,
  payload,
})

export const addOnboardingFeeAction = (payload: string) => ({
  type: SET_DELIVERY_FEE,
  payload,
})

export const addOnboardingTimeAction = (payload: number) => ({
  type: SET_DELIVERY_TIME,
  payload,
})

export const addOnboardingCustomerAction = (payload: string) => ({
  type: ADD_CUSTOMER,
  payload,
})
