import { useEffect, useMemo, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import {
  changeHomecareStatus,
  registerInteraction,
  updateHomecareOrder,
  updatePaymentByPass,
  updatePaymentStatus,
} from 'src/services/homecare'
import { mutate } from 'swr'

import { useNewFetch } from './useFetch'

const useHomecare = () => {
  const alert = useAlert()
  const { id } = useParams<{ id: string }>()

  const [changeStatusLoading, setChangeStatusLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState(false)
  const [product, setProduct] = useState<unknown>()
  const [delivery, setDelivery] = useState<unknown>()

  const { data } = useNewFetch(`/admin/homecares/${id}`, undefined, {
    refreshInterval: 3000,
  })

  const orderRef = useRef()

  const { control, reset, handleSubmit } = useForm()
  const productsForm = useFieldArray({
    control,
    name: 'products',
  })
  const deliveriesForm = useFieldArray({
    control,
    name: 'deliveries',
  })

  const registerInteractionForm = useForm()

  useEffect(() => {
    if (
      data?.order &&
      JSON.stringify(data.order) !== JSON.stringify(orderRef.current)
    ) {
      orderRef.current = data.order
      reset(data.order)
    }
  }, [data?.order])

  useEffect(() => {
    if (!mode && (!data?.order?.products || data?.order?.deliveries)) {
      setMode(true)
    }
  }, [mode, data])

  const toggleMode = () => {
    setMode((old) => !old)
  }

  const closeProductModalHandler = () => {
    setProduct(undefined)
  }

  const closeDeliveryModalHandler = () => {
    setDelivery(undefined)
  }

  const selectProductToEdit = (index: number, data: any) => {
    return () => {
      setProduct({ index, data })
    }
  }

  const createProductHandler = () => {
    setProduct({ index: productsForm?.fields?.length || 0, data: {} })
  }

  const removeProductHandler = (index: number) => {
    return () => {
      productsForm.remove(index)
    }
  }

  const selectDeliveryToEdit = (index: number, data: any) => {
    return () => {
      setDelivery({ index, data })
    }
  }

  const removeDeliveryHandler = (index: number) => {
    return () => {
      deliveriesForm.remove(index)
    }
  }

  const createDeliveryHandler = () => {
    setDelivery({ index: deliveriesForm?.fields?.length || 0, data: {} })
  }

  const onProductSubmitHandler = (index: number, data: any) => {
    productsForm.update(index, data)
  }

  const onDeliverySubmitHandler = (index: number, data: any) => {
    deliveriesForm.update(index, data)
  }

  const onNotFoundChange = (index: number, data: any) => {
    return () => {
      productsForm.update(index, data)
    }
  }

  const formattedAddress = useMemo(() => {
    const address = data?.order?.delivery_address
    if (!address) return ''

    return `${address.street}, ${address.number}, ${address.neighborhood}, ${address.city}/${address.country}`
  }, [data?.order?.delivery_address])

  const saveHandler = async (data: any) => {
    try {
      setLoading(true)
      await updateHomecareOrder(data.id, {
        products: data.products,
        deliveries: data.deliveries,
      })
      await mutate(`/admin/homecares/${id}`)
      setMode(false)

      alert.success('Pedido atualizado com sucesso!')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  }

  const changeStatusHandler = async () => {
    try {
      setChangeStatusLoading(true)

      await changeHomecareStatus(id, {
        id: +data.order.status.id + 1,
      })
      await mutate(`/admin/homecares/${id}`)

      alert.success('Status alterado com sucesso!')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setChangeStatusLoading(false)
    }
  }

  const registerInteractionHandler = async (data: any) => {
    try {
      console.log(data)
      setLoading(true)

      await registerInteraction(id, data)
      await mutate(`/admin/homecares/${id}`)
      registerInteractionForm.reset()
      registerInteractionForm.setValue('description', '')

      alert.success('Interação cadastrada com sucesso!')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  }

  const handleInteractionChange = (event: any) => {
    registerInteractionForm.setValue('description', event.target.value)
  }

  const handleUpdatePayment = (status: string) => {
    return async () => {
      try {
        if (status === '') {
          throw new Error('Selecione ')
        }

        setLoading(true)
        await updatePaymentStatus(id, { payment_status: status })
        await mutate(`/admin/homecares/${id}`)

        alert.success('Status atualizado com sucesso')
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleAccept = async () => {
    try {
      setLoading(true)

      await updatePaymentByPass(id)
      await mutate(`/admin/homecares/${id}`)

      alert.success('Status do pedido atualizado')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  }

  return {
    changeStatusLoading,
    loading,
    data,
    mode,
    formattedAddress,
    product,
    delivery,
    productsForm,
    deliveriesForm,
    toggleMode,
    selectProductToEdit,
    createProductHandler,
    closeProductModalHandler,
    onProductSubmitHandler,
    selectDeliveryToEdit,
    createDeliveryHandler,
    closeDeliveryModalHandler,
    onDeliverySubmitHandler,
    handleSubmit,
    saveHandler,
    removeProductHandler,
    removeDeliveryHandler,
    changeStatusHandler,
    onNotFoundChange,
    registerInteractionHandler,
    registerInteractionForm,
    handleInteractionChange,
    handleUpdatePayment,
    handleAccept,
  }
}

export default useHomecare
