import './styles/global.css'

import React from 'react'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import Amplify from 'aws-amplify'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle, theme } from './styles'

import { AlertTemplate } from './components/elements'
import amplifyConfig from './config/aws'
import { persistor, store } from './redux/store'
import Routers from './router'
import AlertEventListener from './util/helpers/alert'

Amplify.configure(amplifyConfig)

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
  transition: transitions.SCALE,
  containerStyle: {
    padding: '1rem',
    zIndex: 2000,
  },
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AlertProvider template={AlertTemplate} {...options}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routers />
              <AlertEventListener />
            </BrowserRouter>
          </ThemeProvider>
        </AlertProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
