/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'

import { Divider } from '@material-ui/core'
import IconDinheiro from 'src/assets/icons/icon-dinheiro.svg'
import IconMaquininha from 'src/assets/icons/icon-maquininha.svg'
import { Modal } from 'src/components/elements'
import { Button, InputText } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { createLog } from 'src/services/log'
import { changePaymentType } from 'src/services/OrderService'
import { formatCurrency, unMaskOnlyNumber } from 'src/util/helpers/string'
import { mutate } from 'swr'

import { border } from 'src/styles'

interface ChangePaymentModalProps {
  orderId: string
  payment: any
  isVisible: boolean
  onClose: () => void
  minChangeFor: number
}

const ChangePaymentModal = ({
  orderId,
  payment,
  onClose,
  minChangeFor,
}: ChangePaymentModalProps) => {
  const alert = useAlert()

  const [loading, setLoading] = useState(false)
  const [paymentType, setPaymentType] = useState<string>(payment.mode)
  const [changeFor, setChangeFor] = useState(payment.change_for)

  useEffect(() => {
    if (payment.change_for) {
      setChangeFor(payment.change_for)
    }
  }, [])

  const onConfirm = async () => {
    try {
      setLoading(true)

      const payloadCash = {
        payment_type: {
          description: 'presential',
          mode: 'cash',
          change_for: changeFor,
        },
      }

      const payloadCard = {
        payment_type: {
          description: 'presential',
          mode: 'card',
          change_for: 0,
        },
      }

      const payload = paymentType === 'card' ? payloadCard : payloadCash

      if (orderId) {
        await changePaymentType(orderId, payload)
        createLog({
          orderId,
          log: 'CHANGE_PAYMENT_TYPE',
        })
        mutate(`/admin/orders/${orderId}`)
      }

      alert.success('Tipo de pagamento alterado com sucesso')

      onClose()
    } finally {
      setLoading(false)
    }
  }

  const handleSelectType = (type: string) => {
    return () => {
      setPaymentType(type)
    }
  }

  const onChangeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(unMaskOnlyNumber(e.target.value))

    if (value) setChangeFor(value / 100)
    else setChangeFor(0)
  }

  const isInvalidChangeFor = useMemo(() => {
    if (changeFor === 0) return false

    return changeFor < minChangeFor
  }, [changeFor, minChangeFor])

  const buttonDisabled = useMemo(() => {
    if (paymentType === 'card') return false

    return isInvalidChangeFor
  }, [changeFor, minChangeFor])

  return (
    <Modal paddingTop={3} width={45} padding={0} onClose={onClose}>
      <View padding={2} style={{ minHeight: '200px' }}>
        <View mb={1}>
          <Text size={18} fontWeight={900}>
            Alterar tipo de pagamento
          </Text>
        </View>

        <Divider />

        <View>
          <View
            mt={1}
            pt={1}
            pb={1}
            pl={0.5}
            pr={0.5}
            flex
            onClick={handleSelectType('cash')}
            style={{
              ...border,
              borderColor: paymentType === 'cash' ? '#3097E2' : '#C4C4C4',
            }}
          >
            <img src={IconDinheiro} width={35} height={21} alt='Dinheiro' />

            <View ml={1}>
              <Text size={14} fontWeight={500}>
                Em espécie
              </Text>

              {paymentType === 'cash' && (
                <View direction='row' gap={1} justify='center' mt={0.6}>
                  <View>
                    <Text size={11} fontWeight={500}>
                      Vou precisar de troco para
                    </Text>
                    <Text size={10} fontWeight={300} mt={0.4}>
                      Caso não precise, deixe o campo em branco
                    </Text>
                  </View>

                  <View>
                    <InputText
                      value={formatCurrency(changeFor)}
                      onChange={onChangeChange}
                      name='change'
                    />
                    {isInvalidChangeFor ? (
                      <Text
                        size={10}
                        color='#A70000'
                      >{`O valor deve ser ${formatCurrency(
                        0
                      )} ou maior que ${formatCurrency(minChangeFor)}`}</Text>
                    ) : null}
                  </View>
                </View>
              )}
            </View>
          </View>

          <View
            mt={1}
            pt={1}
            pb={1}
            pl={0.5}
            pr={0.5}
            flex
            align='center'
            onClick={handleSelectType('card')}
            style={{
              ...border,
              borderColor: paymentType === 'card' ? '#3097E2' : '#C4C4C4',
            }}
          >
            <img src={IconMaquininha} width={35} height={35} alt='Maquininha' />

            <Text size={14} fontWeight={500} ml={1}>
              Maquininha (débito ou crédito)
            </Text>
          </View>
        </View>

        <View mt={2}>
          <Button
            disabled={buttonDisabled}
            loading={loading}
            label='Alterar'
            onClick={onConfirm}
          />
        </View>
      </View>
    </Modal>
  )
}

export default ChangePaymentModal
