import { ChangeEvent, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useDispatch } from 'react-redux'

import Clock from 'src/assets/icons/icone-clock.svg'
import Close from 'src/assets/icons/icone-sair.svg'
import { Dots } from 'src/components/elements'
import { Button, CheckBox, Select, Switch } from 'src/components/form'
import { ShadowBox, Text, View } from 'src/components/helper'
import useStoreOnboarding from 'src/hooks/useStoreOnboarding'
import {
  addOnboardingNotCloseAction,
  addOnboardingOpeningAction,
  setScreenStepAction,
  StoreOnboardingReducerProps,
} from 'src/redux/reducers/storeOnboardingReducer'
import { hours } from 'src/util/helpers/date'

import { Leave } from './styles'

function StoreHour({
  onboarding,
}: {
  onboarding: StoreOnboardingReducerProps
}) {
  const dispatch = useDispatch()
  const [check, setCheck] = useState(false)
  const { handleLeave } = useStoreOnboarding()

  const onSubmit = () => dispatch(setScreenStepAction('StoredeliveryService'))

  const handleNoClose = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch(addOnboardingNotCloseAction(e.target.checked))

  const handleChangeAll = () => {
    const currentCheck = !check
    setCheck(currentCheck)
    if (currentCheck) {
      const copy: any = [...onboarding.openingHours.hours]
      const open = Number(copy[0].open)
      const close = Number(copy[0].close)
      const updateData = copy.map((item: any) =>
        !['sab', 'dom'].includes(item.short) ? { ...item, open, close } : item
      )
      dispatch(addOnboardingOpeningAction(updateData))
    }
  }

  const handleWeekEnd = (short: string) => () => {
    const updateHours = onboarding.openingHours.hours.map((item) =>
      item.short === short ? { ...item, openStore: !item.openStore } : item
    )
    dispatch(addOnboardingOpeningAction(updateHours))
  }

  const handleSelectHour =
    (type: string, index: number) => (e: ChangeEvent<HTMLSelectElement>) => {
      const copy: any = [...onboarding.openingHours.hours]
      const value = parseInt(e.target.value)

      if (type === 'open') copy[index].open = value
      else copy[index].close = value

      dispatch(addOnboardingOpeningAction(copy))
    }

  return (
    <>
      <View flex justify='flex-end'>
        <Leave onClick={handleLeave}>
          <Text size={11}>Sair</Text>
          <img src={Close} alt='Sair' />
        </Leave>
      </View>

      <View>
        <img src={Clock} alt='Conversa' />
      </View>

      <View mt={0.2}>
        <Text color='#A70000' fontWeight='bold' size={27}>
          Horários de atendimento
        </Text>
      </View>

      <View mt={1}>
        <Text size={14}>
          Digite aqui os dias e os horários de atendimento da sua loja.
        </Text>
      </View>

      <View textAlign='center' mt={1}>
        <ShadowBox>
          <View flex justify='space-between' style={{ width: '100%' }}>
            <View>
              <Text size={13} fontWeight={500}>
                Atendimento 24h
              </Text>
              <Text size={9}>De segunda a domingo</Text>
            </View>
            <Switch onChange={handleNoClose} />
          </View>
        </ShadowBox>
      </View>

      {!onboarding.openingHours?.notClose && (
        <View
          mt={0.6}
          mb={0.6}
          style={{ height: 500, overflowY: 'auto', padding: 2 }}
        >
          {onboarding.openingHours.hours.map((date, index) => (
            <View key={index} mb={0.8}>
              <View mb={0.4}>
                <Text size={12}>{date.label}</Text>
              </View>
              <Row nogutter align='center' justify='center'>
                <Col xs={3}>
                  <Select
                    name='open'
                    value={date.open}
                    onChange={handleSelectHour('open', index)}
                    disabled={check}
                  >
                    {hours.map((hour, index) => (
                      <option key={index} value={hour.value}>
                        {hour.label}
                      </option>
                    ))}
                  </Select>
                </Col>

                <Col xs={2}>
                  <Text size={11} textAlign='center'>
                    Até
                  </Text>
                </Col>

                <Col xs={3}>
                  <Select
                    name='close'
                    value={date.close}
                    onChange={handleSelectHour('close', index)}
                    disabled={check}
                  >
                    {hours.map((hour, index) => (
                      <option key={index} value={hour.value}>
                        {hour.label}
                      </option>
                    ))}
                  </Select>
                </Col>
                <Col>
                  {date.short === 'seg' && (
                    <View ml={0.8} direction='row'>
                      <CheckBox
                        name='all'
                        background='#52C372'
                        onClick={handleChangeAll}
                        checked={check}
                      />
                      <Text size={9}>Igual para todos os dias da semana</Text>
                    </View>
                  )}

                  {['ter', 'qua', 'qui', 'sex'].includes(date.short) && check && (
                    <View ml={0.8} direction='row'>
                      <CheckBox name='all' background='#52C372' checked />
                    </View>
                  )}

                  {['sab', 'dom'].includes(date.short) && (
                    <View ml={0.8} direction='row' align='center'>
                      <CheckBox
                        name='weekend'
                        background='#EB5757'
                        borderWidthCheck='0px 2px 0px 0px'
                        rotate={90}
                        onClick={handleWeekEnd(date.short)}
                        checked={date.openStore}
                      />
                      <Text size={9}>Loja fechada</Text>
                    </View>
                  )}
                </Col>
              </Row>
            </View>
          ))}
        </View>
      )}

      <View style={{ marginTop: 'auto' }}>
        <Row align='center'>
          <Col xs={7}>
            <Dots length={5} active={3} />
          </Col>
          <Col xs={5}>
            <Button label='Próximo' background='#52C372' onClick={onSubmit} />
          </Col>
        </Row>
      </View>
    </>
  )
}

export default StoreHour
