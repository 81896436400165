import { DataRow, Header, Table, Text, View } from 'src/components/helper'
import { formatCurrency } from 'src/util/helpers/string'

interface RackingItem {
  nome: string
  quantidade: number
  valor: number
}

interface RankingUsersProps {
  items: RackingItem[]
}

const RankingUsers = ({ items }: RankingUsersProps) => {
  return (
    <View mt={1}>
      <Table.Table>
        <thead>
          <tr>
            <Header textAlign='left'>Nome</Header>
            <Header textAlign='left'>Quantidade</Header>
            <Header textAlign='left'>Valor</Header>
          </tr>
        </thead>
        <tbody>
          {items?.length > 0 ? (
            items?.map((item, idx: number) => (
              <Table.Row key={idx}>
                <DataRow>
                  <Text size={15}>{item.nome}</Text>
                </DataRow>
                <DataRow>
                  <Text size={15}>{item.quantidade}</Text>
                </DataRow>
                <DataRow>
                  <Text size={15}>{formatCurrency(item.valor)}</Text>
                </DataRow>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <DataRow colSpan={6}>
                <Text size={15}>
                  <Text textAlign='center' size={14} fontWeight={500}>
                    Nenhum registro
                  </Text>
                </Text>
              </DataRow>
            </Table.Row>
          )}
        </tbody>
      </Table.Table>
    </View>
  )
}

export default RankingUsers
