import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'

import { addOnboardingCustomerAction } from 'src/redux/reducers/storeOnboardingReducer'
import {
  getOrderRetrieveCustomer,
  patchAddHomecareUser,
  patchDeactivateUser,
} from 'src/services/Costumer'
import { mutate } from 'swr'

const useCustomer = () => {
  const alert = useAlert()
  const history = useHistory()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const [openModal, setOpenModal] = useState(false)
  const [openModalAddHomecare, setOpenModalAddHomecare] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userSelected, setUserSelected] = useState('')
  const [openModalSales, setOpenModalSales] = useState(false)
  const [salesCostumer, setSalesCostuemr] = useState([])
  const [filter, setFilter] = useState({})

  const handleToggleModal = () => setOpenModal((state) => !state)

  const handleToggleModalHomecare = () =>
    setOpenModalAddHomecare((state) => !state)

  const handleToggleModalSales = () => setOpenModalSales((state) => !state)

  const handleDisableUser = (id: string) => () => {
    setUserSelected(id)
    setOpenModal(true)
  }

  const handleAddHomecareUser = (id: string) => () => {
    setUserSelected(id)
    setOpenModalAddHomecare(true)
  }

  const handleSalesUser = (id: string) => async () => {
    try {
      setLoading(true)
      setOpenModalSales(true)
      setUserSelected(id)
      const { data } = await getOrderRetrieveCustomer({ customer_id: id })
      setSalesCostuemr(data.Items)
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Ação não concluída')
    } finally {
      setLoading(false)
    }
  }

  const handleCreateStore = (id: string) => () => {
    dispatch(addOnboardingCustomerAction(id))

    history.push('/lojas/cadastrar')
  }

  const handleSubmitDisableUser = async () => {
    try {
      setLoading(true)
      await patchDeactivateUser(userSelected)
      mutate(['/customers/admin/list', filter])
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Ação não concluída')
    } finally {
      setOpenModal(false)
      setLoading(false)
      setUserSelected('')
    }
  }

  const handleSubmitAddHomecareUser = async () => {
    try {
      setLoading(true)
      await patchAddHomecareUser(userSelected)
      mutate(['/customers/admin/list', filter])
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Ação não concluída')
    } finally {
      setOpenModalAddHomecare(false)
      setLoading(false)
      setUserSelected('')
    }
  }

  const handleGoToCreateUser = () => {
    history.push(`${path}/cadastrar`)
  }

  const setFilterParams = (data: any) => setFilter(data)

  return {
    loading,
    openModal,
    openModalSales,
    filter,
    openModalAddHomecare,
    setFilterParams,
    salesCostumer,
    handleSalesUser,
    handleCreateStore,
    userSelected,
    handleToggleModal,
    handleDisableUser,
    handleSubmitDisableUser,
    handleToggleModalSales,
    handleGoToCreateUser,
    handleAddHomecareUser,
    handleToggleModalHomecare,
    handleSubmitAddHomecareUser,
  }
}

export default useCustomer
