export const statusValues: { [key: string]: string } = {
  'awaiting payment': 'Aguardando pagamento',
  'select stores': 'Conectando lojas',
  'awaiting offers': 'Buscando produto(s)',
  accept: 'Aceito',
  canceled: 'Pedido cancelado',
  checkout: 'Preparando produto(s)',
  'checkout confirmed': 'Preparando pedido',
  'awaiting delivery': 'Conectando entregador',
  'customer delivering': 'Produto(s) a caminho',
  'store delivering': 'Coletando pedido para entrega',
  delivered: 'Produto(s) entregue',
  decline: 'Pedido rejeitado',
  scheduled: 'Agendado',
  'no response': 'Sem resposta das lojas',
  'payment rejected': 'Pagamento rejeitado',
  expired: 'Expirado',
}

export const OrderStatusEnum: Record<number, string> = {
  1: 'Aguardando pagamento',
  2: 'Conectando lojas',
  3: 'Buscando produto(s)',
  5: 'Pedido Confirmado',
  6: 'Buscando entregador',
  7: 'Entregando pedido para o entregador',
  8: 'Pedido a caminho',
  9: 'Produto(s) entregue',
}

export const AllOrderStatusEnum: { [key: number]: string } = {
  1: 'Aguardando pagamento',
  2: 'Conectando lojas',
  3: 'Buscando produto(s)',
  4: 'Cancelado',
  5: 'Pedido Confirmado',
  6: 'Buscando entregador',
  7: 'Entregando pedido para o entregador',
  8: 'Pedido a caminho',
  9: 'Produto(s) entregue',
  10: 'Pedido Agendado',
  11: 'Sem resposta das lojas',
  12: 'Pagamento rejeitado',
}

export const StoreOrderSteps: { [key: number]: string } = {
  1: 'Aguardando pagamento',
  2: 'Conectando lojas',
  3: 'Rejeitado',
  4: 'Expirado',
  5: 'Preparando o pedido',
  6: 'Entregando pedido para o entregador',
  7: 'Pedido a caminho',
  8: 'Pedido entregue com sucesso!',
}

export const disableCancel = [
  'expired',
  'canceled',
  'awaiting delivery',
  'store delivering',
  'customer delivering',
  'delivered',
]

export const enableLoggi = [
  'awaiting delivery',
  'store delivering',
  'customer delivering',
]

export const statusInvites: { [key: string]: string } = {
  'new proposal': 'Nova proposta',
  accept: 'Aceito',
  decline: 'Rejeitado',
  expired: 'Expirado',
  'checkout confirmed': 'Checkout confirmado',
  'store delivering': 'Buscando o pedido',
  'customer delivering': 'Pedido a caminho',
  delivered: 'Pedido entregue',
  canceled: 'Pedido Cancelado',
}

export const enableInvites = [
  'accept',
  'checkout confirmed',
  'store delivering',
  'customer delivering',
  'delivered',
]

export const saleStatus: { [key: string]: string } = {
  succeeded: 'Paga',
  canceled: 'Estornado',
}

export const saleStatusColor: { [key: string]: string } = {
  succeeded: '#00997F',
  canceled: '#FF0000',
}

export const cupomTypes = [
  { name: 'Valor a ser descontado', value: 'value' },
  { name: 'Porcentagem a ser descontado', value: 'percent' },
  { name: 'Elimina o frete', value: 'delivery' },
]

export const PROPOSAL_STATUS_SHORT: { [key: number]: string } = {
  1: 'Nova Proposta',
  2: 'Aguardando',
  5: 'Conectando entregador',
  6: 'Entregando',
  7: 'A caminho',
  8: 'Entregue',
}

export const PROPOSAL_WITH_ACTIVE_STATE = [5, 6, 7]

export const OrderSteps: { [key: number]: string } = {
  2: 'Confirmar pedido',
  5: 'Conectando entregador',
  6: 'Entregador a caminho',
  7: 'Pedido a caminho',
  8: 'Pedido entregue com sucesso!',
}

export const CHANGE_ORDER_STATUS_LABELS: { [key: number]: string } = {
  5: 'Pedido pronto',
  6: 'Pedido a caminho',
  7: 'Pedido entregue',
}

export const statusGA: { [key: number]: string } = {
  5: 'preparando_pedido',
  6: 'buscando_pedido',
  7: 'pedido_entregue',
}
