import React, { forwardRef } from 'react'

import { Container, InputStyle, TextError, Label } from './styles'

interface InputProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  messageError?: any
  validationError?: any
  label?: string
  rows?: number
}

function TextArea(
  { messageError, validationError, label, rows, ...props }: InputProps,
  forwardedRef: React.Ref<HTMLTextAreaElement>
) {
  return (
    <>
      <Container error={validationError ? 1 : 0}>
        {label && <Label>{label}</Label>}
        <InputStyle
          {...props}
          rows={rows}
          error={validationError ? 1 : 0}
          ref={forwardedRef}
        />
      </Container>
      {messageError && <TextError>{messageError}</TextError>}
    </>
  )
}

export default forwardRef(TextArea)
