import { Route, Redirect } from 'react-router-dom'

import { getAccessToken } from 'src/services/api'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isLogged = getAccessToken()

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? <Component {...props} /> : <Redirect to='/signin' />
      }
    />
  )
}

export default PrivateRoute
