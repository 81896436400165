import styled from 'styled-components'

interface ButtonProps {
  background?: string
  color?: string
  disabled?: boolean
  borderColor?: string
  height?: string
}

export const Container = styled.button<ButtonProps>`
  background: ${({ background = '#52C472' }) => background};
  border-radius: 8px;
  border: 1px solid ${({ borderColor = 'transparent' }) => borderColor};
  width: 100%;
  color: ${({ color = '#fff' }) => color};
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  min-height: ${({ height = '50px' }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    opacity: 0.8;
  }
`

export const Spinner = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`

export const Image = styled.img`
  position: absolute;
  left: 10px;
`

export const RightImage = styled.img`
  position: absolute;
  right: 10px;
`
