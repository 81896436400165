import { useState } from 'react'
import { useAlert } from 'react-alert'

import {
  getOrderPIN,
  getSendPIN,
  deleteCancelOrderById,
} from 'src/services/StoresService'

const useOrder = () => {
  const alert = useAlert()
  const [filter, setFilter] = useState({ page: 1 })

  const handleConfirm = () => {
    deleteCancelOrderById('')
      .then(() => {
        // getDataStore(idCustomer, getValues())
        // setIDOrder('')
        // setModalCancel(!modalCancel)
        alert.success('Pedido cancelado com sucesso!')
      })
      .catch((error) => {
        alert.error(
          error.response?.data?.message ?? 'Erro de comunicação com api.'
        )
      })
  }

  const handleInvitePin = (id: string) => async () =>
    getOrderPIN(id)
      .then(() => alert.success('PIN solicitado com sucesso.'))
      .catch(() => alert.error('Falha na solicitação do PIN.'))

  const handleResendPin = (id: string) => async () =>
    getSendPIN(id)
      .then(() => alert.success('PIN enviado com sucesso.'))
      .catch(() => alert.error('Falha no envio do PIN.'))

  const setFilterParams = (data: any) => setFilter(data)

  const getValuesSaved = () => sessionStorage.getItem('ORDER')

  const setValuesSaved = (values: any) =>
    sessionStorage.setItem('ORDER', JSON.stringify(values))

  return {
    filter,
    setFilterParams,
    handleConfirm,
    handleInvitePin,
    handleResendPin,
    getValuesSaved,
    setValuesSaved,
  }
}

export default useOrder
