import styled from 'styled-components'

export const Circle = styled.div<{ color?: string }>`
  height: 24px;
  width: 24px;
  border-radius: 50px;
  background-color: ${({ color = '#3097E2' }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Circle
