import styled, { css } from 'styled-components'

interface ViewProps {
  mb?: number
  mt?: number
  mr?: number
  ml?: number
  justify?: string
  align?: string
  width?: string
  height?: string
  background?: string
  pt?: number
  pb?: number
  pr?: number
  pl?: number
  radius?: number
  cursor?: boolean
  direction?: string
  textAlign?: string
  flex?: boolean
  padding?: number
  borderBottom?: boolean
  gap?: number
}

export default styled.div<ViewProps>`
  ${({ mb = 0 }) => mb && `margin-bottom: ${mb}rem`};
  ${({ mt = 0 }) => mt && `margin-top: ${mt}rem`};
  ${({ mr = 0 }) => mr && `margin-right: ${mr}rem`};
  ${({ ml = 0 }) => ml && `margin-left: ${ml}rem`};

  ${({ pb = 0 }) => pb && `padding-bottom: ${pb}rem`};
  ${({ pt = 0 }) => pt && `padding-top: ${pt}rem`};
  ${({ pr = 0 }) => pr && `padding-right: ${pr}rem`};
  ${({ pl = 0 }) => pl && `padding-left: ${pl}rem`};
  ${({ padding = 0 }) => padding && `padding: ${padding}rem`};
  ${({ radius = 0 }) => radius && `border-radius: ${radius}px`};
  ${({ borderBottom = false }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid #c4c4c4;
    `};
  ${({ flex }) => flex && 'display: flex'};
  ${({ gap = 0 }) => gap && `gap: ${gap}rem`};

  ${({ justify }) => justify && `justify-content: ${justify};`}
  ${({ background = 'transparent' }) =>
    background && `background-color: ${background}`};
  ${({ direction }) =>
    direction && `display: flex;flex-direction: ${direction};`};
  ${({ align }) => align && `align-items: ${align};`};
  ${({ width }) => width && `width: ${width};`};
  ${({ height }) => height && `height: ${height};`};
  ${({ cursor }) => cursor && 'cursor: pointer;'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`};
  position: relative;
`
