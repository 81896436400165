import { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'

import Disable from 'src/assets/icons/disable.svg'
import IconeProductCancel from 'src/assets/icons/icone-cancel-offer.svg'
import Editar from 'src/assets/icons/icone-editar.svg'
import EmptyProduct from 'src/assets/images/empty-produto.svg'
import { LoadingSearch, ModalConfirm } from 'src/components/elements'
import { Button, Input } from 'src/components/form'
import { Header, Table, Text, View, DataRow } from 'src/components/helper'
import useProduct from 'src/hooks/useProduct'
import { getAllProducts } from 'src/services/ProductService'
import { formatCurrency } from 'src/util/helpers/string'
import useSWR from 'swr'

import { Container, shadowBox } from './styles'

function ListProduct() {
  const {
    loading,
    openModal,
    handleToggleModal,
    handleDisable,
    handleOpenDisable,
    handleEditProduct,
  } = useProduct()

  const location = useLocation<any>()
  const history = useHistory()
  const [name, setName] = useState(
    new URLSearchParams(location.search).get('name')
  )

  const { register, handleSubmit } = useForm()

  const handleSubmitForm = handleSubmit((data) => {
    history.push(`/produtos?name=${data.name}`)
    setName(data.name)
  })

  const { data } = useSWR<any, any>(
    ['/admin/products', name],
    async (_url: string, name: string) => {
      const result = await getAllProducts({ name })
      return result
    }
  )

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View>
            <Text size={25} fontWeight={700}>
              Produtos
            </Text>
          </View>
        </View>
        <View height='calc(100% - 100px)' style={shadowBox}>
          <form onSubmit={handleSubmitForm}>
            <Row>
              <Col xs={2}>
                <View mt={1}>
                  <Text size={14} fontWeight={400}>
                    Buscar por nome:
                  </Text>
                </View>
              </Col>
              <Col xs={3}>
                <Input
                  type='text'
                  {...register('name')}
                  defaultValue={name ?? ''}
                />
              </Col>
              <Col xs={3}>
                <View>
                  <Button label='Filtrar' height='40px' />
                </View>
              </Col>
            </Row>
          </form>
          <View height='100%' mt={1.4}>
            {!data && <LoadingSearch />}

            {data && (
              <Table.Table>
                <thead>
                  <tr>
                    <Header textAlign='left'>imagem</Header>
                    <Header>Nome</Header>
                    <Header>Preço</Header>
                    {/* <Header>Preço Min.</Header> */}
                    <Header>Ações</Header>
                  </tr>
                </thead>
                <tbody>
                  {data.data.data.map((item: any) => (
                    <tr key={item?.pub_id}>
                      <DataRow textAlign='left'>
                        <img
                          src={item?.img || EmptyProduct}
                          alt='Imagem produto'
                          height={85}
                          width={85}
                        />
                      </DataRow>
                      <DataRow>
                        <View direction='row'>
                          <View mt={0.8}>
                            <Text fontWeight={700} size={14}>
                              {item?.name}
                            </Text>
                          </View>
                        </View>
                      </DataRow>
                      <DataRow>
                        <View
                          direction='column'
                          align='center'
                          width='60px'
                          style={{ margin: '0 auto' }}
                        >
                          <Text size={14} color='#52C372' fontWeight={700}>
                            {formatCurrency(item?.max_price)}
                          </Text>
                        </View>
                      </DataRow>
                      {/* <DataRow>
                        <Text size={14} color='#52C372' fontWeight={700}>
                          {formatCurrency(item?.min_price)}
                        </Text>
                      </DataRow> */}
                      <DataRow>
                        <View flex>
                          <View mt={0.2} mr={0.2} cursor>
                            <img
                              src={Editar}
                              alt='Editar'
                              height={18}
                              onClick={handleEditProduct(item?.pub_id, item)}
                            />
                          </View>
                          <View cursor ml={1}>
                            <img
                              src={Disable}
                              alt='Deletar'
                              onClick={handleOpenDisable(item?.pub_id)}
                            />
                          </View>
                        </View>
                      </DataRow>
                    </tr>
                  ))}
                </tbody>
              </Table.Table>
            )}
          </View>
        </View>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={IconeProductCancel}
          loading={loading}
          description='Tem certeza que deseja desabilitar este produto?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          backgroundConfirm='#FF0000'
          handleConfirm={handleDisable}
        />
      )}
    </>
  )
}

export default ListProduct
