import { useCallback, useEffect } from 'react'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useParams, withRouter } from 'react-router'

import RightArrow from 'src/assets/icons/icone-right.svg'
import OfferUser from 'src/assets/images/empty-user.svg'
import { ModalConfirm } from 'src/components/elements'
import { Button, Input } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useUser from 'src/hooks/useUser'
import { DefaultRootState } from 'src/redux/store'
import { getSalesCenterSWR } from 'src/services/OrderService'
import { SellerProps } from 'src/services/StoreService'
import { formatPhone, unFormatPhone } from 'src/util/helpers/string'
import useSWR from 'swr'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

const AddUser = (props: RouteComponentProps<any, any, SellerProps>) => {
  const { storeId } = useSelector<
    DefaultRootState,
    { storeId: string; name: string }
  >((state) => ({
    storeId: state.store.pub_id,
    name: state.store.name,
  }))

  const { data } = useSWR('/sales-center/stores', getSalesCenterSWR)

  const dispatch = useDispatch()
  const { userId } = useParams<{ userId: string }>()
  const user = props.location.state ?? {}

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm<any>()

  const {
    loading,
    openModal,
    handleUpdateUser,
    handleCreateUser,
    handleToggleModal,
    handleConfirmCancel,
  } = useUser(storeId)

  const formatPhoneInput = useCallback((event: any) => {
    setValue('username', formatPhone(event.target.value))
    clearErrors()
  }, [])

  const onSubmit = handleSubmit((data) => {
    if (userId) dispatch(handleUpdateUser(userId, data))
    else dispatch(handleCreateUser(data))
  })

  const store = watch('store')

  useEffect(() => {
    if (!store && data?.Items?.length) {
      setValue('store', data?.Items[0].pub_id)
    }
  }, [store, data?.Items])

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View mr={1}>
            <Text size={18} fontWeight={300}>
              Equipe
            </Text>
          </View>
          <View mr={1}>
            <img src={RightArrow} alt='Próximo' />
          </View>
          <View>
            <Text size={25} fontWeight={700}>
              {userId ? 'Editar pessoa' : 'Cadastrar pessoa'}
            </Text>
          </View>
        </View>

        <form style={{ height: 'calc(100% - 40px)' }} onSubmit={onSubmit}>
          <View height='calc(100% - 110px)' style={shadowBox}>
            <Row>
              <Col xs={4}>
                <View>
                  <Text fontWeight={500} size={12}>
                    Nome *
                  </Text>
                  <View mt={0.8}>
                    <Input
                      {...register('name', {
                        required: 'O nome é obrigatório',
                      })}
                      type='text'
                      defaultValue={user.name}
                      validationError={errors.name}
                      messageError={errors.name?.message}
                    />
                  </View>
                </View>
              </Col>
              <Col xs={4}>
                <View>
                  <Text fontWeight={500} size={12}>
                    Telefone celular *
                  </Text>
                  <View mt={0.8}>
                    <Input
                      {...register('username', {
                        required: 'O telefone é obrigatório',
                        pattern: {
                          value: /\d{5}-\d{4}/,
                          message: 'Digite seu número de telefone com DDD.',
                        },
                      })}
                      type='text'
                      defaultValue={
                        user.username ? unFormatPhone(user.username) : ''
                      }
                      validationError={errors.username}
                      messageError={errors.username?.message}
                      onChange={formatPhoneInput}
                    />
                  </View>
                </View>
              </Col>
              <Col xs={4}>
                <View>
                  <Text fontWeight={500} size={12}>
                    E-mail
                  </Text>
                  <View mt={0.8}>
                    <Input
                      {...register('email', {
                        pattern: {
                          value: /^\S+@\S+$/,
                          message: 'Digite um e-mail válido.',
                        },
                      })}
                      type='text'
                      defaultValue={user.email}
                      validationError={errors.email}
                      messageError={errors.email?.message}
                    />
                  </View>
                </View>
              </Col>
            </Row>

            <View mt={1.5}>
              {!userId ? (
                <Col xs={4}>
                  <View
                    flex
                    height='50px'
                    align='center'
                    justify='center'
                    background='#dff2ff'
                    mt={1.28}
                  >
                    <Text size={11} fontWeight={400}>
                      A senha será enviada por SMS do telefone cadastrado.
                    </Text>
                  </View>
                </Col>
              ) : null}
            </View>
          </View>

          <View flex justify='flex-end' mt={2}>
            <View width='50%' direction='row'>
              <View width='100%' mr={1}>
                <Button
                  type='reset'
                  label='Cancelar'
                  borderColor='#3097E2'
                  color='#3097E2'
                  background='#fff'
                  onClick={handleToggleModal}
                />
              </View>
              <View width='100%'>
                <Button
                  type='submit'
                  label={userId ? 'Editar pessoa' : 'Cadastrar pessoa'}
                  background='#3097E2'
                  loading={loading}
                />
              </View>
            </View>
          </View>
        </form>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={OfferUser}
          description='Tem certeza que deseja cancelar a edição da pessoa?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          handleConfirm={handleConfirmCancel}
        />
      )}
    </>
  )
}

export default withRouter(AddUser)
