import api, { newApi } from './api'

export interface UserProps {
  cpf: string
  name: string
  email: string
  phone_number: string
}

export const postUser = async (payload: UserProps) =>
  newApi.post('/admin/customers', payload)

export const patchDeactivateUser = async (pubId: string) =>
  api.patch(`/customers/admin/${pubId}/activate-deactivate`)

export const patchAddHomecareUser = async (pubId: string) =>
  newApi.patch(`/admin/homecares/add-user/${pubId}`)

export const getOrderRetrieveCustomer = async (params = {}) =>
  api.get('/orders/admin/retrieve/customer', { params: params })

export const getAllAddressCustomer = async (customerId: string) =>
  api.get(`/customers/admin/list-address/${customerId}`)
