import { useState } from 'react'
import { useAlert } from 'react-alert'
import { batch, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Auth from '@aws-amplify/auth'
import { addSetupDataAction } from 'src/redux/reducers/appReducer'
import {
  addStoreActiveAction,
  addStoreGroupAction,
  addUserNameAction,
  resetStoreAction,
} from 'src/redux/reducers/storeReducer'
import { clearAccessToken, setAccessToken } from 'src/services/api'
import { getProductSetup } from 'src/services/ProductService'
import { getSaleCenter } from 'src/services/StoreService'
import { formatDate } from 'src/util/helpers/date'

const useAuth = () => {
  const [validation, setValidation] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const alert = useAlert()
  const dispatch = useDispatch()

  const signInRoles = async (username: string, password: string) => {
    const signInResponse = await Auth.signIn({
      username,
      password,
    })

    let payloadSign
    if (signInResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const { challengeParam } = signInResponse
      const verifyUser = await Auth.completeNewPassword(
        signInResponse,
        password,
        {
          email: challengeParam.userAttributes.email,
          phone_number: challengeParam.userAttributes.phone_number,
        }
      )

      const {
        signInUserSession: { idToken },
      } = verifyUser
      payloadSign = {
        email: idToken.payload.email,
        phoneNumber: idToken.payload.phone_number,
        accessToken: idToken.jwtToken,
        userRole: idToken.payload['cognito:groups'],
      }
    } else {
      const {
        signInUserSession: { idToken },
      } = signInResponse
      payloadSign = {
        email: idToken.payload.email,
        phoneNumber: idToken.payload.phone_number,
        accessToken: idToken.jwtToken,
        userRole: idToken.payload['cognito:groups'],
      }
    }

    return {
      ...payloadSign,
      signInUserSession: signInResponse.signInUserSession,
      loggedInWith: 'Bearer',
    }
  }

  const confirmPIN = async (code: string) => {
    setLoading(true)
    try {
      if (String(code).length < 6) throw new Error()

      const username =
        localStorage.getItem('@farmazon-store/recover-phone') ?? ''
      const password = localStorage.getItem('@farmazon-store/temp-pass') ?? ''

      const confirmPINResponse = await Auth.confirmSignUp(username, code)
      if (confirmPINResponse !== 'SUCCESS') throw new Error(confirmPINResponse)

      const result = await signInRoles(username, password)
      setAccessToken(result.signInUserSession.idToken.jwtToken, true)

      localStorage.removeItem('@farmazon-store/recover-phone')
      localStorage.removeItem('@farmazon-store/temp-pass')

      history.push('/successsignup')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  }

  const handleGoToSignup = () => {
    history.push('/signup')
  }

  const doSignIn = async (
    username: string,
    password: string,
    keepLogin: boolean
  ) => {
    setLoading(true)
    const formatUsername = `+55${username.replace(/\D/g, '')}`
    try {
      const result = await signInRoles(formatUsername, password)

      if (result.userRole === undefined) {
        throw new Error('Usuário não faz parte do grupo.')
      }

      if (
        result.userRole instanceof Array &&
        !result.userRole.some((element) =>
          ['admin', 'store_sales_center'].includes(element)
        )
      ) {
        throw new Error('Usuário não pertence ao grupo.')
      }

      const currentUser = await Auth.currentSession()
      const user = currentUser.getIdToken().payload
      const groups = result?.signInUserSession.idToken.payload['cognito:groups']

      if (user) {
        batch(() => {
          dispatch(addUserNameAction(user.name, user.email, user.phone_number))
          dispatch(addStoreGroupAction(groups))
        })
      }

      setAccessToken(result.accessToken, keepLogin)

      if (
        result.userRole instanceof Array &&
        result.userRole.some((element) =>
          ['store_sales_center'].includes(element)
        )
      ) {
        try {
          const { data } = await getSaleCenter()

          dispatch(
            addStoreActiveAction({
              pub_id: data.pub_id,
              name: data.name,
            })
          )
        } catch (error) {
          // console.log(error)
        }
      }

      window.location.href = '/'
    } catch (error: any) {
      if (error.code === 'UserNotConfirmedException') {
        await Auth.resendSignUp(formatUsername)
        history.push('/signupconfirm')
        return
      }

      setValidation(true)
      setTimeout(() => setValidation(false), 5000)
    } finally {
      setLoading(false)
    }
  }

  const doSignUp = async (data: any) => {
    setLoading(true)
    try {
      const formatUsername = `+55${data.username.replace(/\D/g, '')}`
      await Auth.signUp({
        username: formatUsername,
        password: data.password,
        attributes: {
          name: data.name,
          email: data.email,
          birthdate: data?.birthdate ? formatDate(data.birthdate) : '',
        },
      })

      localStorage.setItem('@farmazon-store/recover-phone', formatUsername)
      localStorage.setItem('@farmazon-store/temp-pass', data.password)

      history.push('/signupconfirm')
    } catch (error: any) {
      if (error.code === '"UsernameExistsException"') {
        alert.error('Já existe uma conta com o número fornecido.')
      } else {
        alert.error(error?.message ?? 'Falha na chamada com API')
      }
    } finally {
      setLoading(false)
    }
  }

  const forgotPasswordRequest = async (username: string) => {
    setLoading(true)
    try {
      const formatUsername = `+55${username.replace(/\D/g, '')}`
      await Auth.forgotPassword(formatUsername)

      localStorage.setItem('@farmazon-store/recover-phone', formatUsername)

      alert.success(
        'Pedido de recuperar senha realizado. Você receberá um e-mail com pin.'
      )
      history.push('/recovery-pin')
    } catch (error) {
      setValidation(true)
      setTimeout(() => setValidation(false), 5000)
    } finally {
      setLoading(false)
    }
  }

  const forgotPasswordSubmit = async (code: string, password: string) => {
    setLoading(true)
    try {
      if (String(code).length < 6) throw new Error()

      const username =
        localStorage.getItem('@farmazon-store/recover-phone') ?? ''
      await Auth.forgotPasswordSubmit(username, code, password)

      alert.success('Senha alterada com sucesso.')
      history.push('/')
    } catch (error) {
      setValidation(true)
      setTimeout(() => setValidation(false), 5000)
    } finally {
      setLoading(false)
    }
  }

  const handleLogout = () => {
    clearAccessToken()
    dispatch(resetStoreAction())
    window.location.reload()
  }

  const getStoreSetup = async () => {
    try {
      const { data } = await getProductSetup()
      dispatch(addSetupDataAction(data))
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    }
  }

  const resendSignPIN = async () => {
    try {
      const username =
        localStorage.getItem('@farmazon-store/recover-phone') ?? ''
      await Auth.forgotPassword(username)
      alert.success('Código reenviado com sucesso')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    }
  }

  const resendPIN = async () => {
    try {
      const username =
        localStorage.getItem('@farmazon-store/recover-phone') ?? ''
      await Auth.resendSignUp(username)
      alert.success('Código reenviado com sucesso')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    }
  }

  return {
    validation,
    loading,
    doSignIn,
    doSignUp,
    confirmPIN,
    resendSignPIN,
    resendPIN,
    forgotPasswordRequest,
    forgotPasswordSubmit,
    handleLogout,
    getStoreSetup,
    handleGoToSignup,
  }
}

export default useAuth
