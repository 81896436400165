import { forwardRef } from 'react'

import { Container, RadioInput } from './styles'

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  background?: string
  borderWidthCheck?: string
  rotate?: number
}

function Radio(
  { onClick, background, rotate, borderWidthCheck, ...rest }: RadioProps,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  return (
    <Container rotate={rotate} borderWidthCheck={borderWidthCheck}>
      <RadioInput
        {...rest}
        background={background}
        type='radio'
        ref={forwardedRef}
      />
      <span className='checkmark' onClick={onClick} />
    </Container>
  )
}

export default forwardRef(Radio)
