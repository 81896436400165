import { useState } from 'react'
import { Container, Col, Row, Hidden } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import PinInput from 'react-pin-input'

import Image from 'src/assets/images/farmazon-user.png'
import Logo from 'src/assets/images/logo-farmazon-black.svg'
import { AlertLogin } from 'src/components/elements'
import { Button, InputText } from 'src/components/form'
import {
  ImageResize,
  View,
  Text,
  ContainerAnimation,
} from 'src/components/helper'
import useAuth from 'src/hooks/useAuth'

import { loginAnimation } from 'src/styles'

function RecoveryPin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { validation, loading, forgotPasswordSubmit, resendSignPIN } = useAuth()
  const [pin, setPin] = useState('')

  const onSubmit = (data: any) => {
    forgotPasswordSubmit(pin, data.password)
  }

  const handleChange = (value: string) => setPin(value)

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row
        justify='center'
        nogutter
        align='stretch'
        style={{ height: 'calc(100vh)' }}
      >
        <Col xs={0} md={6} lg={7} xl={8}>
          <Hidden xs sm>
            <ImageResize url={`url(${Image})`} />
          </Hidden>
        </Col>
        <Col xs={12} md={6} lg={5} xl={4}>
          <View style={{ padding: 40 }}>
            <ContainerAnimation {...loginAnimation}>
              <View textAlign='center' pt={4}>
                <img src={Logo} alt='Farmazon logo' />
              </View>

              <View mt={2}>
                <Text textAlign='center' fontWeight={500} size={25}>
                  SMS enviado
                </Text>
              </View>

              <form onSubmit={handleSubmit(onSubmit)}>
                <View flex mt={2} mb={3} justify='center'>
                  <PinInput
                    length={6}
                    onChange={handleChange}
                    onComplete={handleChange}
                    inputStyle={{
                      border: '1px solid #C4C4C4',
                      borderRadius: 5,
                      width: 40,
                    }}
                  />
                </View>

                <View textAlign='center' mt={1.4} mb={1}>
                  <InputText
                    {...register('password', {
                      required: 'A senha é obrigatória',
                      minLength: {
                        value: 8,
                        message: 'Mínimo de 8 caracteres, máximo 20',
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{8,}/,
                        message:
                          'A senha deve conter 8 caracteries, ao menos uma letra maiúscula, uma minúscula e número.',
                      },
                    })}
                    type='password'
                    name='password'
                    placeholder='Nova senha'
                    validationError={errors.password}
                    messageError={errors.password?.message}
                  />
                </View>

                <View mt={3}>
                  <Button
                    label='Enviar'
                    loading={loading}
                    disabled={pin === ''}
                  />
                </View>

                <View
                  mt={2}
                  flex
                  align='center'
                  justify='center'
                  direction='column'
                >
                  <Text size={14} fontWeight={400}>
                    Não recebi o código
                  </Text>
                  <Text
                    size={12}
                    fontWeight={300}
                    onClick={resendSignPIN}
                    cursor={1}
                  >
                    Enviar novamente
                  </Text>
                </View>
              </form>
            </ContainerAnimation>

            <View mt={1}>
              <Text size={13} color='#C4C4C4' textAlign='center'>
                Copyright © 2022 Farmazon. Todos os direitos reservados.
              </Text>
            </View>
          </View>

          {validation && <AlertLogin message='Número inválido.' />}
        </Col>
      </Row>
    </Container>
  )
}

export default RecoveryPin
