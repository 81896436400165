import { useState } from 'react'
import { useAlert } from 'react-alert'

import {
  getDeactiveStore,
  getOrderPIN,
  getSendPIN,
} from 'src/services/StoresService'
import { mutate } from 'swr'

const useStores = () => {
  const alert = useAlert()
  const [filter, setFilter] = useState({ active: '', state: 'SP', page: 1 })

  const handleInvitePin = (storeId: string) => {
    return async () => {
      try {
        await getOrderPIN(storeId)
        mutate('/admin/stores')
        alert.success('PIN solicitado com sucesso')
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Ação não concluída')
      }
    }
  }

  const handleResendPin = (storeId: string) => {
    return async () => {
      try {
        await getSendPIN(storeId)
        mutate('/admin/stores')
        alert.success('PIN enviado com sucesso')
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Ação não concluída')
      }
    }
  }

  const handleDeactiveStore = (storeId: string) => {
    return async () => {
      try {
        await getDeactiveStore(storeId)
        await mutate('/admin/stores')
        alert.success('Loja desativada com sucesso')
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Ação não concluída')
      }
    }
  }

  const setFilterParams = (data: any) => setFilter(data)

  return {
    filter,
    handleInvitePin,
    handleResendPin,
    handleDeactiveStore,
    setFilterParams,
  }
}

export default useStores
