import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
`

export const ButtonsBox = styled.div`
  width: fit-content;
  padding: 20px 0;
  margin-left: auto;
`

interface ButtonProps {
  bgColor: string
  color?: string
  disabled: boolean
}

export const Button = styled.button<ButtonProps>`
  width: 100px;
  padding: 10px;
  margin-left: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px #00000099;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color || 'initial'};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
