import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div`
  position: absolute;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  z-index: 3;
  top: 44px;
  right: -14px;
  width: 350px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
