import { useMemo } from 'react'
import { Col, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'

import { LoadingSearch } from 'src/components/elements'
import CardOrder from 'src/components/elements/CardOrder'
import CardRequestSaleCenter from 'src/components/elements/CardRequestSaleCenter'
import { Text, View } from 'src/components/helper'
import { getOrderSWR } from 'src/services/OrderService'
import {
  PROPOSAL_STATUS_SHORT,
  PROPOSAL_WITH_ACTIVE_STATE,
} from 'src/util/constants'
import { eventGA } from 'src/util/gtag'
import { formatCurrency } from 'src/util/helpers/string'
import useSWR from 'swr'

import OrderModal from './OrderModal'
import useOrder from './useOrder'

const Order = () => {
  const {
    loading,
    orderDetails,
    orderModalVisible,
    handleCloseModal,
    handleOrderModalDetails,
  } = useOrder()

  const { data } = useSWR('/sales-center/orders/in-progress', getOrderSWR, {
    refreshInterval: 2000,
  })

  const dataOrder = useMemo(() => {
    return data?.filter((item: any) =>
      PROPOSAL_WITH_ACTIVE_STATE.includes(item.state.id)
    )
  }, [data])

  const dataNewOrder = useMemo(() => {
    return data?.filter((item: any) => [1, 2].includes(item.state.id))
  }, [data])

  const handleEventOrder = () => {
    eventGA('detalhes_pedido')
  }

  const handleNewOrder = (saleId: string) => () => {
    eventGA('novo_pedido')
    handleOrderModalDetails(saleId)
  }

  return (
    <>
      <Row style={{ height: '100%' }}>
        <Col xs={12} lg={6} style={{ paddingBottom: 10 }}>
          <View
            mb={1}
            flex
            direction='row'
            justify='space-between'
            align='center'
          >
            <Text size={25} fontWeight={700}>
              Meus pedidos
            </Text>
          </View>

          <View
            style={{
              height: '100%',
              overflowY: 'auto',
              paddingRight: '2px',
            }}
          >
            {!dataOrder && (
              <View height='300px' justify='center' align='center'>
                <LoadingSearch />
              </View>
            )}

            {dataOrder ? (
              <>
                {dataOrder.length ? (
                  dataOrder.map((item: any, index: number) => (
                    <Link
                      key={index}
                      to={`/pedidos/detalhe/${item?.pub_id}`}
                      onClick={handleEventOrder}
                    >
                      <CardRequestSaleCenter
                        title={`#${
                          item?.identifier?.toUpperCase() ??
                          String(item?.pub_id).substr(-6)
                        }`}
                        deliveryType={item?.delivery_type}
                        customer={item?.customer?.name}
                        store={item.store_info}
                        status={
                          item?.state
                            ? PROPOSAL_STATUS_SHORT[item.state.id]
                            : ' - '
                        }
                        delivery={
                          item?.payment_type?.description === 'app'
                            ? 'Pagamento no app'
                            : `Entrega - ${
                                item?.payment_type?.mode === 'card'
                                  ? 'Maquininha'
                                  : 'Dinheiro'
                              }`
                        }
                        deliveryDescription={
                          item?.payment_type?.mode === 'cash'
                            ? `Troco - ${formatCurrency(
                                parseFloat(item?.payment_type?.change_for)
                              )}`
                            : null
                        }
                        createdAt={item?.created_at}
                      />
                    </Link>
                  ))
                ) : (
                  <View height='300px' justify='center' direction='column'>
                    <View pb={2}>
                      <Text textAlign='center' size={14}>
                        Nenhum pedido encontrado
                      </Text>
                    </View>
                  </View>
                )}
              </>
            ) : null}
          </View>
        </Col>

        <Col xs={12} lg={6}>
          <View mb={1}>
            <Text size={25} fontWeight={700}>
              Novos pedidos
            </Text>
          </View>

          <View
            style={{
              height: '100%',
              overflowY: 'auto',
              paddingRight: '2px',
            }}
          >
            {!dataNewOrder && (
              <View height='300px' justify='center' align='center'>
                <LoadingSearch />
              </View>
            )}

            {dataNewOrder ? (
              <>
                {dataNewOrder.length ? (
                  dataNewOrder.map((item: any, index: number) => {
                    if ([2].includes(item.state.id)) {
                      return (
                        <Link
                          key={index}
                          to={`/pedidos/detalhe/${item?.pub_id}`}
                          onClick={handleEventOrder}
                        >
                          <CardRequestSaleCenter
                            title={`#${
                              item?.identifier?.toUpperCase() ??
                              String(item?.pub_id).substr(-6)
                            }`}
                            deliveryType={item?.delivery_type}
                            customer={item?.customer?.name}
                            store={item.store_info}
                            status={
                              item?.state
                                ? PROPOSAL_STATUS_SHORT[item.state.id]
                                : ' - '
                            }
                            delivery={
                              item?.payment_type?.description === 'app'
                                ? 'Pagamento no app'
                                : `Entrega - ${
                                    item?.payment_type?.mode === 'card'
                                      ? 'Maquininha'
                                      : 'Dinheiro'
                                  }`
                            }
                            deliveryDescription={
                              item?.payment_type?.mode === 'cash'
                                ? `Troco - ${formatCurrency(
                                    parseFloat(item?.payment_type?.change_for)
                                  )}`
                                : null
                            }
                            createdAt={item?.created_at}
                          />
                        </Link>
                      )
                    }

                    return (
                      <CardOrder
                        key={index}
                        title={`#${
                          item?.identifier?.toUpperCase() ??
                          String(item?.pub_id).substr(-6)
                        }`}
                        deliveryType={item?.delivery_type}
                        onClick={handleNewOrder(item?.pub_id)}
                        cursor
                      />
                    )
                  })
                ) : (
                  <View height='300px' justify='center' direction='column'>
                    <View pb={2}>
                      <Text textAlign='center' size={14}>
                        Nenhum pedido encontrado
                      </Text>
                    </View>
                  </View>
                )}
              </>
            ) : null}
          </View>
        </Col>
      </Row>

      {orderModalVisible && (
        <OrderModal
          loading={loading}
          details={orderDetails}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  )
}

export default Order
