import currency from 'currency.js'
// const deliveryFee = 10.45
// const rate = 3
// const antiFraude = 0.39
// const totalPrice = totalPriceProduct([])

// const valueTax = getAllTax(totalPrice, deliveryFee, rate, antiFraude)
// const totalWithTax = getTotalWithTax(totalPrice, valueTax)
// const totalOrder = getTotalOrder(totalPrice, valueTax, deliveryFee)

export const getMaxOfValueOfSubstitutes = (subsitutes: any[]) => {
  const listMaxPrices = subsitutes?.map((substitute) => substitute.max_price)
  return listMaxPrices?.length > 0 ? Math.max(...listMaxPrices) : 0
}

export const getTotalProductWithValueCoupon = (
  totalProducts: number,
  couponValue: number
) => {
  return totalProducts - couponValue
}

export const getMultiply = (total: number, multiply: number) => {
  return currency(total).multiply(multiply).value
}

export const getTotalProductWithPercentCoupon = (
  totalProducts: number,
  couponValue: number
) => {
  const percent = totalProducts * (couponValue / 100)
  return totalProducts - percent
}

export const totalPriceProduct = (products: any[], coupon: any = null) => {
  const totalOfProducts = products?.reduce((acc: number, product) => {
    const moreExpensiveSubstitute = getMaxOfValueOfSubstitutes(
      product?.substitute_products
    )

    if (moreExpensiveSubstitute > product.max_price) {
      return acc + moreExpensiveSubstitute * product.quantity
    }

    if (product?.normal_price) {
      return acc + product.price * product.quantity
    }

    return acc + product.max_price * product.quantity
  }, 0)

  if (coupon) {
    switch (coupon.type) {
      case 'value':
        return getTotalProductWithValueCoupon(totalOfProducts, coupon.value)
      case 'percent':
        return getTotalProductWithPercentCoupon(totalOfProducts, coupon.value)
      default:
        return totalOfProducts
    }
  }
  return totalOfProducts
}

export const getAllTax = (
  totalProduct: number,
  deliveryFee: number,
  rate: number,
  antiFraude: number
) => {
  return (totalProduct + deliveryFee + antiFraude) * (rate / 100) + antiFraude
}

export const getTotalOrder = (
  totalProduct: number,
  tax: number,
  deliveryFee: number
) => {
  return totalProduct + tax + deliveryFee
}

export const getMoreExpensiveProductSubstitute = (substitutes: any[]) => {
  const result = substitutes.reduce((latest, currentSubstitutes) => {
    return currentSubstitutes?.max_price > latest?.max_price
      ? currentSubstitutes
      : latest
  }, substitutes[0])
  return result
}
