import { ChangeEvent, useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import Map, { Marker } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line

import 'mapbox-gl/dist/mapbox-gl.css'

import DeleteOutline from '@material-ui/icons/DeleteOutline'
import { fromUnixTime, getUnixTime, set } from 'date-fns'
import AddIcon from 'src/assets/icons/icone-adicionar.svg'
import IconCancelAbout from 'src/assets/icons/icone-cancel-about.svg'
import Edit from 'src/assets/icons/icone-edit.svg'
import { LoadingSearch, ModalConfirm } from 'src/components/elements'
import {
  Button,
  Switch,
  Input,
  Select,
  CheckBox,
  Range,
} from 'src/components/form'
import { Text, View, ContentShadow, ShadowBox } from 'src/components/helper'
import Pin from 'src/components/map/Pin'
import useAbout from 'src/hooks/useAbout'
import { DefaultRootState } from 'src/redux/store'
import { getAllSellers, getStoreRetrieve } from 'src/services/StoreService'
import { hours } from 'src/util/helpers/date'
import { formatPhone } from 'src/util/helpers/string'

import { Container, Box, Content } from './styles'

interface PhoneProps {
  contact_phones: Array<{
    phone: string
    whatsapp: boolean
    principal: boolean
  }>
  opening_hours: any
  phone: string
}

function StoreDetail() {
  const { storeId } = useParams<{ storeId: string }>()

  const { app } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )

  const [sellers, setSellers] = useState([])

  const {
    control,
    handleSubmit,
    register,
    clearErrors,
    setValue,
    formState: { errors },
    reset,
    getValues,
  } = useForm<PhoneProps>()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'contact_phones',
  })

  const value = useWatch({
    name: 'contact_phones',
    control,
  })

  const [notClose, setNotClose] = useState(false)
  const [loadingData, setLoading] = useState(true)
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    name,
    address,
    coords,
    delivery,
    farmazonDelivery,
    deliveryRange,
    hoursWeek,
    openModal,
    mode,
    loading,
    check,
    customer,
    populateAboutData,
    handleSelectCustomer,
    handleToggleDelivery,
    handleToggleFarmazonDelivery,
    handleToggleCancelModal,
    handleToggleEditMode,
    handleCancelEdit,
    handleChangeAll,
    handleWeekEnd,
    handleSelectHour,
    isSameHourAllWeek,
    populateDefaultHour,
    populateNoHour,
    handleSubmitStoreRetrive,
    setCheck,
    setDeliveryRange,
    deliveryFee,
    handleChangeDeliveryFee,
    deliveryTime,
    handleChangeDeliveryTime,
    storeManagerMode,
    handleToggleEditModeStoreManager,
    onSubmitStoreManagerHandler,
    administrationUpdateLoading,
  } = useAbout()

  useEffect(() => {
    const monday = hoursWeek.find((week) => week.short === 'seg')

    if (monday) {
      const openTime = getUnixTime(
        set(fromUnixTime(monday.open), {
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
      )

      const closeTime = getUnixTime(
        set(fromUnixTime(monday.close), {
          hours: 23,
          minutes: 59,
          seconds: 0,
        })
      )

      const isClosed = hoursWeek.find(
        (week) => openTime !== week.open || closeTime !== week.close
      )

      setNotClose(!isClosed)
    }
  }, [hoursWeek])

  const getData = async () =>
    getStoreRetrieve(storeId)
      .then(async (result) => {
        remove()
        if (result.data?.contact_phones) append(result.data.contact_phones)
        populateAboutData({
          name: result.data.name,
          address: `${result.data.address.street}, ${result.data.address.number} ${result.data.address.neighborhood} - ${result.data.address.city}, ${result.data.address.state} - CEP: ${result.data.address.cep}`,
          hours: result.data.opening_hours,
          phones: result.data.contact_phones,
          lat: result.data.address.lat,
          lng: result.data.address.lng,
          delivery: result.data.self_delivery,
          allow_farmazon_delivery: result.data.allow_farmazon_delivery,
          deliveryRange: result.data.self_delivery_range,
          deliveryFee: String(
            (result.data.self_delivery_fee ||
              app.setup?.admin.delivery_average.store_delivery.default_value) *
              100
          ),
          deliveryTime:
            result.data.self_delivery_time ||
            app.setup?.admin.delivery_average.store_delivery.timeOnlyNumber,
          customer: result.data.customer,
        })

        const hours = result.data.opening_hours

        if (hours) {
          setCheck(isSameHourAllWeek(hours))
        }

        if (hours.all_week) {
          setNotClose(true)
        }

        const data = await getAllSellers()
        setSellers(data || [])
      })
      .finally(() => setLoading(false))

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!notClose) populateDefaultHour()
    else populateNoHour()
  }, [notClose])

  const handleCancelEditReturnState = async () => {
    const phones = await handleCancelEdit()
    remove()
    const value = getValues()
    reset({ contact_phones: value.contact_phones })
    if (phones) {
      append(phones)
    }
    history.push('/stores')
  }

  const handleAddPhone = () => {
    append({ phone: '', whatsapp: false, principal: false })
  }

  const maskPhone = (index: number) => (event: any) => {
    const value = formatPhone(event.target.value)
    setValue(`contact_phones.${index}.phone`, value)
    clearErrors(`contact_phones.${index}.phone`)
  }

  const handleSwitch =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      update(index, { ...value[index], whatsapp: e.target.checked })

      if (e.target.value) {
        fields.forEach((field, fieldIndex) => {
          if (fieldIndex !== index)
            update(fieldIndex, { ...field, whatsapp: false })
        })
      }
    }

  const handleSwitchDefault =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      update(index, { ...value[index], principal: e.target.checked })

      if (e.target.value) {
        fields.forEach((field, fieldIndex) => {
          if (fieldIndex !== index)
            update(fieldIndex, { ...field, principal: false })
        })
      }
    }

  const handleChangeRange = (e: ChangeEvent<HTMLInputElement>) =>
    setDeliveryRange(parseInt(e.target.value))

  const onSubmit = handleSubmit((data) => {
    const value = getValues()

    reset({ contact_phones: value.contact_phones })
    dispatch(handleSubmitStoreRetrive(storeId, data, hoursWeek, notClose))
  })

  const handleNoClose = (e: ChangeEvent<HTMLInputElement>) =>
    setNotClose(e.target.checked)

  return (
    <>
      <Container>
        {loadingData && (
          <View height='calc(100% - 100px)' justify='center' align='center'>
            <LoadingSearch />
          </View>
        )}

        {!loadingData && (
          <>
            <View mb={3}>
              <form onSubmit={onSubmitStoreManagerHandler(storeId)}>
                <Row>
                  <Col xs={10}>
                    <View pb={2} flex align='center'>
                      <View>
                        <Text size={25} fontWeight={700}>
                          Administração
                        </Text>
                      </View>
                    </View>
                  </Col>
                  <Col xs={2}>
                    <View>
                      <Button
                        label='Editar admin.'
                        icone={Edit}
                        height='40px'
                        background='#3097E2'
                        disabled={storeManagerMode}
                        type='button'
                        onClick={handleToggleEditModeStoreManager}
                      />
                    </View>
                  </Col>
                </Row>

                <View mt={2}>
                  <ContentShadow>
                    <Row>
                      <Col xs={3}>
                        <View>
                          <Text fontWeight={500} size={12}>
                            Usuário responsável pela loja
                          </Text>
                          <View mt={0.4}>
                            <Select
                              value={customer}
                              onChange={handleSelectCustomer}
                              disabled={!storeManagerMode}
                            >
                              <option value=''>Selecione</option>
                              {sellers?.map((item: any, index: number) => (
                                <option value={item.customer} key={index}>
                                  {item.name} - {item.username}
                                </option>
                              ))}
                            </Select>
                          </View>
                        </View>
                      </Col>
                    </Row>
                  </ContentShadow>
                </View>

                <View flex justify='flex-end' mt={2}>
                  <View width='50%' direction='row'>
                    <View width='100%' mr={1}>
                      <Button
                        type='reset'
                        label='Cancelar'
                        borderColor='#3097E2'
                        color='#3097E2'
                        background='#fff'
                        onClick={handleToggleCancelModal}
                      />
                    </View>
                    <View width='100%'>
                      <Button
                        label='Salvar'
                        background='#3097E2'
                        disabled={!storeManagerMode}
                        loading={administrationUpdateLoading}
                      />
                    </View>
                  </View>
                </View>
              </form>
            </View>

            <form onSubmit={onSubmit}>
              <Row>
                <Col xs={10}>
                  <View pb={2} flex align='center'>
                    <View>
                      <Text size={25} fontWeight={700}>
                        Sobre a loja
                      </Text>
                    </View>
                  </View>
                </Col>
                <Col xs={2}>
                  <View>
                    <Button
                      label='Editar loja'
                      icone={Edit}
                      height='40px'
                      background='#3097E2'
                      disabled={mode}
                      type='button'
                      onClick={handleToggleEditMode}
                    />
                  </View>
                </Col>
              </Row>

              <Content>
                <ContentShadow>
                  <Row>
                    <Col xs={3}>
                      <View>
                        <Text fontWeight={500} size={12}>
                          Nome da loja
                        </Text>
                        <View mt={0.4}>
                          <Text size={14}>{name}</Text>
                        </View>
                      </View>
                    </Col>
                    <Col xs={9}>
                      <View>
                        <Text fontWeight={500} size={12}>
                          Endereço
                        </Text>
                        <View mt={0.4}>
                          <Text size={14}>{address}</Text>
                        </View>
                      </View>
                    </Col>
                  </Row>
                </ContentShadow>

                <View mt={2}>
                  <ContentShadow>
                    <Row align='center'>
                      <Col xs={9}>
                        <View>
                          <Text fontWeight={500} size={12}>
                            Telefones de contato
                          </Text>
                        </View>
                      </Col>
                      <Col xs={3}>
                        <View>
                          {mode && (
                            <Button
                              type='reset'
                              label='Telefone'
                              background='#3097E2'
                              height='40px'
                              icone={AddIcon}
                              onClick={handleAddPhone}
                            />
                          )}
                        </View>
                      </Col>
                    </Row>

                    {fields.map((item, index) => (
                      <Box key={item.id}>
                        <Row align='center'>
                          <Col xs={4}>
                            <View>
                              {mode ? (
                                <Input
                                  {...register(
                                    `contact_phones.${index}.phone`,
                                    {
                                      required: 'O Número é obrigatório',
                                      pattern: {
                                        value: /\(\d{2}\) \d{4,5}-\d{4}/,
                                        message:
                                          'Digite seu número de telefone com DDD.',
                                      },
                                    }
                                  )}
                                  defaultValue={item.phone}
                                  onChange={maskPhone(index)}
                                  validationError={
                                    errors.contact_phones?.[index]?.phone
                                  }
                                  messageError={
                                    errors.contact_phones?.[index]?.phone
                                      ?.message
                                  }
                                />
                              ) : (
                                <Text fontWeight={300} size={14}>
                                  {item.phone}
                                </Text>
                              )}
                            </View>
                          </Col>
                          <Col />
                          <Col xs={4}>
                            <View
                              direction='row'
                              align='center'
                              justify='flex-end'
                            >
                              <View flex align='center' mr={1}>
                                <Switch
                                  disabled={!mode}
                                  checked={item.principal}
                                  onChange={handleSwitchDefault(index)}
                                />
                                <Text size={9} fontWeight={300}>
                                  Principal
                                </Text>
                              </View>

                              <Switch
                                disabled={!mode}
                                checked={item.whatsapp}
                                onChange={handleSwitch(index)}
                              />
                              <Text size={9} fontWeight={300}>
                                Whatsapp
                              </Text>
                              {mode && (
                                <DeleteOutline
                                  fontSize='medium'
                                  onClick={() => remove(index)}
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: 6,
                                    color: '#FF0000',
                                  }}
                                />
                              )}
                            </View>
                          </Col>
                        </Row>
                      </Box>
                    ))}
                  </ContentShadow>
                </View>

                <View mt={2}>
                  <ContentShadow>
                    <Box>
                      <View
                        flex
                        direction='row'
                        justify='space-between'
                        align='center'
                      >
                        <View>
                          <Text fontWeight={500} size={12}>
                            Horários de atendimento
                          </Text>
                        </View>
                        <ShadowBox>
                          <View
                            flex
                            justify='space-between'
                            style={{ width: '280px' }}
                          >
                            <View>
                              <Text size={13} fontWeight={500}>
                                atendimento 24h
                              </Text>
                              <Text size={9}>De segunda a domingo</Text>
                            </View>
                            <Switch
                              disabled={!mode}
                              defaultChecked={notClose}
                              onChange={handleNoClose}
                            />
                          </View>
                        </ShadowBox>
                      </View>
                    </Box>

                    {hoursWeek.map((date, index) => (
                      <View key={index} mb={0.8}>
                        <Box>
                          <Row nogutter align='center' justify='center'>
                            <Col xs={8}>
                              <Text size={12}>{date.label}</Text>
                            </Col>
                            <Col xs={2}>
                              <View flex justify='center'>
                                {mode ? (
                                  <>
                                    {date?.openStore ? (
                                      <Text fontWeight={500}>-</Text>
                                    ) : (
                                      <Select
                                        {...register(
                                          `opening_hours.${date.short}.open`
                                        )}
                                        value={date.open}
                                        onChange={handleSelectHour(
                                          'open',
                                          index,
                                          hoursWeek
                                        )}
                                        disabled={check}
                                      >
                                        {hours.map((hour, index) => (
                                          <option
                                            key={index}
                                            value={hour.value}
                                          >
                                            {hour.label}
                                          </option>
                                        ))}
                                      </Select>
                                    )}
                                  </>
                                ) : (
                                  <Text fontWeight={500}>
                                    {date?.openStore
                                      ? '-'
                                      : `${new Date(
                                          date.open * 1000
                                        ).getHours()} h`}
                                  </Text>
                                )}

                                <View mr={1} ml={1} flex align='center'>
                                  <Text size={11} textAlign='center'>
                                    Até
                                  </Text>
                                </View>

                                {mode ? (
                                  <>
                                    {date?.openStore ? (
                                      <Text fontWeight={500}>-</Text>
                                    ) : (
                                      <Select
                                        {...register(
                                          `opening_hours.${date.short}.close`
                                        )}
                                        onChange={handleSelectHour(
                                          'close',
                                          index,
                                          hoursWeek
                                        )}
                                        disabled={check}
                                        value={date.close}
                                      >
                                        {hours.map((hour, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={hour.value}
                                            >
                                              {hour.label}
                                            </option>
                                          )
                                        })}
                                      </Select>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Text fontWeight={500}>
                                      {date?.openStore
                                        ? '-'
                                        : `${new Date(
                                            date.close * 1000
                                          ).getHours()} h`}
                                    </Text>
                                  </>
                                )}
                              </View>
                            </Col>
                            <Col>
                              {date.short === 'seg' && (
                                <View ml={0.8} direction='row'>
                                  <CheckBox
                                    name='all'
                                    background='#52C372'
                                    onClick={handleChangeAll(hoursWeek)}
                                    checked={check}
                                    disabled={!mode}
                                    readOnly
                                  />
                                  <Text size={9}>
                                    Igual para todos os dias da semana
                                  </Text>
                                </View>
                              )}

                              {['ter', 'qua', 'qui', 'sex'].includes(
                                date.short
                              ) &&
                                check && (
                                  <View ml={0.8} direction='row'>
                                    <CheckBox
                                      name='all'
                                      background='#52C372'
                                      checked
                                      disabled={!mode}
                                      readOnly
                                    />
                                  </View>
                                )}

                              {['sab', 'dom'].includes(date.short) && (
                                <View ml={0.8} direction='row' align='center'>
                                  <CheckBox
                                    name='weekend'
                                    background='#EB5757'
                                    borderWidthCheck='0px 2px 0px 0px'
                                    rotate={90}
                                    onClick={handleWeekEnd(
                                      date.short,
                                      hoursWeek
                                    )}
                                    checked={date.openStore}
                                    disabled={!mode}
                                    readOnly
                                  />
                                  <Text size={9}>Loja fechada</Text>
                                </View>
                              )}
                            </Col>
                          </Row>
                        </Box>
                      </View>
                    ))}
                  </ContentShadow>
                </View>

                <View mt={2}>
                  <ContentShadow>
                    <Box>
                      <View
                        flex
                        direction='row'
                        justify='space-between'
                        align='center'
                      >
                        <View>
                          <Text fontWeight={500} size={12}>
                            Área de entrega
                          </Text>
                        </View>

                        <ShadowBox
                          style={{ marginLeft: 'auto', marginRight: '1rem' }}
                        >
                          <View
                            flex
                            justify='space-between'
                            style={{ width: '280px' }}
                          >
                            <View>
                              <Text size={13} fontWeight={500}>
                                Serviço de entrega
                              </Text>
                              <Text size={9}>
                                Também tenho serviço de entrega própria
                              </Text>
                            </View>
                            <Switch
                              disabled={!mode}
                              defaultChecked={delivery}
                              checked={delivery}
                              onChange={handleToggleDelivery}
                            />
                          </View>
                        </ShadowBox>

                        <ShadowBox>
                          <View
                            flex
                            justify='space-between'
                            style={{ width: '280px' }}
                          >
                            <View>
                              <Text size={13} fontWeight={500}>
                                Entrega farmazon
                              </Text>
                              <Text size={9}>
                                Também aceito entregas feitas pela farmazon
                              </Text>
                            </View>
                            <Switch
                              disabled={!mode}
                              defaultChecked={farmazonDelivery}
                              checked={farmazonDelivery}
                              onChange={handleToggleFarmazonDelivery}
                            />
                          </View>
                        </ShadowBox>
                      </View>
                    </Box>

                    {delivery && coords?.lat && coords?.lng && (
                      <View mt={2}>
                        <Row align='center'>
                          <Col xs={6}>
                            <Map
                              initialViewState={{
                                latitude: +coords.lat,
                                longitude: +coords.lng,
                                zoom: 14,
                              }}
                              mapStyle='mapbox://styles/mapbox/streets-v12'
                              mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                              style={{ height: 350, width: 580 }}
                            >
                              <Marker
                                key='marker'
                                longitude={+coords.lng}
                                latitude={+coords.lat}
                                anchor='bottom'
                              >
                                <Pin active />
                              </Marker>
                            </Map>
                          </Col>
                          <Col xs={6}>
                            <View width='300px' style={{ margin: 'auto' }}>
                              <View mb={0.5}>
                                <Text size={12}>Preço da entrega</Text>
                                <Input
                                  disabled={!mode}
                                  value={deliveryFee}
                                  onChange={handleChangeDeliveryFee}
                                />
                              </View>
                              <View mb={1}>
                                <Text size={12}>
                                  Tempo médio de entrega <b>em minutos</b>
                                </Text>
                                <Input
                                  disabled={!mode}
                                  type='number'
                                  value={deliveryTime}
                                  onChange={handleChangeDeliveryTime}
                                />
                              </View>

                              <Text
                                fontWeight={300}
                                size={16}
                                textAlign='center'
                              >
                                Defina aqui o raio de entrega da sua loja:
                              </Text>
                              <View mt={3}>
                                <Range
                                  min={1}
                                  max={6}
                                  disabled={!mode}
                                  onInput={handleChangeRange}
                                  value={deliveryRange}
                                />
                              </View>
                              <View mt={0.6} mb={0.6}>
                                <Text
                                  color='#2D9CDB'
                                  fontWeight='bold'
                                  textAlign='center'
                                >
                                  {deliveryRange} km
                                </Text>
                              </View>
                            </View>
                          </Col>
                        </Row>
                      </View>
                    )}
                  </ContentShadow>
                </View>
              </Content>

              <View flex justify='flex-end' mt={2}>
                <View width='50%' direction='row'>
                  <View width='100%' mr={1} mb={2}>
                    <Button
                      type='reset'
                      label='Cancelar'
                      borderColor='#3097E2'
                      color='#3097E2'
                      background='#fff'
                      onClick={handleToggleCancelModal}
                    />
                  </View>
                  <View width='100%'>
                    <Button
                      label='Salvar'
                      background='#3097E2'
                      disabled={!mode}
                      loading={loading}
                    />
                  </View>
                </View>
              </View>
            </form>
          </>
        )}
      </Container>
      {openModal && (
        <ModalConfirm
          icon={IconCancelAbout}
          loading={loading}
          description='Tem certeza que deseja cancelar a edição da sua loja?'
          labelCancel='Não'
          handleCancel={handleToggleCancelModal}
          labelConfirm='Sim'
          handleConfirm={handleCancelEditReturnState}
        />
      )}
    </>
  )
}

export default StoreDetail
