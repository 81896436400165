import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router'

import {
  BankAccountProps,
  deleteBankAccountById,
  postBankAccount,
} from 'src/services/CardsService'
import { eventGA } from 'src/util/gtag'
import { mutate } from 'swr'

const useBank = (storeId?: string) => {
  const history = useHistory()
  const alert = useAlert()
  const [openModal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bankSelected, setBankSelected] = useState('')

  const handleNavegateAdd = () => {
    eventGA('nova_conta')
    history.push(`/contas_bancarias/${storeId}/addBank`)
  }

  const handleConfirmCancel = () => {
    eventGA('cancelar_conta')
    history.push(`/contas_bancarias/${storeId}`)
  }

  const handleToggleModal = () => setModal((state) => !state)

  const handleCreateBankAccount = async (data: BankAccountProps) => {
    try {
      if (!storeId) {
        throw new Error('ID da loja é necessário para essa ação.')
      }

      setLoading(true)

      const result = await postBankAccount(storeId, data)
      eventGA('cadastrar_conta')
      if (result.request.status === 400) {
        throw new Error('Banco não encotrado.')
      }

      mutate(`/stores/${storeId}/banking-accounts`)
      alert.success('Conta cadastrada com sucesso!')
      setTimeout(() => {
        history.push(`/contas_bancarias/${storeId}`)
      }, 500)
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteBankAccount = async () => {
    try {
      if (!storeId) {
        throw new Error('ID da loja é necessário para essa ação.')
      }
      setLoading(true)
      await deleteBankAccountById(storeId, bankSelected)
      setBankSelected('')
      mutate(`/stores/${storeId}/banking-accounts`)
      alert.success('Conta excluída com sucesso')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setModal(false)
      setLoading(false)
    }
  }

  const handleDeleteSingleBank = (id: string) => () => {
    setBankSelected(id)
    setModal(!openModal)
  }

  const handleEditBank = (data: any) => () =>
    history.push({
      pathname: `/contas_bancarias/${storeId}/addUser/${data.pub_id}`,
      state: data,
    })

  return {
    openModal,
    loading,
    handleNavegateAdd,
    handleToggleModal,
    handleConfirmCancel,
    handleCreateBankAccount,
    handleDeleteBankAccount,
    handleDeleteSingleBank,
    handleEditBank,
  }
}

export default useBank
