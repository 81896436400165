import { useEffect, useState } from 'react'

import { format, fromUnixTime } from 'date-fns'
import { LoadingSearch, Modal } from 'src/components/elements'
import { Text, View } from 'src/components/helper'
import { getLogs } from 'src/services/log'

interface LogModalProps {
  orderId: string
  onClose: () => void
}

interface Log {
  action: string
  change: string
  update: number
  user: {
    pub_id: string
    name: string
  }
}

const LogModal = ({ orderId, onClose }: LogModalProps) => {
  const [loading, setLoading] = useState(false)
  const [logs, setLogs] = useState<Log[]>([])

  useEffect(() => {
    const handleLogs = async () => {
      try {
        setLoading(true)
        const { data } = await getLogs(orderId)

        if (data.logs) {
          setLogs(data.logs)
        }
      } finally {
        setLoading(false)
      }
    }

    handleLogs()
  }, [])

  return (
    <Modal onClose={onClose} width={40}>
      <View padding={1}>
        <Text fontWeight={900} size={18}>
          Logs
        </Text>
      </View>

      <View
        style={{ width: '100%', height: '1px', backgroundColor: '#000000' }}
      />

      <View
        mt={1}
        pl={1}
        pr={1}
        style={{ maxHeight: '30rem', overflow: 'auto' }}
      >
        {loading ? (
          <View height='100%' justify='center' align='center'>
            <LoadingSearch />
          </View>
        ) : null}

        {!loading && !logs.length ? (
          <Text>Não há logs para serem exibidos para este pedido</Text>
        ) : null}

        {!loading && logs.length ? (
          <>
            {logs.map((log, index) => {
              const id = `log-${index}`
              return (
                <View padding={1} key={id} mb={1} borderBottom>
                  <View direction='row' align='center'>
                    <Text style={{ width: '5rem' }} fontWeight={500}>
                      Data:
                    </Text>

                    <Text ml={1}>
                      {format(fromUnixTime(log.update), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  </View>

                  <View direction='row' align='center'>
                    <Text style={{ width: '5rem' }} fontWeight={500}>
                      Alteração:
                    </Text>

                    <Text ml={1}>{log.change}</Text>
                  </View>

                  <View direction='row' align='center'>
                    <Text style={{ width: '5rem' }} fontWeight={500}>
                      Usuário:
                    </Text>

                    <Text ml={1}>{log.user.name}</Text>
                  </View>
                </View>
              )
            })}
          </>
        ) : null}
      </View>
    </Modal>
  )
}

export default LogModal
