import styled from 'styled-components'

interface HeaderProps {
  width?: string
  textAlign?: string
}

interface DataRowProps {
  textAlign?: string
}

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const Header = styled.th<HeaderProps>`
  background-color: #474747;
  color: #fff;
  padding: 16px;
  font-size: 11px;
  text-align: ${({ textAlign = 'center' }) => textAlign};
  width: ${({ width = 'auto' }) => width};
`

export const DataRow = styled.td<DataRowProps>`
  padding: 12px;
  text-align: ${({ textAlign = 'center' }) => textAlign};
`

export const Row = styled.tr`
  border-bottom: 1px solid #ccc;
`

export default {
  Table,
  Header,
  DataRow,
  Row,
}
