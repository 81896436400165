import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { appReducer, AppReducerProps } from './reducers/appReducer'
import { offerReducer, OfferReducerProps } from './reducers/offerReducer'
import {
  onboardingReducer,
  OnboardingReducerProps,
} from './reducers/onboardingReducer'
import { saleReducer, SaleReducerProps } from './reducers/saleReducer'
import {
  storeOnboardingReducer,
  StoreOnboardingReducerProps,
} from './reducers/storeOnboardingReducer'
import { storeReducer, StoreReducerProps } from './reducers/storeReducer'
import { userReducer, UserReducerProps } from './reducers/userReducer'

declare const window: any

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['user', 'offer', 'sale', 'storeOnboarding'],
}

export interface DefaultRootState {
  onboarding: OnboardingReducerProps
  storeOnboarding: StoreOnboardingReducerProps
  app: AppReducerProps
  store: StoreReducerProps
  offer: OfferReducerProps
  user: UserReducerProps
  sale: SaleReducerProps
}

const rootReducer = combineReducers({
  onboarding: onboardingReducer,
  storeOnboarding: storeOnboardingReducer,
  app: appReducer,
  store: storeReducer,
  offer: offerReducer,
  user: userReducer,
  sale: saleReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(thunk))
)

export const persistor = persistStore(store)
