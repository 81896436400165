import styled, { css } from 'styled-components'

const disabledStyles = css`
  pointer-events: none;
  opacity: 0.5;
`

export const ProductsBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

export const TitleBox = styled.div`
  width: 120px;
  margin: 0 20px;
  text-align: center;
`

export const Button = styled.button`
  padding: 10px;

  font-size: 14px;
  cursor: pointer;

  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px #00000066;
  margin-left: auto;
`

interface LabelProps {
  checked: boolean
}

export const FalseLabel = styled.label`
  width: 20px;
  height: 20px;

  pointer-events: none;
  opacity: 1;
`

interface OuterBoxProps {
  index: number
}

export const OuterBox = styled.div<OuterBoxProps>`
  margin-top: ${({ index }) => (index === 0 ? 0 : '20px')};
  position: relative;
  display: flex;
  align-items: center;
  height: fit-content;
`

export const Label = styled.label<LabelProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  border: 2px solid #666;
  border-radius: 15%;
  background-color: ${({ checked }) => (checked ? '#666' : '#fff')};

  cursor: pointer;

  span {
    width: 20px;
    height: 20px;
    color: #fff;
  }

  input {
    display: none;
  }
`

interface ContainerProps {
  isDragging: boolean
  disabled: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: move;
  width: 100%;
  opacity: ${({ isDragging }) => (isDragging ? 0.3 : 1)};
  ${(props) => props.disabled && disabledStyles}
`
