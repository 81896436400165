import { useSelector } from 'react-redux'

import { DefaultRootState } from 'src/redux/store'

import { Container, Content } from './styles'

import StoredeliveryService from './StoredeliveryService'
import StoreHour from './StoreHour'
import StoreInfo from './StoreInfo'
import StoreLocation from './StoreLocation'
import StorePhone from './StorePhone'

function CreateStore() {
  const { storeOnboarding } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )

  const renderSwitch = (screen: string) => {
    switch (screen) {
      case 'StoredeliveryService':
        return (
          <Content height={690}>
            <StoredeliveryService data={storeOnboarding} />
          </Content>
        )
      case 'StoreHour':
        return (
          <Content height={700}>
            <StoreHour onboarding={storeOnboarding} />
          </Content>
        )
      case 'StorePhone':
        return (
          <Content>
            <StorePhone data={storeOnboarding} />
          </Content>
        )
      case 'StoreLocation':
        return (
          <Content>
            <StoreLocation data={storeOnboarding} />
          </Content>
        )
      case 'StoreInfo':
        return (
          <Content>
            <StoreInfo data={storeOnboarding} />
          </Content>
        )
    }
  }

  return <Container>{renderSwitch(storeOnboarding.screenStep)}</Container>
}

export default CreateStore
