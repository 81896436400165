import { useState } from 'react'
import { useAlert } from 'react-alert'

import {
  deleteCupomById,
  postCupom,
  putCupomById,
} from 'src/services/CupomService'
import { mutate } from 'swr'

const useCoupon = () => {
  const alert = useAlert()
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataCoupoun, setDataCoupoun] = useState(null)

  const handleToggleModal = () => {
    setDataCoupoun(null)
    setOpenModal((state) => !state)
  }

  const handleRemoveCoupon = (couponId: string) => {
    return async () => {
      try {
        await deleteCupomById(couponId)
        mutate('/orders/admin/list/discount-coupon')
        alert.success('Cupom removido com sucesso')
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Ação não concluída')
      }
    }
  }

  const handleSubmitCoupon = async (data: any) => {
    try {
      setLoading(true)
      const { pub_id, ...coupon } = data
      if (pub_id) {
        await putCupomById(pub_id, coupon)
        alert.success('Cupom editado com sucesso')
      } else {
        await postCupom(coupon)
        alert.success('Cupom cadastrado com sucesso')
      }
      mutate('/orders/admin/list/discount-coupon')
      setOpenModal(false)
      setDataCoupoun(null)
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Ação não concluída')
    } finally {
      setLoading(false)
    }
  }

  const handleEditCoupon = (coupon: any) => {
    return () => {
      setOpenModal(true)
      setDataCoupoun(coupon)
    }
  }

  return {
    loading,
    openModal,
    dataCoupoun,
    handleToggleModal,
    handleRemoveCoupon,
    handleEditCoupon,
    handleSubmitCoupon,
  }
}

export default useCoupon
