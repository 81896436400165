/* eslint-disable @typescript-eslint/no-inferrable-types */
import VMasker from 'vanilla-masker'

export function toFixed(num: number, fixed = 2) {
  fixed = fixed || 0
  fixed = 10 ** fixed
  return +((num * fixed) / fixed).toFixed(2)
}

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
    toFixed(value)
  )

export const formatPercent = (value: number) => VMasker.toPattern(value, '99')

export function formatPhone(text: string = '') {
  const telMask = ['(99) 9999-9999', '(99) 99999-9999']
  const index = text.length > 14 ? 1 : 0
  return VMasker.toPattern(text, telMask[index])
}

export function formatLicensePlace(text: string = '') {
  return VMasker.toPattern(text, 'AAA-9S99').toUpperCase()
}

export function unFormatPhone(text: string = '') {
  const telReplace = text.replace('+55', '')
  return formatPhone(`(${telReplace.substr(0, 2)}) ${telReplace.substr(2)} `)
}

export function formatCPF(text: string = '') {
  return text ? VMasker.toPattern(text, '999.999.999-99') : ''
}

export function formatCNPJ(text: string = '') {
  return text ? VMasker.toPattern(text, '99.999.999/9999-99') : ''
}

export function formatCep(text: string = '') {
  return text ? VMasker.toPattern(text, '99999-999') : ''
}

interface MoneyOptions {
  // Decimal precision -> "90"
  precision?: number | undefined

  // Decimal separator -> ",90"
  separator?: string | undefined

  // Number delimiter -> "12.345.678"
  delimiter?: string | undefined

  // Money unit -> "R$ 12.345.678,90"
  unit?: string | undefined

  // Money unit -> "12.345.678,90 R$"
  suffixUnit?: string | undefined

  // Force type only number instead decimal,
  // masking decimals with ",00"
  // Zero cents -> "R$ 1.234.567.890,00"
  zeroCents?: boolean | undefined
}

export const formatMoney = (text: string, options?: MoneyOptions) =>
  VMasker.toMoney(text, options)

export const unformatMoney = (text: string = '') =>
  parseFloat(text.replace('.', '').replace(',', '.'))

export const unMaskOnlyNumber = (text: string) => {
  return text.match(/\d/g)?.join('') ?? ''
}

export const formatDecimal = (num: number) =>
  (Math.round(num * 100) / 100).toFixed(2)

export const formatFilterDate = (date: number) => {
  switch (date) {
    case 1:
      return 'Hoje'
    case 2:
      return 'Ontem'
    default:
      return `${date} dias`
  }
}

export function truncate(input: string = '', limit = 30) {
  if (input.length > limit) return `${input.substring(0, limit)} ...`
  return input
}
