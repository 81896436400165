import { useEffect, useState } from 'react'
import { Col } from 'react-grid-system'

import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { format } from 'date-fns'
import { DataRow, Header, Table, Text, View } from 'src/components/helper'
import { listClientDevices } from 'src/services/Notification'

import { Container, Button } from './styles'

const ListDevices = () => {
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [devices, setDevices] = useState([])

  useEffect(() => {
    const loadDevices = async () => {
      const { data } = await listClientDevices({ page })

      setDevices(data.data)
      setLastPage(data.pagination.lastPage)
    }

    loadDevices()
  }, [page])

  function handlePreviousPage() {
    setPage((current) => {
      if (current > lastPage) return current - 1

      return current
    })
  }

  function handleNextPage() {
    setPage((current) => {
      if (current < lastPage) return current + 1

      return current
    })
  }

  return (
    <Container>
      <Table.Table id='listSale'>
        <thead>
          <tr>
            <Header textAlign='left'>Nome</Header>
            <Header textAlign='left'>Código do dispositivo</Header>
            <Header textAlign='left'>Data expiração</Header>
          </tr>
        </thead>
        <tbody>
          {devices.length ? (
            <>
              {devices.map((item: any, idx: number) => (
                <Table.Row key={idx}>
                  <DataRow>
                    <Text size={15} fontWeight={500}>
                      {item.cognito_name}
                    </Text>
                  </DataRow>
                  <DataRow>
                    <Text size={15}>{item.device_id}</Text>
                  </DataRow>
                  <DataRow>
                    <Text size={15}>
                      {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  </DataRow>
                </Table.Row>
              ))}
            </>
          ) : (
            <Table.Row>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
            </Table.Row>
          )}
        </tbody>
      </Table.Table>

      <Col md={8} lg={4} xl={2} style={{ marginTop: '2rem' }}>
        <View flex direction='row' height='60px'>
          <Button disabled={page === 1} onClick={handlePreviousPage}>
            <ChevronLeft style={{ color: '#ffffff' }} />
          </Button>
          <View
            style={{ width: '10rem', height: '100%' }}
            flex
            justify='center'
            align='center'
          >
            <Text>{page.toString()}</Text>
          </View>
          <Button disabled={page === lastPage} onClick={handleNextPage}>
            <ChevronRight style={{ color: '#ffffff' }} />
          </Button>
        </View>
      </Col>
    </Container>
  )
}

export default ListDevices
