import axios from 'axios'

export const getCepAddressService = async (cep: string) =>
  axios.get<any>(`https://viacep.com.br/ws/${cep.replace('-', '')}/json`)

export const getGoogleAddress = async (params: string) =>
  axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${params}.json?limit=1&fuzzyMatch=true&language=pt-BR&types=address&access_token=${process.env.REACT_APP_MAPBOX}`
  )

export const getLatLngAddress = async (lat: number, lng: number) =>
  axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?language=pt-BR&types=address&access_token=${process.env.REACT_APP_MAPBOX}`
  )
