/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import debounce from 'lodash.debounce'
import Location from 'src/assets/icons/icone-map.svg'
import Close from 'src/assets/icons/icone-sair.svg'
import { Dots, LoadingSearch } from 'src/components/elements'
import { Button, InputText } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useStoreOnboarding from 'src/hooks/useStoreOnboarding'
import {
  addOnboardingLocationAction,
  setScreenStepAction,
  StoreOnboardingReducerProps,
} from 'src/redux/reducers/storeOnboardingReducer'
import { getLatLngAddress } from 'src/services/GeoService'
import { formatCep } from 'src/util/helpers/string'

import { Leave } from './styles'

function StoreLocation({ data }: { data: StoreOnboardingReducerProps }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm()
  const dispatch = useDispatch()
  const { loading, getCepAddress, setLoading, handleLeave } =
    useStoreOnboarding()
  const [form, setForm] = useState(false)

  const debounceFn = useCallback(debounce(handleRequestCep, 1000), [])

  const onSubmit = (data: any) => {
    dispatch(addOnboardingLocationAction(data))
    dispatch(setScreenStepAction('StorePhone'))
  }

  const handleSearchCep = () => {
    setForm(true)
    setLoading(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        getLatLngAddress(position.coords.latitude, position.coords.longitude)
          .then((result) => {
            const address = result.data.features[0]

            const cep =
              address.context.find((item: any) => item.id.match(/postcode/))
                ?.text || ''
            const street = address.text
            const neighborhood =
              address.context.find((item: any) => item.id.match(/neighborhood/))
                ?.text || ''

            const state =
              address.context.find((item: any) => item.id.match(/region/))
                ?.text || ''
            const city =
              address.context.find((item: any) => item.id.match(/place/))
                ?.text || ''

            setValue('street', street)
            setValue('city', city)
            setValue('state', state)
            setValue('cep', cep)
            setValue('more', '')
            setValue('neighborhood', neighborhood)
          })
          .finally(() => {
            setLoading(false)
          })
      })
    }
  }

  async function handleRequestCep(value: string) {
    const values = await getCepAddress(value)
    if (values) {
      setValue('street', values.logradouro)
      setValue('more', values.complemento ?? '')
      setValue('neighborhood', values.bairro ?? '')
      setValue('city', values.localidade)
      setValue('state', values.uf)
    }
  }

  const maskCEP = (event: any) => {
    const value = formatCep(event.target.value)
    setValue('cep', value)
    clearErrors()
    if (value.length >= 9) {
      setForm(true)
      setLoading(true)
      debounceFn(value)
    }
  }

  return (
    <>
      <View flex justify='flex-end'>
        <Leave onClick={handleLeave}>
          <Text size={11}>Sair</Text>
          <img src={Close} alt='Sair' />
        </Leave>
      </View>

      <View>
        <img src={Location} alt='Localização' />
      </View>

      <View mt={0.2}>
        <Text color='#A70000' fontWeight='bold' size={27}>
          Onde sua loja está localizada?
        </Text>
      </View>

      <View mt={1}>
        <Text size={14}>Digite aqui o seu CEP</Text>
      </View>

      <View textAlign='center' mt={1}>
        <Row>
          <Col xs={7}>
            <InputText
              {...register('cep', {
                required: 'O Cep é obrigatório',
              })}
              defaultValue={data.location.cep}
              type='text'
              name='cep'
              validationError={errors.cep}
              onChange={maskCEP}
            />
          </Col>

          <Col xs={5}>
            <Button
              label='Não sei meu CEP'
              type='button'
              style={{ fontSize: 9, padding: '12px 8px', minHeight: '0px' }}
              background='#F5B85B'
              onClick={handleSearchCep}
            />
          </Col>
        </Row>
      </View>

      {form && (
        <View
          pt={1}
          style={{
            marginTop: 'auto',
            height: 200,
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {loading && <LoadingSearch />}

          {!loading && (
            <>
              <Row>
                <Col xs={8}>
                  <InputText
                    {...register('street', {
                      required: 'O Endereço é obrigatório',
                    })}
                    defaultValue={data.location.street}
                    label='Endereço'
                    type='text'
                    name='street'
                    validationError={errors.street}
                  />
                </Col>
                <Col xs={4}>
                  <InputText
                    {...register('number', {
                      required: 'O Número é obrigatório',
                    })}
                    defaultValue={data.location.number}
                    label='Número'
                    type='number'
                    name='number'
                    validationError={errors.number}
                  />
                </Col>
              </Row>

              <View mt={1}>
                <Row>
                  <Col xs={5}>
                    <InputText
                      {...register('more')}
                      defaultValue={data.location.more}
                      label='Complemento'
                      type='text'
                      name='more'
                      validationError={errors.more}
                    />
                  </Col>
                  <Col xs={7}>
                    <InputText
                      {...register('neighborhood', {
                        required: 'O Bairro é obrigatório',
                      })}
                      defaultValue={data.location.neighborhood}
                      label='Bairro'
                      type='text'
                      name='neighborhood'
                      validationError={errors.neighborhood}
                    />
                  </Col>
                </Row>
              </View>

              <View mt={1}>
                <Row>
                  <Col xs={8}>
                    <InputText
                      {...register('city', {
                        required: 'A Cidade é obrigatório',
                      })}
                      defaultValue={data.location.city}
                      label='Cidade'
                      type='text'
                      name='city'
                      validationError={errors.city}
                    />
                  </Col>
                  <Col xs={4}>
                    <InputText
                      {...register('state', {
                        required: 'O Estado é obrigatório',
                      })}
                      defaultValue={data.location.state}
                      label='UF'
                      type='text'
                      name='state'
                      validationError={errors.state}
                    />
                  </Col>
                </Row>
              </View>
            </>
          )}
        </View>
      )}

      <View style={{ marginTop: 'auto' }}>
        <Row align='center'>
          <Col xs={7}>
            <Dots length={5} active={1} />
          </Col>
          <Col xs={5}>
            <Button
              label='Próximo'
              background='#52C372'
              onClick={handleSubmit(onSubmit)}
            />
          </Col>
        </Row>
      </View>
    </>
  )
}

export default StoreLocation
