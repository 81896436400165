import { useEffect, useState } from 'react'

// import {
//   getUpdateListOrders,
//   getStores,
//   getStoresRanking,
//   getCustomeres,
//   getClientsRanking,
//   getProducts,
//   getProductsRanking,
//   getCouponDetailOrder,
//   getOrdersInProgress,
//   getQuantityOfNewUsers,
//   getQuantityOfLostedUsers,
//   getTicketAvarage,
//   getLatestSale,
//   getAvarageDeliveryTime,
//   getNewClients
// } from 'src/services/kpis'

const useHome = () => {
  const [loading, setLoading] = useState(false)

  const [stores, setStores] = useState<any>()
  const [storesRanking, setStoresRanking] = useState<any>()
  const [customeres, setCustomeres] = useState<any>()
  const [clientsRanking, setClientsRanking] = useState<any>()
  const [products, setProducts] = useState<any>()
  const [productsRanking, setProductsRanking] = useState<any>()
  const [couponDetailOrder, setCouponDetailOrder] = useState<any>()
  const [ordersInProgress, setOrdersInProgress] = useState<any>()
  const [quantityOfNewUsers, setQuantityOfNewUsers] = useState<any>()
  const [quantityOfLostedUsers, setQuantityOfLostedUsers] = useState<any>()
  const [ticketAvarage, setTicketAvarage] = useState<any>()
  const [latestSale, setLatestSale] = useState<any>()
  const [avarageDeliveryTime, setAvarageDeliveryTime] = useState<any>()
  const [newClients, setNewClients] = useState<any>()

  const handleLoadInformations = () => {
    try {
      setLoading(true)

      // await getUpdateListOrders()

      // const [
      //   storesResults,
      //   storesRankingResults,
      //   customeresResults,
      //   clientsRankingResults,
      //   productsResults,
      //   productsRankingResults,
      //   couponDetailOrderResults,
      //   ordersInProgressResults,
      //   quantityOfNewUsersResults,
      //   quantityOfLostedUsersResults,
      //   ticketAvarageResults,
      //   latestSaleResults,
      //   avarageDeliveryTimeResults,
      //   newClientsResults
      // ] = await Promise.all([
      //   getStores(),
      //   getStoresRanking(),
      //   getCustomeres(),
      //   getClientsRanking(),
      //   getProducts(),
      //   getProductsRanking(),
      //   getCouponDetailOrder(),
      //   getOrdersInProgress(),
      //   getQuantityOfNewUsers(),
      //   getQuantityOfLostedUsers(),
      //   getTicketAvarage(),
      //   getLatestSale(),
      //   getAvarageDeliveryTime(),
      //   getNewClients()
      // ])

      // setStores(storesResults)
      // setStoresRanking(storesRankingResults)
      // setCustomeres(customeresResults)
      // setClientsRanking(clientsRankingResults)
      // setProducts(productsResults)
      // setProductsRanking(productsRankingResults)
      // setCouponDetailOrder(couponDetailOrderResults)
      // setOrdersInProgress(ordersInProgressResults)
      // setQuantityOfNewUsers(quantityOfNewUsersResults)
      // setQuantityOfLostedUsers(quantityOfLostedUsersResults)
      // setTicketAvarage(ticketAvarageResults)
      // setLatestSale(latestSaleResults)
      // setAvarageDeliveryTime(avarageDeliveryTimeResults)
      // setNewClients(newClientsResults)

      setStores([])
      setStoresRanking([])
      setCustomeres([])
      setClientsRanking([])
      setProducts([])
      setProductsRanking([])
      setCouponDetailOrder([])
      setOrdersInProgress([])
      setQuantityOfNewUsers([])
      setQuantityOfLostedUsers([])
      setTicketAvarage([])
      setLatestSale([])
      setAvarageDeliveryTime([])
      setNewClients([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleLoadInformations()
  }, [])

  return {
    loading,
    stores,
    storesRanking,
    customeres,
    clientsRanking,
    products,
    productsRanking,
    couponDetailOrder,
    ordersInProgress,
    quantityOfNewUsers,
    quantityOfLostedUsers,
    ticketAvarage,
    latestSale,
    avarageDeliveryTime,
    newClients,
    handleLoadInformations,
  }
}

export default useHome
