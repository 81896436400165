import { Suspense, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'
import { useSelector } from 'react-redux'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
  // FIXME: TIVE QUE ADICIONAR O react-router NO PROJETO POIS O STYORYBOOK ESTAVA PUXANDO ELE COMO peerDependecy NA V6 E ELE NESSA VERSSÃO ESTAVA NOS TRAZENDO PROBLEMAS DE TIPAGEM
  // att: uma possível solução talvez seja inidicar a versão a ser usada como peerDependency utilizando a chave "resolutions" no package.json
} from 'react-router-dom'

import { LoadingSearch } from 'src/components/elements'
import useAuth from 'src/hooks/useAuth'
import Login from 'src/pages/Auth/Login'
import Recovery from 'src/pages/Auth/Recovery'
import RecoveryPin from 'src/pages/Auth/RecoveryPin'
import Signup from 'src/pages/Auth/Signup'
import SignUpConfirm from 'src/pages/Auth/SignUpConfirm'
import SuccessSignUp from 'src/pages/Auth/SuccessSignUp'
import Home from 'src/pages/Home'
import Onboard from 'src/pages/Onboard'
import { DefaultRootState } from 'src/redux/store'

import PrivateRoute from './PrivateRouter'

const LayourLoading = (
  <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
    <LoadingSearch />
  </div>
)

const Routers = () => {
  const { onboarding } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )
  const { getStoreSetup } = useAuth()
  const location = useLocation()
  const [isSignup, setIsSignup] = useState(false)

  useEffect(() => {
    if (location.pathname === '/signup') setIsSignup(true)

    getStoreSetup()

    if (process.env.REACT_APP_GA) {
      ReactGA.initialize(process.env.REACT_APP_GA)
    }

    const hotjarID = process.env.REACT_APP_HOTJAR_ID
      ? parseInt(process.env.REACT_APP_HOTJAR_ID)
      : 0
    hotjar.initialize(hotjarID, 6)
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Suspense fallback={LayourLoading}>
          {isSignup && <Redirect to='/signup' />}
          {!onboarding.startFlow ? (
            <>
              <PrivateRoute component={Home} path='/' />
              <Route component={Login} path='/signin' exact />
              <Route component={Signup} path='/signup' exact />
              <Route component={Recovery} path='/recovery' exact />
              <Route component={RecoveryPin} path='/recovery-pin' exact />
              <Route component={SignUpConfirm} path='/signupconfirm' exact />
              <Route component={SuccessSignUp} path='/successsignup' exact />
            </>
          ) : (
            <>
              <Route component={Onboard} path='/onboarding' exact />
              {onboarding.startFlow && <Redirect to='/onboarding' />}
            </>
          )}
        </Suspense>
      </Switch>
    </BrowserRouter>
  )
}

export default Routers
