import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'

import { format } from 'date-fns'
import Close from 'src/assets/icons/close.svg'
import Documento from 'src/assets/icons/icone-documento.svg'
import Verificado from 'src/assets/icons/status-aprovado.svg'
import Rejeitado from 'src/assets/icons/status-rejeitado.svg'
import OfferUser from 'src/assets/images/empty-user.svg'
import MenuLoja from 'src/assets/images/menu-loja.svg'
import {
  DropDownMenu,
  LoadingSearch,
  MenuItem,
  ModalConfirm,
  ModalList,
} from 'src/components/elements'
import { Button, Input, Select } from 'src/components/form'
import { DataRow, Header, Table, Text, View } from 'src/components/helper'
import useCustomer from 'src/hooks/useCustomer'
import { useFetchFilter } from 'src/hooks/useFetch'
import { removeEmptyString } from 'src/util/helpers/object'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

function Users() {
  const {
    loading,
    openModal,
    salesCostumer,
    filter,
    setFilterParams,
    openModalSales,
    handleToggleModal,
    handleDisableUser,
    handleSubmitDisableUser,
    handleSalesUser,
    handleCreateStore,
    handleToggleModalSales,
    handleGoToCreateUser,
    openModalAddHomecare,
    handleAddHomecareUser,
    handleToggleModalHomecare,
    handleSubmitAddHomecareUser,
  } = useCustomer()

  const { register, handleSubmit } = useForm()

  const { data } = useFetchFilter(['/customers/admin/list', filter])

  const onSubmit = handleSubmit((data: any) =>
    setFilterParams(removeEmptyString(data))
  )

  return (
    <>
      <Container>
        <View pb={2}>
          <View direction='row' align='center' justify='space-between'>
            <Text size={25} fontWeight={700}>
              Clientes
            </Text>

            <Col xs={2}>
              <Button
                type='button'
                label='Cadastrar clientes'
                height='40px'
                onClick={handleGoToCreateUser}
              />
            </Col>
          </View>

          <View mt={0.9}>
            <View style={shadowBox}>
              <form onSubmit={onSubmit}>
                <Row align='center'>
                  <Col xs={3}>
                    <Input
                      {...register('name')}
                      type='text'
                      id='name'
                      placeholder='Nome'
                    />
                  </Col>

                  <Col xs={3}>
                    <Input
                      {...register('phone')}
                      type='text'
                      id='phone'
                      placeholder='Exemplo: +5511999999999'
                    />
                  </Col>

                  <Col xs={2}>
                    <Select {...register('active')} id='active'>
                      <option value=''>Todas as lojas</option>
                      <option value='true'>Ativo</option>
                      <option value='false'>Inativo</option>
                    </Select>
                  </Col>

                  <Col xs={2}>
                    <Select {...register('limit')} id='limit'>
                      <option value=''>Selecione um limite</option>
                      {[20, 40, 100].map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  </Col>

                  <Col xs={2}>
                    <Button label='Filtrar' height='40px' />
                  </Col>
                </Row>
              </form>
            </View>
          </View>
        </View>

        <View height='calc(100% - 180px)' style={shadowBox}>
          {!data && (
            <View height='100%' justify='center' align='center'>
              <LoadingSearch />
            </View>
          )}

          {data && (
            <View height='100%' mt={1.4}>
              <Table.Table id='listSale'>
                <thead>
                  <tr>
                    <Header textAlign='left' width='150px'>
                      ID
                    </Header>
                    <Header textAlign='left' width='80px'>
                      Nome
                    </Header>
                    <Header textAlign='left'>CPF</Header>
                    <Header textAlign='left'>Telefone</Header>
                    <Header textAlign='left'>Telefone verificado</Header>
                    <Header textAlign='left'>E-mail verificado</Header>
                    <Header textAlign='left'>Status</Header>
                    <Header textAlign='left'>Senha temporaria</Header>
                    <Header textAlign='left'>Data criação</Header>
                    <Header textAlign='left'>Ações</Header>
                  </tr>
                </thead>
                <tbody>
                  {data.Users.map((item: any, idx: number) => (
                    <Table.Row key={idx}>
                      <DataRow>
                        <Text size={11} fontWeight={500}>
                          {item.sub}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <Text size={12} fontWeight={500}>
                          {item.name}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <Text size={12}>{item['custom:cpf']}</Text>
                      </DataRow>
                      <DataRow>
                        <Text size={12}>{item.phone_number}</Text>
                      </DataRow>
                      <DataRow>
                        <Text size={12}>
                          {item.phone_number_verified ? (
                            <img src={Verificado} />
                          ) : (
                            <img src={Rejeitado} />
                          )}
                        </Text>
                      </DataRow>

                      <DataRow>
                        <Text size={12}>
                          {item.email_verified ? (
                            <img src={Verificado} />
                          ) : (
                            <img src={Rejeitado} />
                          )}
                        </Text>
                      </DataRow>

                      <DataRow>
                        <Text size={12}>
                          {item.state ? (
                            <img src={Verificado} />
                          ) : (
                            <img src={Rejeitado} />
                          )}
                        </Text>
                      </DataRow>

                      <DataRow>
                        <Text size={15}>{item?.password || '-'}</Text>
                      </DataRow>

                      <DataRow>
                        <Text size={15}>
                          {format(new Date(item.created_at), 'dd/MM HH:mm')}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <View mt={0.2} mr={0.2} cursor>
                          <DropDownMenu>
                            <MenuItem
                              source={Documento}
                              label='Pedidos do cliente'
                              onClick={handleSalesUser(item.sub)}
                            />
                            <MenuItem
                              source={MenuLoja}
                              label='Cadastrar loja'
                              onClick={handleCreateStore(item.sub)}
                            />
                            <MenuItem
                              source={Close}
                              label='Adicionar homecare'
                              onClick={handleAddHomecareUser(item.sub)}
                            />
                            <MenuItem
                              source={Close}
                              label='Desativar cliente'
                              onClick={handleDisableUser(item.sub)}
                            />
                          </DropDownMenu>
                        </View>
                      </DataRow>
                    </Table.Row>
                  ))}
                </tbody>
              </Table.Table>
            </View>
          )}
        </View>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={OfferUser}
          loading={loading}
          description='Tem certeza que deseja desativar esta pessoa?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          backgroundConfirm='#FF0000'
          handleConfirm={handleSubmitDisableUser}
        />
      )}

      {openModalAddHomecare && (
        <ModalConfirm
          icon={OfferUser}
          loading={loading}
          description='Tem certeza que deseja adicionar esta pessoa ao home care?'
          labelCancel='Não'
          handleCancel={handleToggleModalHomecare}
          labelConfirm='Sim'
          backgroundConfirm='#FF0000'
          handleConfirm={handleSubmitAddHomecareUser}
        />
      )}

      {openModalSales && (
        <ModalList
          data={salesCostumer}
          loading={loading}
          onClose={handleToggleModalSales}
        />
      )}
    </>
  )
}

export default Users
