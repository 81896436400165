/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect } from 'react'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { debounce } from 'lodash'
import OfferUser from 'src/assets/images/empty-user.svg'
import { ModalConfirm } from 'src/components/elements'
import { Button, Input } from 'src/components/form'
import { StepsRegister, Text, View } from 'src/components/helper'
import useSaleCenter from 'src/hooks/useSaleCenter'
import { resetOnboardingAction } from 'src/redux/reducers/onboardingReducer'
import { DefaultRootState } from 'src/redux/store'
import {
  getCepAddressService,
  getGoogleAddress,
  getLatLngAddress,
} from 'src/services/GeoService'
import { formatCep, formatCNPJ } from 'src/util/helpers/string'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

function AddStore() {
  const {
    name,
    cnpj,
    location: address,
    lat,
    lng,
  } = useSelector<DefaultRootState, DefaultRootState['onboarding']>(
    (state) => state.onboarding
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name,
      cnpj,
      ...address,
      lat,
      lng,
    },
  })

  const {
    openModal,
    location,
    setLocation,
    handleToggleModal,
    handleToggleLoading,
    handleCancelConfirm,
    handleAddStore,
  } = useSaleCenter()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetOnboardingAction())
  }, [])

  const handleOnSubmit = handleSubmit((data) => {
    handleAddStore(data, location)
  })

  const debounceFn = useCallback(debounce(handleRequestCep, 1000), [])

  async function handleRequestCep(cep: string) {
    const values = await getCepAddressService(cep)
    if (values.data) {
      setValue('street', values.data.logradouro)
      setValue('more', values.data.complemento ?? '')
      setValue('neighborhood', values.data.bairro ?? '')
      setValue('city', values.data.localidade)
      setValue('state', values.data.uf)

      const params =
        `${values.data.logradouro}+${values.data.bairro},+${values.data.localidade},+${values.data.uf}`.replace(
          ' ',
          '+'
        )
      try {
        const { data: googleResp } = await getGoogleAddress(params)

        const lat = googleResp.features[0].center[1]
        const lng = googleResp.features[0].center[0]

        setLocation({ lat, lng })
      } catch (error) {
        console.log(error)
      }
    }
    handleToggleLoading()
  }

  const handleSearchCep = () => {
    handleToggleLoading()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          lat: String(position.coords.latitude),
          lng: String(position.coords.longitude),
        })
        getLatLngAddress(position.coords.latitude, position.coords.longitude)
          .then((result) => {
            const address = result.data.features[0]

            const cep =
              address.context.find((item: any) => item.id.match(/postcode/))
                ?.text || ''
            const street = address.text
            const neighborhood =
              address.context.find((item: any) => item.id.match(/neighborhood/))
                ?.text || ''

            const state =
              address.context.find((item: any) => item.id.match(/region/))
                ?.text || ''
            const city =
              address.context.find((item: any) => item.id.match(/place/))
                ?.text || ''

            setValue('street', street)
            setValue('city', city)
            setValue('state', state)
            setValue('cep', cep)
            setValue('more', '')
            setValue('neighborhood', neighborhood)
          })
          .finally(() => {
            handleToggleLoading()
          })
      })
    }
  }

  const maskCEP = (event: any) => {
    const value = formatCep(event.target.value)
    setValue('cep', value)
    clearErrors()
    if (value.length >= 9) {
      handleToggleLoading()
      debounceFn(value)
    }
  }

  const maskCNPJ = useCallback((event: any) => {
    setValue('cnpj', formatCNPJ(event.target.value))
    clearErrors()
  }, [])

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View>
            <Text size={25} fontWeight={700}>
              Cadastrar loja
            </Text>
          </View>
        </View>

        <form style={{ height: 'calc(100% - 40px)' }} onSubmit={handleOnSubmit}>
          <View height='calc(100% - 110px)' style={shadowBox}>
            <View mb={5} flex justify='center' width='100%'>
              <StepsRegister.Container>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current color='#3097E2' />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Text size={15} textAlign='center' fontWeight={500}>
                        Nome e endereço
                      </Text>
                    </View>
                  </StepsRegister.Li>

                  <StepsRegister.Li>
                    <StepsRegister.Divider activeStep={0} />
                  </StepsRegister.Li>
                </>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Text size={15} textAlign='center'>
                        Entrega
                      </Text>
                    </View>
                  </StepsRegister.Li>

                  <StepsRegister.Li>
                    <StepsRegister.Divider activeStep={0} />
                  </StepsRegister.Li>
                </>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Text size={15} textAlign='center'>
                        Telefones
                      </Text>
                    </View>
                  </StepsRegister.Li>

                  <StepsRegister.Li>
                    <StepsRegister.Divider activeStep={0} />
                  </StepsRegister.Li>
                </>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Text size={15} textAlign='center'>
                        Horários
                      </Text>
                    </View>
                  </StepsRegister.Li>
                </>
              </StepsRegister.Container>
            </View>

            <View flex justify='center' width='100%' mt={2}>
              <View width='22rem'>
                <View>
                  <Text fontWeight={500} size={12}>
                    Nome da loja
                  </Text>
                  <View mt={0.8}>
                    <Input
                      {...register('name', {
                        required: 'O nome é obrigatório',
                      })}
                      type='text'
                      validationError={errors.name}
                      messageError={errors.name?.message}
                    />
                  </View>
                </View>

                <View mt={1}>
                  <Text fontWeight={500} size={12}>
                    CNPJ
                  </Text>
                  <View mt={0.8}>
                    <Input
                      {...register('cnpj', {
                        required: 'O CNPJ é obrigatório',
                      })}
                      type='text'
                      name='cnpj'
                      validationError={errors.cnpj}
                      messageError={errors.cnpj?.message}
                      onChange={maskCNPJ}
                    />
                  </View>
                </View>

                <View mt={1}>
                  <Row>
                    <Col xs={7}>
                      <View>
                        <Text fontWeight={500} size={12}>
                          CEP
                        </Text>
                        <View mt={0.8}>
                          <Input
                            {...register('cep', {
                              required: 'O Cep é obrigatório',
                            })}
                            type='text'
                            name='cep'
                            validationError={errors.cep}
                            messageError={errors.cep?.message}
                            onChange={maskCEP}
                          />
                        </View>
                      </View>
                    </Col>
                    <Col xs={5}>
                      <View mt={1.8}>
                        <Button
                          label='Não sei meu CEP'
                          type='button'
                          style={{
                            fontSize: 12,
                            padding: '12px 8px',
                            minHeight: '0px',
                          }}
                          background='#F5B85B'
                          onClick={handleSearchCep}
                        />
                      </View>
                    </Col>
                  </Row>
                </View>

                <View mt={1}>
                  <Text fontWeight={500} size={12}>
                    Endereço
                  </Text>
                  <View mt={0.8}>
                    <Input
                      {...register('street', {
                        required: 'O endereço é obrigatório',
                      })}
                      type='text'
                      validationError={errors.street}
                      messageError={errors.street?.message}
                    />
                  </View>
                </View>

                <View mt={1}>
                  <Row>
                    <Col xs={6}>
                      <View>
                        <Text fontWeight={500} size={12}>
                          Número
                        </Text>
                        <View mt={0.8}>
                          <Input
                            {...register('number', {
                              required: 'O número é obrigatório',
                            })}
                            type='text'
                            validationError={errors.number}
                            messageError={errors.number?.message}
                          />
                        </View>
                      </View>
                    </Col>
                    <Col xs={6}>
                      <View>
                        <Text fontWeight={500} size={12}>
                          Complemento
                        </Text>
                        <View mt={0.8}>
                          <Input
                            {...register('more')}
                            type='text'
                            validationError={errors.more}
                            messageError={errors.more?.message}
                          />
                        </View>
                      </View>
                    </Col>

                    <Col xs={12}>
                      <View mt={1}>
                        <Text fontWeight={500} size={12}>
                          Bairro
                        </Text>
                        <View mt={0.8}>
                          <Input
                            {...register('neighborhood', {
                              required: 'O bairro é obrigatório',
                            })}
                            type='text'
                            validationError={errors.neighborhood}
                            messageError={errors.neighborhood?.message}
                          />
                        </View>
                      </View>
                    </Col>

                    <Col xs={8}>
                      <View mt={1}>
                        <Text fontWeight={500} size={12}>
                          Cidade
                        </Text>
                        <View mt={0.8}>
                          <Input
                            {...register('city', {
                              required: 'A cidade é obrigatório',
                            })}
                            type='text'
                            validationError={errors.city}
                            messageError={errors.city?.message}
                          />
                        </View>
                      </View>
                    </Col>

                    <Col xs={4}>
                      <View mt={1}>
                        <Text fontWeight={500} size={12}>
                          UF
                        </Text>
                        <View mt={0.8}>
                          <Input
                            {...register('state', {
                              required: 'Uf é obrigatório',
                            })}
                            type='text'
                            validationError={errors.state}
                            messageError={errors.state?.message}
                          />
                        </View>
                      </View>
                    </Col>
                  </Row>
                </View>
              </View>
            </View>
          </View>

          <View flex justify='flex-end' mt={2}>
            <View width='50%' direction='row'>
              <View width='100%' mr={1}>
                <Button
                  type='reset'
                  label='Cancelar'
                  borderColor='#3097E2'
                  color='#3097E2'
                  background='#fff'
                  onClick={handleToggleModal}
                />
              </View>
              <View width='100%'>
                <Button type='submit' label='Próximo' background='#3097E2' />
              </View>
            </View>
          </View>
        </form>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={OfferUser}
          description='Tem certeza que deseja cancelar o cadastro da loja?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          handleConfirm={handleCancelConfirm}
        />
      )}
    </>
  )
}

export default AddStore
