import styled from 'styled-components'

export const Container = styled.div<{ disabled: boolean }>`
  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 26px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ disabled }) =>
      disabled ? '#c4c4c4' : 'rgba(187, 134, 252, 0.38)'};
    height: 15px;
    width: 34px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: -2px;
    background-color: #474747;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${({ disabled }) =>
      disabled ? '#39343D33' : '#F48D4277'};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #f48d42;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background-color: ${({ disabled }) => (disabled ? '#39343D' : '#F48D42')};
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`
