import { ChangeEvent, useState } from 'react'

import Reject from 'src/assets/icons/icon-rejeitado.svg'
import { Modal } from 'src/components/elements'
import { Button, InputText, Radio } from 'src/components/form'
import { Text, View } from 'src/components/helper'

import { ModalDeclineContent } from './styles'

export interface HandleConfirmProps {
  reason: string
  description: string
}

interface DeclineOrderModalProps {
  loading: boolean
  handleConfirm(data: HandleConfirmProps): void
}

const DeclineOrderModal = ({
  loading,
  handleConfirm,
}: DeclineOrderModalProps) => {
  const [reason, setRease] = useState('Oferta vencida')
  const [description, setDescription] = useState('')

  const handleReason = (reason: string) => () => setRease(reason)

  const handleDescription = (e: ChangeEvent<HTMLInputElement>) =>
    setDescription(e.target.value)

  return (
    <Modal paddingTop={3} width={20} padding={0}>
      <ModalDeclineContent>
        <View flex direction='column' height='29rem'>
          <View mt={1} mb={1} flex justify='center'>
            <img src={Reject} alt='Direita' />
          </View>
          <Text textAlign='center' fontWeight={700} color='#FF0000'>
            Pedido rejeitado
          </Text>
          <Text textAlign='center' size={13} mt={0.6}>
            Qual o motivo que fez você rejeitar este pedido?
          </Text>

          <View style={{ right: 20 }}>
            <View height='38px'>
              <Radio
                checked={reason === 'Oferta vencida'}
                onClick={handleReason('Oferta vencida')}
                readOnly
              />
              <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                Oferta vencida
              </Text>
            </View>
            <View height='38px'>
              <Radio
                checked={reason === 'Preços abaixo da tabela'}
                onClick={handleReason('Preços abaixo da tabela')}
                readOnly
              />
              <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                Preços abaixo da tabela
              </Text>
            </View>
            <View height='38px'>
              <Radio
                checked={reason === 'Sem estoque'}
                onClick={handleReason('Sem estoque')}
                readOnly
              />
              <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                Sem estoque
              </Text>
            </View>
            <View height='38px'>
              <Radio
                checked={reason === 'Fora da área de entrega'}
                onClick={handleReason('Fora da área de entrega')}
                readOnly
              />
              <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                Fora da área de entrega
              </Text>
            </View>
            <View height='38px'>
              <Radio
                checked={reason === 'Outro'}
                onClick={handleReason('Outro')}
                readOnly
              />
              <Text ml={3.5} mt={0.7} size={14} fontWeight={500}>
                Outro
              </Text>
              {reason === 'Outro' && (
                <View mt={1} ml={1}>
                  <InputText
                    label='descreva o motivo:'
                    value={description}
                    onChange={handleDescription}
                  />
                </View>
              )}
            </View>
          </View>

          <View flex style={{ marginTop: 'auto' }}>
            <Button
              label='Enviar'
              background='#3097E2'
              loading={loading}
              onClick={() => handleConfirm({ reason, description })}
            />
          </View>
        </View>
      </ModalDeclineContent>
    </Modal>
  )
}

export default DeclineOrderModal
