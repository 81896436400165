import styled from 'styled-components'

const Divider = styled.div<{ activeStep?: number }>`
  width: 100px;
  height: 3px;
  border-bottom: 1px dashed #c4c4c4;
  transition: all 800ms ease;
  margin-top: 3px;
`

const Node = styled.div<{ activeStep?: number }>`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transition: all 1000ms ease;
  border: 2px solid ${({ activeStep }) => (activeStep ? '#3097E2' : '#C4C4C4')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Li = styled.li`
  list-style: none;
  line-height: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const Current = styled.div<{ color?: string }>`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  transition: all 1000ms ease;
  border: 3px solid ${({ color }) => color || '#C4C4C4'};
  background-color: ${({ color }) => color || '#C4C4C4'};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.ul`
  display: flex;
`

export default {
  Container,
  Current,
  Divider,
  Node,
  Li,
}
