import { Container, Text } from './styles'

interface AlertLoginProps {
  message: string
}

function AlertLogin({ message }: AlertLoginProps) {
  return (
    <Container>
      <Text>{message}</Text>
    </Container>
  )
}

export default AlertLogin
