import { useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import { batch, useDispatch } from 'react-redux'

import { format, fromUnixTime } from 'date-fns'
import { Dispatch } from 'redux'
import {
  disableMainModalAction,
  enableMainModalAction,
} from 'src/redux/reducers/appReducer'
import {
  addSaleDetailAction,
  cleanSaleDetailAction,
  setDaysSaleAction,
} from 'src/redux/reducers/saleReducer'
import { DefaultRootState } from 'src/redux/store'
import { createLog } from 'src/services/log'
import {
  getAcceptOrderByIdSale,
  getSaleCenterAcceptOrder,
  getSaleCenterDeclineOrder,
  getSalesById,
  postOrderNf,
  postOrderStatus,
} from 'src/services/OrderService'
import { saleStatus, statusGA } from 'src/util/constants'
import { eventGA } from 'src/util/gtag'
import { formatCurrency } from 'src/util/helpers/string'
import { mutate } from 'swr'

export interface Product {
  name: string
  quantity: number
  pub_id: string
  price: number
  min_price: number
  max_price: number
  offer?: any
  product_image: string
  required: boolean
  substitute_products: any[]
  medical_prescription?: any
}

export interface Customer {
  name: string
  phone: string
  cpf?: any
}

export interface DeliveryAddress {
  pub_id: string
  country: string
  lng: string
  city: string
  more: string
  created_at: number
  active: boolean
  cep: string
  number: number
  default: boolean
  street: string
  nickname: string
  state: string
  neighborhood: string
  lat: string
  customer: string
}

export interface PaymentInfo {
  status: string
  split_value: number
}

export interface SaleProps {
  created_at: number
  products: Product[]
  identifier?: any
  order: string
  total: number
  customer: Customer
  pub_id: string
  proposal: string
  rate: number
  nf_key: string
  delivery_fee: number
  payment_type?: any
  delivery_address: DeliveryAddress
  payment_info: PaymentInfo
  schedule_info?: {
    date: string
    time: string
  }
  store: {
    name: string
    pub_id: string
  }
  confirm_checkout?: {
    nf_total: number
  }
  store_rate: number
  delivery_type?: string
  max_total: number
}

export interface ProductProps {
  name: string
  quantity: number
  pub_id: string
  price: number
  ean: string
  min_price: number
  max_price: number
  offer?: any
  product_image: string
  required: boolean
  substitute_products: any[]
  medical_prescription?: any
}

export interface CustomerProps {
  name: string
  phone_number: string
  phone: string
  cpf?: any
}

export interface State {
  description: string
  id: number
}

export interface PaymentType {
  mode: 'card' | 'cash'
  change_for: string
  description: 'app' | 'presential'
}

export interface DeliveryAddressProps {
  reference?: string
  pub_id: string
  country: string
  lng: string
  city: string
  more: string
  created_at: number
  active: boolean
  cep: string
  number: number
  default: boolean
  street: string
  nickname: string
  state: string
  neighborhood: string
  lat: string
  customer: string
}

export interface LatLngProps {
  lng: string
  lat: string
}

export interface SaleDetailProps {
  created_at: number
  products: ProductProps[]
  order: string
  proposal: string
  total: number
  customer: CustomerProps
  max_total: number
  state: State
  pub_id: string
  obs?: any
  rate: number
  delivery_fee: number
  payment_type: PaymentType
  delivery_type: 'store_delivery' | 'farmazon_delivery'
  delivery_address: DeliveryAddressProps
  delivery_average: string
  delivery_lat_lng: LatLngProps
  delivery_order_id?: string
  delivery_order_driver_id?: string
  delivery_driver?: {
    name: string
    photo?: string
    license_plate: string
    phone?: string
    track?: string
  }
  schedule_info?: {
    date: string
    time: string
  }
  store: {
    name: string
    pub_id: string
  }
  confirm_checkout?: {
    nf_file: string
    nf_total: number
  }
  store_lat_lng: LatLngProps
  store_rate?: number
  identifier?: string
}

const daysFilter = [1, 2, 7, 15, 30, 60, 90]

const useSaleCenterSale = () => {
  const [openModal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [nfLoading, setNfLoading] = useState(false)
  const [modelToShow, setModalToShow] = useState<'cancel' | 'nf'>('cancel')
  const [details, setDetails] = useState<SaleProps | null>(null)
  const alert = useAlert()
  const dispatch = useDispatch()

  const handleToggleModal = () => {
    eventGA('incluir_nota')

    setModalToShow('nf')
    setModal((state) => !state)
  }

  const handleOrderDetails = (storeId: string, saleId: string) => {
    return async () => {
      try {
        setDetails(null)
        setLoading(true)
        setModal(true)
        const response = await getSalesById(storeId, saleId)
        setDetails(response.data)
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleIncrementDay = () => {
    return (dispatch: Dispatch, getStore: () => DefaultRootState) => {
      const { sale } = getStore()
      const index = daysFilter.indexOf(sale.days)
      if (index < daysFilter.length - 1) {
        dispatch(setDaysSaleAction(daysFilter[index + 1]))
      }
    }
  }

  const handleDecrementDay = () => {
    return (dispatch: Dispatch, getStore: () => DefaultRootState) => {
      const { sale } = getStore()

      const index = daysFilter.indexOf(sale.days)
      if (index > 0) dispatch(setDaysSaleAction(daysFilter[index - 1]))
      else dispatch(setDaysSaleAction(daysFilter[0]))
    }
  }

  const handleExportExcel = (data: any) => {
    return () => {
      const header =
        'data:text/csv;charset=utf-8,Data,Pedido,Status,Meio de pagamento,Valor total\r\n'

      const getPayment = (item: any) => {
        let value = 'Pagamento via cartão de crédito'
        if (
          item.payment_type?.description === 'presential' &&
          item.payment_type?.mode === 'cash'
        ) {
          value = 'Pagamento em dinheiro'
        }
        if (
          item.payment_type?.description === 'presential' &&
          item.payment_type?.mode === 'card'
        ) {
          value = 'Pagamento via maquininha (débido/crédito)'
        }
        return value
      }

      const content = data
        .map((item: any) =>
          [
            format(fromUnixTime(item.created_at), 'dd/MM'),
            `${item.identifier}`,
            saleStatus[item?.payment_info?.status] ?? '',
            getPayment(item),
            `"${formatCurrency(item.total)}"`,
          ].join(',')
        )
        .join('\r\n')

      const csvContent = `${header}${content}`

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'minhas_vendas.xls')
      document.body.appendChild(link)
      link.click()
    }
  }

  const handleAcceptOrder = (storeId: string, saleId: string) => {
    return async () => {
      try {
        eventGA('aceitar_pedido')
        setLoading(true)
        await getSaleCenterAcceptOrder(storeId, saleId)
        alert.success('Pedido aceito com sucesso')
        batch(() => {
          dispatch(cleanSaleDetailAction())
          dispatch(disableMainModalAction())
        })
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleOpenModalDecline = () => {
    eventGA('rejeitar_pedido')

    setModalToShow('cancel')
    setModal((state) => !state)
  }

  const getEventName = useCallback((reason: string) => {
    if (reason === 'Oferta vencida') return 'oferta_vencida'
    if (reason === 'Preços abaixo da tabela') return 'preco_abaixo'
    if (reason === 'Sem estoque') return 'sem_estoque'
    if (reason === 'Fora da área de entrega') return 'fora_area_entrega'
    return 'outro_motivo'
  }, [])

  const handleDeclineOrder = (
    storeId: string,
    saleId: string,
    orderId: string,
    data: any
  ) => {
    return async () => {
      try {
        eventGA(getEventName(data.reason))
        setLoading(true)
        await getSaleCenterDeclineOrder(storeId, saleId, data)
        createLog({
          orderId,
          log: 'DECLINE_ORDER',
        })
        alert.success('Pedido rejeitado com sucesso')
        batch(() => {
          dispatch(cleanSaleDetailAction())
          dispatch(disableMainModalAction())
        })

        return Promise.resolve()
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')

        return Promise.reject(error)
      } finally {
        setLoading(false)
        setModal(false)
      }
    }
  }

  const handleOrderModalDetails = async (storeId: string, saleId: string) => {
    try {
      setLoading(true)
      batch(() => {
        dispatch(cleanSaleDetailAction())
        dispatch(enableMainModalAction())
      })
      const { data } = await getAcceptOrderByIdSale(storeId, saleId)

      dispatch(addSaleDetailAction(data))
    } catch (error: any) {
      alert.error(error?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => dispatch(disableMainModalAction())

  const handleDetail = async (storeId: string, orderId: string) => {
    try {
      setLoading(true)
      const { data } = await getAcceptOrderByIdSale(storeId, orderId)
      setDetails(data)
    } catch (error: any) {
      alert.error(error?.response?.data?.message ?? 'Ação não concluída')
    } finally {
      setLoading(false)
    }
  }

  const handleGoogleLink = (details: any) => {
    if (details) {
      const clientAddress = `${details.delivery_address.street},+${details.delivery_address.number}+-+${details.delivery_address.neighborhood},+${details.delivery_address.city}+-+${details.delivery_address.state},+${details.delivery_address.cep}`
      const googleLink = `https://www.google.com/maps/search/farmacia/@${details.delivery_address.lat},${details.delivery_address.lng},15z/data=!4m8!2m7!3m6!1sfarmacia!2s${clientAddress}!3s0x9bd0bdcb4d385f:0x3246793563845369!4m2!1d${details.delivery_address.lng}!2d${details.delivery_address.lat}`
      window.open(googleLink, '_blank')
    }
  }

  const handleChangeStateSaleCenter = (
    storeId: string,
    proposalId: string,
    orderId: string,
    stateId: number
  ) => {
    return async () => {
      try {
        setLoading(true)
        if (stateId < 5) stateId = 5
        await postOrderStatus(storeId, proposalId, { state: stateId })
        createLog({
          orderId,
          log: 'CHANGE_STATUS_MANUALLY',
        })

        eventGA(statusGA[stateId])
        mutate(`/sales-center/orders/${proposalId}`)
        alert.success('Status alterado com sucesso')
      } catch (error: any) {
        alert.error(error?.response?.data?.message ?? 'Ação não concluída')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleSubmitNf = async (
    stateId: number,
    storeId: string,
    orderId: string,
    saleId: string,
    file = null,
    total: any,
    link = null
  ) => {
    try {
      setNfLoading(true)

      const data = new FormData()
      if (file) {
        data.append('nf_file', file[0])
      }

      if (link) {
        data.append('link', link)
      }

      data.set('nf_total', total)

      await postOrderNf(storeId, saleId, data)
      if (stateId === 2) {
        createLog({
          orderId,
          log: 'POST_NFE',
        })
      } else {
        createLog({
          orderId,
          log: 'CHANGE_FINAL_PRICE',
        })
      }
      eventGA('sucesso_incluir_nota')
      mutate(`/sales-center/orders/${saleId}`)
      alert.success('Upload realizado com sucesso')
      setModal(false)
    } catch (error: any) {
      alert.error(error?.response?.data?.message ?? 'Ação não concluída')
    } finally {
      setNfLoading(false)
    }
  }

  return {
    openModal,
    nfLoading,
    loading,
    details,
    handleToggleModal,
    handleOrderDetails,
    handleIncrementDay,
    handleDecrementDay,
    handleExportExcel,
    handleAcceptOrder,
    handleOrderModalDetails,
    handleDeclineOrder,
    handleOpenModalDecline,
    handleCloseModal,
    handleDetail,
    handleGoogleLink,
    handleSubmitNf,
    handleChangeStateSaleCenter,
    modelToShow,
  }
}

export default useSaleCenterSale
