import styled from 'styled-components'

const ContentShadow = styled.div`
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 30px;
  overflow: auto;
`

export default ContentShadow
