import { ChangeEvent, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import OfferUser from 'src/assets/images/empty-user.svg'
import { ModalConfirm } from 'src/components/elements'
import { Button, CheckBox, Select, Switch } from 'src/components/form'
import { ShadowBox, StepsRegister, Text, View } from 'src/components/helper'
import useSaleCenter from 'src/hooks/useSaleCenter'
import {
  addOnboardingNotCloseAction,
  addOnboardingOpeningAction,
} from 'src/redux/reducers/onboardingReducer'
import { DefaultRootState } from 'src/redux/store'
import { hours } from 'src/util/helpers/date'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

function AddHour() {
  const { onboarding } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )

  const dispatch = useDispatch()
  const [check, setCheck] = useState(false)

  const {
    loading,
    openModal,
    handleToggleModal,
    handleCancelConfirm,
    handleCreateStore,
  } = useSaleCenter()

  const onSubmit = () => dispatch(handleCreateStore())

  const handleNoClose = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch(addOnboardingNotCloseAction(e.target.checked))

  const handleChangeAll = () => {
    const currentCheck = !check
    setCheck(currentCheck)
    if (currentCheck) {
      const copy: any = [...onboarding.openingHours.hours]
      const open = Number(copy[0].open)
      const close = Number(copy[0].close)
      const updateData = copy.map((item: any) =>
        !['sab', 'dom'].includes(item.short) ? { ...item, open, close } : item
      )
      dispatch(addOnboardingOpeningAction(updateData))
    }
  }

  const handleWeekEnd = (short: string) => () => {
    const updateHours = onboarding.openingHours.hours.map((item) =>
      item.short === short ? { ...item, openStore: !item.openStore } : item
    )
    dispatch(addOnboardingOpeningAction(updateHours))
  }

  const handleSelectHour =
    (type: string, index: number) => (e: ChangeEvent<HTMLSelectElement>) => {
      const copy: any = [...onboarding.openingHours.hours]
      const value = parseInt(e.target.value)

      if (type === 'open') copy[index].open = value
      else copy[index].close = value

      dispatch(addOnboardingOpeningAction(copy))
    }

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View>
            <Text size={25} fontWeight={700}>
              Cadastrar loja
            </Text>
          </View>
        </View>

        <View height='calc(100% - 130px)' style={shadowBox}>
          <View mb={5} flex justify='center' width='100%'>
            <StepsRegister.Container>
              <>
                <StepsRegister.Li>
                  <StepsRegister.Current color='#00997F' />
                  <View width='5rem' mt={1.4} style={{ position: 'absolute' }}>
                    <Link to='/stores/addStore'>
                      <Text size={15} textAlign='center'>
                        Nome e endereço
                      </Text>
                    </Link>
                  </View>
                </StepsRegister.Li>

                <StepsRegister.Li>
                  <StepsRegister.Divider activeStep={0} />
                </StepsRegister.Li>
              </>
              <>
                <StepsRegister.Li>
                  <StepsRegister.Current color='#00997F' />
                  <View width='5rem' mt={1.4} style={{ position: 'absolute' }}>
                    <Link to='/stores/addDelivery'>
                      <Text size={15} textAlign='center'>
                        Entrega
                      </Text>
                    </Link>
                  </View>
                </StepsRegister.Li>

                <StepsRegister.Li>
                  <StepsRegister.Divider activeStep={0} />
                </StepsRegister.Li>
              </>
              <>
                <StepsRegister.Li>
                  <StepsRegister.Current color='#00997F' />
                  <View width='5rem' mt={1.4} style={{ position: 'absolute' }}>
                    <Link to='/stores/addPhones'>
                      <Text size={15} textAlign='center'>
                        Telefones
                      </Text>
                    </Link>
                  </View>
                </StepsRegister.Li>

                <StepsRegister.Li>
                  <StepsRegister.Divider activeStep={0} />
                </StepsRegister.Li>
              </>
              <>
                <StepsRegister.Li>
                  <StepsRegister.Current color='#3097E2' />
                  <View width='5rem' mt={1.4} style={{ position: 'absolute' }}>
                    <Text size={15} textAlign='center' fontWeight={500}>
                      Horários
                    </Text>
                  </View>
                </StepsRegister.Li>
              </>
            </StepsRegister.Container>
          </View>

          <View flex justify='center' width='100%' mt={2}>
            <View width='22rem'>
              <View textAlign='center' mt={1}>
                <ShadowBox>
                  <View flex justify='space-between' style={{ width: '100%' }}>
                    <View>
                      <Text size={13} fontWeight={500}>
                        Atendimento 24h
                      </Text>
                      <Text size={9}>De segunda a domingo</Text>
                    </View>
                    <Switch onChange={handleNoClose} />
                  </View>
                </ShadowBox>
              </View>

              {!onboarding.openingHours?.notClose && (
                <View
                  mt={0.6}
                  mb={0.6}
                  style={{ height: 500, overflowY: 'auto', padding: 2 }}
                >
                  {onboarding.openingHours.hours.map((date, index) => (
                    <View key={index} mb={0.8}>
                      <View mb={0.4}>
                        <Text size={12}>{date.label}</Text>
                      </View>
                      <Row nogutter align='center' justify='center'>
                        <Col xs={3}>
                          <Select
                            name='open'
                            value={date.open}
                            onChange={handleSelectHour('open', index)}
                            disabled={check}
                          >
                            {hours.map((hour, index) => (
                              <option key={index} value={hour.value}>
                                {hour.label}
                              </option>
                            ))}
                          </Select>
                        </Col>

                        <Col xs={2}>
                          <Text size={11} textAlign='center'>
                            Até
                          </Text>
                        </Col>

                        <Col xs={3}>
                          <Select
                            name='close'
                            value={date.close}
                            onChange={handleSelectHour('close', index)}
                            disabled={check}
                          >
                            {hours.map((hour, index) => (
                              <option key={index} value={hour.value}>
                                {hour.label}
                              </option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          {date.short === 'seg' && (
                            <View ml={0.8} direction='row'>
                              <CheckBox
                                name='all'
                                background='#52C372'
                                onClick={handleChangeAll}
                                checked={check}
                              />
                              <Text size={9}>
                                Igual para todos os dias da semana
                              </Text>
                            </View>
                          )}

                          {['ter', 'qua', 'qui', 'sex'].includes(date.short) &&
                            check && (
                              <View ml={0.8} direction='row'>
                                <CheckBox
                                  name='all'
                                  background='#52C372'
                                  checked
                                />
                              </View>
                            )}

                          {['sab', 'dom'].includes(date.short) && (
                            <View ml={0.8} direction='row' align='center'>
                              <CheckBox
                                name='weekend'
                                background='#EB5757'
                                borderWidthCheck='0px 2px 0px 0px'
                                rotate={90}
                                onClick={handleWeekEnd(date.short)}
                                checked={date.openStore}
                              />
                              <Text size={9}>Loja fechada</Text>
                            </View>
                          )}
                        </Col>
                      </Row>
                    </View>
                  ))}
                </View>
              )}
            </View>
          </View>
        </View>
        <View flex justify='flex-end' mt={2}>
          <View width='50%' direction='row'>
            <View width='100%' mr={1}>
              <Button
                type='reset'
                label='Cancelar'
                borderColor='#3097E2'
                color='#3097E2'
                background='#fff'
                onClick={handleToggleModal}
              />
            </View>
            <View width='100%'>
              <Button
                type='reset'
                label='Próximo'
                background='#3097E2'
                onClick={onSubmit}
                loading={loading}
              />
            </View>
          </View>
        </View>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={OfferUser}
          description='Tem certeza que deseja cancelar o cadastro da loja?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          handleConfirm={handleCancelConfirm}
        />
      )}
    </>
  )
}

export default AddHour
