import { useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { Button, InputText } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { postUser } from 'src/services/Costumer'
import { formatCPF, formatPhone } from 'src/util/helpers/string'
import { mutate } from 'swr'

import { Container } from './styles'

function CreateUser() {
  const alert = useAlert()
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm()

  const [loading, setLoading] = useState(false)

  const handleOnSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true)

      const { name, cpf, email, username } = data

      const usernameOnlyNumbers = username.replace(/\D/g, '')
      const cpfOnlyNumbers = cpf.replace(/\D/g, '')

      await postUser({
        name,
        cpf: cpfOnlyNumbers,
        email,
        phone_number: `+55${usernameOnlyNumbers}`,
      })

      mutate('/customers/admin/list')
      reset()

      alert.success('Cliente cadastrado com sucesso')
      history.goBack()
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  })

  const formatUsernameInput = useCallback((event: any) => {
    setValue('username', formatPhone(event.target.value))
    clearErrors()
  }, [])

  const formatCPfInput = (event: any) => {
    setValue('cpf', formatCPF(event.target.value))
    clearErrors()
  }

  return (
    <Container>
      <View flex justify='center'>
        <Col lg={3}>
          <View pb={2}>
            <Text size={25} fontWeight={700}>
              Cadastrar cliente
            </Text>
          </View>

          <form onSubmit={handleOnSubmit}>
            <View textAlign='center' mt={2}>
              <InputText
                {...register('name', {
                  required: 'O Nome é obrigatório',
                })}
                type='text'
                name='name'
                label='Seu nome'
                validationError={errors.name}
                messageError={errors.name?.message}
              />
            </View>

            <View textAlign='center' mt={2}>
              <InputText
                {...register('cpf', {
                  required: 'O CPF é obrigatório',
                })}
                type='text'
                name='cpf'
                label='CPF'
                onChange={formatCPfInput}
                validationError={errors.cpf}
                messageError={errors.cpf?.message}
              />
            </View>

            <View textAlign='center' mt={2}>
              <InputText
                {...register('email', {
                  required: 'O E-mail é obrigatório',
                })}
                type='text'
                name='email'
                label='E-mail'
                validationError={errors.email}
                messageError={errors.email?.message}
              />
            </View>

            <View textAlign='center' mt={2} mb={3}>
              <InputText
                {...register('username', {
                  required: 'O telefone é obrigatório',
                  pattern: {
                    value: /\d{5}-\d{4}/,
                    message: 'Digite seu número de telefone com DDD.',
                  },
                })}
                type='text'
                name='username'
                label='Telefone celular'
                validationError={errors.username}
                messageError={errors.username?.message}
                onChange={formatUsernameInput}
              />
            </View>

            <View style={{ marginTop: 'auto' }}>
              <Row align='center'>
                <Col xs={12}>
                  <Button
                    label='Salvar'
                    background='#3097E2'
                    loading={loading}
                  />
                </Col>
              </Row>
            </View>
          </form>
        </Col>
      </View>
    </Container>
  )
}

export default CreateUser
