/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useState } from 'react'
import Map, { Layer, Marker, Popup, Source } from 'react-map-gl'

import { circle } from '@turf/turf'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line

import 'mapbox-gl/dist/mapbox-gl.css'

import { LoadingSearch } from 'src/components/elements'
import { Select } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import Pin from 'src/components/map/Pin'
import { getAllListStores } from 'src/services/StoresService'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

function MapStore() {
  const [filter, setFilter] = useState({
    active: true,
    state: 'SP',
    limit: 200,
  })
  const [popupInfo, setPopupInfo] = useState<any>(null)
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    const data = async () => {
      const response = await getAllListStores(filter)
      setData(response.data.items)
    }
    data()
  }, [filter])

  const handleActive = (e: any) => {
    setFilter((state) => ({ ...state, active: e.target.value }))
  }

  const handleState = (e: any) => {
    setFilter((state) => ({ ...state, state: e.target.value }))
  }

  const handlePop = (data: any) => {
    return (e: any) => {
      e.originalEvent.stopPropagation()
      setPopupInfo(data)
    }
  }

  const handleClosePop = () => {
    setPopupInfo(null)
  }

  return (
    <Container>
      <View pb={2}>
        <View direction='row' align='center' justify='space-between'>
          <Text size={25} fontWeight={700}>
            Lojas
          </Text>
        </View>

        <View direction='row'>
          <View mt={1}>
            <Select id='active' onChange={handleActive} defaultValue='true'>
              <option value=''>Todas as lojas</option>
              <option value='true'>Ativo</option>
              <option value='false'>Inativo</option>
            </Select>
          </View>

          <View ml={1} mt={1}>
            <Select id='state' onChange={handleState} defaultValue='SP'>
              <option value='AC'>AC</option>
              <option value='AL'>AL</option>
              <option value='AP'>AP</option>
              <option value='AM'>AM</option>
              <option value='BA'>BA</option>
              <option value='CE'>CE</option>
              <option value='DF'>DF</option>
              <option value='ES'>ES</option>
              <option value='GO'>GO</option>
              <option value='MA'>MA</option>
              <option value='MT'>MT</option>
              <option value='MS'>MS</option>
              <option value='MG'>MG</option>
              <option value='PA'>PA</option>
              <option value='PB'>PB</option>
              <option value='PR'>PR</option>
              <option value='PE'>PE</option>
              <option value='PI'>PI</option>
              <option value='RJ'>RJ</option>
              <option value='RN'>RN</option>
              <option value='RS'>RS</option>
              <option value='RO'>RO</option>
              <option value='RR'>RR</option>
              <option value='SC'>SC</option>
              <option value='SP'>SP</option>
              <option value='SE'>SE</option>
              <option value='TO'>TO</option>
            </Select>
          </View>
        </View>
      </View>

      <View direction='row' mb={1}>
        <Text size={16} fontWeight={700}>
          Total de lojas: {data?.length ?? 0}
        </Text>
        <View direction='row' align='center' ml={1}>
          <View height='16px' width='16px' background='#d00' mr={0.4} />
          <Text size={13} fontWeight={500}>
            Lojas Ativas
          </Text>
        </View>

        <View direction='row' align='center' ml={1}>
          <View height='16px' width='16px' background='#ccc' mr={0.4} />
          <Text size={13} fontWeight={500}>
            Lojas inativas
          </Text>
        </View>
      </View>

      <View height='calc(100% - 130px)' style={shadowBox}>
        {!data && (
          <View height='100%' justify='center' align='center'>
            <LoadingSearch />
          </View>
        )}

        {data && (
          <>
            <Map
              initialViewState={{
                latitude: -23.2377202,
                longitude: -45.0901949,
                zoom: 7,
              }}
              mapStyle='mapbox://styles/mapbox/streets-v12'
              mapboxAccessToken={process.env.REACT_APP_MAPBOX}
            >
              {data?.map((item: any, idx: number) => (
                <>
                  <Source
                    type='geojson'
                    data={circle(
                      [+item.address.lng, +item.address.lat],
                      item?.self_delivery_range > 0
                        ? item.self_delivery_range
                        : 1,
                      { units: 'kilometers' }
                    )}
                  >
                    <Layer
                      type='fill'
                      paint={{
                        'fill-color': '#11b4da',
                        'fill-opacity': 0.2,
                      }}
                    />
                  </Source>
                  <Marker
                    key={`marker-${idx}`}
                    longitude={+item.address.lng}
                    latitude={+item.address.lat}
                    anchor='bottom'
                    onClick={handlePop(item)}
                  >
                    <Pin active={item.active} />
                  </Marker>
                </>
              ))}
              {popupInfo && (
                <Popup
                  anchor='top'
                  longitude={+popupInfo.address.lng}
                  latitude={+popupInfo.address.lat}
                  onClose={handleClosePop}
                >
                  <Text fontWeight={500} size={13}>
                    {popupInfo.name}
                  </Text>
                  {popupInfo.contact_phones?.map((item: any) => (
                    <Text fontWeight={500} size={11}>
                      {item.phone} - {item?.whatsapp && 'whatsapp'}{' '}
                      {item?.principal && 'Principal'}
                    </Text>
                  ))}
                </Popup>
              )}
            </Map>
          </>
        )}
      </View>
    </Container>
  )
}

export default MapStore
