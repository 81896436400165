import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 200px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`

export const Button = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  padding: 2px 4px;
`

export const Label = styled.p`
  font-size: 14px;
  font-weight: 400;
`
