import api, { newApi } from './api'

export interface SellerProps {
  pub_id: string
  email: string
  name: string
  username: string
  store: string
}

export interface GetSellerProps {
  Items: SellerProps[]
  Count: number
}

export interface StoreRetriveProps {
  contact_phones: Array<{
    whatsapp: boolean
    phone: string
  }>
  opening_hours: {
    [key: string]: string[]
  }
  self_delivery: boolean
  self_delivery_range: number
}

interface AssociateProps {
  store_id: string
  seller_id: string
}
//

export const getAllSellers = async () =>
  newApi.get('/sales-center/stores/sellers').then(({ data }) => data)

export const postAssociateUser = async (data: AssociateProps) =>
  newApi.post('/sales-center/stores/sellers/associate', data)

export const postSalesCenter = async (data: any) =>
  api.post('/stores/sales-center/', data)

export const postTermUseById = async (pubID: number, version: string) =>
  newApi.post(`/stores/terms-of-use/${pubID}`, {
    terms_version: version,
  })

export const postCreateStore = async (paylaod: any) =>
  newApi.post<any>('/sales-center/stores', paylaod)

export const putStoreInative = async (_storeId: string, data: string[]) =>
  newApi.put<{ stores: string[] }>('/sales-center/stores/deactivate-stores/', {
    stores: data,
  })

export const getStoreSeller = async (url: string) => {
  const response = await newApi.get<GetSellerProps[]>(url)
  return response.data
}

export const postStoreSeller = async (data: SellerProps) =>
  newApi.post('/sales-center/stores/sellers', data)

export const putStoreSeller = async (
  userId: string,
  data: Partial<SellerProps>
) => newApi.put(`/sales-center/stores/sellers/${userId}`, data)

export const deleteStoreSeller = async (users: string[]) =>
  newApi.delete('/sales-center/stores/sellers', { data: { sellers: users } })

export const getSaleCenter = async () => api.get('/stores/sales-center/')

export const getStoreRetrieve = async (storeId: string) =>
  newApi.get(`/sales-center/stores/retrieve/${storeId}`)

export const putStoreRetrive = async (
  storeId: string,
  params: Partial<StoreRetriveProps>
) => newApi.patch(`/sales-center/stores/${storeId}`, params)
