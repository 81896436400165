import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const Dot = styled.div<{
  active: number
  step: number
  current: number
}>`
  border-radius: 50px;
  background-color: ${({ active, current, step }) =>
    active ? '#3097E2' : current > step ? '#52C472' : 'transparent'};
  border: 2px solid
    ${({ active, current, step }) =>
      active ? '#3097E2' : current > step ? 'transparent' : '#c4c4c4'};
  width: 15px;
  height: 15px;
  margin-right: 4px;
`
