import { Col, Row } from 'react-grid-system'
import Map from 'react-map-gl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line

import 'mapbox-gl/dist/mapbox-gl.css'

import OfferUser from 'src/assets/images/empty-user.svg'
import { ModalConfirm } from 'src/components/elements'
import { Button, Input, Range, Switch } from 'src/components/form'
import { StepsRegister, Text, View } from 'src/components/helper'
import useSaleCenter from 'src/hooks/useSaleCenter'
import { DefaultRootState } from 'src/redux/store'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

function AddDelivery() {
  const { onboarding } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )

  const {
    delivery,
    farmazonDelivery,
    range,
    openModal,
    handleSwitch,
    handleSwitchFarmazonDelivery,
    handleToggleModal,
    handleCancelConfirm,
    handleChangeRange,
    handleAddDelivery,
    deliveryFee,
    handleChangeDeliveryFee,
    deliveryTime,
    handleChangeDeliveryTime,
  } = useSaleCenter()

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View>
            <Text size={25} fontWeight={700}>
              Cadastrar loja
            </Text>
          </View>
        </View>

        <div style={{ height: 'calc(100% - 40px)' }}>
          <View height='calc(100% - 110px)' style={shadowBox}>
            <View mb={5} flex justify='center' width='100%'>
              <StepsRegister.Container>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current color='#00997F' />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Link to='/stores/addStore'>
                        <Text size={15} textAlign='center'>
                          Nome e endereço
                        </Text>
                      </Link>
                    </View>
                  </StepsRegister.Li>

                  <StepsRegister.Li>
                    <StepsRegister.Divider activeStep={0} />
                  </StepsRegister.Li>
                </>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current color='#3097E2' />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Text size={15} textAlign='center' fontWeight={500}>
                        Entrega
                      </Text>
                    </View>
                  </StepsRegister.Li>

                  <StepsRegister.Li>
                    <StepsRegister.Divider activeStep={0} />
                  </StepsRegister.Li>
                </>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Text size={15} textAlign='center'>
                        Telefones
                      </Text>
                    </View>
                  </StepsRegister.Li>

                  <StepsRegister.Li>
                    <StepsRegister.Divider activeStep={0} />
                  </StepsRegister.Li>
                </>
                <>
                  <StepsRegister.Li>
                    <StepsRegister.Current />
                    <View
                      width='5rem'
                      mt={1.4}
                      style={{ position: 'absolute' }}
                    >
                      <Text size={15} textAlign='center'>
                        Horários
                      </Text>
                    </View>
                  </StepsRegister.Li>
                </>
              </StepsRegister.Container>
            </View>

            <View>
              <Text textAlign='center' color='#EA2A2F' fontWeight={700}>
                Tenha uma receita EXTRA em cada entrega que você fizer com a
                gente.
              </Text>
              <Text mt={0.6} size={13} textAlign='center'>
                Caso você não possua, não tem problema porque nós fazemos a
                entrega para você sem custo a mais por isso.
              </Text>
            </View>

            <View>
              <View
                flex
                direction='row'
                align='center'
                justify='center'
                width='100%'
                mt={1}
              >
                <View width='22rem' height='9rem' style={shadowBox}>
                  <Row align='center' style={{ height: '100%' }}>
                    <Col xs={10}>
                      <Text fontWeight={500} size={20}>
                        Entrega própria
                      </Text>
                      <Text fontWeight={300} size={14} mt={1}>
                        Caso possua entrega própria, deixe o botão habilitado
                      </Text>
                    </Col>
                    <Col xs={2}>
                      <Switch
                        onChange={handleSwitch}
                        checked={delivery}
                        readOnly
                      />
                    </Col>
                  </Row>
                </View>

                <View width='22rem' height='9rem' ml={2} style={shadowBox}>
                  <Row align='center' style={{ height: '100%' }}>
                    <Col xs={10}>
                      <Text fontWeight={500} size={20}>
                        Entrega farmazon
                      </Text>
                      <Text fontWeight={300} size={14} mt={1}>
                        Aceito entrega farmazon
                      </Text>
                    </Col>
                    <Col xs={2}>
                      <Switch
                        onChange={handleSwitchFarmazonDelivery}
                        checked={farmazonDelivery}
                        readOnly
                      />
                    </Col>
                  </Row>
                </View>
              </View>

              {delivery && (
                <View mt={2}>
                  <Row align='center'>
                    <Col xs={6}>
                      <Map
                        initialViewState={{
                          latitude: +onboarding.lat,
                          longitude: +onboarding.lng,
                          zoom: 14,
                        }}
                        mapStyle='mapbox://styles/mapbox/streets-v12'
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                        style={{ height: 366 }}
                      />
                    </Col>
                    <Col xs={6}>
                      <View width='300px' style={{ margin: 'auto' }}>
                        <Text fontWeight={300} size={16} textAlign='center'>
                          Defina aqui o raio de entrega da sua loja:
                        </Text>
                        <View mt={3}>
                          <Range
                            min={1}
                            max={6}
                            onInput={handleChangeRange}
                            value={range}
                          />
                        </View>
                        <View mt={0.6} mb={0.6}>
                          <Text
                            color='#2D9CDB'
                            fontWeight='bold'
                            textAlign='center'
                          >
                            {range} km
                          </Text>
                        </View>
                      </View>

                      <View mb={0.5}>
                        <Text size={12}>Preço da entrega</Text>
                        <Input
                          value={deliveryFee}
                          onChange={handleChangeDeliveryFee}
                        />
                      </View>
                      <View mb={1}>
                        <Text size={12}>
                          Tempo médio de entrega <b>em minutos</b>
                        </Text>
                        <Input
                          type='number'
                          value={deliveryTime}
                          onChange={handleChangeDeliveryTime}
                        />
                      </View>
                    </Col>
                  </Row>
                </View>
              )}
            </View>
          </View>

          <View flex justify='flex-end' mt={2}>
            <View width='50%' direction='row'>
              <View width='100%' mr={1}>
                <Button
                  type='reset'
                  label='Cancelar'
                  borderColor='#3097E2'
                  color='#3097E2'
                  background='#fff'
                  onClick={handleToggleModal}
                />
              </View>
              <View width='100%'>
                <Button
                  type='submit'
                  label='Próximo'
                  background='#3097E2'
                  onClick={handleAddDelivery}
                />
              </View>
            </View>
          </View>
        </div>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={OfferUser}
          description='Tem certeza que deseja cancelar o cadastro da loja?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          handleConfirm={handleCancelConfirm}
        />
      )}
    </>
  )
}

export default AddDelivery
