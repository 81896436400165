import { Container, Col, Row, Hidden } from 'react-grid-system'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Image from 'src/assets/images/farmazon-user.png'
import Logo from 'src/assets/images/logo-farmazon-black.svg'
import { Button } from 'src/components/form'
import {
  ImageResize,
  View,
  Text,
  ContainerAnimation,
} from 'src/components/helper'
import { startOnboardingAction } from 'src/redux/reducers/onboardingReducer'

import { loginAnimation } from 'src/styles'

const SuccessSignUp = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleCompleteRegister = () => {
    dispatch(startOnboardingAction())
    history.push('/onboarding')
  }

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row nogutter align='stretch' style={{ height: 'calc(100vh)' }}>
        <Hidden xs sm md>
          <Col md={8}>
            <ImageResize url={`url(${Image})`} />
          </Col>
        </Hidden>
        <Col xs={12} lg={4}>
          <Row
            align='center'
            justify='center'
            nogutter
            style={{ height: '100%' }}
          >
            <Col xs={8}>
              <ContainerAnimation {...loginAnimation}>
                <View textAlign='center' pt={4}>
                  <img src={Logo} alt='Farmazon logo' />
                </View>

                <View mt={3} justify='center'>
                  <Text
                    color='#52C372'
                    textAlign='center'
                    fontWeight='bold'
                    size={24}
                    style={{ width: 300 }}
                  >
                    Cadastro confirmado com sucesso!
                  </Text>
                </View>

                <View mt={2} justify='center'>
                  <Text textAlign='center' style={{ width: 350 }}>
                    Finalize seu cadastro para começar a vender agora mesmo!
                  </Text>
                </View>

                <View mt={3}>
                  <Row align='center'>
                    <Col xs={12}>
                      <Button
                        type='button'
                        label='Completar cadastro'
                        background='#3097E2'
                        onClick={handleCompleteRegister}
                      />
                    </Col>
                  </Row>
                </View>
              </ContainerAnimation>
            </Col>

            <View mt={1}>
              <Text size={13} color='#C4C4C4' textAlign='center'>
                Copyright © 2022 Farmazon. Todos os direitos reservados.
              </Text>
            </View>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default SuccessSignUp
