const RESET_DATA = 'storeReducer/RESET_DATA'
const ADD_STORE = 'storeReducer/ADD_STORE'
const ADD_USERNAME = 'storeReducer/ADD_USERNAME'
const ADD_GROUP = 'storeReducer/ADD_GROUP'

export interface StoreReducerProps {
  pub_id: string
  name: string
  userName: string
  phone: string
  email: string
  group: string[]
  address: {
    country: string
    number: number
    lng: string
    city: string
    more: string
    street: string
    neighborhood: string
    state: string
    lat: string
    cep: string
  }
  onboarding_step: {
    documents?: boolean
    offers?: boolean
    bank_account?: boolean
    users?: boolean
  } | null
}

const stateInit = {
  pub_id: '',
  name: '',
  userName: '',
  phone: '',
  email: '',
  group: [],
  address: {
    country: '',
    number: 0,
    lng: '',
    city: '',
    more: '',
    street: '',
    neighborhood: '',
    state: '',
    lat: '',
    cep: '',
  },
  onboarding_step: null,
}

export const storeReducer = (
  state: StoreReducerProps = stateInit,
  { type, payload }: any
) => {
  switch (type) {
    case ADD_STORE:
      return {
        ...state,
        ...payload,
      }
    case ADD_USERNAME:
      return {
        ...state,
        userName: payload.name,
        email: payload.email,
        phone: payload.phone,
      }
    case ADD_GROUP:
      return {
        ...state,
        group: payload,
      }
    case RESET_DATA:
      return stateInit
    default:
      return state
  }
}

export const addStoreActiveAction = (payload: Partial<StoreReducerProps>) => ({
  type: ADD_STORE,
  payload,
})

export const addStoreGroupAction = (payload: string[]) => ({
  type: ADD_GROUP,
  payload,
})

export const resetStoreAction = () => ({ type: RESET_DATA })

export const addUserNameAction = (
  name: string,
  email: string,
  phone: string
) => ({ type: ADD_USERNAME, payload: { name, email, phone } })
