import api, { newApi } from './api'

export const updateHomecareOrder = async (id: string, data: any) => {
  return newApi.patch(`/admin/homecares/${id}`, data)
}

export const changeHomecareStatus = async (id: string, data: any) => {
  return newApi.patch(`/admin/homecares/${id}/change-status`, data)
}

export const createHomecareOrder = async (data: any) => {
  return newApi.post('/admin/homecares', data)
}

export const searchUserhomecare = async (phone: string) => {
  return api.get('/customers/admin/list', {
    params: {
      phone,
    },
  })
}

interface RegisterInteractionParams {
  description: string
}

export const registerInteraction = async (
  id: string,
  data: RegisterInteractionParams
) => {
  return newApi.patch(`/admin/homecares/${id}/register-interaction`, data)
}

export const updatePaymentStatus = async (
  id: string,
  data: { payment_status: string }
) => {
  return newApi.put(`/admin/homecares/${id}/payment-status`, data)
}

export const updatePaymentByPass = async (id: string) => {
  return newApi.put(`/admin/homecares/${id}/payment-bypass`)
}

interface DownloadReportParams {
  user_phone: string
  start_date: string
  end_date: string
}

export const downloadReport = async (data: DownloadReportParams) => {
  return newApi.post('admin/homecares/generate-pdf', data)
}
