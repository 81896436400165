import styled from 'styled-components'

export const ModalMainHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding-top: 2rem;
  position: relative;
`

export const ModalMainContent = styled.div`
  padding: 18px;
`

export const DashedLine = styled.div`
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.2);

  margin: 1.2rem 0px;
`

export const Container = styled.div`
  height: 100%;
`

export const ModalContent = styled.div`
  padding: 18px;
`

export const StatusBox = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  gap: 10px;
`

const handleColorType = (color?: string) => {
  switch (color) {
    case 'previous':
      return '#52C372'
    case 'current':
      return '#3097E2'
    default:
      return '#C4C4C4'
  }
}

export const StatusItem = styled.div<{ color?: string }>`
  width: 20px;
  height: 6px;
  background: ${({ color }) => handleColorType(color)};
  border-radius: 8px;
`

export const CloseBox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
`

export const ModalDeclineContent = styled.div`
  padding: 18px;
  height: 100%;
`
