/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { format, fromUnixTime } from 'date-fns'
import { Dispatch } from 'redux'
import {
  addPointStoreAction,
  resetOnboardingAction,
} from 'src/redux/reducers/storeOnboardingReducer'
import { DefaultRootState } from 'src/redux/store'
import { getCepAddressService, getGoogleAddress } from 'src/services/GeoService'
import { postStore } from 'src/services/StoresService'
import { unMaskOnlyNumber } from 'src/util/helpers/string'
import { mutate } from 'swr'

const useStoreOnboarding = () => {
  const alert = useAlert()
  const dispatch = useDispatch()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [createStoreLoading, setCreateStoreLoading] = useState(false)

  const handleLeave = useCallback(() => {
    history.goBack()
    dispatch(resetOnboardingAction())
  }, [history])

  const getCepAddress = async (cep: string) => {
    try {
      const { data } = await getCepAddressService(cep)

      const params =
        `${data.logradouro}+${data.bairro},+${data.localidade},+${data.uf}`.replace(
          ' ',
          '+'
        )
      const { data: googleResp } = await getGoogleAddress(params)

      const lat = googleResp.data.features[0].center[1]
      const lng = googleResp.data.features[0].center[0]

      dispatch(addPointStoreAction(lat, lng))

      return data
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }

  const createStore = () => {
    return async (_dispatch: Dispatch, getState: () => DefaultRootState) => {
      try {
        const { storeOnboarding } = getState()

        if (storeOnboarding.self_delivery_time === 0) {
          alert.error('O tempo de entrega não pode ser zero')
          return
        }

        const deliveryFee =
          Number(unMaskOnlyNumber(storeOnboarding.self_delivery_fee)) / 100

        const formattedHoursClose = storeOnboarding.openingHours.hours
          .filter((item) => item.short !== 'fer')
          .reduce((acc, item) => {
            const modifield = {
              ...acc,
              [item.short]: [
                format(fromUnixTime(item.open), 'HH:mm'),
                format(fromUnixTime(item.close), 'HH:mm'),
              ],
            }
            return modifield
          }, [])

        const payload = {
          name: storeOnboarding.name,
          cnpj: storeOnboarding.cnpj.replace(/\D/g, ''),
          customer: storeOnboarding.customer,
          allow_farmazon_delivery: storeOnboarding.allow_farmazon_delivery,
          self_delivery: storeOnboarding.self_delivery,
          self_delivery_range: storeOnboarding.self_delivery
            ? storeOnboarding.self_delivery_range
            : 0,
          self_delivery_fee: deliveryFee,
          self_delivery_time: storeOnboarding.self_delivery_time,
          address: storeOnboarding.location,
          contact_phones: storeOnboarding.contact_phones,
          opening_hours: {
            ...(storeOnboarding.openingHours.notClose
              ? { all_week: ['00:00', '23:59'] }
              : formattedHoursClose),
          },
        }

        await postStore(payload)
        mutate('/admin/stores')

        alert.success('Loja criada com sucesso')
        handleLeave()
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      }
    }
  }

  const handleCreateStore = async () => {
    try {
      setCreateStoreLoading(true)

      await dispatch(createStore())
    } finally {
      setCreateStoreLoading(false)
    }
  }

  return {
    loading,
    createStoreLoading,
    getCepAddress,
    setLoading,
    handleLeave,
    handleCreateStore,
  }
}

export default useStoreOnboarding
