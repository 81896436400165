import styled from 'styled-components'

export const Container = styled.div<{ error?: number }>`
  border: 1px solid
    ${({ error }) => (error ? 'rgba(227, 80, 43, 1)' : '#C4C4C4')};
  border-radius: 4px;
`

export const InputStyle = styled.input<{ error?: number }>`
  font: inherit;
  color: #444;
  width: calc(100% - 24px);
  border: 0;
  height: 28px;
  margin: 0;
  display: block;
  padding: 6px 12px;
  background: none;
  box-sizing: content-box;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
  ${({ error = false }) => error && 'color: rgba(227, 80, 43, 1);'}
`

export const TextError = styled.p`
  font-size: 11px;
  color: rgba(227, 80, 43, 1);
  text-align: initial;
  padding-top: 8px;
`

export const Label = styled.p`
  font-size: 10px;
  color: #474747;
  font-weight: 300;
  text-align: left;
`
