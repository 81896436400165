import { ReactNode } from 'react'

import { BackGround, Container, Content } from './styles'

interface ModalProps {
  children: ReactNode
  width?: number
  padding?: number
  paddingTop?: number
  marginTop?: number
  onClick?: () => void
}

function ModalEvent({
  children,
  width,
  padding,
  paddingTop,
  marginTop,
  onClick,
}: ModalProps) {
  return (
    <Container>
      <BackGround onClick={onClick} />
      <Content
        marginTop={marginTop}
        paddingTop={paddingTop}
        width={width}
        padding={padding}
      >
        {children}
      </Content>
    </Container>
  )
}

export default ModalEvent
