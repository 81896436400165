/* eslint-disable @typescript-eslint/no-unused-vars */
// import { Auth } from 'aws-amplify'
import Auth from '@aws-amplify/auth'
import axios from 'axios'
import { resetStoreAction } from 'src/redux/reducers/storeReducer'
import { store } from 'src/redux/store'
import { showAlert } from 'src/util/helpers/alert'

export const lalamoveInstance = axios.create({
  baseURL: process.env.REACT_APP_LALAMOVE,
})

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

const newApi = axios.create({
  baseURL: process.env.REACT_APP_NEW_BASE_URL,
})

const kpisApi = axios.create({
  baseURL: process.env.REACT_APP_KPIS_BASE_URL,
})

const ACCESS_TOKEN = '@farmazon-store/accesstoken'

export const getAccessToken = () => {
  const userStorage = localStorage.getItem(ACCESS_TOKEN)
  return userStorage
}

export const setAccessToken = (token: string, _keepLogin: boolean) => {
  localStorage.setItem(ACCESS_TOKEN, token)
}

export const clearAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN)
}

const requestInterceptor = (request: any) => {
  const token = getAccessToken()

  if (token) {
    request.headers.authorization = `Bearer ${token}`
  }
  return request
}

const errorReponse = async (error: any) => {
  const oldToken = getAccessToken()
  const originalRequest = error.config

  if (error?.response?.status === 401 && oldToken) {
    try {
      originalRequest._retry = true
      const { idToken } = await refreshToken()

      originalRequest.headers.authorization = `Beader ${idToken}`
      setAccessToken(idToken, true)

      return api(originalRequest)
    } catch {
      clearAccessToken()
      store.dispatch(resetStoreAction())
      window.location.href = '/'

      showAlert({
        type: 'error',
        message: 'Não conseguimos renovar sua sessão',
      })

      return Promise.reject(error)
    }
  }

  return Promise.reject(error)
}

newApi.interceptors.request.use(requestInterceptor)
newApi.interceptors.response.use((response) => {
  return response
}, errorReponse)

api.interceptors.request.use(requestInterceptor)
api.interceptors.response.use((response) => {
  return response
}, errorReponse)

async function refreshToken() {
  return new Promise<{ idToken: string }>((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((currentAuthenticatedUser) => {
        Auth.currentSession()
          .then((currentSession) => {
            currentAuthenticatedUser.refreshSession(
              currentSession.getRefreshToken(),
              (error: any, response: any) => {
                if (error) {
                  reject(error)
                } else {
                  console.log(response.idToken.jwtToken)
                  resolve({ idToken: response.idToken.jwtToken })
                }
              }
            )
          })
          .catch((error: any) => {
            reject(error)
          })
      })
      .catch((error: any) => {
        reject(error)
      })
  })
}

export { kpisApi, newApi }
export default api
