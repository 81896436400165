import { useMemo } from 'react'
import { Col, Row } from 'react-grid-system'
import { useSelector } from 'react-redux'

import AddIcon from 'src/assets/icons/icone-adicionar.svg'
import Deletar from 'src/assets/icons/icone-deletar.svg'
import DeleteIcon from 'src/assets/icons/icone-delete.svg'
import Editar from 'src/assets/icons/icone-editar.svg'
import OfferUser from 'src/assets/images/empty-user.svg'
import { LoadingSearch, ModalConfirm } from 'src/components/elements'
import { Button, CheckBox } from 'src/components/form'
import { Header, Table, Text, View, DataRow } from 'src/components/helper'
import useUser from 'src/hooks/useUser'
import { DefaultRootState } from 'src/redux/store'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

interface UserReducerProps {
  storeId: string
  name: string
}

function ListUser({ users }: any) {
  const { storeId } = useSelector<DefaultRootState, UserReducerProps>(
    (state) => ({
      storeId: state.store.pub_id,
      name: state.store.name,
    })
  )

  const {
    openModal,
    loading,
    userSelected,
    checkedAll,
    handleNavegateAdd,
    handleEditUser,
    handleToggleModal,
    handleDeleteUser,
    handleAllSelectedUser,
    handleDeleteSingleUser,
    handleSelectedUser,
  } = useUser(storeId)

  const enableDelete = useMemo(() => userSelected.length === 0, [userSelected])

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View>
            <Text size={25} fontWeight={700}>
              Equipe
            </Text>
          </View>
        </View>
        <View height='calc(100% - 100px)' style={shadowBox}>
          <Row>
            <Col xs={2}>
              <Button
                type='reset'
                label='Excluir'
                background='#FF0000'
                icone={DeleteIcon}
                height='40px'
                disabled={enableDelete}
                onClick={handleToggleModal}
              />
            </Col>
            <Col xs={3}>
              <Button
                type='reset'
                label='Novo membro'
                background='#3097E2'
                height='40px'
                icone={AddIcon}
                onClick={handleNavegateAdd}
              />
            </Col>
          </Row>

          {!users ? (
            <View height='100%' justify='center' align='center'>
              <LoadingSearch />
            </View>
          ) : (
            <View height='100%' mt={1.4}>
              <Table.Table>
                <thead>
                  <tr>
                    <Header width='40px'>
                      <CheckBox
                        background='#52C372'
                        onClick={handleAllSelectedUser(users)}
                        onChange={() => console.log()}
                        checked={checkedAll}
                        readOnly
                      />
                    </Header>
                    <Header>Nome</Header>
                    <Header>Telefone celular</Header>
                    <Header>E-mail</Header>
                    <Header>Senha temporaria</Header>
                    <Header>Grupo</Header>
                    <Header>Associado a uma loja</Header>
                    <Header>Ações</Header>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((item: any) => (
                      <tr key={item.pub_id}>
                        <DataRow>
                          <CheckBox
                            name='all'
                            background='#52C372'
                            checked={userSelected.includes(item.pub_id)}
                            onChange={() => console.log()}
                            onClick={handleSelectedUser(item.pub_id)}
                            readOnly
                          />
                        </DataRow>
                        <DataRow>
                          <Text textAlign='center' fontWeight={700} size={14}>
                            {item.name}
                          </Text>
                        </DataRow>
                        <DataRow>
                          <Text textAlign='center' size={12}>
                            {item.username}
                          </Text>
                        </DataRow>
                        <DataRow>
                          <Text textAlign='center' size={12}>
                            {item.email}
                          </Text>
                        </DataRow>
                        <DataRow>
                          <Text size={12}>{item.password}</Text>
                        </DataRow>
                        <DataRow>
                          <Text textAlign='center' size={12}>
                            Loja
                          </Text>
                        </DataRow>
                        <DataRow>
                          <Text textAlign='center' size={12}>
                            {item.is_associated ? 'Sim' : 'Não'}
                          </Text>
                        </DataRow>

                        <DataRow>
                          <View flex justify='center' align='center'>
                            <View mt={0.2} mr={0.2} cursor>
                              <img
                                src={Editar}
                                alt='Editar'
                                onClick={handleEditUser(item)}
                              />
                            </View>

                            <View cursor>
                              <img
                                src={Deletar}
                                alt='Deletar'
                                onClick={handleDeleteSingleUser(item.pub_id)}
                              />
                            </View>
                          </View>
                        </DataRow>
                      </tr>
                    ))}
                </tbody>
              </Table.Table>
            </View>
          )}
        </View>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={OfferUser}
          loading={loading}
          description='Tem certeza que deseja excluir esta pessoa?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          backgroundConfirm='#FF0000'
          handleConfirm={handleDeleteUser}
        />
      )}
    </>
  )
}

export default ListUser
