export const LOGS = {
  POST_NFE: {
    action: 'POST_NFE',
    change: 'Postar a NFE',
  },
  CHANGE_STATUS_MANUALLY: {
    action: 'CHANGE_STATUS_MANUALLY',
    change: 'Alteração de status manualmente',
  },
  CHANGE_FINAL_PRICE: {
    action: 'CHANGE_FINAL_PRICE',
    change: 'Alteração do preço final',
  },
  CHANGE_ADDRESS: {
    action: 'CHANGE_ADDRESS',
    change: 'Alteração de endereço',
  },
  CHANGE_DELIVERY_ADDRESS: {
    action: 'CHANGE_DELIVERY_ADDRESS',
    change: 'Alteração do endereço de entrega',
  },
  CHANGE_PAYMENT_TYPE: {
    action: 'CHANGE_PAYMENT_TYPE',
    change: 'Alteração do tipo de pagamento',
  },
  RESTART_STORE_INVITATION: {
    action: 'RESTART_STORE_INVITATION',
    change: 'Reiniciar convites',
  },
  CHANGE_STORE_INVITATION: {
    action: 'CHANGE_STORE_INVITATION',
    change: 'Alteração do convite para uma loja',
  },
  ACCEPT_ORDER: {
    action: 'ACCEPT_ORDER',
    change: 'Aceitar pedido',
  },
  DECLINE_ORDER: {
    action: 'DECLINE_ORDER',
    change: 'Recusar pedido',
  },
}
