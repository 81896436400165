import { Route, Switch, useRouteMatch } from 'react-router-dom'

import AddProduct from './AddProduct'
import ListProduct from './ListProduct'

function Products() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <ListProduct />
      </Route>
      <Route path={`${path}/adicionar/:productId?`}>
        <AddProduct />
      </Route>
    </Switch>
  )
}

export default Products
