export const HOMECARE_STATUS = [1, 2, 3, 4, 5, 6]

export const HOMECARE_STATUS_LABELS: Record<number, string> = {
  1: 'Em negociação',
  2: 'Pagamento aprovado',
  3: 'Preparando pedido',
  4: 'Entregador buscando produtos',
  5: 'Entregador a caminho',
  6: 'Entregue',
}
