import { Col, Row } from 'react-grid-system'

import Close from 'src/assets/icons/close.svg'
import AddIcon from 'src/assets/icons/icone-adicionar.svg'
import Documento from 'src/assets/icons/icone-documento.svg'
import {
  DropDownMenu,
  LoadingSearch,
  MenuItem,
  ModalCoupon,
} from 'src/components/elements'
import { Button } from 'src/components/form'
import { DataRow, Header, Table, Text, View } from 'src/components/helper'
import useCoupon from 'src/hooks/useCoupon'
import { useFetch } from 'src/hooks/useFetch'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

function Coupons() {
  const { data } = useFetch('/orders/admin/list/discount-coupon')

  const {
    loading,
    openModal,
    dataCoupoun,
    handleToggleModal,
    handleEditCoupon,
    handleRemoveCoupon,
    handleSubmitCoupon,
  } = useCoupon()

  return (
    <>
      <Container>
        <View pb={2}>
          <Row>
            <Col xs={9}>
              <Text size={25} fontWeight={700}>
                Cupons
              </Text>
            </Col>
            <Col xs={3}>
              <Button
                label='Adicionar cupom'
                background='#3097E2'
                height='40px'
                icone={AddIcon}
                onClick={handleToggleModal}
              />
            </Col>
          </Row>
        </View>

        <View height='calc(100% - 80px)' style={shadowBox}>
          {!data && (
            <View height='100%' justify='center' align='center'>
              <LoadingSearch />
            </View>
          )}

          {data && (
            <View height='100%' mt={1.4}>
              <Table.Table id='listSale'>
                <thead>
                  <tr>
                    <Header textAlign='left'>Tipo</Header>
                    <Header textAlign='left'>Valor</Header>
                    <Header textAlign='left'>Código</Header>
                    <Header textAlign='left'>Data expiração</Header>
                    <Header textAlign='left'>Ações</Header>
                  </tr>
                </thead>
                <tbody>
                  {data.Items.map((item: any, idx: number) => (
                    <Table.Row key={idx}>
                      <DataRow>
                        <Text size={15} fontWeight={500}>
                          {item.type}
                        </Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>{item.value}</Text>
                      </DataRow>
                      <DataRow>
                        <Text size={15}>{item.code}</Text>
                      </DataRow>

                      <DataRow>
                        <Text size={15}>{item.expiration}</Text>
                      </DataRow>
                      <DataRow textAlign='right'>
                        <View mt={0.2} mr={0.2} cursor>
                          <DropDownMenu>
                            <MenuItem
                              source={Documento}
                              label='Editar'
                              onClick={handleEditCoupon(item)}
                            />
                            <MenuItem
                              source={Close}
                              label='Remover'
                              onClick={handleRemoveCoupon(item.pub_id)}
                            />
                          </DropDownMenu>
                        </View>
                      </DataRow>
                    </Table.Row>
                  ))}
                </tbody>
              </Table.Table>
            </View>
          )}
        </View>
      </Container>

      {openModal && (
        <ModalCoupon
          data={dataCoupoun}
          loading={loading}
          onClose={handleToggleModal}
          onSubmit={handleSubmitCoupon}
        />
      )}
    </>
  )
}

export default Coupons
