import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory, useRouteMatch } from 'react-router'

import {
  deleteStoreSeller,
  postStoreSeller,
  putStoreSeller,
  SellerProps,
} from 'src/services/StoreService'
import { eventGA } from 'src/util/gtag'
import { mutate } from 'swr'

const useUser = (storeId?: string) => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const alert = useAlert()

  const [loading, setLoading] = useState(false)
  const [openModal, setModal] = useState(false)
  const [userSelected, setUserSelected] = useState<string[]>([])
  const [checkedAll, setCheckAll] = useState(false)

  const handleNavegateAdd = () => {
    eventGA('add_membro')
    history.push('/users/addUser')
  }

  const handleConfirmCancel = () => {
    history.push('/users')
  }

  const handleEditUser = (data: any) => () => {
    eventGA('editar_membro')
    history.push({
      pathname: `${path}/addUser/${data.pub_id}`,
      state: data,
    })
  }

  const handleUpdateUser = (userId: string, data: Partial<SellerProps>) => {
    return async () => {
      try {
        setLoading(true)

        if (data.username) {
          const formatUsername = `+55${data.username.replace(/\D/g, '')}`
          data.username = formatUsername
        }

        await putStoreSeller(userId, data)

        mutate('/sales-center/stores/sellers')
        alert.success('Pessoa atualizado com sucesso')
        setTimeout(() => {
          history.push('/users')
        }, 200)
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleCreateUser = (data: SellerProps) => {
    return async () => {
      try {
        setLoading(true)

        const formatUsername = `+55${data.username.replace(/\D/g, '')}`
        data.username = formatUsername

        await postStoreSeller(data)
        eventGA('cadastrar_membro')
        mutate('/sales-center/stores/sellers')

        alert.success('Pessoa salvo com sucesso')
        setTimeout(() => {
          history.push('/users')
        }, 200)
      } catch (error: any) {
        alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleDeleteUser = async () => {
    try {
      if (!storeId) {
        throw new Error('ID da loja é necessário para essa ação.')
      }
      setLoading(true)
      await deleteStoreSeller(userSelected)
      eventGA('excluir_membro')
      setUserSelected([])
      mutate('/sales-center/stores/sellers')
      alert.success('Pessoa excluído com sucesso')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setModal(false)
      setLoading(false)
    }
  }

  const handleToggleModal = () => {
    eventGA('cancelar_membro')
    setModal((state) => !state)
  }

  const handleAllSelectedUser = (users: SellerProps[]) => {
    return () => {
      setCheckAll(!checkedAll)
      if (checkedAll) setUserSelected([])
      else setUserSelected(users.map((item) => item.pub_id ?? ''))
    }
  }

  const handleSelectedUser = (id: string) => () => {
    const index = userSelected.indexOf(id)
    if (index > -1) setUserSelected(userSelected.filter((item) => item !== id))
    else setUserSelected([...userSelected, id])
  }

  const handleDeleteSingleUser = (id: string) => () => {
    setUserSelected([id])
    setModal(!openModal)
  }

  return {
    loading,
    openModal,
    userSelected,
    checkedAll,
    handleNavegateAdd,
    handleUpdateUser,
    handleCreateUser,
    handleToggleModal,
    handleConfirmCancel,
    handleDeleteUser,
    handleEditUser,
    handleAllSelectedUser,
    handleSelectedUser,
    handleDeleteSingleUser,
  }
}

export default useUser
