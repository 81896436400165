import { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Svg from 'react-inlinesvg'
import { useSelector } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'

import { Collapse } from '@material-ui/core'
import IconArrowDropDown from 'src/assets/icons/icon-arrow-drop-down.svg'
import IconNotification from 'src/assets/icons/icon-notification.svg'
import Logout from 'src/assets/icons/icone-logout.svg'
import MenuCardiology from 'src/assets/images/cardiology.svg'
import Logo from 'src/assets/images/logo-farmazon-white.svg'
import MenuConfiguracao from 'src/assets/images/menu-configuracao.svg'
import MenuCupom from 'src/assets/images/menu-cupom.svg'
// import MenuDestaques from 'src/assets/images/menu-destaques.svg'
import MenuHome from 'src/assets/images/menu-home.svg'
import MenuLoja from 'src/assets/images/menu-loja.svg'
import MenuPedido from 'src/assets/images/menu-pedidos.svg'
import MenuProduct from 'src/assets/images/menu-produtos.svg'
import UsuarioMenu from 'src/assets/images/menu-usuario.svg'
import { Header, Menu } from 'src/components/elements'
import {
  Container,
  MainContent,
  MenuLink,
  Text,
  View,
} from 'src/components/helper'
import useAuth from 'src/hooks/useAuth'
import { DefaultRootState } from 'src/redux/store'

import pack from '../../../package.json'
import Coupons from '../Coupons'
import CreateStore from '../CreateStore'
import CreateUser from '../CreateUser'
import { Highlights } from '../Highlights'
import Homecare from '../Homecare'
import HomecareDetails from '../HomecareDetails'
import NotificationBroadcast from '../Notifications/Broadcast'
// import NotificationPerBehavior from '../Notifications/PerBehavior'
// import NotificationPerRegion from '../Notifications/PerRegion'
import ListDevices from '../Notifications/ListDevices'
import ListNotifications from '../Notifications/ListNotifications'
import Products from '../Products'
import Banks from '../SaleCenter/Banks'
import Order from '../SaleCenter/Order'
import SaleCenterOrderDetail from '../SaleCenter/Order/Detail'
import StoresSaleCenter from '../SaleCenter/Store'
import User from '../SaleCenter/User'
import Sales from '../Sales'
import Detail from '../Sales/Detail'
import Setup from '../Setup'
import Stores from '../Stores'
import MapStore from '../Stores/map'
import StoresDetail from '../Stores/StoreDetail'
import Users from '../Users'
import Main from './Main'

const Home = () => {
  const { store } = useSelector<DefaultRootState, DefaultRootState>(
    (state) => state
  )

  const [menuDropDown, setMenuDropDown] = useState('')

  const { handleLogout } = useAuth()

  const handleOpenMenu = (menu: string) => {
    return () => {
      setMenuDropDown((old) => {
        if (old.split('.')[0] === menu) return ''

        return menu
      })
    }
  }

  const handleOpenSubmenu = (submenu: string) => {
    return () => {
      setMenuDropDown((old) => {
        const [prefix, oldSubmenu] = old.split('.')

        if (oldSubmenu === submenu) return prefix

        return `${prefix}.${submenu}`
      })
    }
  }

  const renderMenu = () => {
    if (store.group.includes('store_sales_center')) {
      return (
        <>
          <Link to='/pedidos'>
            <MenuLink selected={location.pathname.includes('/pedidos')}>
              <Svg src={MenuPedido} title='Pedidos' />
              <Text color='#474747' size={14} ml={1}>
                Pedidos
              </Text>
            </MenuLink>
          </Link>

          <Link to='/stores'>
            <MenuLink selected={location.pathname.includes('/stores')}>
              <Svg src={MenuLoja} title='Lojas' />
              <Text color='#474747' size={14} ml={1}>
                Lojas
              </Text>
            </MenuLink>
          </Link>

          <Link to='/users'>
            <MenuLink selected={location.pathname.includes('/users')}>
              <Svg src={UsuarioMenu} title='Usuários' />
              <Text color='#F9FFFA' size={14} ml={1}>
                Equipe
              </Text>
            </MenuLink>
          </Link>
        </>
      )
    }

    return (
      <>
        <Link to='/pedidos'>
          <MenuLink selected={location.pathname.includes('/pedidos')}>
            <Svg src={MenuPedido} title='Pedidos' />
            <Text color='#474747' size={14} ml={1}>
              Pedidos
            </Text>
          </MenuLink>
        </Link>

        <Link to='/homecare'>
          <MenuLink selected={location.pathname.includes('/homecare')}>
            <Svg src={MenuCardiology} title='Home care' />
            <Text color='#474747' size={14} ml={1}>
              Home care
            </Text>
          </MenuLink>
        </Link>

        <Link to='/lojas'>
          <MenuLink selected={location.pathname.includes('/lojas')}>
            <Svg src={MenuLoja} title='Lojas' />
            <Text color='#474747' size={14} ml={1}>
              Lojas
            </Text>
          </MenuLink>
        </Link>

        <Link to='/produtos'>
          <MenuLink selected={location.pathname.includes('/produtos')}>
            <Svg src={MenuProduct} title='Produtos' />
            <Text color='#474747' size={14} ml={1}>
              Produtos
            </Text>
          </MenuLink>
        </Link>

        {/* <Link to='/destaques'>
          <MenuLink selected={location.pathname.includes('/destaques')}>
            <Svg src={MenuDestaques} title='Destaques' />
            <Text color='#474747' size={14} ml={1}>Destaques</Text>
          </MenuLink>
        </Link> */}

        <Link to='/cupons'>
          <MenuLink selected={location.pathname.includes('/cupons')}>
            <Svg src={MenuCupom} title='Cupons' />
            <Text color='#474747' size={14} ml={1}>
              Cupons
            </Text>
          </MenuLink>
        </Link>

        <Link to='/clientes'>
          <MenuLink selected={location.pathname === '/clientes'}>
            <Svg src={UsuarioMenu} title='Clientes' />
            <Text color='#474747' size={14} ml={1}>
              Clientes
            </Text>
          </MenuLink>
        </Link>

        <View>
          <MenuLink onClick={handleOpenMenu('notifications')}>
            <Svg
              width={25}
              height={25}
              src={IconNotification}
              title='Clientes'
            />
            <Text
              color='#474747'
              size={14}
              style={{ marginRight: 'auto' }}
              ml={1}
            >
              Notificações
            </Text>

            <Svg
              width={25}
              height={25}
              src={IconArrowDropDown}
              title='icon-arrow-drop-down'
            />
          </MenuLink>

          <Collapse
            in={menuDropDown.split('.')[0] === 'notifications'}
            timeout='auto'
            unmountOnExit
          >
            <View ml={1.5}>
              <MenuLink onClick={handleOpenSubmenu('clients')}>
                <Text
                  color='#474747'
                  style={{ marginRight: 'auto' }}
                  size={14}
                  ml={1}
                >
                  Clientes
                </Text>

                <Svg
                  width={25}
                  height={25}
                  src={IconArrowDropDown}
                  title='icon-arrow-drop-down'
                />
              </MenuLink>
              <Collapse
                in={menuDropDown.split('.')[1] === 'clients'}
                timeout='auto'
                unmountOnExit
              >
                <View ml={1.5}>
                  <Link to='/notificacoes/clientes/logs'>
                    <MenuLink
                      selected={
                        location.pathname === '/notificacoes/clientes/logs'
                      }
                    >
                      <Text
                        color='#474747'
                        size={14}
                        style={{ marginRight: 'auto' }}
                        ml={1}
                      >
                        Listar notificações
                      </Text>
                    </MenuLink>
                  </Link>
                  <Link to='/notificacoes/clientes/geral'>
                    <MenuLink
                      selected={
                        location.pathname === '/notificacoes/clientes/geral'
                      }
                    >
                      <Text color='#474747' size={14} ml={1}>
                        Enviar para todos
                      </Text>
                    </MenuLink>
                  </Link>
                  {/* <Link to='/notificacoes/clientes/por_region'>
                    <MenuLink selected={location.pathname === '/notificacoes/clientes/por_region'}>
                      <Text color='#474747' size={14} ml={1}>Por região</Text>
                    </MenuLink>
                  </Link>
                  <Link to='/notificacoes/clientes/por_comportamento'>
                    <MenuLink selected={location.pathname === '/notificacoes/clientes/por_comportamento'}>
                      <Text color='#474747' size={14} ml={1}>Por comportamento</Text>
                    </MenuLink>
                  </Link> */}
                </View>
              </Collapse>

              {/* <MenuLink onClick={handleOpenSubmenu('stores')} selected={location.pathname.includes('/notificacoes/lojas')}>
                <Text color='#474747' style={{ marginRight: 'auto' }} size={14} ml={1}>Lojas</Text>

                <Svg width={25} height={25} src={IconArrowDropDown} title='icon-arrow-drop-down' />
              </MenuLink>
              <Collapse
                in={menuDropDown.split('.')[1] === 'stores'}
                timeout='auto'
                unmountOnExit
              >
                <View ml={1.5}>
                  <Link to='/notificacoes/lojas/geral'>
                    <MenuLink selected={location.pathname === '/notificacoes/lojas/geral'}>
                      <Text color='#474747' size={14} ml={1}>Para todos</Text>
                    </MenuLink>
                  </Link>
                  <Link to='/notificacoes/lojas/por_estado'>
                    <MenuLink selected={location.pathname === '/notificacoes/lojas/por_estado'}>
                      <Text color='#474747' size={14} ml={1}>Por estado</Text>
                    </MenuLink>
                  </Link>
                  <Link to='/notificacoes/lojas/por_region'>
                    <MenuLink selected={location.pathname === '/notificacoes/lojas/por_region'}>
                      <Text color='#474747' size={14} ml={1}>Por região</Text>
                    </MenuLink>
                  </Link>
                  <Link to='/notificacoes/lojas/por_comportamento'>
                    <MenuLink selected={location.pathname === '/notificacoes/lojas/por_comportamento'}>
                      <Text color='#474747' size={14} ml={1}>Por comportamento</Text>
                    </MenuLink>
                  </Link>
                </View>
              </Collapse> */}
            </View>
          </Collapse>
        </View>

        <Link to='/configuracao'>
          <MenuLink selected={location.pathname.includes('/configuracao')}>
            <Svg src={MenuConfiguracao} title='Configurações' />
            <Text color='#474747' size={14} ml={1}>
              Configurações
            </Text>
          </MenuLink>
        </Link>
      </>
    )
  }

  const renderRoutes = () => {
    if (store.group.includes('store_sales_center')) {
      return (
        <Switch>
          <Route exact path='/' component={Order} />
          <Route exact path='/pedidos' component={Sales} />
          <Route path='/stores' component={StoresSaleCenter} />
          <Route path='/users' component={User} />
          <Route
            path='/pedidos/detalhe/:orderId'
            component={SaleCenterOrderDetail}
          />
          <Route path='/contas_bancarias/:storeId' component={Banks} />
        </Switch>
      )
    }

    return (
      <Switch>
        <Route exact path='/' component={Main} />
        <Route path='/pedidos/detalhe/:orderId' component={Detail} />
        <Route exact path='/pedidos' component={Sales} />
        <Route exact path='/lojas' component={Stores} />
        <Route exact path='/lojas/cadastrar' component={CreateStore} />
        <Route path='/lojas/detalhe/:storeId' component={StoresDetail} />
        <Route path='/lojas/mapa' component={MapStore} />
        <Route path='/produtos' component={Products} />
        <Route path='/cupons' component={Coupons} />
        <Route exact path='/clientes' component={Users} />
        <Route path='/clientes/cadastrar' component={CreateUser} />
        <Route path='/configuracao' component={Setup} />
        <Route
          path='/notificacoes/clientes/logs'
          component={ListNotifications}
        />
        <Route
          path='/notificacoes/clientes/geral'
          component={NotificationBroadcast}
        />
        <Route
          path='/notificacoes/clientes/dispositivos'
          component={ListDevices}
        />
        <Route exact path='/homecare' component={Homecare} />
        <Route path='/homecare/:id' component={HomecareDetails} />
        {/* <Route path='/notificacoes/clientes/por_region' component={NotificationPerRegion} />
        <Route path='/notificacoes/clientes/por_comportamento' component={NotificationPerBehavior} /> */}
        <DndProvider backend={HTML5Backend}>
          <Route path='/destaques' component={Highlights} />
        </DndProvider>
      </Switch>
    )
  }

  return (
    <>
      <Container>
        <Header>
          <img src={Logo} alt='Farmazon - sempre on' width={80} height={30} />
        </Header>

        <MainContent>
          <Menu>
            <View style={{ padding: 20 }}>
              <View flex justify='space-between'>
                <View>
                  <Text color='#474747' fontWeight={600} size={13}>
                    {store.userName}
                  </Text>
                  <Text size={11} fontWeight={400} color='#474747'>
                    {store.email}
                  </Text>
                  <Text size={11} fontWeight={400} color='#474747'>
                    {store.phone}
                  </Text>
                </View>
              </View>
            </View>

            <View>
              <Link to='/'>
                <MenuLink selected={location.pathname === '/'}>
                  <Svg src={MenuHome} title='Principal' />
                  <Text color='#474747' size={14} ml={1}>
                    Principal
                  </Text>
                </MenuLink>
              </Link>

              {renderMenu()}
            </View>

            <View style={{ marginTop: 'auto' }}>
              <MenuLink onClick={handleLogout}>
                <img src={Logout} alt='Logout' />
                <Text color='#F48D42' size={13} ml={1}>
                  Sair
                </Text>
              </MenuLink>

              <View style={{ padding: 20 }}>
                <View mb={1}>
                  <Text color='#474747' size={11}>
                    Versão: {pack.version}
                  </Text>
                </View>
                <Text color='#474747' size={11}>
                  Copyright © 2022 Farmazon Todos os direitos reservados.
                </Text>
              </View>
            </View>
          </Menu>

          <View style={{ padding: 30, width: '100%', overflowY: 'auto' }}>
            {renderRoutes()}
          </View>
        </MainContent>
      </Container>
    </>
  )
}

export default Home
