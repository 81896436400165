import { Col, Row } from 'react-grid-system'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import AddIcon from 'src/assets/icons/icone-adicionar.svg'
import Deletar from 'src/assets/icons/icone-deletar.svg'
import EmptyBankImage from 'src/assets/images/empty-bank.svg'
import { ModalConfirm } from 'src/components/elements'
import { Button } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useBank from 'src/hooks/useBank'
import { useNewFetch } from 'src/hooks/useFetch'
import { DefaultRootState } from 'src/redux/store'
import {
  BankAccountProps,
  getSWRAllBankAccount,
} from 'src/services/CardsService'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

enum bankTypes {
  'checking' = 'Conta Corrente',
  'savings' = 'Conta Poupança',
}

interface ListBankReducerProps {
  bankNumbers: Array<{
    name: string
    cod: string
  }>
}

const ListBank = () => {
  const { storeId } = useParams<any>()

  const { bankNumbers } = useSelector<DefaultRootState, ListBankReducerProps>(
    (state) => ({
      bankNumbers: state.app.setup?.store.banks ?? [],
    })
  )
  const { data: banks } = useNewFetch<BankAccountProps[]>(
    `/stores/${storeId}/banking-accounts`,
    getSWRAllBankAccount
  )

  const {
    openModal,
    loading,
    handleNavegateAdd,
    handleToggleModal,
    handleDeleteSingleBank,
    handleDeleteBankAccount,
  } = useBank(storeId)

  return (
    <>
      <Container>
        <Row>
          <Col xs={10}>
            <View>
              <Text size={25} fontWeight={700}>
                Dados bancários
              </Text>
            </View>
          </Col>
          <Col xs={2}>
            <Button
              label='Nova conta'
              background='#3097E2'
              height='40px'
              icone={AddIcon}
              onClick={handleNavegateAdd}
            />
          </Col>
        </Row>

        <section style={{ marginTop: '1.6rem' }}>
          {banks?.map((item, index) => (
            <View key={index} mt={1} style={shadowBox}>
              <Row align='center'>
                <Col>
                  <Text fontWeight={500} size={14}>
                    {item.name}
                  </Text>
                </Col>
                <Col>
                  <Text fontWeight={500} size={12}>
                    Tipo
                  </Text>
                  <Text fontWeight={300} size={14} mt={0.4}>
                    {bankTypes[item.type]}
                  </Text>
                </Col>
                <Col>
                  <Text fontWeight={500} size={12}>
                    Banco
                  </Text>
                  <Text fontWeight={300} size={14} mt={0.4}>
                    {item.bank_code} -{' '}
                    {
                      bankNumbers.find((bank) => bank.cod === item.bank_code)
                        ?.name
                    }
                  </Text>
                </Col>
                <Col>
                  <Text fontWeight={500} size={12}>
                    Agência
                  </Text>
                  <Text fontWeight={300} size={14} mt={0.4}>
                    {item.routing_number}
                  </Text>
                </Col>
                <Col>
                  <Text fontWeight={500} size={12}>
                    Conta
                  </Text>
                  <Text fontWeight={300} size={14} mt={0.4}>
                    {item.account_number}
                  </Text>
                </Col>
                <Col xs={1}>
                  <View flex>
                    <View cursor>
                      <img
                        src={Deletar}
                        alt='Deletar'
                        onClick={handleDeleteSingleBank(item.pub_id)}
                      />
                    </View>
                  </View>
                </Col>
              </Row>
            </View>
          ))}
        </section>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={EmptyBankImage}
          loading={loading}
          description='Tem certeza que deseja excluir esta conta?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          backgroundConfirm='#FF0000'
          handleConfirm={handleDeleteBankAccount}
        />
      )}
    </>
  )
}
export default ListBank
