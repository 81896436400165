import { useState } from 'react'
import { useAlert } from 'react-alert'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'

import { Button, InputText, TextArea } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { sendClientBroadcast } from 'src/services/Notification'

import { Container } from './styles'

const Broadcast = () => {
  const alert = useAlert()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [loading, setLoading] = useState(false)

  const handleOnSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true)

      await sendClientBroadcast({
        title: data.title,
        body: data.body,
      })

      reset()

      alert.success('Notificação enviada com sucesso')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  })

  return (
    <Container>
      <View flex justify='center'>
        <Col lg={5}>
          <View pb={2}>
            <Text size={25} fontWeight={700}>
              Enviar notificação para todos os clientes
            </Text>
          </View>

          <form onSubmit={handleOnSubmit}>
            <View textAlign='center' mt={2}>
              <InputText
                {...register('title', {
                  required: 'O título é obrigatório',
                })}
                type='text'
                name='title'
                label='Título'
                validationError={!!errors.title?.message}
                messageError={errors.title?.message}
              />
            </View>

            <View textAlign='center' mb={3} mt={2}>
              <TextArea {...register('body')} />
            </View>

            <View style={{ marginTop: 'auto' }}>
              <Row align='center'>
                <Col xs={12}>
                  <Button
                    label='Enviar'
                    background='#3097E2'
                    loading={loading}
                  />
                </Col>
              </Row>
            </View>
          </form>
        </Col>
      </View>
    </Container>
  )
}

export default Broadcast
