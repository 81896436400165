import { Container, Dot } from './styles'

interface DotsProps {
  length: number
  active: number
}

function Dots({ length = 0, active = 0 }: DotsProps) {
  return (
    <Container>
      {[...Array(length).keys()].map((item) => (
        <Dot
          key={item}
          step={item}
          current={active}
          active={item === active ? 1 : 0}
        />
      ))}
    </Container>
  )
}

export default Dots
