import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 30px;
  left: calc(50% - 300px);
  padding: 26px;
  width: 600px;
  height: 200px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 7%);
`

export const Text = styled.p`
  color: #474747;
`

export const Content = styled.div`
  display: flex;
`

export const Box = styled.div`
  flex: 1 0 auto;
  width: 0;
  padding-left: 30px;
`

export const ButtonCancel = styled.button`
  color: #3097e2;
  background-color: transparent;
  padding: 16px 20px;
  border: none;
  font-size: 16px;
  cursor: pointer;
`

export const ButtonConfirm = styled.button`
  background-color: #3097e2;
  padding: 16px 32px;
  margin-left: 20px;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  cursor: pointer;
`

export const BoxButton = styled.div`
  display: flex;
  justify-content: flex-end;
`
