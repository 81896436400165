import { forwardRef } from 'react'

import { Container } from './styles'

function Switch(
  { disabled = false, ...props }: React.InputHTMLAttributes<HTMLInputElement>,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  return (
    <Container disabled={disabled}>
      <label className='switch'>
        <input
          type='checkbox'
          ref={forwardedRef}
          disabled={disabled}
          {...props}
        />
        <span className='slider round' />
      </label>
    </Container>
  )
}

export default forwardRef(Switch)
