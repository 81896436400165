import queryString from 'query-string'

import api, { newApi } from './api'

interface AllListStoresProps {
  active?: boolean
  limit?: number
}

interface StoreUpdateProps {
  address: {
    lng: string
    lat: string
  }
  allow_bkc_delivery: boolean
}

export const getStoreInfo = async (storeId: string) =>
  newApi.get(`/admin/stores/${storeId}`)

export const postStore = async (payload: any) =>
  newApi.post('/admin/stores', payload)

export const getAllListStores = async (params: AllListStoresProps = {}) =>
  newApi.get(`/admin/stores?${queryString.stringify(params)}`)

export const getOrderPIN = async (id: string) =>
  api.get(`/stores/admin/activate-store-solicitation/${id}`)

export const getSendPIN = async (id: string) =>
  api.get(`/stores/admin/send-store-solicitation/${id}`)

export const getDeactiveStore = async (idStore: string) =>
  newApi.put(`/admin/stores/deactivate-store/${idStore}`)

export const deleteCancelOrderById = async (id: string) =>
  newApi.delete(`/admin/orders/${id}/cancel`)

export const updateLocationStore = async (
  storeId: string,
  data: StoreUpdateProps
) => newApi.put(`/admin/stores/update/${storeId}`, data)
