/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import { Container, Col, Row, Hidden } from 'react-grid-system'
import { useForm } from 'react-hook-form'

import Image from 'src/assets/images/farmazon-user.png'
import Logo from 'src/assets/images/logo-farmazon-black.svg'
import { AlertLogin } from 'src/components/elements'
import { Button, InputText } from 'src/components/form'
import {
  ImageResize,
  View,
  Text,
  ContainerAnimation,
} from 'src/components/helper'
import useAuth from 'src/hooks/useAuth'
import { formatPhone } from 'src/util/helpers/string'

import { loginAnimation } from 'src/styles'

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm()
  const { validation, loading, doSignUp } = useAuth()

  const handleOnSubmit = handleSubmit((data) => {
    doSignUp(data)
  })

  const formatMoneyInput = useCallback((event: any) => {
    setValue('username', formatPhone(event.target.value))
    clearErrors()
  }, [])

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row nogutter align='stretch' style={{ height: 'calc(100vh)' }}>
        <Hidden xs sm md>
          <Col md={8}>
            <ImageResize url={`url(${Image})`} />
          </Col>
        </Hidden>
        <Col xs={8} lg={4}>
          <Row
            align='center'
            justify='center'
            nogutter
            style={{ height: '100%' }}
          >
            <Col xs={8}>
              <ContainerAnimation {...loginAnimation}>
                <View textAlign='center' pt={4}>
                  <img src={Logo} alt='Farmazon logo' />
                </View>

                <View mt={2}>
                  <Text textAlign='center' fontWeight={500} size={25}>
                    Faça o seu cadastro
                  </Text>
                </View>

                <form onSubmit={handleOnSubmit} style={{ height: '100%' }}>
                  <View direction='column' height='100%'>
                    <View textAlign='center' mt={2}>
                      <InputText
                        {...register('name', {
                          required: 'O Nome é obrigatório',
                        })}
                        type='text'
                        name='name'
                        label='Seu nome'
                        validationError={errors.name}
                        messageError={errors.name?.message}
                      />
                    </View>

                    <View textAlign='center' mt={2}>
                      <InputText
                        {...register('email', {
                          required: 'O E-mail é obrigatório',
                        })}
                        type='text'
                        name='email'
                        label='E-mail'
                        validationError={errors.email}
                        messageError={errors.email?.message}
                      />
                    </View>

                    <View textAlign='center' mt={2}>
                      <InputText
                        {...register('username', {
                          required: 'O telefone é obrigatório',
                          pattern: {
                            value: /\d{5}-\d{4}/,
                            message: 'Digite seu número de telefone com DDD.',
                          },
                        })}
                        type='text'
                        name='username'
                        label='Telefone celular'
                        validationError={errors.username}
                        messageError={errors.username?.message}
                        onChange={formatMoneyInput}
                      />
                    </View>

                    <View textAlign='center' mt={2} mb={1}>
                      <InputText
                        {...register('password', {
                          required: 'Senha é obrigatória',
                          minLength: {
                            value: 8,
                            message: 'A senha deve ter 8 letras e números.',
                          },
                        })}
                        type='password'
                        name='password'
                        label='Senha'
                        validationError={errors.password}
                        messageError={errors.password?.message}
                      />
                    </View>

                    <View style={{ marginTop: 'auto' }}>
                      <Row align='center'>
                        <Col xs={12}>
                          <Button
                            label='Fazer cadastro'
                            background='#3097E2'
                            loading={loading}
                          />
                        </Col>
                      </Row>
                    </View>
                  </View>
                </form>
              </ContainerAnimation>
            </Col>
          </Row>

          {validation && (
            <AlertLogin message='Ops! Seu login ou senha estão incorretos.' />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Signup
