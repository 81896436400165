import styled from 'styled-components'

export const Container = styled.div<{ error?: number }>`
  border-bottom: 1px solid
    ${({ error }) => (error ? 'rgba(227, 80, 43, 1)' : 'rgba(0, 0, 0, 0.42)')};
`

export const Input = styled.input<{ error?: number }>`
  font: inherit;
  color: #444;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  background: none;
  box-sizing: content-box;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
  ${({ error = false }) => error && 'color: rgba(227, 80, 43, 1);'}
`

export const TextError = styled.p`
  font-size: 11px;
  color: rgba(227, 80, 43, 1);
  text-align: initial;
  padding-top: 8px;
`

export const Label = styled.p`
  font-size: 10px;
  color: #474747;
  font-weight: 300;
  text-align: left;
`
