import styled from 'styled-components'

export const Container = styled.div<{ color: string }>`
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border-left: 18px solid ${({ color = '#F48D42' }) => color};
  padding: 18px 14px 12px 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`
