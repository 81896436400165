import styled from 'styled-components'

interface ContainerProps {
  index: number
  isDragging: boolean
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;

  padding: 10px !important;
  margin-left: ${({ index }) => (index === 0 ? 0 : '20px')};

  opacity: ${({ isDragging }) => (isDragging ? 0.3 : 1)};
  overflow: visible !important;

  cursor: move;
`

export const DeleteButton = styled.button`
  position: absolute;
  right: -6px;
  top: -6px;

  border: none;
  border-radius: 50%;

  width: 18px;
  height: 18px;
  background-color: #ff0000;

  cursor: pointer;

  &::before {
    content: '×';
    position: absolute;
    color: #fff;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    font-size: 18px;
  }
`
