import styled from 'styled-components'

export const BackGround = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
`

interface ContentProps {
  width?: number
  padding?: number
  paddingTop?: number
  marginTop?: number
}

export const Content = styled.div<ContentProps>`
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: auto;
  padding: ${({ padding = 20 }) => `${padding}px`};
  border: 1px solid #888;
  width: ${({ width = 25 }) => `${width}rem`};
  max-height: 90%;
  overflow-y: auto;
  position: fixed;
  z-index: 3;
  left: 50%;
  top: 0;
  margin-top: ${({ marginTop = 1 }) => `${marginTop}rem`};
  transform: translateX(-50%);
`
