/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'

import { Modal } from 'src/components/elements'
import { Button, InputText } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { DeliveryAddress } from 'src/hooks/useSale'
import { createLog } from 'src/services/log'
import { updateAddress } from 'src/services/OrderService'
import { formatCep } from 'src/util/helpers/string'
import { mutate } from 'swr'

interface EditAddressModalProps {
  orderId: string
  address: DeliveryAddress
  onClose: () => void
}

const EditAddressModal = ({
  orderId,
  onClose,
  address,
}: EditAddressModalProps) => {
  const alert = useAlert()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm<any>({
    defaultValues: {
      street: address.street,
      cep: address.cep,
      number: address.number,
      state: address.state,
      city: address.city,
      more: address.more,
      neighborhood: address.neighborhood,
    },
  })

  const [loading, setLoading] = useState(false)

  const handleOnSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true)

      await updateAddress(orderId, {
        ...data,
        cep: data.cep.replace(/\D/g, ''),
      })

      mutate(`/admin/orders/${orderId}`)

      await createLog({
        orderId,
        log: 'CHANGE_DELIVERY_ADDRESS',
      })
      reset()

      alert.success('Endereço editado com sucesso')
      onClose()
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  })

  const onChangeCep = useCallback((event: any) => {
    setValue('cnpj', formatCep(event.target.value))
    clearErrors()
  }, [])

  return (
    <Modal onClose={onClose} width={40} paddingTop={4}>
      <View padding={1}>
        <Text fontWeight={900} size={18}>
          Editar endereço
        </Text>
      </View>

      <View
        style={{ width: '100%', height: '1px', backgroundColor: '#000000' }}
      />

      <View mt={1} pl={1} pr={1} style={{ overflow: 'auto' }}>
        <form onSubmit={handleOnSubmit}>
          <View textAlign='center' mt={2}>
            <InputText
              {...register('street', {
                required: 'O logradouro é obrigatório',
              })}
              type='text'
              name='street'
              label='Logradouro'
              validationError={errors.street}
              messageError={errors.street?.message}
            />
          </View>

          <View textAlign='center' mt={2}>
            <InputText
              {...register('number', {
                required: 'O número é obrigatório',
              })}
              type='text'
              name='number'
              label='Número'
              validationError={errors.number}
              messageError={errors.number?.message}
            />
          </View>

          <View textAlign='center' mt={2}>
            <InputText
              {...register('more')}
              type='text'
              name='more'
              label='Complemento'
              validationError={errors.more}
              messageError={errors.more?.message}
            />
          </View>

          <View textAlign='center' mt={2}>
            <InputText
              {...register('cep', {
                required: 'O CEP é obrigatório',
              })}
              type='text'
              name='cep'
              label='CEP'
              validationError={errors.cep}
              onChange={onChangeCep}
              messageError={errors.cep?.message}
            />
          </View>

          <View textAlign='center' mt={2}>
            <InputText
              {...register('neighborhood', {
                required: 'A bairro é obrigatória',
              })}
              type='text'
              name='neighborhood'
              label='Bairro'
              validationError={errors.neighborhood}
              messageError={errors.neighborhood?.message}
            />
          </View>

          <View textAlign='center' mt={2}>
            <InputText
              {...register('city', {
                required: 'A cidade é obrigatória',
              })}
              type='text'
              name='city'
              label='Cidade'
              validationError={errors.city}
              messageError={errors.city?.message}
            />
          </View>

          <View textAlign='center' mt={2} mb={4}>
            <InputText
              {...register('state', {
                required: 'O estado é obrigatório',
              })}
              type='text'
              name='state'
              label='Estado'
              validationError={errors.state}
              messageError={errors.state?.message}
            />
          </View>

          <View style={{ marginTop: 'auto' }}>
            <Row align='center'>
              <Col xs={12}>
                <Button
                  label='Alterar'
                  background='#3097E2'
                  loading={loading}
                />
              </Col>
            </Row>
          </View>
        </form>
      </View>
    </Modal>
  )
}

export default EditAddressModal
