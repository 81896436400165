import styled from 'styled-components'

export const Container = styled.div<{
  borderWidthCheck?: string
  rotate?: number
}>`
  display: inline-block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  height: 18px;
  width: 18px;

  .checkmark {
    display: block;
    position: absolute;
    border: 2px solid #aaaaaa;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    top: 30px;
    left: 20px;
    z-index: 5;
  }

  .checkmark::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 3px;
    left: 3px;
    margin: auto;
  }
`

export const RadioInput = styled.input<{ background?: string }>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    border: 2px solid #52c372;
  }
  &:checked ~ .checkmark::before {
    background: #52c372;
  }

  &:checked ~ .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
`
