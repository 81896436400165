import useSWR from 'swr'

import api, { newApi } from '../services/api'

export function useFetch<T = any>(url: any, parans = {}, options = {}) {
  const { data, error, mutate } = useSWR(
    url,
    async (url: string) => {
      const response = await api.get<T>(url, {
        params: parans,
      })
      return response.data
    },
    options
  )
  return { data, error, mutate }
}

export function useNewFetch<T = any>(url: any, parans = {}, options = {}) {
  const { data, error, mutate } = useSWR(
    url,
    async (url: string) => {
      const response = await newApi.get<T>(url, {
        params: parans,
      })
      return response.data
    },
    options
  )
  return { data, error, mutate }
}

export function useFetchFilter(url: any, options = {}) {
  const { data, error, mutate } = useSWR(
    url,
    async (url: string, params) => {
      const response = await api.get(url, {
        params: params,
      })
      return response.data
    },
    options
  )
  return { data, error, mutate }
}

export function useFetchFilterNewApi(url: any, options = {}) {
  const { data, error, mutate } = useSWR(
    url,
    async (url: string, params) => {
      const response = await newApi.get(url, {
        params: params,
      })
      return response.data
    },
    options
  )
  return { data, error, mutate }
}
