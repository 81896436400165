/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Col, Row } from 'react-grid-system'
import { useFieldArray, useForm } from 'react-hook-form'

import Deletar from 'src/assets/icons/icone-deletar.svg'
import { LoadingSearch } from 'src/components/elements'
import { Button, Input, Switch } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { getSetupConfig, updateSetupConfig } from 'src/services/SetupService'

import { Container } from './styles'

function Setup() {
  const { control, register, handleSubmit, setValue } = useForm()
  const {
    fields: fieldsCustumer,
    append: appendCustumer,
    remove: removeCustumer,
  } = useFieldArray({
    control,
    name: 'customer.static_pages',
  })

  const {
    fields: fieldsStore,
    append: appendStore,
    remove: removeStore,
  } = useFieldArray({
    control,
    name: 'store.static_pages',
  })

  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const alert = useAlert()

  useEffect(() => {
    getSetupConfig()
      .then((result) => {
        setLoading(true)

        const { data } = result

        setValue('default_range', data.default_range)
        setValue('max_range', data.max_range)
        setValue('max_total_percentage', data.max_total_percentage)
        setValue('order_proposal_timer', data.order_proposal_timer)

        setValue('default_opening_hour[0]', data.default_opening_hour[0])
        setValue('default_opening_hour[1]', data.default_opening_hour[1])

        setValue('admin.priority_fee', data.admin.priority_fee)

        setValue('admin.callcenter_phone', data.admin.callcenter_phone)
        setValue(
          'admin.average_delivery_time',
          data.admin.average_delivery_time
        )
        setValue('admin.chat_url', data.admin.chat_url)
        setValue(
          'admin.operating_hours_close',
          data.admin.operating_hours_close
        )
        setValue('admin.callcenter_mobile', data.admin.callcenter_mobile)
        setValue('admin.operating_hours_open', data.admin.operating_hours_open)
        setValue(
          'admin.operating_hours_delay',
          data.admin.operating_hours_delay
        )

        setValue(
          'admin.delivery_average.farmazon.time',
          data.admin.delivery_average.farmazon.time
        )
        setValue(
          'admin.delivery_average.store_delivery.time',
          data.admin.delivery_average.store_delivery.time
        )
        setValue(
          'admin.delivery_average.store_delivery.value',
          data.admin.delivery_average.store_delivery.value
        )

        setValue('customer.rate', data.customer.rate)
        setValue(
          'customer.checkout_description.products_total',
          data.customer.checkout_description.products_total
        )
        setValue(
          'customer.checkout_description.delivery_fee',
          data.customer.checkout_description.delivery_fee
        )
        setValue(
          'customer.checkout_description.rate',
          data.customer.checkout_description.rate
        )
        setValue('customer.static_pages', data.customer.static_pages)

        setValue('customer.terms_url', data.customer.terms_url)
        setValue('customer.android.version', data.customer.android.version)
        setValue('customer.android.mandatory', data.customer.android.mandatory)
        setValue('customer.android.store_url', data.customer.android.store_url)

        setValue('customer.update_uri', data.customer.update_uri)
        setValue('customer.ios.version', data.customer?.ios?.version)
        setValue('customer.ios.mandatory', data.customer?.ios?.mandatory)
        setValue('customer.ios.store_url', data.customer?.ios?.store_url)

        setValue(
          'customer.welcome.active',
          data.customer?.welcome?.active === 1
        )
        setValue(
          'customer.welcome.welcome_uri',
          data.customer?.welcome?.welcome_uri
        )

        setValue(
          'customer.maintenence.active',
          data.customer?.maintenence?.active === 1
        )
        setValue(
          'customer.maintenence.maintenence_uri',
          data.customer?.maintenence?.maintenence_uri
        )

        setValue('store.rate', data.store.rate)
        setValue('store.backoffice_store', data.store.backoffice_store)
        setValue('store.terms_url', data.store.terms_url)
        setValue('store.android.version', data.store.android.version)
        setValue('store.android.mandatory', data.store.android.mandatory)
        setValue('store.android.store_url', data.store.android.store_url)
        setValue('store.static_pages', data.store.static_pages)

        setValue('store.update_uri', data.store.update_uri)
        setValue('store.ios.version', data.store.ios.version)
        setValue('store.ios.mandatory', data.store.ios.mandatory)
        setValue('store.ios.store_url', data.store.ios.store_url)

        setValue('store.welcome.active', data.store.welcome.active === 1)
        setValue('store.welcome.welcome_uri', data.store.welcome.welcome_uri)

        setValue(
          'store.maintenence.active',
          data.store.maintenence.active === 1
        )
        setValue(
          'store.maintenence.maintenence_uri',
          data.store.maintenence.maintenence_uri
        )

        setValue('antifraude_tax', data.antifraude_tax)
        setValue('customer.offer_distance', data.customer.offer_distance)
        setValue('delivery_fee', data.delivery_fee)
      })
      .catch((error: any) => {
        alert.error(
          error.response?.data?.message ??
            `Error com integração com api. ${error.toString()}`
        )
      })
  }, [])

  const onSubmit = (data: any) => {
    setButtonLoading(true)
    data.customer.welcome.active = data.customer.welcome.active ? 1 : 0
    data.customer.maintenence.active = data.customer.maintenence.active ? 1 : 0
    data.store.welcome.active = data.store.welcome.active ? 1 : 0
    data.store.maintenence.active = data.store.maintenence.active ? 1 : 0

    updateSetupConfig(data)
      .then(() => {
        setButtonLoading(false)
        alert.success('Configuração atualizada com sucesso.')
      })
      .catch((error) => {
        setButtonLoading(false)
        alert.error(
          error.response?.data?.message ??
            `Erro de comunicação com api. ${error.toString()}`
        )
      })
  }

  return (
    <Container>
      <View>
        <Row>
          <Col xs={7}>
            <Text size={25} fontWeight={700}>
              Configurações
            </Text>
          </Col>
        </Row>
      </View>

      <View height='calc(100% - 80px)'>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!loading && (
            <View height='100%' justify='center' align='center'>
              <LoadingSearch />
            </View>
          )}

          {loading && (
            <View height='100%'>
              <Row>
                <Col xs={10} />
                <Col xs={2}>
                  <Button label='Atualizar' loading={buttonLoading} />
                </Col>
                <Col xs={12}>
                  <Text size={18} fontWeight={700}>
                    Geral
                  </Text>

                  <Row>
                    <Col xs={2}>
                      <Text size={10} mt={1}>
                        Entrega prioritária
                      </Text>
                      <Input
                        type='text'
                        id='admin.priority_fee'
                        placeholder='Digite um número'
                        {...register('admin.priority_fee', {
                          valueAsNumber: true,
                        })}
                      />
                    </Col>

                    <Col xs={2}>
                      <Text size={10} mt={1}>
                        Alcance máximo padrão
                      </Text>
                      <Input
                        type='number'
                        id='default_range'
                        placeholder=''
                        {...register('default_range', {
                          valueAsNumber: true,
                        })}
                      />
                    </Col>

                    <Col xs={2}>
                      <Text size={10} mt={1}>
                        Alcance máximo
                      </Text>
                      <Input
                        type='number'
                        id='max_range'
                        placeholder=''
                        {...register('max_range', {
                          valueAsNumber: true,
                        })}
                      />
                    </Col>

                    <Col xs={2}>
                      <Text size={10} mt={1}>
                        Porcentagem total máxima
                      </Text>
                      <Input
                        type='number'
                        id='max_total_percentage'
                        placeholder=''
                        {...register('max_total_percentage', {
                          valueAsNumber: true,
                        })}
                      />
                    </Col>

                    <Col xs={2}>
                      <Text size={10} mt={1}>
                        Cronômetro de proposta
                      </Text>
                      <Input
                        type='number'
                        id='order_proposal_timer'
                        placeholder=''
                        {...register('order_proposal_timer', {
                          valueAsNumber: true,
                        })}
                      />
                    </Col>

                    <Col xs={2}>
                      <Text size={10} mt={1}>
                        Hora de abertura padrão
                      </Text>
                      <Input
                        type='time'
                        placeholder=''
                        {...register('default_opening_hour[0]')}
                      />
                    </Col>

                    <Col xs={2}>
                      <View mt={1.8}>
                        <Input
                          type='time'
                          placeholder=''
                          {...register('default_opening_hour[1]')}
                        />
                      </View>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12}>
                  <View mt={2}>
                    <Text size={18} fontWeight={700}>
                      Administração
                    </Text>

                    <Row>
                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Tempo de entrega farmazon
                        </Text>
                        <Input
                          type='text'
                          id='admin.delivery_average.farmazon.time'
                          {...register('admin.delivery_average.farmazon.time')}
                        />
                      </Col>
                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Tempo de entrega loja
                        </Text>
                        <Input
                          type='text'
                          id='admin.delivery_average.store_delivery.time'
                          {...register(
                            'admin.delivery_average.store_delivery.time'
                          )}
                        />
                      </Col>
                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Varlor de entrega loja
                        </Text>
                        <Input
                          type='text'
                          id='admin.delivery_average.store_delivery.value'
                          {...register(
                            'admin.delivery_average.store_delivery.value',
                            {
                              valueAsNumber: true,
                            }
                          )}
                        />
                      </Col>
                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Telefone fixo Call Center
                        </Text>
                        <Input
                          type='text'
                          id='admin.callcenter_phone'
                          placeholder='Exemplo: +5511999999999'
                          {...register('admin.callcenter_phone')}
                        />
                      </Col>

                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Telefone móvel Call Center
                        </Text>
                        <Input
                          type='text'
                          id='admin.callcenter_mobile'
                          placeholder='Exemplo: +5511999999999'
                          {...register('admin.callcenter_mobile')}
                        />
                      </Col>

                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Tempo médio de entrega
                        </Text>
                        <Input
                          type='number'
                          id='admin.average_delivery_time'
                          placeholder='Tempo em minutos'
                          {...register('admin.average_delivery_time', {
                            valueAsNumber: true,
                          })}
                        />
                      </Col>

                      <Col xs={5}>
                        <Text size={10} mt={1}>
                          Endereço Chat
                        </Text>
                        <Input
                          type='text'
                          id='admin.chat_url'
                          placeholder='Digite a url'
                          {...register('admin.chat_url')}
                        />
                      </Col>

                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Horário de abertura
                        </Text>
                        <Input
                          type='text'
                          id='admin.operating_hours_open'
                          placeholder='Digite a horário'
                          {...register('admin.operating_hours_open')}
                        />
                      </Col>

                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Horário de fechamento
                        </Text>
                        <Input
                          type='text'
                          id='admin.operating_hours_close'
                          placeholder='Digite a horário'
                          {...register('admin.operating_hours_close', {
                            valueAsNumber: true,
                          })}
                        />
                      </Col>

                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Atraso no horário
                        </Text>
                        <Input
                          type='text'
                          id='admin.operating_hours_delay'
                          placeholder='Digite a horário'
                          {...register('admin.operating_hours_delay', {
                            valueAsNumber: true,
                          })}
                        />
                      </Col>

                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Taxa de Delivery
                        </Text>
                        <Input
                          type='text'
                          id='delivery_fee'
                          placeholder='Digite a Taxa'
                          {...register('delivery_fee')}
                        />
                      </Col>
                    </Row>
                  </View>
                </Col>

                <Col xs={12}>
                  <View mt={2}>
                    <Text size={18} fontWeight={700}>
                      Cliente
                    </Text>

                    <Row>
                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Distância de oferta
                        </Text>
                        <Input
                          type='text'
                          id='customer.offer_distance'
                          placeholder='Digite um número'
                          {...register('customer.offer_distance', {
                            valueAsNumber: true,
                          })}
                        />
                      </Col>

                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Taxa de transação
                        </Text>
                        <Input
                          type='text'
                          id='customer.rate'
                          placeholder='Digite um número'
                          {...register('customer.rate', {
                            valueAsNumber: true,
                          })}
                        />
                      </Col>

                      <Col xs={2}>
                        <Text size={10} mt={1}>
                          Taxa de Anti-Fraude
                        </Text>
                        <Input
                          type='text'
                          id='antifraude_tax'
                          placeholder='Digite a Taxa'
                          {...register('antifraude_tax', {
                            valueAsNumber: true,
                          })}
                        />
                      </Col>

                      <Col xs={12} />

                      <Col xs={12}>
                        <Text size={10} mt={1}>
                          Descrição produto total
                        </Text>
                        <Input
                          type='text'
                          id='customer.checkout_description.products_total'
                          placeholder=''
                          {...register(
                            'customer.checkout_description.products_total'
                          )}
                        />

                        <Text size={10} mt={1}>
                          Descrição taxa de entrega
                        </Text>
                        <Input
                          type='text'
                          id='customer.checkout_description.delivery_fee'
                          placeholder=''
                          {...register(
                            'customer.checkout_description.delivery_fee'
                          )}
                        />

                        <Text size={10} mt={1}>
                          Descrição de avaliação
                        </Text>
                        <Input
                          type='text'
                          id='customer.checkout_description.rate'
                          placeholder=''
                          {...register('customer.checkout_description.rate')}
                        />
                      </Col>

                      <Col xs={6}>
                        <Text size={10} mt={1}>
                          Url do termos{' '}
                        </Text>
                        <Input
                          type='text'
                          id='customer.terms_url'
                          placeholder=''
                          {...register('customer.terms_url')}
                        />
                      </Col>

                      <Col xs={6}>
                        <Text size={10} mt={1}>
                          Url do atualização{' '}
                        </Text>
                        <Input
                          type='text'
                          id='customer.update_uri'
                          placeholder=''
                          {...register('customer.update_uri')}
                        />
                      </Col>

                      <Col xs={6}>
                        <Text size={10} mt={1}>
                          Versão do android
                        </Text>
                        <Input
                          type='text'
                          id='customer.android.version'
                          placeholder=''
                          {...register('customer.android.version')}
                        />

                        <Text size={10} mt={1}>
                          Android - obrigatório
                        </Text>
                        <Switch {...register('customer.android.mandatory')} />

                        <Text size={10} mt={1}>
                          Url loja android
                        </Text>
                        <Input
                          type='text'
                          id='customer.android.store_url'
                          placeholder=''
                          {...register('customer.android.store_url')}
                        />
                      </Col>

                      <Col xs={6}>
                        <Text size={10} mt={1}>
                          Versão do iOS
                        </Text>
                        <Input
                          type='text'
                          id='customer.ios.version'
                          placeholder=''
                          {...register('customer.ios.version')}
                        />

                        <Text size={10} mt={1}>
                          IOS - obrigatório
                        </Text>
                        <Switch {...register('customer.ios.mandatory')} />

                        <Text size={10} mt={1}>
                          Url loja iOS
                        </Text>
                        <Input
                          type='text'
                          id='customer.ios.store_url'
                          placeholder=''
                          {...register('customer.ios.store_url')}
                        />
                      </Col>

                      <Col xs={6}>
                        <Text size={10} mt={1}>
                          Url de bem vindo
                        </Text>
                        <Input
                          type='text'
                          id='customer.welcome.welcome_uri'
                          placeholder=''
                          {...register('customer.welcome.welcome_uri')}
                        />

                        <Row>
                          <Col xs={4}>
                            <Text size={10} mt={1}>
                              Bem vindo - activo
                            </Text>
                            <Switch {...register('customer.welcome.active')} />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={6}>
                        <Text size={10} mt={1}>
                          Url de manutençao
                        </Text>
                        <Input
                          type='text'
                          id='customer.maintenence.maintenence_uri'
                          placeholder=''
                          {...register('customer.maintenence.maintenence_uri')}
                        />

                        <Row>
                          <Col xs={4}>
                            <Text size={10} mt={1}>
                              Manutençao - activo
                            </Text>
                            <Switch
                              {...register('customer.maintenence.active')}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12}>
                        <View mt={2}>
                          <Text size={18} fontWeight={700}>
                            Cliente - Página estática
                          </Text>

                          <View flex justify='flex-end'>
                            <View width='200px'>
                              <Button
                                label='Adicionar'
                                type='button'
                                onClick={() => appendCustumer({})}
                              />
                            </View>
                          </View>

                          {fieldsCustumer.map((item: any, index) => (
                            <Row key={item.id}>
                              <Col xs={2}>
                                <Text size={10} mt={1}>
                                  Nome da página
                                </Text>
                                <Input
                                  type='text'
                                  placeholder='Nome da página'
                                  defaultValue={item.page_name}
                                  {...register(
                                    `customer.static_pages[${index}].page_name`
                                  )}
                                />
                              </Col>

                              <Col xs={4}>
                                <Text size={10} mt={1}>
                                  Url Página
                                </Text>
                                <Input
                                  type='text'
                                  placeholder='Url da página'
                                  defaultValue={item.page_url}
                                  {...register(
                                    `customer.static_pages[${index}].page_url`
                                  )}
                                />
                              </Col>
                              <Col xs={2}>
                                <Text size={10} mt={1}>
                                  Nome ícone
                                </Text>
                                <Input
                                  type='text'
                                  placeholder='Nome do ícone'
                                  defaultValue={item.icon_name}
                                  {...register(
                                    `customer.static_pages[${index}].icon_name`
                                  )}
                                />
                              </Col>

                              <Col xs={2}>
                                <Text size={10} mt={1}>
                                  Tipo do ícone
                                </Text>
                                <Input
                                  type='text'
                                  placeholder='Tipo de ícone'
                                  defaultValue={item.icon_type}
                                  {...register(
                                    `customer.static_pages[${index}].icon_type`
                                  )}
                                />
                              </Col>

                              <Col xs={2}>
                                <View mt={2.5} cursor>
                                  <img
                                    src={Deletar}
                                    alt='Deletar'
                                    onClick={() => removeCustumer(index)}
                                  />
                                </View>
                              </Col>
                            </Row>
                          ))}
                        </View>
                      </Col>
                    </Row>
                  </View>
                </Col>

                <Col xs={12}>
                  <Text size={18} fontWeight={700}>
                    Loja
                  </Text>

                  <Row>
                    <Col xs={2}>
                      <Text size={10} mt={1}>
                        Taxa de transação
                      </Text>
                      <Input
                        type='text'
                        id='store.rate'
                        placeholder='Digite um número'
                        {...register('store.rate', {
                          valueAsNumber: true,
                        })}
                      />
                    </Col>

                    <Col xs={3}>
                      <Text size={10} mt={1}>
                        ID loja Back Office
                      </Text>
                      <Input
                        type='text'
                        id='store.backoffice_store'
                        placeholder='ID loja Back Office'
                        {...register('store.backoffice_store')}
                      />
                    </Col>

                    <Col xs={12}>
                      <Text size={10} mt={1}>
                        Url do termos{' '}
                      </Text>
                      <Input
                        type='text'
                        id='store.terms_url'
                        placeholder=''
                        {...register('store.terms_url')}
                      />
                    </Col>

                    <Col xs={12}>
                      <Text size={10} mt={1}>
                        Url do atualização{' '}
                      </Text>
                      <Input
                        type='text'
                        id='store.update_uri'
                        placeholder=''
                        {...register('store.update_uri')}
                      />
                    </Col>

                    <Col xs={6}>
                      <Text size={10} mt={1}>
                        Versão do android
                      </Text>
                      <Input
                        type='text'
                        id='store.android.version'
                        placeholder=''
                        {...register('store.android.version')}
                      />

                      <Text size={10} mt={1}>
                        Android - obrigatório
                      </Text>
                      <Switch {...register('store.android.mandatory')} />

                      <Text size={10} mt={1}>
                        Url loja android
                      </Text>
                      <Input
                        type='text'
                        id='store.android.store_url'
                        placeholder=''
                        {...register('store.android.store_url')}
                      />
                    </Col>

                    <Col xs={6}>
                      <Text size={10} mt={1}>
                        Versão do iOS
                      </Text>
                      <Input
                        type='text'
                        id='store.ios.version'
                        placeholder=''
                        {...register('store.ios.version')}
                      />

                      <Text size={10} mt={1}>
                        IOS - obrigatório
                      </Text>
                      <Switch {...register('store.ios.mandatory')} />

                      <Text size={10} mt={1}>
                        Url loja iOS
                      </Text>
                      <Input
                        type='text'
                        id='store.ios.store_url'
                        placeholder=''
                        {...register('store.ios.store_url')}
                      />
                    </Col>

                    <Col xs={6}>
                      <Text size={10} mt={1}>
                        Url de bem vindo
                      </Text>
                      <Input
                        type='text'
                        id='store.welcome.welcome_uri'
                        placeholder=''
                        {...register('store.welcome.welcome_uri')}
                      />

                      <Row>
                        <Col xs={4}>
                          <Text size={10} mt={1}>
                            Bem vindo - activo
                          </Text>
                          <Switch {...register('store.welcome.active')} />
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={6}>
                      <Text size={10} mt={1}>
                        Url de manutençao
                      </Text>
                      <Input
                        type='text'
                        id='store.maintenence.maintenence_uri'
                        placeholder=''
                        {...register('store.maintenence.maintenence_uri')}
                      />

                      <Row>
                        <Col xs={4}>
                          <Text size={10} mt={1}>
                            Manutençao - activo
                          </Text>
                          <Switch {...register('store.maintenence.active')} />
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12}>
                      <View mt={2}>
                        <Text size={18} fontWeight={700}>
                          Loja - Página estática
                        </Text>

                        <View flex justify='flex-end'>
                          <View width='200px'>
                            <Button
                              label='Adicionar'
                              type='button'
                              onClick={() => appendStore({})}
                            />
                          </View>
                        </View>

                        {fieldsStore.map((item: any, index) => (
                          <Row key={item.id}>
                            <Col xs={2}>
                              <Text size={10} mt={1}>
                                Nome da página
                              </Text>
                              <Input
                                type='text'
                                placeholder='Nome da página'
                                defaultValue={item.page_name}
                                {...register(
                                  `store.static_pages[${index}].page_name`
                                )}
                              />
                            </Col>

                            <Col xs={4}>
                              <Text size={10} mt={1}>
                                Url Página
                              </Text>
                              <Input
                                type='text'
                                placeholder='Url da página'
                                defaultValue={item.page_url}
                                {...register(
                                  `store.static_pages[${index}].page_url`
                                )}
                              />
                            </Col>
                            <Col xs={2}>
                              <Text size={10} mt={1}>
                                Nome ícone
                              </Text>
                              <Input
                                type='text'
                                placeholder='Nome do ícone'
                                defaultValue={item.icon_name}
                                {...register(
                                  `store.static_pages[${index}].icon_name`
                                )}
                              />
                            </Col>

                            <Col xs={2}>
                              <Text size={10} mt={1}>
                                Tipo do ícone
                              </Text>
                              <Input
                                type='text'
                                placeholder='Tipo de ícone'
                                defaultValue={item.icon_type}
                                {...register(
                                  `store.static_pages[${index}].icon_type`
                                )}
                              />
                            </Col>

                            <Col xs={2}>
                              <View mt={2.5} cursor>
                                <img
                                  src={Deletar}
                                  alt='Deletar'
                                  onClick={() => removeStore(index)}
                                />
                              </View>
                            </Col>
                          </Row>
                        ))}
                      </View>
                    </Col>
                  </Row>
                </Col>

                <input type='hidden' {...register('pub_id')} value='foo' />

                <Col xs={12}>
                  <View flex justify='flex-end' mt={2}>
                    <View width='200px'>
                      <Button label='Atualizar' loading={buttonLoading} />
                    </View>
                  </View>
                </Col>
              </Row>
            </View>
          )}
        </form>
      </View>
    </Container>
  )
}

export default Setup
