import { useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { ModalConfirm } from 'src/components/elements'

import { Container, DeleteButton } from './styles'
import { shadowBox } from 'src/styles'

import { ProductProps } from '../../types'

export const Product = ({
  id,
  url,
  index,
  moveProduct,
  name,
  category,
  setCategories,
  setEnableSave,
}: ProductProps) => {
  const ref = useRef(null)
  const [openModal, setOpenModal] = useState(false)

  const [{ handlerId }, drop] = useDrop({
    accept: 'product',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: any, monitor: any) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = (ref.current as any)?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }
      // Time to actually perform the action
      moveProduct(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'product',
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const handleDeleteProduct = () => {
    setEnableSave(true)
    setCategories((prev) =>
      prev.map((cat) =>
        cat.title === category
          ? { ...cat, products: cat.products.filter((p) => p.pub_id !== id) }
          : cat
      )
    )
  }

  return (
    <>
      {openModal && (
        <ModalConfirm
          icon={url}
          description={`Deseja excluir este produto da categoria ${category}?`}
          labelCancel='Não'
          handleCancel={() => setOpenModal(false)}
          labelConfirm='Sim'
          backgroundConfirm='#FF0000'
          handleConfirm={handleDeleteProduct}
        />
      )}
      <Container
        index={index}
        isDragging={isDragging}
        ref={ref}
        style={shadowBox}
        data-handler-id={handlerId}
      >
        <DeleteButton onClick={() => setOpenModal(true)} />
        <img title={name} src={url} width='50px' height='50px' alt='image' />
      </Container>
    </>
  )
}
