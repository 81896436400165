import api, { newApi } from './api'

export const getNotificationRead = async (pubId: string) =>
  api.get(`/notify/internal-notification/read/${pubId}`)

interface SendClientBroadcastParams {
  title: string
  body?: string
}

export const sendClientBroadcast = async (payload: SendClientBroadcastParams) =>
  newApi.post('notifications/clients/broadcast', payload)

interface ListClientDevicesParams {
  page?: number
}

export const listClientDevices = async ({
  page = 1,
}: ListClientDevicesParams) =>
  newApi.get('notifications/clients/devices', {
    headers: {
      page,
    },
  })

interface ListLogsParams {
  page?: number
  type?: string
}

export const listLogs = async ({ page = 1, type }: ListLogsParams) =>
  newApi.get('notifications', {
    params: {
      type,
    },
    headers: {
      page,
    },
  })
