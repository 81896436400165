import { useEffect, useState } from 'react'
import { Col } from 'react-grid-system'
import { useHistory, useLocation } from 'react-router'

import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { format } from 'date-fns'
import { Button } from 'src/components/form'
import { DataRow, Header, Table, Text, View } from 'src/components/helper'
import { listLogs } from 'src/services/Notification'

import { Container, ArrowButton } from './styles'

const ListNotifications = () => {
  const location = useLocation()
  const history = useHistory()

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    const loadNotifications = async () => {
      const { data } = await listLogs({
        page,
        type: location.pathname.includes('clientes')
          ? 'customers'
          : 'store_admin',
      })

      setNotifications(data.data)
      setLastPage(data.pagination.lastPage)
    }

    loadNotifications()
  }, [page])

  function handlePreviousPage() {
    setPage((current) => {
      if (current > lastPage) return current - 1

      return current
    })
  }

  function handleNextPage() {
    setPage((current) => {
      if (current < lastPage) return current + 1

      return current
    })
  }

  function goToDevicesRegistered() {
    history.push('/notificacoes/clientes/dispositivos')
  }

  return (
    <Container>
      <View
        mb={0.9}
        flex
        direction='row'
        align='center'
        justify='space-between'
      >
        <Text size={25} fontWeight={700}>
          Notificações enviadas
        </Text>

        <Col xs={8} lg={4} xl={2}>
          <Button
            background='#3097E2'
            onClick={goToDevicesRegistered}
            label='Listar dispositivos cadastrados'
          />
        </Col>
      </View>

      <Table.Table id='listSale'>
        <thead>
          <tr>
            <Header textAlign='left'>Nome</Header>
            <Header textAlign='left'>Tipo</Header>
            <Header textAlign='left'>Título</Header>
            <Header textAlign='left'>Mensagem</Header>
            <Header textAlign='left'>Successo</Header>
            <Header textAlign='left'>Falha</Header>
            <Header textAlign='left'>Total</Header>
            <Header textAlign='left'>Enviado em</Header>
          </tr>
        </thead>
        <tbody>
          {notifications.length ? (
            <>
              {notifications.map((item: any, idx: number) => (
                <Table.Row key={idx}>
                  <DataRow>
                    <Text size={15} fontWeight={500}>
                      {item.cognito_name}
                    </Text>
                  </DataRow>
                  <DataRow>
                    <Text size={15}>{item.type}</Text>
                  </DataRow>
                  <DataRow>
                    <Text size={15}>{item.title}</Text>
                  </DataRow>
                  <DataRow>
                    <Text size={15}>{item.body ?? '-'}</Text>
                  </DataRow>
                  <DataRow>
                    <Text size={15}>{item.successed}</Text>
                  </DataRow>
                  <DataRow>
                    <Text size={15}>{item.failed}</Text>
                  </DataRow>
                  <DataRow>
                    <Text size={15}>
                      {Number(item.successed ?? 0) + Number(item.failed)}
                    </Text>
                  </DataRow>
                  <DataRow>
                    <Text size={15}>
                      {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  </DataRow>
                </Table.Row>
              ))}
            </>
          ) : (
            <Table.Row>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
              <DataRow>-</DataRow>
            </Table.Row>
          )}
        </tbody>
      </Table.Table>

      <Col md={8} lg={4} xl={2} style={{ marginTop: '2rem' }}>
        <View flex direction='row' height='60px'>
          <ArrowButton disabled={page === 1} onClick={handlePreviousPage}>
            <ChevronLeft style={{ color: '#ffffff' }} />
          </ArrowButton>
          <View
            style={{ width: '10rem', height: '100%' }}
            flex
            justify='center'
            align='center'
          >
            <Text>{page.toString()}</Text>
          </View>
          <ArrowButton disabled={page >= lastPage} onClick={handleNextPage}>
            <ChevronRight style={{ color: '#ffffff' }} />
          </ArrowButton>
        </View>
      </Col>
    </Container>
  )
}

export default ListNotifications
