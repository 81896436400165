import styled from 'styled-components'

export const Container = styled.div`
  padding: 17px 20px;
  background-color: #ff0000;
  position: fixed;
  bottom: 0;
  width: 100%;
`

export const Text = styled.p`
  color: #fff;
  font-size: 14px;
`
