import { useCallback, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import update from 'immutability-helper'

import {
  Container,
  ProductsBox,
  TitleBox,
  Label,
  Button,
  FalseLabel,
  OuterBox,
} from './styles'
import { shadowBox } from 'src/styles'

import { CategoryProps, CategoryT, ProductT } from '../../types'
import { Product } from '../Product'

export const Category = ({
  id,
  title,
  index,
  moveCategory,
  products,
  setCategories,
  checked,
  handleCheck,
  enableSave,
  setEnableSave,
  setCurrentCategory,
}: CategoryProps) => {
  const ref = useRef(null)

  const moveProduct = useCallback((dragIndex, hoverIndex) => {
    !enableSave && setEnableSave(true)
    setCategories((prevCategories: CategoryT[]) =>
      prevCategories.map((category: CategoryT) => {
        return category.id === id
          ? {
              ...category,
              products: update(category.products, {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, category.products[dragIndex]],
                ],
              }),
            }
          : category
      })
    )
  }, [])

  const [{ handlerId }, drop] = useDrop({
    accept: 'category',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: any, monitor: any) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = (ref.current as any)?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCategory(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'category',
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const handleSearchScreen = () => setCurrentCategory(title)

  return (
    <OuterBox index={index}>
      <Label checked={checked} htmlFor={`checkbox-${id}`}>
        <span>&#10004;</span>
        <input
          checked={checked}
          onChange={() => handleCheck(id)}
          id={`checkbox-${id}`}
          type='checkbox'
        />
      </Label>
      <Container
        isDragging={isDragging}
        ref={ref}
        style={shadowBox}
        data-handler-id={handlerId}
        disabled={!checked}
      >
        <FalseLabel />
        <TitleBox>
          <p>{title}</p>
        </TitleBox>
        <ProductsBox>
          {products.map((card: ProductT, index: number) => (
            <Product
              setEnableSave={setEnableSave}
              name={card.name}
              key={card.pub_id}
              index={index}
              id={card.pub_id}
              url={card.img}
              moveProduct={moveProduct}
              category={title}
              setCategories={setCategories}
            />
          ))}
        </ProductsBox>
        <Button disabled={!checked} onClick={handleSearchScreen}>
          Selecionar Produtos
        </Button>
      </Container>
    </OuterBox>
  )
}
