import { useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'

import { Divider } from '@material-ui/core'
import { Modal } from 'src/components/elements'
import { Button, InputText } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { updateDriverData } from 'src/services/OrderService'
import { formatLicensePlace, formatPhone } from 'src/util/helpers/string'
import { mutate } from 'swr'

interface UpdateDriverModalProps {
  orderId: string
  onClose: () => void
}

const UpdateDriverModal = ({ orderId, onClose }: UpdateDriverModalProps) => {
  const alert = useAlert()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm<any>({
    defaultValues: {
      name: '',
      phone: '',
      business_name: '',
    },
  })

  const [loading, setLoading] = useState(false)

  const handleOnSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true)

      if (data.phone) {
        data.phone = `+55${data.phone?.replace(/\D/g, '')}`
      }

      await updateDriverData(orderId, data)

      mutate(`/admin/orders/${orderId}`)

      alert.success('Dados do entregador alterado com sucesso')
      onClose()
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Falha na chamada com API')
    } finally {
      setLoading(false)
    }
  })

  const onChangePhone = useCallback((event: any) => {
    setValue('phone', formatPhone(event.target.value))
    clearErrors()
  }, [])

  const onChangeLicensePlace = useCallback((event: any) => {
    setValue('license_plate', formatLicensePlace(event.target.value))
    clearErrors()
  }, [])

  return (
    <Modal paddingTop={3} width={45} padding={0} onClose={onClose}>
      <View padding={2} style={{ minHeight: '200px' }}>
        <View mb={1}>
          <Text size={18} fontWeight={900}>
            Alterar dados do entregador
          </Text>
        </View>

        <Divider />

        <View mt={1} pl={1} pr={1} style={{ overflow: 'auto' }}>
          <form onSubmit={handleOnSubmit}>
            <View textAlign='center' mt={2}>
              <InputText
                {...register('name', {
                  required: 'O nome do entregador é obrigatório',
                })}
                type='text'
                name='name'
                label='Nome'
                validationError={errors.name}
                messageError={errors.name?.message}
              />
            </View>

            <View textAlign='center' mt={2}>
              <InputText
                {...register('phone')}
                type='text'
                name='phone'
                label='Telefone'
                onChange={onChangePhone}
                validationError={errors.street}
                messageError={errors.street?.message}
              />
            </View>

            <View textAlign='center' mt={2} mb={4}>
              <InputText
                {...register('business_name', {
                  required: 'O nome da empresa é obrigatório',
                })}
                type='text'
                name='business_name'
                label='Empresa'
                validationError={errors.name}
                onChange={onChangeLicensePlace}
                messageError={errors.name?.message}
              />
            </View>

            <View style={{ marginTop: 'auto' }}>
              <Row align='center'>
                <Col xs={12}>
                  <Button
                    label='Alterar'
                    background='#3097E2'
                    loading={loading}
                  />
                </Col>
              </Row>
            </View>
          </form>
        </View>
      </View>
    </Modal>
  )
}

export default UpdateDriverModal
