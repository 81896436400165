import { Container, Col, Row, Hidden } from 'react-grid-system'
import { useForm } from 'react-hook-form'

import Image from 'src/assets/images/farmazon-user.png'
import Logo from 'src/assets/images/logo-farmazon-black.svg'
import { AlertLogin } from 'src/components/elements'
import { Button, InputText } from 'src/components/form'
import {
  ImageResize,
  View,
  Text,
  ContainerAnimation,
} from 'src/components/helper'
import useAuth from 'src/hooks/useAuth'
import { formatPhone } from 'src/util/helpers/string'

import { loginAnimation } from 'src/styles'

function Recovery() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm()
  const { validation, loading, forgotPasswordRequest } = useAuth()

  const onSubmit = (data: any) => {
    forgotPasswordRequest(data.username)
  }

  const formatMoneyInput = (event: any) => {
    const value = formatPhone(event.target.value)
    setValue('username', value)
    clearErrors()
  }

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row nogutter align='stretch' style={{ height: 'calc(100vh)' }}>
        <Hidden xs sm md>
          <Col md={8}>
            <ImageResize url={`url(${Image})`} />
          </Col>
        </Hidden>
        <Col xs={12} lg={4}>
          <Row
            align='center'
            justify='center'
            nogutter
            style={{ height: '100%' }}
          >
            <Col xs={8}>
              <ContainerAnimation {...loginAnimation}>
                <View textAlign='center' pt={4}>
                  <img src={Logo} alt='Farmazon logo' />
                </View>

                <View mt={2}>
                  <Text textAlign='center' fontWeight={500} size={25}>
                    Recupere seu acesso
                  </Text>
                </View>

                <View mt={1}>
                  <Text size={14} textAlign='center'>
                    Digite seu número de telefone abaixo e receba um PIN via SMS
                    para alterar sua senha
                  </Text>
                </View>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <View textAlign='center' mt={2}>
                    <InputText
                      {...register('username', {
                        required: 'O telefone é obrigatório',
                        pattern: {
                          value: /\d{5}-\d{4}/,
                          message: 'Digite seu número de telefone com DDD.',
                        },
                      })}
                      type='text'
                      name='username'
                      placeholder='Utilize o DDD ex.: (xx) xxxxx-xxxx'
                      validationError={errors.username}
                      messageError={errors.username?.message}
                      onChange={formatMoneyInput}
                    />
                  </View>

                  <View mt={3}>
                    <Button label='Enviar' loading={loading} />
                  </View>
                </form>
              </ContainerAnimation>
            </Col>

            <View mt={1}>
              <Text size={13} color='#C4C4C4' textAlign='center'>
                Copyright © 2022 Farmazon. Todos os direitos reservados.
              </Text>
            </View>
          </Row>

          {validation && <AlertLogin message='Ops! Número inválido.' />}
        </Col>
      </Row>
    </Container>
  )
}

export default Recovery
