import { ReactNode } from 'react'

import { Backgrond, Container, Content } from './styles'

interface ModalProps {
  children: ReactNode
  width?: number
  padding?: number
  paddingTop?: number
  onClose?: () => void
}

function Modal({ children, width, padding, paddingTop, onClose }: ModalProps) {
  return (
    <Container paddingTop={paddingTop}>
      <Backgrond onClick={onClose} />
      <Content width={width} padding={padding}>
        {children}
      </Content>
    </Container>
  )
}

export default Modal
