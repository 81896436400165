import styled from 'styled-components'

export const Container = styled.div<{ color: string }>`
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border-left: 18px solid ${({ color = '#F48D42' }) => color};
  padding: 18px 14px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;
`
export const Status = styled.div`
  border-radius: 30px;
  padding: 4px 18px;
  background-color: #3097e2;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
