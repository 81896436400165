import { ReactNode } from 'react'

import { Container } from './styles'

interface HeaderProps {
  children: ReactNode
}

function Header({ children }: HeaderProps) {
  return <Container>{children}</Container>
}

export default Header
