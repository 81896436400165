import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 0.2rem 0.4rem;
  border-radius: 6px;
`

export const Box = styled.div`
  border: 1px solid #ccc;
  height: 38px;
  margin: 0 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  font-size: 15px;
  font-weight: 100;
  padding: 0 0.8rem;
`

export const Input = styled.input`
  border: none;
  width: 20px;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #3097e2;
  height: 23px;
  width: 22px;
  font-weight: 300;
  font-size: 26px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
`
