import React, { forwardRef } from 'react'

import { Container, Input, TextError, Label } from './styles'

interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  messageError?: any
  validationError?: any
  label?: string
}

function InputText(
  { messageError, validationError, label, ...props }: InputTextProps,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  return (
    <>
      <Container error={validationError ? 1 : 0}>
        {label && <Label>{label}</Label>}
        <Input {...props} error={validationError ? 1 : 0} ref={forwardedRef} />
      </Container>
      {messageError && <TextError>{messageError}</TextError>}
    </>
  )
}

export default forwardRef(InputText)
