/* eslint-disable @typescript-eslint/no-empty-function */

import { ChangeEvent, useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import { Col, Row } from 'react-grid-system'

import { debounce } from 'lodash'
import RightArrow from 'src/assets/icons/icone-right.svg'
import OfferProduct from 'src/assets/images/empty-product.svg'
import { LoadingSearch, ProductSearchItem } from 'src/components/elements'
import { Button, Input } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import { getSearchProductService } from 'src/services/ProductService'

import { columnStyle, Container, ScrollContent, shadowBox } from './styles'

import { CategoryT, SearchProductsProps } from '../../types'

function SearchProducts({
  selectedProduct,
  setSelectedProduct,
  categories,
  currentCategory,
  setCategories,
  setCurrentCategory,
  setEnableSave,
}: SearchProductsProps) {
  const alert = useAlert()
  const [loading, setLoading] = useState(false)
  const [searchText, setSeatchText] = useState('')
  const [listProducts, setListProducts] = useState([])

  const debounceFn = useCallback(debounce(handleRequestProductp, 1000), [])

  async function handleRequestProductp(value: string) {
    try {
      const response = await getSearchProductService(value)

      const { data } = response

      setListProducts(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value.length >= 3) {
      setLoading(true)
      debounceFn(value)
    }
    setSeatchText(value)
  }

  const handleConfirmProduct = () => {
    setCategories((prev) =>
      prev.map((category) =>
        category.title === currentCategory
          ? { ...category, products: [...category.products, selectedProduct] }
          : category
      )
    )
    setEnableSave(true)
    setSelectedProduct(undefined)
    setCurrentCategory('')
  }

  const handleSelectProduct = (item: any) => () => {
    const { name, pub_id, img } = item

    if (currentCategory === '') return
    const products = (
      categories.find((c) => c.title === currentCategory) as CategoryT
    ).products

    if (products.length === 5) return alert.error('Máximo de 5 produtos!')
    if (products.find((p) => p.pub_id === pub_id))
      return alert.error('O produto já faz parte da lista!')

    const sort = products.length + 1
    setSelectedProduct({ name, pub_id, sort, img })
  }

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View mr={1}>
            <Text
              cursor={1}
              onClick={() => setCurrentCategory('')}
              size={18}
              fontWeight={300}
            >
              Destaques
            </Text>
          </View>
          <View mr={1}>
            <img src={RightArrow} alt='Próximo' />
          </View>
          <View>
            <Text size={25} fontWeight={700}>
              Pesquisar produto
            </Text>
          </View>
        </View>
        <View height='calc(100% - 150px)' style={shadowBox}>
          <Row style={{ height: '100%' }}>
            <Col xs={4}>
              <View mr={2}>
                <Text>
                  Digite aqui o nome do produto ou princípio ativo do
                  medicamento
                </Text>
                <View mt={1}>
                  <Input type='text' name='q' onChange={handleSearchText} />
                </View>
              </View>
            </Col>
            <Col xs={8} style={columnStyle}>
              {searchText.length >= 3 ? (
                loading ? (
                  <LoadingSearch />
                ) : listProducts.length > 1 ? (
                  <ScrollContent>
                    {listProducts.map((item: any) => (
                      <ProductSearchItem
                        key={item.pub_id}
                        product={item}
                        image={item.img}
                        selected={item.pub_id === selectedProduct?.pub_id}
                        onClick={handleSelectProduct(item)}
                      />
                    ))}
                  </ScrollContent>
                ) : (
                  <View flex align='center' justify='center' height='100%'>
                    <View width='360px' justify='center' direction='column'>
                      <View pb={1} textAlign='center'>
                        <img src={OfferProduct} alt='Oferta vazia' />
                      </View>
                      <View>
                        <Text
                          mb={0.8}
                          fontWeight={500}
                          size={18}
                          textAlign='center'
                        >
                          Ops...não encontramos nenhum produto
                        </Text>
                        <View width='260px' style={{ margin: '0 auto' }}>
                          <Text size={16} textAlign='center'>
                            Tente pesquisar usando outros termos similares
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )
              ) : null}
            </Col>
          </Row>
        </View>

        <View flex justify='flex-end' mt={2}>
          <View width='50%' direction='row'>
            <View width='100%' mr={1}>
              <Button
                label='Cancelar'
                borderColor='#3097E2'
                color='#3097E2'
                background='#fff'
                onClick={() => setCurrentCategory('')}
              />
            </View>
            <View width='100%'>
              <Button
                label='Selecionar produto'
                background='#3097E2'
                onClick={handleConfirmProduct}
                disabled={!selectedProduct}
              />
            </View>
          </View>
        </View>
      </Container>
    </>
  )
}

export { SearchProducts }
