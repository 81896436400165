import { useMemo } from 'react'

import { formatDistance, fromUnixTime } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { Text, View } from 'src/components/helper'

import { Container, Content, Status } from './styles'

interface CardRequestSaleCenterProps {
  title: string
  onClick?: () => void
  cursor?: boolean
  deliveryType?: string
  customer?: string
  status?: string
  delivery?: string
  deliveryDescription: string | null
  store: any
  createdAt: number
}

function CardRequestSaleCenter({
  title,
  deliveryType,
  onClick,
  customer,
  cursor,
  delivery,
  deliveryDescription,
  status,
  store,
  createdAt,
}: CardRequestSaleCenterProps) {
  const color = useMemo(() => {
    return deliveryType === 'store_delivery' ? '#F48D42' : '#7B61FF'
  }, [deliveryType])

  return (
    <Container
      color={color}
      onClick={onClick}
      style={{ cursor: cursor ? 'pointer' : '' }}
    >
      <Content>
        <View>
          <Text fontWeight={400} size={16}>
            {title}
          </Text>
          <Text fontWeight={500} size={14}>
            {customer}
          </Text>
        </View>
        <Status>
          <Text size={12} fontWeight={300} color='#fff'>
            {status}
          </Text>
        </Status>
      </Content>
      <Content>
        <View>
          {store ? (
            <View mt={0.5} mb={0.3}>
              <Text fontWeight={700} size={14}>
                {store.name}
              </Text>
            </View>
          ) : null}
          <View>
            <Text fontWeight={400} size={12}>
              {formatDistance(new Date(fromUnixTime(createdAt)), new Date(), {
                addSuffix: true,
                locale: ptBR,
              })}
            </Text>
          </View>
          <View mt={0.1}>
            <Text fontWeight={400} size={12}>
              {delivery}
            </Text>
            {deliveryDescription && (
              <Text fontWeight={300} size={14}>
                {deliveryDescription}
              </Text>
            )}
          </View>
        </View>
        <View width='6rem' mt={1}>
          <Text fontWeight={700} size={16} color={color}>
            {deliveryType === 'store_delivery'
              ? 'ENTREGA PRÓPRIA'
              : 'ENTREGA PELA FARMAZON'}
          </Text>
        </View>
      </Content>
    </Container>
  )
}

export default CardRequestSaleCenter
