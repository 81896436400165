import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useParams, withRouter } from 'react-router'

import EmptyBankImage from 'src/assets/images/empty-bank.svg'
import { ModalConfirm } from 'src/components/elements'
import { Button, Input, Select } from 'src/components/form'
import { Text, View } from 'src/components/helper'
import useBank from 'src/hooks/useBank'
import { DefaultRootState } from 'src/redux/store'
import { BankAccountProps } from 'src/services/CardsService'

import { Container } from './styles'
import { shadowBox } from 'src/styles'

interface BankSelectorProps {
  typeAccounts: Array<{
    value: string
    label: string
  }>
  bankNumbers: Array<{
    name: string
    cod: string
  }>
}

function AddBank(props: RouteComponentProps<any, any, BankAccountProps>) {
  const { storeId } = useParams<any>()

  const { typeAccounts, bankNumbers } = useSelector<
    DefaultRootState,
    BankSelectorProps
  >((state) => ({
    typeAccounts: state.app.setup?.store.account_types ?? [],
    bankNumbers: state.app.setup?.store.banks ?? [],
  }))
  const { bankId } = useParams<{ bankId: string }>()
  const bank = props.location.state ?? {}

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BankAccountProps>()

  const {
    openModal,
    loading,
    handleToggleModal,
    handleConfirmCancel,
    handleCreateBankAccount,
  } = useBank(storeId)

  const handleSubmitBank = handleSubmit((data) => {
    handleCreateBankAccount(data)
  })

  return (
    <>
      <Container>
        <View pb={2} flex align='center'>
          <View>
            <Text size={25} fontWeight={700}>
              Dados bancários
            </Text>
          </View>
        </View>

        <form
          style={{ height: 'calc(100% - 40px)' }}
          onSubmit={handleSubmitBank}
        >
          <View height='calc(100% - 110px)' style={shadowBox}>
            <Row>
              <Col xs={4}>
                <View>
                  <Text fontWeight={500} size={12}>
                    Nome da conta *
                  </Text>
                  <View mt={0.8}>
                    <Input
                      {...register('name', {
                        required: 'O nome da conta é obrigatório',
                      })}
                      type='text'
                      validationError={errors.name}
                      messageError={errors.name?.message}
                      defaultValue={bank.name}
                    />
                  </View>
                </View>
              </Col>
              <Col xs={3}>
                <View>
                  <Text fontWeight={500} size={12}>
                    Tipo:
                  </Text>
                  <View mt={0.8}>
                    <Select
                      {...register('type', {
                        required: 'Selecione o tipo de conta',
                      })}
                      validationError={errors.type}
                      messageError={errors.type?.message}
                      defaultValue={bank.type}
                    >
                      {typeAccounts.map((item, index: number) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  </View>
                </View>
              </Col>
              <Col xs={3}>
                <View>
                  <Text fontWeight={500} size={12}>
                    Banco:
                  </Text>
                  <View mt={0.8}>
                    <Select
                      {...register('bank_code', {
                        required: 'Selecione o banco',
                      })}
                      validationError={errors.bank_code}
                      messageError={errors.bank_code?.message}
                      defaultValue={bank.bank_code}
                    >
                      {bankNumbers.map((item, index: number) => (
                        <option key={index} value={item.cod}>
                          {item.name}
                        </option>
                      ))}
                      <option>Sample</option>
                    </Select>
                  </View>
                </View>
              </Col>
            </Row>

            <View mt={1.8}>
              <Row>
                <Col xs={2}>
                  <View>
                    <Text fontWeight={500} size={12}>
                      Agência
                    </Text>
                    <View mt={0.8}>
                      <Input
                        {...register('routing_number', {
                          required: 'Agência é obrigatório',
                        })}
                        type='text'
                        validationError={errors.routing_number}
                        messageError={errors.routing_number?.message}
                        defaultValue={bank.routing_number}
                      />
                    </View>
                  </View>
                </Col>
                <Col xs={3}>
                  <View>
                    <Text fontWeight={500} size={12}>
                      Conta
                    </Text>
                    <View mt={0.8}>
                      <Input
                        {...register('account_number', {
                          required: 'Conta é obrigatório',
                        })}
                        type='text'
                        validationError={errors.account_number}
                        messageError={errors.account_number?.message}
                        defaultValue={bank.account_number}
                      />
                    </View>
                  </View>
                </Col>
              </Row>
            </View>
          </View>

          <View flex justify='flex-end' mt={2}>
            <View width='50%' direction='row'>
              <View width='100%' mr={1}>
                <Button
                  type='reset'
                  label='Cancelar'
                  borderColor='#3097E2'
                  color='#3097E2'
                  background='#fff'
                  onClick={handleToggleModal}
                />
              </View>
              <View width='100%'>
                <Button
                  type='submit'
                  label={bankId ? 'Editar conta' : 'Cadastrar conta'}
                  background='#3097E2'
                  loading={loading}
                />
              </View>
            </View>
          </View>
        </form>
      </Container>
      {openModal && (
        <ModalConfirm
          icon={EmptyBankImage}
          description='Tem certeza que deseja cancelar a edição de dados bancários?'
          labelCancel='Não'
          handleCancel={handleToggleModal}
          labelConfirm='Sim'
          handleConfirm={handleConfirmCancel}
        />
      )}
    </>
  )
}

export default withRouter(AddBank)
