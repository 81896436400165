/* eslint-disable @typescript-eslint/no-unused-vars */
import api, { newApi } from './api'

export const getOrderSWR = async (url: string) => {
  const response = await newApi.get(url)
  return response.data.items
}

export const putAddressOrder = async (
  orderId: string,
  deliveryAddressId: string
) => api.get(`/orders/admin/change-address/${orderId}/${deliveryAddressId}`)

export const getSalesById = async (storeId: string, saleId: string) =>
  api.get(`/stores/${storeId}/orders/${saleId}`)

export const getAcceptOrderByIdSale = async (
  idOrder: string,
  _storeId: string
) => newApi.get(`/admin/orders/${idOrder}`)

export const getSalesCenterAcceptOrderByIdSale = async (idOrder: string) =>
  newApi.get(`/sales-center/orders/${idOrder}`)

export const getAcceptOrder = async (idStore: string, idProposal: string) =>
  newApi.get(`/admin/orders/${idStore}/${idProposal}/accept`)

export const getSaleCenterAcceptOrder = async (
  idStore: string,
  idProposal: string
) => newApi.get(`/stores/${idStore}/orders/${idProposal}/accept`)

interface DeclineProps {
  reason: string
  description: string
}

export const getDeclineOrder = async (
  idStore: string,
  orderId: string,
  data: DeclineProps
) => newApi.post(`/admin/orders/${idStore}/${orderId}/decline`, data)

export const getSaleCenterDeclineOrder = async (
  idStore: string,
  orderId: string,
  data: DeclineProps
) => newApi.post(`/stores/${idStore}/orders/${orderId}/decline`, data)

export const postOrderProposal = async (
  storeId: string,
  orderId: string,
  data: { state: number }
) => newApi.patch(`/stores/${storeId}/orders/${orderId}/change_state`, data)

export const postOrderNf = async (
  idStore: string,
  orderId: string,
  data: any
) => newApi.patch(`/stores/${idStore}/orders/${orderId}/post_nf`, data)

export const putObservationOrder = async (
  orderId: string,
  observation: string
) => api.put(`/orders/admin/${orderId}/observation`, { observation })

export const postProposalCheckout = async (
  idStore: string,
  idProposal: string,
  data: any
) => newApi.patch(`admin/orders/${idStore}/${idProposal}/post_nf`, data)

export const postOrderStatusAdmin = async (idOrder: string, data: any) =>
  newApi.patch(`admin/orders/${idOrder}/change_state/`, data)

export const postOrderStatus = async (
  storeId: string,
  idOrder: string,
  data: any
) => newApi.patch(`stores/${storeId}/orders/${idOrder}/change_state/`, data)

export const getSalesCenterSWR = async (url: string) => {
  try {
    const response = await newApi.get<any, any>(url)

    if (response?.message === 'Request failed with status code 404') {
      throw new Error(response.message)
    }

    return response.data
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const getRestartInvites = async (orderId: string) =>
  newApi.get(`/admin/orders/restart/${orderId}`)

export const getAllstoresInvite = async (orderId: string) =>
  api.get(`/orders/admin/transfer-available-stores/${orderId}`)

export const getTransferInvite = async (orderId: string, storeId: string) =>
  newApi.get(`/admin/orders/transfer/${orderId}/${storeId}`)

export const changePaymentType = async (orderId: string, payload: any) =>
  api.put(`/orders/admin/change-payment-type/${orderId}`, payload)

export const updateAddress = async (orderId: string, payload: any) =>
  newApi.patch(`/admin/orders/${orderId}`, payload)

export const cancelDelivery = async (orderId: string) =>
  newApi.delete(`/admin/orders/${orderId}/cancel_delivery`)

export const retryDelivery = async (orderId: string, scheduleFor?: string) =>
  newApi.get(`/admin/orders/${orderId}/retry_delivery`, {
    params: { scheduleFor },
  })

export const updateDriverData = async (orderId: string, driver: any) =>
  newApi.patch(`/admin/orders/${orderId}/update_delivery_driver`, driver)
