const ADD_NAME = 'userReducer/ADD_NAME'

export interface UserReducerProps {
  nameSearch: string
}

const stateInit = {
  nameSearch: '',
}

export const userReducer = (
  state: UserReducerProps = stateInit,
  { type, payload }: any
) => {
  switch (type) {
    case ADD_NAME:
      return { ...state, nameSearch: payload }
    default:
      return state
  }
}
export const addNameSearchAction = (payload: string) => ({
  type: ADD_NAME,
  payload,
})
